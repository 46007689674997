<template>
  <div
    v-if="dragging"
    class="absolute inset-0 z-[100]"
    :class="[dragging ? 'flex items-center justify-center bg-gray-800/90' : '']"
    @drop.prevent="handleDragDropFiles"
    @dragover.prevent="hover"
    @dragleave.prevent="stopHovering"
  >
    <div
      v-show="dragging"
      class="pointer-events-none rounded-lg max-w-lg p-3 bg-indigo-600"
    >
      <div
        class="relative h-full w-full flex flex-col space-y-3 justify-center items-center rounded-lg p-6 border-2 border-dashed border-gray-200"
      >
        <div
          class="absolute top-0 left-0 bg-indigo-800 h-full overflow-hidden direct-upload__progress"
          :style="progressWidth"
        />
        <div class="text-gray-200 size-14">
          <svg class="" stroke="currentColor" fill="none" viewBox="0 0 48 48">
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div v-if="instantUpload" class="text-2xl font-semibold text-gray-200">
          Instant Upload Mode!
        </div>
        <div v-else class="text-2xl font-semibold text-gray-200">
          Upload to ...
        </div>
        <div class="text-center text-gray-200 text-sm">
          <div v-if="instantUpload">Drop the file to upload immediately.</div>
          <template v-else>
            <div>You can add comments before uploading.</div>
            <div>Hold shift to upload directly.</div>
          </template>
        </div>
        <div
          class="flex items-center space-x-1 font-medium text-xs text-red-400"
        >
          <div class="flex-shrink-0">
            <ExclamationTriangleIcon
              class="h-5 w-5 text-red-400"
              aria-hidden="true"
            />
          </div>
          <span> Only upload files that you have permission to use. </span>
        </div>
      </div>
    </div>
    <input
      ref="filesInput"
      type="file"
      :multiple="true"
      :accept="'image/*,' + all.join(',')"
      :data-direct-upload-url="fileUploadUrl"
      class="hidden"
      name="Files"
      @change="handleInputFiles"
      data-test="upload-files-input"
    />
  </div>
</template>

<script setup>
import Uploader from "@/components/crowdsourcing/uploader";
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
import { all } from "@/assets/documentFileTypes";
import { useUserStore } from "@/stores/user";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";
import _ from "lodash";

const wePanelStore = useWePanelStore();
const { dragging, instantUpload } = storeToRefs(wePanelStore);
const userStore = useUserStore();
const { emailUnverified } = storeToRefs(userStore);

const files = ref([]);
const filesInput = ref(null);
const uploadedFiles = ref([]);
const pendingFileCount = ref(0);
const fileUploadUrl = "/rails/active_storage/direct_uploads";
const uploading = ref(false);
const uploadingProgress = ref(null);
const uploadingTotal = ref(null);

const progressWidth = computed(() => {
  if (uploading.value && uploadingTotal.value) {
    const progress = (uploadingProgress.value / uploadingTotal.value) * 100;

    return `width: ${progress}%;`;
  } else {
    return "";
  }
});

const fileNames = computed(() => uploadedFiles.value.map((f) => f.name));
const fileSizeLimit = computed(() => 100);

const filesToPersist = computed(() =>
  _.differenceBy(files.value, uploadedFiles.value, "signedId"),
);
const readyToPersist = computed(() => {
  return (
    filesToPersist.value.length > 0 &&
    pendingFileCount.value === 0 &&
    filesToPersist.value.every((file) => !file.errorMessage)
  );
});

watch(filesToPersist, async () => {
  if (readyToPersist.value) {
    if (instantUpload.value) {
      console.log("TODO: SEND TO SERVER AS INSTANT MESSAGE.");
    } else {
      console.log(
        "TODO: MESSAGING FILES READY TO PERSIST IN WEPANEL STORE. SEND TO SERVER WITH MESSAGE SUBMIT.",
      );
    }
    console.log("set dragging false");
    dragging.value = false;
  }
});

function hover(e) {
  e.dataTransfer.dropEffect = "move";
  dragging.value = true;
}
function stopHovering() {
  dragging.value = false;
}

async function handleDragDropFiles(e) {
  console.log("handle drag drop", e);
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else if (dragging.value) {
    let droppedFiles = e.dataTransfer.files;

    if (!droppedFiles) return;
    const toLoad = [...droppedFiles];

    pendingFileCount.value = toLoad.length;
    for (const file of toLoad) {
      await loadFiles(file);
    }
  }
}
function handleInputFiles() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    const toLoad = Array.from(filesInput.value.files);

    pendingFileCount.value = toLoad.length;
    toLoad.forEach(loadFiles);
    filesInput.value.value = null;
  }
}
async function loadFiles(file) {
  if (_.includes(fileNames, file.name)) return;

  await processFile(file);
}
async function processFile(file) {
  const fileSize = (file.size / 1024 / 1024).toFixed(4); // MB

  if (fileSize > fileSizeLimit.value) {
    files.value.push(
      Object.assign(
        {},
        {
          name: file.name,
          size: file.size,
          signedId: null,
          errorMessage: `is > ${fileSizeLimit.value}MB.`,
        },
      ),
    );
    pendingFileCount.value--;
    setTimeout(() => {
      remove(file);
    }, 3500);
  } else {
    await uploadFile(file);
  }
}
async function uploadFile(file) {
  const uploader = new Uploader(
    file,
    fileUploadUrl,
    files,
    pendingFileCount,
    uploading,
    uploadingProgress,
    uploadingTotal,
  );
  const reader = new FileReader();

  reader.addEventListener("load", () => {
    // console.log("loaded");
  });

  reader.readAsDataURL(file);

  await uploader.directUpload();
}
function remove(file) {
  const newFiles = files.value.filter((f) => f.name !== file.name);

  files.value = newFiles;

  attemptDirectRemove(file);
}

let channelMessageFiles = null;
function attemptDirectRemove(file) {
  // TODO: Store should maintain message files state on a per-channel basis.
  if (channelMessageFiles) {
    const newFiles = channelMessageFiles.filter((f) => f.name !== file.name);

    channelMessageFiles = newFiles;
  }
}
</script>
