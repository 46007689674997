import {
  PencilIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  DocumentDuplicateIcon,
  LinkIcon,
  TrashIcon,
  FlagIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/vue/20/solid";
import { useUserStore } from "@/stores/user";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useWePanelStore } from "@/stores/wePanel";
import { useModalStore } from "@/stores/modal";
import { defineStore, storeToRefs, acceptHMRUpdate } from "pinia";
import { computed, markRaw } from "vue";
import _ from "lodash";

export const useWeMessageOutputStore = defineStore("weMessageOutput", () => {
  const secondaryPanelStore = useSecondaryPanelStore();
  const userStore = useUserStore();
  const { signedIn } = storeToRefs(userStore);
  const wePanelStore = useWePanelStore();
  const { mainContent } = storeToRefs(wePanelStore);
  const modalStore = useModalStore();
  const { confirmationPayload } = storeToRefs(modalStore);
  const actions = computed(() => {
    return _.compact([
      {
        name: "Edit",
        icon: markRaw(PencilIcon),
        inline: true,
        moreActionsSection: "00_directActions",
        handler: () => {
          const callback = () => {};
          registrationGuard(callback);
        },
      },
      {
        name: "Reply",
        icon: markRaw(ArrowUturnLeftIcon),
        inline: true,
        moreActionsSection: "00_directActions",
        handler: () => {
          const callback = () => {};
          registrationGuard(callback);
        },
      },
      {
        name: "Forward",
        icon: markRaw(ArrowUturnRightIcon),
        inline: true,
        moreActionsSection: "00_directActions",
        handler: () => {
          const callback = () => {};
          registrationGuard(callback);
        },
      },
      threadable.value
        ? {
            name: "Create Thread",
            icon: markRaw(ChatBubbleLeftRightIcon),
            inline: false,
            moreActionsSection: "00_directActions",
            handler: () => {
              const callback = () => {};
              registrationGuard(callback);
            },
          }
        : null,
      {
        name: "Copy Text",
        icon: markRaw(DocumentDuplicateIcon),
        inline: false,
        moreActionsSection: "01_secondaryActions",
        handler: () => {
          const callback = () => {};
          registrationGuard(callback);
        },
      },
      {
        name: "Copy Message Link",
        icon: markRaw(LinkIcon),
        inline: false,
        moreActionsSection: "01_secondaryActions",
        handler: () => {
          const callback = () => {};
          registrationGuard(callback);
        },
      },
      {
        name: "Delete Message",
        icon: markRaw(TrashIcon),
        inline: false,
        moreActionsSection: "02_confirmationActions",
        handler: () => {
          const deleteCallback = () => {};
          const callback = () => {
            confirmationPayload.value = {
              title: "Delete Message",
              message: "Are you sure you want to delete this message?",
              affirmText: "Delete",
              affirmCallback: deleteCallback,
            };
          };

          registrationGuard(callback);
        },
      },
      {
        name: "Report Message",
        icon: markRaw(FlagIcon),
        inline: false,
        moreActionsSection: "02_confirmationActions",
        handler: () => {
          const reportCallback = () => {};
          const callback = () => {
            confirmationPayload.value = {
              title: "Report Message",
              message: "Are you sure you want to report this message?",
              affirmText: "Report",
              affirmCallback: reportCallback,
            };
          };

          registrationGuard(callback);
        },
      },
    ]);
  });

  const inlineActions = computed(() => {
    return actions.value.filter((action) => action.inline);
  });

  const groupedMoreActions = computed(() => {
    return _.groupBy(actions.value, "moreActionsSection");
  });

  const threadable = computed(
    () =>
      !_.includes(["directMessage", "thread"], mainContent.value?.contentType),
  );

  function registrationGuard(callback) {
    if (signedIn.value) {
      callback();
    } else {
      secondaryPanelStore.promptToRegister();
    }
  }

  return {
    inlineActions,
    groupedMoreActions,
    threadable,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useWeMessageOutputStore, import.meta.hot),
  );
}
