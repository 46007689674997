/* global L */
import { ref, computed, toRaw } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { createChannel, removeChannel } from "@/channels/channel";
import { useUserStore } from "@/stores/user";
import { useMainMapStore } from "@/stores/mainMap";
import locationMarker from "@/components/maps/locationMarker";
import userMarker from "@/components/maps/userMarker";
import "leaflet.animatedmarker/src/AnimatedMarker";
import _ from "lodash";

export const useUserGeographiesStore = defineStore("userGeographies", () => {
  const editingColorCodes = [
    "#38bdf8",
    "#2dd4bf",
    "#a3e635",
    "#fbbf24",
    "#fb923c",
    "#f472b6",
  ];
  const editingColorNames = [
    "sky",
    "teal",
    "lime",
    "amber",
    "orange",
    "pink",
  ];

  const mapStore = useMainMapStore();
  const userStore = useUserStore();
  const { currentUser } = storeToRefs(userStore);
  const userGeographies = ref({});
  const userTokens = computed(() => _.keys(userGeographies.value));
  const editingUserTokens = computed(() => {
    const filtered = _.map(
      userGeographies.value,
      function ({ pendingLayerId }, token) {
        if (pendingLayerId) {
          return token;
        } else {
          return null;
        }
      },
    );

    return _.compact(filtered);
  });
  function markerColor() {
    const size = _.size(editingUserTokens.value);
    const numerator = size > 0 ? size - 1 : 0;
    const modIndex = numerator % 6;

    return editingColorNames[modIndex];
  }
  function lineColor() {
    const size = _.size(editingUserTokens.value);
    const numerator = size > 0 ? size - 1 : 0;
    const modIndex = numerator % 6;

    return editingColorCodes[modIndex];
  }

  function updateUsers(intents, mapStore, mapFeatureGroup, documentationStore) {
    const incomingTokens = intents.map((geographyIntent) =>
      _.get(geographyIntent, "user.token"),
    );
    const orphanedTokens = _.difference(userTokens.value, incomingTokens);
    disconnectChannelsFor(orphanedTokens, mapFeatureGroup);

    intents.forEach((geographyIntent) => {
      const token = _.get(geographyIntent, "user.token");
      const existingUser = userGeographies.value[token];
      if (existingUser) {
        updateExistingUserIntent(
          geographyIntent,
          mapStore,
          mapFeatureGroup,
          documentationStore,
        );
      } else {
        const icon = userMarker({
          geographyIntent,
          mapStore,
          documentationStore,
        });

        let workspaceMarker = null;

        if (icon && currentUser.value?.token !== token) {
          workspaceMarker = L.marker(
            [geographyIntent.lat, geographyIntent.lng],
            { icon, zIndexOffset: -9999 },
          );
          mapFeatureGroup.addLayer(workspaceMarker);
        }

        userGeographies.value[token] = {
          layerId: workspaceMarker
            ? mapFeatureGroup.getLayerId(workspaceMarker)
            : null,
          pendingLayerId: null,
          connectingLineId: null,
          geographyIntent,
          channel: createChannel(
            {
              channel: "UserGeographiesChannel",
              userToken: token,
            },
            {
              connected() {},
              received(data) {
                if (currentUser.value?.token !== token) {
                  switch (data.action) {
                    case "create":
                      createPendingMarker(mapFeatureGroup, token, data);
                      break;
                    case "remove":
                      removePendingMarker(mapFeatureGroup, token);
                      break;
                    case "rename":
                      renamePendingMarker(mapFeatureGroup, token, data);
                      break;
                    case "relocate":
                      relocatePendingMarker(mapFeatureGroup, token, data);
                      break;
                  }
                }
                updateExistingUserIntent(
                  data.geographyIntent,
                  mapStore,
                  mapFeatureGroup,
                  documentationStore,
                );
              },
            },
          ),
        };
      }
    });
  }

  function createPendingMarker(mapFeatureGroup, token, { lat, lng }) {
    // console.log("create", token, lat, lng);
    const existingUserChannel = userGeographies.value[token];
    if (existingUserChannel) {
      const mapMarker = L.marker([lat, lng]);
      mapFeatureGroup.addLayer(mapMarker);

      existingUserChannel.pendingLayerId =
        mapFeatureGroup.getLayerId(mapMarker);

      const color = markerColor();
      const markerIcon = locationMarker({
        classes: `h-6 w-6 bg-${color}-400 hover:bg-${color}-500 focus:ring-${color}-300`,
        interactive: false,
        mapStore,
      });
      mapMarker.setIcon(markerIcon);

      // create line from user to pending property
      const userLatLng = existingUserChannel.geographyIntent;
      const line = L.polyline(
        [
          [lat, lng],
          [userLatLng.lat, userLatLng.lng],
        ],
        { color: lineColor(), weight: 6, dashArray: "5, 10" },
      );
      mapFeatureGroup.addLayer(line);
      line.bindTooltip("User adding a new property").openTooltip();
      existingUserChannel.connectingLineId = mapFeatureGroup.getLayerId(line);
    }
  }
  function relocatePendingMarker(mapFeatureGroup, token, { lat, lng }) {
    // console.log("relocate", token, lat, lng);
    const existingUserChannel = userGeographies.value[token];
    if (existingUserChannel?.pendingLayerId) {
      const marker = mapFeatureGroup.getLayer(
        existingUserChannel.pendingLayerId,
      );
      const line = mapFeatureGroup.getLayer(
        existingUserChannel.connectingLineId,
      );
      line.closeTooltip();
      const latLng = L.latLng(lat, lng);

      marker.setLatLng(latLng);
      const userLatLng = existingUserChannel.geographyIntent;
      line.setLatLngs([latLng, [userLatLng.lat, userLatLng.lng]]);
      line.openTooltip();
    }
  }
  function renamePendingMarker(mapFeatureGroup, token, { name }) {
    const existingUserChannel = userGeographies.value[token];
    // console.log("rename", token, name, existingUserChannel);
    if (existingUserChannel?.pendingLayerId) {
      const marker = mapFeatureGroup.getLayer(
        existingUserChannel.pendingLayerId,
      );

      if (marker.isPopupOpen()) {
        marker.setTooltipContent(name);
      } else {
        marker.bindTooltip(name, { permanent: true }).openTooltip();
      }
    }
  }
  function removePendingMarker(mapFeatureGroup, token) {
    // console.log("remove", token);
    const existingUserChannel = userGeographies.value[token];
    if (existingUserChannel?.pendingLayerId) {
      mapFeatureGroup.removeLayer(existingUserChannel.pendingLayerId);
      mapFeatureGroup.removeLayer(existingUserChannel.connectingLineId);
      existingUserChannel.pendingLayerId = null;
      existingUserChannel.connectingLineId = null;
    }
  }

  function updateExistingUserIntent(
    geographyIntent,
    mapStore,
    mapFeatureGroup,
    documentationStore,
  ) {
    const token = _.get(geographyIntent, "user.token");
    const existingUser = userGeographies.value[token];
    if (existingUser) {
      if (existingUser.layerId) {
        // create line
        const line = L.polyline([
          [existingUser.geographyIntent.lat, existingUser.geographyIntent.lng],
          [geographyIntent.lat, geographyIntent.lng],
        ]);
        // create icon
        const icon = userMarker({
          geographyIntent,
          mapStore,
          documentationStore,
        });
        let animatedMarker = null;

        if (icon) {
          // animate marker
          animatedMarker = L.animatedMarker(line.getLatLngs(), {
            icon,
            zIndexOffset: -9999,
          });
          mapFeatureGroup.removeLayer(existingUser.layerId);
          mapFeatureGroup.addLayer(animatedMarker);
          existingUser.layerId = mapFeatureGroup.getLayerId(animatedMarker);
        }
      }

      existingUser.geographyIntent = geographyIntent;
    }
  }

  function disconnectChannelsFor(orphanedTokens, mapFeatureGroup) {
    orphanedTokens.forEach((token) => {
      const intent = _.get(userGeographies.value, token);

      if (intent) {
        const rawChannel = toRaw(intent.channel);
        removeChannel(rawChannel);

        if (intent.layerId) {
          mapFeatureGroup.removeLayer(intent.layerId);
        }

        delete userGeographies.value[token];
      }
    });
  }

  function addLayerIdToUser(token, layerId) {
    userGeographies.value[token].layerId = layerId;
  }

  return {
    userGeographies,
    updateUsers,
    addLayerIdToUser,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserGeographiesStore, import.meta.hot),
  );
}
