<template>
  <HeaderContainer>
    <header class="flex-grow">
      <div class="grid grid-cols-1" :class="[expandedView ? 'hidden' : '']">
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select
          v-model="networkTab"
          aria-label="Select a tab"
          class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
        >
          <option
            v-for="tab in networkTabs"
            :key="tab.name"
            :value="tab.name"
            :selected="tab.name === networkTab"
          >
            {{ tab.name }}
          </option>
        </select>
        <ChevronDownIcon
          class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
          aria-hidden="true"
        />
      </div>
      <div
        class=""
        :class="[expandedView ? 'flex items-center justify-between' : 'hidden']"
      >
        <div class="flex items-center gap-4">
          <div class="flex items-center space-x-2">
            <UserGroupIcon class="size-5 text-gray-500" />
            <span class="text-gray-500 font-medium">Network</span>
          </div>
          <span class="text-xl text-gray-500 font-bold">&middot;</span>
          <nav class="flex space-x-3" aria-label="Tabs">
            <a
              v-for="tab in networkTabs"
              :key="tab.name"
              :href="tab.href"
              :class="[
                tab.name === networkTab
                  ? 'bg-gray-300 text-gray-700 font-bold'
                  : 'text-gray-500 hover:text-gray-700 font-medium',
                'rounded-md px-2 py-1.5 text-sm',
              ]"
              @click.prevent="networkTab = tab.name"
              >{{ tab.name }}</a
            >
            <a
              href=""
              :class="[
                'rounded-md px-2 py-1.5 text-sm font-medium bg-indigo-500 text-gray-50 hover:bg-indigo-600',
              ]"
              @click.prevent
              >Add Friend</a
            >
          </nav>
        </div>
        <button
          v-tooltip.top-end="'Create DM'"
          type="button"
          class="inline-flex items-center text-gray-500 hover:text-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
        >
          <ChatBubbleOvalLeftIcon class="size-5" />
        </button>
      </div>
    </header>
  </HeaderContainer>
</template>

<script setup>
import HeaderContainer from "@/components/we/main-content/HeaderContainer.vue";
import { ChevronDownIcon } from "@heroicons/vue/16/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { ChatBubbleOvalLeftIcon, UserGroupIcon } from "@heroicons/vue/20/solid";

const wePanelStore = useWePanelStore();
const { expandedView, networkTab, networkTabs } = storeToRefs(wePanelStore);
</script>
