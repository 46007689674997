<template>
  <div
    @click="emit('clicked')"
    :data-test="testKey"
    :class="
      dataField
        ? `flex flex-col flex-grow items-start justify-start truncate pointer-events-auto my-0.5 mx-2 rounded-lg ${outerStyling}`
        : 'mx-2 my-0.5'
    "
  >
    <VMenu
      v-if="dataField"
      :disabled="editingMode"
      class="w-full h-2"
      theme="diagram-popup"
    >
      <div class="h-full w-full" />
      <template #popper>
        <DataFieldInfoPopup :data-field="dataField" />
      </template>
    </VMenu>
    <div
      v-if="dataField && floorAreas.length > 0"
      class="w-full h-full flex flex-col divide-y-2 divide-white"
    >
      <VMenu
        :disabled="editingMode"
        theme="diagram-popup"
        v-for="(floorDataField, index) in floorAreas"
        :key="`air-right-floor-area-${index}`"
        :test-key="`air-right-floor-area-${index}`"
      >
        <NoLevelFloorAreaBlock
          :floor-area-data-field="floorDataField"
          :color-style="floorStyle(floorDataField)"
          :clickable="true"
          @clicked="floorlessClickHandler"
          @refetch="emit('refetch')"
        />
        <template #popper>
          <DataFieldInfoPopup :data-field="floorDataField" />
        </template>
      </VMenu>
    </div>
  </div>
</template>

<script setup>
import NoLevelFloorAreaBlock from "@/components/property-diagram/NoLevelFloorAreaBlock.vue";
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import { floorAreaColorStyle } from "@/components/property-diagram/floorAreaColorStyles";
import selfSelected from "@/components/property-diagram/diagramBlockSelfSelected";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { usePropertyDiagramContentSelectionStore } from "@/stores/propertyDiagramContentSelection";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps([
  "dataField",
  "floorAreas",
  "backgroundStyle",
  "testKey",
]);
const emit = defineEmits(["clicked", "refetch"]);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  propertyDiagramSelected,
  propertyDiagramSelectedPropertyId,
  editingMode,
  legendLayer,
  authors,
} = storeToRefs(propertyDiagramStore);
const diagramContentSelectionStore = usePropertyDiagramContentSelectionStore();

const airRight = computed(() => {
  return _.get(props.dataField, "fieldContent", null);
});
const outerStyling = computed(() => {
  return (
    props.backgroundStyle ||
    "text-gray-500 font-medium border-2 border-dashed border-gray-300 bg-cyan-300 hover:border-gray-400"
  );
});

function isSelected(dataField) {
  return (
    selfSelected(
      _.get(dataField, "fieldContent"),
      propertyDiagramSelected.value,
    ) || diagramContentSelectionStore.remoteSelected(dataField)
  );
}

function floorStyle(dataField) {
  if (legendLayer.value.key === "authorship") {
    const selectedText = "text-gray-700 font-semibold";
    const unselectedText = "text-gray-500 font-medium";
    const authorColor = authors.value[dataField.userId]?.color || "gray";

    return isSelected(dataField)
      ? `${selectedText} border-2 bg-${authorColor}-300`
      : `${unselectedText} border-2 bg-${authorColor}-200 hover:bg-${authorColor}-300`;
  } else {
    return floorAreaColorStyle(dataField, isSelected(dataField));
  }
}

function floorlessClickHandler({ dataField, recordType }) {
  diagramContentSelectionStore.singleClickSelect({
    propertyId: propertyDiagramSelectedPropertyId.value,
    data: _.merge(
      {},
      { dataField, recordType },
      {
        propertyRight: airRight.value,
      },
    ),
  });
}
</script>
