<template>
  <div>
    <ExpandedMessageInput v-if="isDesktop" />
    <MobileMessageInput v-else />
  </div>
</template>

<script setup>
import MobileMessageInput from "@/components/we/message-input/MobileMessageInput.vue";
import ExpandedMessageInput from "@/components/we/message-input/ExpandedMessageInput.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
</script>
