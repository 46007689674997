import {
  slabToSlabLevels,
  totalSlabToSlabHeight,
} from "@/assets/landCoveringLevelHeight";
import { computed, ref, watch, nextTick, markRaw } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import selfSelected from "@/components/property-diagram/diagramBlockSelfSelected";
import { useUserStore } from "@/stores/user";
import { useRoute, useRouter } from "vue-router";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { usePropertyUsersChannelsStore } from "@/stores/propertyUsersChannels";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useNotificationsStore } from "@/stores/notifications";
import { useAvailableValidationsChannelStore } from "@/stores/availableValidationsChannel";
import LandCoveringDiagramColumn from "@/components/property-diagram/LandCoveringDiagramColumn.vue";
import LandCoveringUnitLayeredRightDiagramColumn from "@/components/property-diagram/LandCoveringUnitLayeredRightDiagramColumn.vue";
import LandCoveringUnitRightDiagramColumn from "@/components/property-diagram/LandCoveringUnitRightDiagramColumn.vue";
import LayeredPropertyRights from "@/components/property-diagram/LayeredPropertyRights.vue";
import PropertyRightDiagramColumn from "@/components/property-diagram/PropertyRightDiagramColumn.vue";
import UnlicensedContentWarning from "@/components/users/subscribe-prompts/UnlicensedContentWarning.vue";
import unlockableInnerContent from "@/components/crowdsourcing/unlockableInnerContent";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import api from "@/router/api";
import _ from "lodash";

export const usePropertyDiagramStore = defineStore("propertyDiagram", () => {
  const legendLayer = ref({
    name: "Physical info",
    key: "physical",
  });
  const authorColors = ["sky", "teal", "lime", "amber", "orange", "pink"];
  const authorIds = computed(() => {
    return _.uniq(diagramAllVisibleFields.value.map(({ userId }) => userId));
  });
  const authors = ref({});
  const authorDatapointCounts = computed(() => {
    return _.countBy(diagramAllVisibleFields.value, "userId");
  });
  async function fetchAuthor(userId) {
    if (authors.value[userId]) return;

    const userResponse = await api.get(`datapoint_authors/${userId}`);

    if (userResponse?.data) {
      const coloredUser = _.merge({}, userResponse.data, {
        color: authorColors[_.size(authors.value)],
      });
      authors.value[userId] = coloredUser;
    }
  }
  const leftLabelColumnPixelWidth = 50;
  const fetchingDiagramData = ref(false);
  const innerContentDisplayable = ref(true);
  const defaultContentPagy = {
    PropertyRight: {},
    PropertyEnhancement: {},
    LandCovering: {},
    LandCoveringUnitRight: {},
    LandCoveringLevel: {},
    VerticalOpening: {},
    VerticalGrouping: {},
    VerticalVisualOrdering: {},
    HorizontalVisualOrdering: {},
    FloorArea: {},
    FloorAreaLayout: {},
  };
  const diagramContentPagy = ref(_.merge({}, defaultContentPagy));
  function diagramContentFor({
    propertyId,
    landCoveringId,
    landCoveringLevelId,
    fieldName,
  }) {
    switch (fieldName) {
      case "PropertyRight":
      case "PropertyEnhancement":
      case "FloorAreaLayout":
      case "LandCovering":
        return diagramContentPagy.value?.[fieldName]?.[`property${propertyId}`];
      case "LandCoveringLevel":
      case "LandCoveringUnitRight":
      case "VerticalOpening":
      case "VerticalGrouping":
      case "VerticalVisualOrdering":
      case "HorizontalVisualOrdering":
        return diagramContentPagy.value?.[fieldName]?.[landCoveringId];
      case "FloorArea": {
        if (landCoveringLevelId) {
          return diagramContentPagy.value?.[fieldName]?.[
            `landCoveringLevel${landCoveringLevelId}`
          ];
        } else if (landCoveringId) {
          return diagramContentPagy.value?.[fieldName]?.[
            `landCovering${landCoveringId}`
          ];
        } else {
          return diagramContentPagy.value?.[fieldName]?.[
            `property${propertyId}`
          ];
        }
      }
      default:
        return null;
    }
  }
  function pagyFor({
    propertyId,
    landCoveringId,
    landCoveringLevelId,
    fieldName,
  }) {
    return diagramContentFor({
      propertyId,
      landCoveringId,
      landCoveringLevelId,
      fieldName,
    })?.pagy;
  }
  function cleanUrl(url) {
    return url.replace("/api/v1/", "");
  }
  async function loadPaginatedRecords({
    state,
    propertyId,
    landCoveringId,
    landCoveringLevelId,
    fieldName,
  }) {
    // console.log(
    //   "pagination attempt",
    //   fieldName,
    //   propertyId,
    //   landCoveringId,
    //   landCoveringLevelId,
    // );
    let diagramContent = diagramContentFor({
      propertyId,
      landCoveringId,
      landCoveringLevelId,
      fieldName,
    });

    if (diagramContent?.pagy?.next && !diagramContent.loadingEvents) {
      diagramContent.loadingEvents = true;
      const endpoint = cleanUrl(diagramContent?.pagy?.next_url);
      try {
        api.get(endpoint).then((json) => {
          const { data, pagy } = json.data;

          diagramContent.data = _.uniqBy(
            _.concat(diagramContent.data, data),
            "localId",
          );
          diagramContent.pagy = pagy;
          interceptablePatch(data);
          if (data.length < 3) {
            state.complete();
            diagramContent.loadingEvents = false;
            diagramContent.loaded = true;
            // console.log("pagination fully complete", fieldName, propertyId);
          } else {
            state.loaded();
            diagramContent.loadingEvents = false;
          }

          if (fieldName === "LandCovering") {
            data.forEach((dataField) => {
              fetchPaginatedLandCoveringData(dataField.fieldContentId);
            });
          } else if (fieldName === "LandCoveringLevel") {
            data.forEach((dataField) => {
              fetchLandCoveringLevelFloorAreas(dataField.fieldContentId);
            });
          } else if (fieldName === "FloorArea") {
            addFloorAreaUsageData(data);
            fetchSpaceUsages(data);
          } else if (fieldName === "PropertyRight") {
            addPropertyRightDetails(data);
          }
        });
      } catch (error) {
        state.error();
        diagramContent.loadingEvents = false;
      }
    } else {
      // console.log(
      //   "pagination complete without ever fetching",
      //   fieldName,
      //   propertyId,
      // );
      state.complete();
    }
  }
  const diagramAllFetchedFields = ref([]);
  const propertyPatchableTimelineEvents = ref([]);
  const selectableContent = ref(null);
  const editingMode = ref(false);
  const upgradeInProgress = ref(false);
  const { resetRequired, upgradeSuccessful } = storeToRefs(useUnlockerStore());
  const { modalPayload } = storeToRefs(useModalStore());
  const { asOfMilliseconds } = storeToRefs(useTimeTravelStore());
  const propertyFieldsStore = usePropertyFieldsStore();
  const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
  const notificationsStore = useNotificationsStore();
  const availableValidationsChannelStore =
    useAvailableValidationsChannelStore();
  const { activeValidationChangeGroupId } = storeToRefs(
    availableValidationsChannelStore,
  );
  const propertyUsersChannelStore = usePropertyUsersChannelsStore();
  const { propertyChannelSubscriptions, thirdPartyEditingUsersCount } =
    storeToRefs(propertyUsersChannelStore);
  const userStore = useUserStore();
  const { signedIn, currentUser } = storeToRefs(userStore);
  const guestProfileStore = useGuestProfileStore();
  const { geographyIntents } = storeToRefs(guestProfileStore);

  const viewDraftFields = ref(true);
  function filterDraft({ state, safezone, authored, unmasked, userId }) {
    if (viewDraftFields.value) {
      return _.includes(["approved", "proposed", "staked"], state);
    } else if (
      signedIn.value &&
      currentUser.value.id === userId &&
      (safezone || authored)
    ) {
      return unmasked;
    } else {
      return state === "approved";
    }
  }
  const diagramVisibilitySettings = ref({});
  const diagramAllVisibleFields = computed(() => {
    return diagramAllFetchedFields.value
      .filter((dataField) => {
        if (
          _.includes(
            ["unused_development_rights", "fee_simple"],
            dataField.fieldContentSubType,
          )
        ) {
          const operativePropertyId =
            dataField.fieldContentSubType === "unused_development_rights"
              ? propertyDataFieldFor(dataField.joiningContentId)?.fieldContent
                  ?.airLayerPropertyId
              : dataField.joiningContentId;
          const groundLayerPropertyId =
            dataField.fieldContentSubType === "unused_development_rights"
              ? dataField.joiningContentId
              : null;
          const displayGroundLayerAirRights =
            dataField.fieldContentSubType === "unused_development_rights" &&
            _.includes(
              propertyDiagramPropertyIds.value,
              groundLayerPropertyId,
            ) &&
            !_.includes(propertyDiagramPropertyIds.value, operativePropertyId);
          const scopedVisibilitySettings =
            diagramVisibilitySettings.value[operativePropertyId];
          return (
            scopedVisibilitySettings?.feeSimples?.[dataField.fieldContentId] ||
            displayGroundLayerAirRights
          );
        } else if (dataField.fieldContentType === "LandCovering") {
          const scopedVisibilitySettings =
            diagramVisibilitySettings?.value[dataField.joiningContentId];
          return scopedVisibilitySettings?.landCoverings?.[
            dataField.fieldContentId
          ];
        } else if (dataField.propertyId) {
          return _.includes(
            propertyDiagramPropertyIds.value,
            dataField.propertyId,
          );
        } else {
          return true;
        }
      })
      .filter(filterDraft);
  });
  const propertyDiagramPropertyDataFields = ref({});
  const hiddenGroundLayerPropertyDataFields = ref({});
  const propertyDiagramSelectedLandCoveringId = ref(null);
  const propertyDiagramSelectedPropertyId = ref(null);
  const propertyDiagramSelectedParcelId = ref(null);
  const propertyDiagramPropertyIds = ref([]);
  const propertyDiagramRefetch = ref(null);
  const propertyDiagramEditAction = ref(null);
  const propertyDiagramSelected = ref(null);
  const lockedForEditing = ref(false);
  const propertyDiagramActionTrigger = ref(null);
  const propertyDiagramAdding = ref({
    propertyId: null,
    additionType: null,
    additionSubType: null,
    existingId: null,
  });
  const propertyDiagramLastAdded = ref(null);
  const propertyDiagramAdded = ref([]);
  const propertyDiagramAddedTypes = computed(() =>
    _.uniq(
      propertyDiagramAdded.value.map(
        ({ fieldContentType }) => fieldContentType,
      ),
    ),
  );
  const selectedTimelineEvent = ref(null);
  const addingBlocks = computed(() => !!propertyDiagramAdding.value.propertyId);
  const addedBlocks = computed(
    () =>
      propertyDiagramAdded.value.length > 0 &&
      (propertyDiagramAdding.value.additionType ||
        propertyDiagramAdding.value.additionSubType === "batch edit"),
  );
  const editingAction = computed(() =>
    _.get(propertyDiagramEditAction.value, "action"),
  );
  const applyEditingLock = computed(
    () => addingBlocks.value || addedBlocks.value || editingAction.value,
  );
  const propertyDataField = computed(() => {
    if (_.size(propertyDiagramPropertyDataFields.value) > 0) {
      return _.find(propertyDiagramPropertyDataFields.value, function (df, id) {
        return id == propertyDiagramSelectedPropertyId.value;
      });
    } else {
      return null;
    }
  });
  const propertySelectedRecordDataField = computed(() => {
    const recordType = _.get(
      propertyDiagramSelected.value,
      "dataField.fieldContentType",
      "Property",
    );

    if (recordType === "Property" && propertyDataField.value) {
      return propertyDataField.value;
    } else {
      return _.get(propertyDiagramSelected.value, "dataField");
    }
  });

  const route = useRoute();
  const routeName = computed(() => route.name);
  const propertyIdParam = computed(() => {
    return _.get(route, "params.propertyId", null);
  });
  const propertyIdParamInDiagram = computed(() => {
    return _.includes(propertyDiagramPropertyIds.value, propertyIdParam.value);
  });
  const selectedPropertyIsAirLayer = computed(() => {
    if (propertyDiagramSelectedPropertyId.value) {
      const dataField = propertyDataFieldFor(
        propertyDiagramSelectedPropertyId.value,
      );
      return dataField?.fieldContentSubType === "unused_development_rights";
    } else {
      return false;
    }
  });

  const fetchedPopupFields = ref({});
  const fetchedPopupKeys = computed(() => _.keys(fetchedPopupFields.value));

  // POP-UP DATA FETCHING

  async function fetchFloorAreaPopupContext(floorAreaId) {
    const spaceUsageIds = await fetchSpaceUserDataField(floorAreaId);

    if (_.isArray(spaceUsageIds) && spaceUsageIds.length > 0) {
      fetchSpaceUsageDataField(spaceUsageIds, floorAreaId);
    }
  }

  async function fetchSpaceUserDataField(floorAreaId) {
    const json = await api.get(
      `space_users/?content_type=FloorArea&content_id=${floorAreaId}&as_of=${asOfMilliseconds.value}`,
    );

    if (json?.data) {
      const fetchKey = `FloorArea${floorAreaId}_SpaceUsers`;
      fetchedPopupFields.value[fetchKey] = json.data;

      return json.data.map(({ decoratingContentId }) => decoratingContentId);
    } else {
      return null;
    }
  }

  async function fetchSpaceUsageDataField(spaceUsageIds, floorAreaId) {
    for (const id of spaceUsageIds) {
      const json = await api.get(`space_usages/${id}`);

      if (json?.data) {
        const fetchKey = `FloorArea${floorAreaId}_SpaceUsage${id}`;
        fetchedPopupFields.value[fetchKey] = json.data;
      }
    }
  }

  watch(resetRequired, () => {
    if (upgradeInProgress.value) {
      resetRequired.value = false;
      upgradeInProgress.value = false;
    }
  });
  watch(thirdPartyEditingUsersCount, () => {
    if (thirdPartyEditingUsersCount.value > 0 && !viewDraftFields.value) {
      viewDraftFields.value = true;
      notificationsStore.addNotification("displayingDraftFields");
    }
  });
  watch(propertyDiagramPropertyIds, async (arr, oldArr) => {
    if (
      routeName.value === "PropertyShell" &&
      oldArr.length > 0 &&
      arr.length === 0
    ) {
      resetDiagramPropertyIds();
    }

    await nextTick();
    const idDiff = _.difference(arr, oldArr) || [];
    // console.log("props changed", idDiff, arr, oldArr);
    if (routeName.value === "PropertyShell" && idDiff.length > 0) {
      debouncedfetchDiagramData();
    }
  });
  watch(asOfMilliseconds, async () => {
    if (propertyIdParam.value) {
      await removeUnusedDiagramData();
      debouncedfetchDiagramData();
    }
  });
  watch(activeValidationChangeGroupId, () => {
    if (propertyIdParam.value) {
      router.push({
        name: "MainMap",
        query: _.merge(
          {},
          { ...route.query, propertyIds: undefined },
          { horizontalTab: "Validations", verticalTab: "Available" },
        ),
      });
    }
  });

  async function addPropertyRightDetails(propertyRightDataFields = []) {
    const propertyRights = propertyRightDataFields.filter(
      ({ fieldContentType, unmasked }) =>
        unmasked && fieldContentType === "PropertyRight",
    );

    if (propertyRights.length > 0) {
      const payload = {
        propertyRights: _.uniq(
          propertyRights.map(({ fieldContentId }) => fieldContentId),
        ),
        asOf: asOfMilliseconds.value,
        activeChangeGroupId: activeValidationChangeGroupId.value,
      };

      const response = await api.post(`property_right_diagram_data`, payload);

      if (response?.data) {
        const addOnData = response.data;
        const augmentedPropertyRights = propertyRights.map((dataField) => {
          const matchingAddOn = _.find(addOnData, {
            id: dataField.fieldContentId,
          });

          if (matchingAddOn) {
            const existingFieldContent = dataField.fieldContent;
            const augmentedFieldContent = _.merge(
              {},
              existingFieldContent,
              matchingAddOn,
            );
            let newField = _.clone(dataField);
            newField.fieldContent = augmentedFieldContent;
            return newField;
          } else {
            return dataField;
          }
        });

        diagramAllFetchedFields.value = _.unionBy(
          augmentedPropertyRights,
          diagramAllFetchedFields.value,
          "localId",
        );
      }
    }
  }

  async function addFloorAreaUsageData(floorAreaDataFields = []) {
    const floorAreas = floorAreaDataFields.filter(
      ({ fieldContentType, unmasked }) =>
        unmasked && fieldContentType === "FloorArea",
    );

    if (floorAreas.length > 0) {
      const payload = {
        floorAreas: _.uniq(
          floorAreas.map(({ fieldContentId }) => fieldContentId),
        ),
        asOf: asOfMilliseconds.value,
        activeChangeGroupId: activeValidationChangeGroupId.value,
      };

      const response = await api.post(`floor_area_diagram_data`, payload);

      if (response?.data) {
        const addOnData = response.data;
        const augmentedFloorAreas = floorAreas.map((dataField) => {
          const matchingAddOn = _.find(addOnData, {
            id: dataField.fieldContentId,
          });

          if (matchingAddOn) {
            const existingFieldContent = dataField.fieldContent;
            const augmentedFieldContent = _.merge(
              {},
              existingFieldContent,
              matchingAddOn,
            );
            let newField = _.clone(dataField);
            newField.fieldContent = augmentedFieldContent;
            return newField;
          } else {
            return dataField;
          }
        });

        diagramAllFetchedFields.value = _.unionBy(
          augmentedFloorAreas,
          diagramAllFetchedFields.value,
          "localId",
        );
      }
    }
  }

  async function fetchSpaceUsages(floorAreaDataFields = []) {
    const floorAreas = floorAreaDataFields.filter(
      ({ fieldContentType, unmasked }) =>
        unmasked && fieldContentType === "FloorArea",
    );

    if (floorAreas.length > 0) {
      const payload = {
        floorAreas: floorAreaDataFields.map(
          ({ fieldContentId }) => fieldContentId,
        ),
        asOf: asOfMilliseconds.value,
      };

      const usagesResponse = await api.post(`space_usage_layer_data`, payload);

      if (usagesResponse?.data) {
        interceptablePatch(usagesResponse.data);
      }
    }
  }

  function propertyDataFieldFor(propertyId) {
    if (_.size(propertyDiagramPropertyDataFields.value) > 0) {
      return (
        _.find(propertyDiagramPropertyDataFields.value, function (df, id) {
          return id == propertyId;
        }) ||
        _.find(hiddenGroundLayerPropertyDataFields.value, function (df, id) {
          return id == propertyId;
        })
      );
    } else {
      return null;
    }
  }
  function hasLoneEasement(propertyId) {
    const easementDataFields = subTypedDataFields([
      "reciprocal_easement_agreement",
    ]).filter((dataField) => {
      return dataField.decoratingContentId === propertyId;
    });
    const loneEasement = easementDataFields.length === 1;
    const nonEasementFeeParcels = subTypedDataFields(["fee_simple"]).filter(
      (dataField) => {
        const matchesProperty = dataField.joiningContentId === propertyId;
        const hasReciprocalEasement = !!_.get(
          dataField,
          "fieldContent.superiorId",
          null,
        );

        return matchesProperty && !hasReciprocalEasement;
      },
    );

    return loneEasement && nonEasementFeeParcels.length === 0;
  }
  function propertyIsAirLayer(propertyId) {
    const dataField = propertyDataFieldFor(propertyId);
    return dataField?.fieldContentSubType === "unused_development_rights";
  }

  function propertyLabelFor(propertyId) {
    const dataField = propertyDataFieldFor(propertyId);
    const rawName = _.get(
      dataField,
      "fieldContent.name",
      `Property ${propertyId}`,
    );

    if (propertyIsAirLayer(propertyId)) {
      return `${rawName} (Air Rights)`;
    } else {
      return rawName;
    }
  }

  async function refetchDiagramDataField(dataFieldLocalId) {
    api.get(`data_field_by_id/${dataFieldLocalId}`).then((json) => {
      interceptablePatch([json.data]);
    });
  }

  async function removeUnusedDiagramData() {
    return new Promise((resolve) => {
      diagramVisibilitySettings.value = {};
      diagramAllFetchedFields.value = [];
      fetchedPopupFields.value = {};
      diagramContentPagy.value = _.merge({}, defaultContentPagy);
      resolve();
    });
  }

  async function resetDiagramPropertyIds() {
    const queryDiagramPropertyIds = _.get(route.query, "propertyIds");

    if (propertyDiagramPropertyIds.value.length > 0) {
      return;
    } else {
      if (_.isArray(queryDiagramPropertyIds)) {
        const propertyIds = _.uniq(_.get(route.query, "propertyIds"));
        for (const id of propertyIds) {
          pushPropertyIdToDiagram(id);
          await addPropertyDataFieldToDiagram(id);
        }
      } else if (queryDiagramPropertyIds) {
        pushPropertyIdToDiagram(queryDiagramPropertyIds);
        await addPropertyDataFieldToDiagram(queryDiagramPropertyIds);
      } else {
        await addPropertyToDiagram(propertyIdParam.value);
      }
    }
  }

  const debouncedfetchDiagramData = _.debounce(function () {
    fetchDiagramData();
  }, 2000);

  async function fetchDiagramData() {
    const propertyIds = propertyDiagramPropertyIds.value;

    if (fetchingDiagramData.value) return;

    console.log("fetch paginated data", diagramContentPagy.value);
    const fieldNames = [
      "PropertyRight",
      "LandCovering",
      "FloorArea",
      "FloorAreaLayout",
      "PropertyEnhancement",
    ];
    fetchingDiagramData.value = true;
    viewDraftFields.value = true;
    for (const fieldName of fieldNames) {
      await Promise.all(
        propertyIds.map((propertyId) => {
          return fetchPaginatedFieldData(propertyId, fieldName);
        }),
      );
    }
    const landCoveringGroupingFields = await fetchLandCoveringGroupings();
    interceptablePatch(landCoveringGroupingFields);

    await nextTick();

    viewDraftFields.value = false;
    fetchingDiagramData.value = false;
  }

  function fetchPaginatedFieldData(propertyId, fieldName) {
    return new Promise((resolve) => {
      const idKey = `property${propertyId}`;
      if (diagramContentPagy.value?.[fieldName]?.[idKey]?.fetched) {
        resolve();
      } else {
        diagramContentPagy.value[fieldName][idKey] = {
          data: [],
          pagy: null,
          loadingEvents: false,
          fetched: false,
          loaded: false,
        };
        const subTypeParam =
          fieldName === "FloorArea"
            ? "&sub_type=unused_development_rights"
            : "";
        api
          .get(
            `paginated_diagram_data_as_of_date?field_name=${fieldName}${subTypeParam}&as_of=${asOfMilliseconds.value}&active_change_group_id=${activeValidationChangeGroupId.value}&property_ids[]=${propertyId}`,
          )
          .then(async (json) => {
            if (diagramContentPagy.value[fieldName][idKey]) {
              diagramContentPagy.value[fieldName][idKey].data = json.data.data;
              diagramContentPagy.value[fieldName][idKey].pagy = json.data.pagy;
              diagramContentPagy.value[fieldName][idKey].fetched = true;
            }
            interceptablePatch(json.data.data);
            if (fieldName === "LandCovering") {
              for (const dataField of json.data.data) {
                await fetchPaginatedLandCoveringData(dataField.fieldContentId);
              }
            } else if (fieldName === "FloorArea") {
              addFloorAreaUsageData(json.data.data);
              fetchSpaceUsages(json.data.data);
            } else if (fieldName === "PropertyRight") {
              addPropertyRightDetails(json.data.data);
            }
            resolve();
          });
      }
    });
  }

  async function fetchPaginatedLandCoveringData(landCoveringId) {
    if (landCoveringId) {
      console.log("fetch paginated land covering data", landCoveringId);
      await Promise.all([
        fetchLandCoveringUnitRights(landCoveringId),
        fetchLandCoveringFloorAreas(landCoveringId),
        fetchLandCoveringLevels(landCoveringId),
        fetchVerticalGroupings(landCoveringId),
        fetchVerticalOpenings(landCoveringId),
        fetchOrderings("vertical", landCoveringId),
        fetchOrderings("horizontal", landCoveringId),
      ]);
      console.log("fetched", landCoveringId);
    }
  }

  async function fetchLandCoveringGroupings() {
    return new Promise((resolve) => {
      const payload = {
        propertyIds: propertyDiagramPropertyIds.value,
        activeChangeGroupId: activeValidationChangeGroupId.value,
      };

      api.post(`property_land_covering_groupings`, payload).then((json) => {
        resolve(json.data);
      });
    });
  }

  function fetchLandCoveringUnitRights(landCoveringId) {
    return new Promise((resolve) => {
      if (
        diagramContentPagy.value?.["LandCoveringUnitRight"]?.[landCoveringId]
          ?.fetched
      ) {
        resolve();
      } else {
        diagramContentPagy.value["LandCoveringUnitRight"][landCoveringId] = {
          data: [],
          pagy: null,
          loadingEvents: false,
          fetched: false,
          loaded: false,
        };
        api
          .get(
            `land_covering_unit_property_rights/?land_covering_id=${landCoveringId}&active_change_group_id=${activeValidationChangeGroupId.value}`,
          )
          .then((json) => {
            diagramContentPagy.value["LandCoveringUnitRight"][
              landCoveringId
            ].data = json.data.data;
            diagramContentPagy.value["LandCoveringUnitRight"][
              landCoveringId
            ].pagy = json.data.pagy;
            diagramContentPagy.value["LandCoveringUnitRight"][
              landCoveringId
            ].fetched = true;
            interceptablePatch(json.data.data);
            addPropertyRightDetails(json.data.data);
            resolve();
          });
      }
    });
  }
  function fetchLandCoveringFloorAreas(landCoveringId) {
    return new Promise((resolve) => {
      const idKey = `landCovering${landCoveringId}`;
      const propertyId = _.find(landCoveringDataFields.value, {
        fieldContentId: landCoveringId,
      })?.joiningContentId;
      if (
        diagramContentPagy.value?.["FloorArea"]?.[idKey]?.fetched ||
        !propertyId
      ) {
        resolve();
      } else {
        diagramContentPagy.value["FloorArea"][idKey] = {
          data: [],
          pagy: null,
          loadingEvents: false,
          fetched: false,
          loaded: false,
        };
        api
          .get(
            `paginated_diagram_data_as_of_date?field_name=FloorArea&sub_type=land_covering&as_of=${asOfMilliseconds.value}&active_change_group_id=${activeValidationChangeGroupId.value}&property_ids[]=${propertyId}&land_covering_ids[]=${landCoveringId}`,
          )
          .then((json) => {
            diagramContentPagy.value["FloorArea"][idKey].data = json.data.data;
            diagramContentPagy.value["FloorArea"][idKey].pagy = json.data.pagy;
            diagramContentPagy.value["FloorArea"][idKey].fetched = true;
            interceptablePatch(json.data.data);
            addFloorAreaUsageData(json.data.data);
            fetchSpaceUsages(json.data.data);
            resolve();
          });
      }
    });
  }
  function fetchLandCoveringLevels(landCoveringId) {
    return new Promise((resolve) => {
      if (
        diagramContentPagy.value?.["LandCoveringLevel"]?.[landCoveringId]
          ?.fetched
      ) {
        resolve();
      } else {
        diagramContentPagy.value["LandCoveringLevel"][landCoveringId] = {
          data: [],
          pagy: null,
          loadingEvents: false,
          fetched: false,
          loaded: false,
        };
        api
          .get(
            `land_covering_levels/?land_covering_id=${landCoveringId}&as_of=${asOfMilliseconds.value}&active_change_group_id=${activeValidationChangeGroupId.value}`,
          )
          .then(async (json) => {
            diagramContentPagy.value["LandCoveringLevel"][landCoveringId].data =
              json.data.data;
            diagramContentPagy.value["LandCoveringLevel"][landCoveringId].pagy =
              json.data.pagy;
            diagramContentPagy.value["LandCoveringLevel"][
              landCoveringId
            ].fetched = true;
            interceptablePatch(json.data.data);
            for (const { fieldContentId } of json.data.data) {
              await fetchLandCoveringLevelFloorAreas(fieldContentId);
            }
            resolve();
          });
      }
    });
  }
  function fetchLandCoveringLevelFloorAreas(landCoveringLevelId) {
    // TODO: PROVIDE FETCHED FLOOR AREA IDS TO PREVENT REPETITIVE FETCHING
    return new Promise((resolve) => {
      const idKey = `landCoveringLevel${landCoveringLevelId}`;
      const propertyId = _.find(landCoveringLevelDataFields.value, {
        fieldContentId: landCoveringLevelId,
      })?.propertyId;
      if (
        diagramContentPagy.value?.["FloorArea"]?.[idKey]?.fetched ||
        !propertyId
      ) {
        resolve();
      } else {
        diagramContentPagy.value["FloorArea"][idKey] = {
          data: [],
          pagy: null,
          loadingEvents: false,
          fetched: false,
          loaded: false,
        };
        api
          .get(
            `paginated_diagram_data_as_of_date?field_name=FloorArea&sub_type=land_covering_level&as_of=${asOfMilliseconds.value}&active_change_group_id=${activeValidationChangeGroupId.value}&property_ids[]=${propertyId}&land_covering_level_ids[]=${landCoveringLevelId}`,
          )
          .then((json) => {
            diagramContentPagy.value["FloorArea"][idKey].data = json.data.data;
            diagramContentPagy.value["FloorArea"][idKey].pagy = json.data.pagy;
            diagramContentPagy.value["FloorArea"][idKey].fetched = true;
            interceptablePatch(json.data.data);
            addFloorAreaUsageData(json.data.data);
            fetchSpaceUsages(json.data.data);
            resolve();
          });
      }
    });
  }
  function fetchVerticalOpenings(landCoveringId) {
    return new Promise((resolve) => {
      if (
        diagramContentPagy.value?.["VerticalOpening"]?.[landCoveringId]?.fetched
      ) {
        resolve();
      } else {
        diagramContentPagy.value["VerticalOpening"][landCoveringId] = {
          data: [],
          pagy: null,
          loadingEvents: false,
          fetched: false,
          loaded: false,
        };
        api
          .get(
            `floor_area_downward_vertical_openings/?land_covering_id=${landCoveringId}&active_change_group_id=${activeValidationChangeGroupId.value}`,
          )
          .then((json) => {
            diagramContentPagy.value["VerticalOpening"][landCoveringId].data =
              json.data.data;
            diagramContentPagy.value["VerticalOpening"][landCoveringId].pagy =
              json.data.pagy;
            diagramContentPagy.value["VerticalOpening"][
              landCoveringId
            ].fetched = true;
            interceptablePatch(json.data.data);
            resolve();
          });
      }
    });
  }
  function fetchVerticalGroupings(landCoveringId) {
    return new Promise((resolve) => {
      if (
        diagramContentPagy.value?.["VerticalGrouping"]?.[landCoveringId]
          ?.fetched
      ) {
        resolve();
      } else {
        diagramContentPagy.value["VerticalGrouping"][landCoveringId] = {
          data: [],
          pagy: null,
          loadingEvents: false,
          fetched: false,
          loaded: false,
        };
        api
          .get(
            `land_covering_diagram_vertical_groupings/?land_covering_id=${landCoveringId}&grouped_content_type=LandCoveringLevel&active_change_group_id=${activeValidationChangeGroupId.value}`,
          )
          .then((json) => {
            diagramContentPagy.value["VerticalGrouping"][landCoveringId].data =
              json.data.data;
            diagramContentPagy.value["VerticalGrouping"][landCoveringId].pagy =
              json.data.pagy;
            diagramContentPagy.value["VerticalGrouping"][
              landCoveringId
            ].fetched = true;
            interceptablePatch(json.data.data);
            resolve();
          });
      }
    });
  }
  function fetchOrderings(direction, landCoveringId) {
    return new Promise((resolve) => {
      const key =
        direction === "vertical"
          ? "VerticalVisualOrdering"
          : "HorizontalVisualOrdering";
      if (diagramContentPagy.value?.[key]?.[landCoveringId]?.fetched) {
        resolve();
      } else {
        diagramContentPagy.value[key][landCoveringId] = {
          data: [],
          pagy: null,
          loadingEvents: false,
          fetched: false,
          loaded: false,
        };
        api
          .get(
            `property_diagram_visual_orderings/?land_covering_id=${landCoveringId}&direction=${direction}&orderable_type=FloorArea&active_change_group_id=${activeValidationChangeGroupId.value}`,
          )
          .then((json) => {
            diagramContentPagy.value[key][landCoveringId].data = json.data.data;
            diagramContentPagy.value[key][landCoveringId].pagy = json.data.pagy;
            diagramContentPagy.value[key][landCoveringId].fetched = true;
            interceptablePatch(json.data.data);
            resolve();
          });
      }
    });
  }

  function interceptableField(dataField) {
    return _.get(dataField, "meteringStatus") === "prompt_to_subscribe";
  }
  function interceptablePatch(dataFields) {
    if (_.some(dataFields, interceptableField)) {
      promptToSubscribe();
    } else {
      patchDiagramFields(dataFields);
    }
  }
  const debouncedUnlockWarn = _.debounce(function () {
    unlockWarn();
  }, 3000);
  function unlockWarn() {
    return new Promise((resolve) => {
      if (!modalPayload.value) {
        modalPayload.value = {
          size: "base",
          theme: "light",
          component: markRaw(UnlicensedContentWarning),
          props: {},
        };
        resolve();
      } else {
        resolve();
      }
    });
  }
  function promptToSubscribe() {
    return new Promise((resolve) => {
      if (!modalPayload.value) {
        upgradeInProgress.value = true;
        const successCallback = () => {
          if (upgradeSuccessful.value) {
            console.log("user subscribed");
            upgradeInProgress.value = false;
            upgradeSuccessful.value = false;
            debouncedfetchDiagramData();
            resolve();
          }
        };

        subscribeInterceptor({
          apiRequestFunc: null,
          successCallback,
          modalPayloadRef: modalPayload,
          upgradeSuccessfulRef: upgradeSuccessful,
          afterSubscribe: "successCallback",
          promptReason: "dataUsage",
          context: "licensing",
        });
      } else {
        resolve();
      }
    });
  }
  async function postEditingPatch(json) {
    if (!propertyIdParam.value) return;

    let newData = null;
    let updatedContent = null;
    if (json.data.dataField) newData = [json.data.dataField];
    else if (json.data.dataFields) newData = json.data.dataFields;
    if (json.data.updatedContent) updatedContent = json.data.updatedContent;

    if (json.data.timelineEvents) {
      propertyPatchableTimelineEvents.value = json.data.timelineEvents;
    }

    if (newData && updatedContent) {
      await patchDiagramFields(newData);
      patchFieldContent(updatedContent);
    } else if (newData) patchDiagramFields(newData);
    else if (updatedContent) patchFieldContent(updatedContent);

    handleResponseAction(json);
  }
  async function handleResponseAction(json) {
    const {
      availabilityId,
      availabilityGroupId,
      dataFields,
      spaceField,
      responseAction,
    } = json.data;
    if (responseAction) {
      switch (responseAction) {
        case "populateRenewAndExpand": {
          const unlocatedSpaceField = _.head(dataFields);
          const groupAvailabilityFields =
            propertyDiagramSelected.value.groupAvailabilityFields;
          // console.log(
          //   "new unlocated field",
          //   unlocatedSpaceField,
          //   groupAvailabilityFields,
          // );
          spaceUsageBuilderStore.populateRenewAndExpand({
            availabilityId,
            availabilityGroupId,
            spaceField,
            groupAvailabilityFields,
            unlocatedSpaceField,
            selectedSpaceFields: propertyDiagramAdded.value,
          });
          break;
        }
      }
    }
  }
  function patchDiagramFields(newFields) {
    return new Promise((resolve) => {
      dropInterceptFields();
      diagramAllFetchedFields.value = _.unionBy(
        newFields,
        diagramAllFetchedFields.value,
        "localId",
      );
      patchDiagramVisibility(newFields);
      dropDeprecatedFields();

      if (_.some(newFields, unlockableInnerContent)) {
        debouncedUnlockWarn();
      }

      resolve();
    });
  }
  function createVisibilityProperty(id) {
    if (!diagramVisibilitySettings.value[id]) {
      diagramVisibilitySettings.value[id] = {
        feeSimples: {},
        landCoverings: {},
      };
    }
  }
  function patchDiagramVisibility(newFields) {
    newFields.forEach((dataField) => {
      patchFeeSimpleVisibility(dataField);
      patchLandCoveringVisibility(dataField);
    });
  }
  async function patchFeeSimpleVisibility({
    joiningContentType,
    joiningContentId,
    fieldContentId,
    fieldContentSubType,
    deprecatedAt,
    dismissed,
  }) {
    const actualFee =
      joiningContentType === "Property" && fieldContentSubType === "fee_simple";
    const airLayerFee =
      joiningContentType === "Property" &&
      fieldContentSubType === "unused_development_rights";

    if (airLayerFee && !propertyDataFieldFor(joiningContentId)) {
      await addPropertyDataFieldToDiagram(joiningContentId);
    }

    const operativePropertyId = airLayerFee
      ? propertyDataFieldFor(joiningContentId).fieldContent.airLayerPropertyId
      : joiningContentId;
    if (actualFee || airLayerFee) {
      createVisibilityProperty(operativePropertyId);
      if (!deprecatedAt && !dismissed) {
        diagramVisibilitySettings.value[operativePropertyId].feeSimples[
          fieldContentId
        ] = true;
      } else if (diagramVisibilitySettings.value[operativePropertyId]) {
        delete diagramVisibilitySettings.value[operativePropertyId].feeSimples[
          fieldContentId
        ];
      }
    }
  }
  function patchLandCoveringVisibility({
    joiningContentType,
    joiningContentId,
    fieldContentId,
    fieldContentType,
    deprecatedAt,
    dismissed,
  }) {
    if (
      joiningContentType === "Property" &&
      fieldContentType === "LandCovering"
    ) {
      if (
        !deprecatedAt &&
        !dismissed &&
        diagramVisibilitySettings.value[joiningContentId]
      ) {
        diagramVisibilitySettings.value[joiningContentId].landCoverings[
          fieldContentId
        ] = true;
      } else if (diagramVisibilitySettings.value[joiningContentId]) {
        delete diagramVisibilitySettings.value[joiningContentId].landCoverings[
          fieldContentId
        ];
      }
    }
  }
  function patchFieldContent(updatedContent = []) {
    for (const content of updatedContent) {
      let matchedDataFields = _.filter(
        diagramAllFetchedFields.value,
        function (dataField) {
          return (
            dataField.fieldContentType === content.recordType &&
            dataField.fieldContentId == content.id
          );
        },
      );
      let isSelected = selfSelected(content, propertyDiagramSelected.value);

      if (matchedDataFields.length > 0) {
        for (const dataField of matchedDataFields) {
          dataField.fieldContent = content;
        }
        patchDiagramFields(matchedDataFields);
      }
      if (isSelected) {
        propertyDiagramSelected.value.dataField.fieldContent = content;
      }
    }
  }
  function dropInterceptFields() {
    const strippedFields = diagramAllFetchedFields.value.filter((dataField) => {
      return !dataField.meteringStatus;
    });
    diagramAllFetchedFields.value = strippedFields;
  }
  function dropDeprecatedFields() {
    const strippedFields = diagramAllFetchedFields.value.filter((dataField) => {
      return !dataField.deprecatedAt && !dataField.dismissed;
    });
    diagramAllFetchedFields.value = strippedFields;
  }
  function neededByAirLayer(groundLayerPropertyId, collection) {
    const included = _.some(collection, function (df) {
      return df.fieldContent.groundLayerPropertyId == groundLayerPropertyId;
    });

    return included;
  }
  function dropPropertyFields(propertyId) {
    console.log("drop property", propertyId);
    const strippedFields = _.reject(
      diagramAllFetchedFields.value,
      function (dataField) {
        const decoratingMatch =
          dataField.decoratingContentType === "Property" &&
          dataField.decoratingContentId === propertyId;
        const joiningMatch =
          dataField.joiningContentType === "Property" &&
          dataField.joiningContentId === propertyId;
        return decoratingMatch || joiningMatch;
      },
    );
    diagramAllFetchedFields.value = strippedFields;
  }

  const heightLabel = computed(() => {
    return maxHeight.value ? ` ${maxHeight.value}'` : "";
  });

  const diagramGridRows = computed(() => {
    const rows = {
      controls: {
        order: 0,
        name: "",
        internalName: "Property Controls",
        outerRowHeight: "auto",
        rowClasses: "",
      },
      airRights: {
        order: 1,
        loopKeyId: "air-right",
        name: "Air",
        internalName: "Air Rights",
        rowClasses: "",
        outerRowHeight: "auto",
        dataFields: subTypedDataFields(["unused_development_rights"]),
        component: markRaw(PropertyRightDiagramColumn),
      },
      landCoveringAboveGrade: {
        order: 2,
        loopKeyId: "above-grade-land-covering",
        name: `Bldgs${heightLabel.value}`,
        internalName: "Land Coverings (above-grade)",
        rowClasses: "border-b-4 border-gray-800",
        outerRowHeight: landCoveringRowHeights("above"),
        dataFields: subTypedDataFields(["building", "surface_structure"]),
        component: markRaw(LandCoveringDiagramColumn),
      },
      landCoveringBelowGrade: {
        order: 3,
        loopKeyId: "below-grade-land-covering",
        name: "Below grade",
        internalName: "Land Coverings (below-grade)",
        rowClasses: "",
        outerRowHeight: landCoveringRowHeights("below"),
        dataFields: subTypedDataFields(["building", "surface_structure"]),
        component: markRaw(LandCoveringDiagramColumn),
      },
      landCoveringUnitLeaseholds: {
        order: 4,
        loopKeyId: "land-covering-leasehold",
        name: "L'hold",
        internalName: "Land Covering Leaseholds",
        rowClasses: "",
        outerRowHeight: `${orderedLeaseholdLayers("unit").length * 24}px`,
        apiValue: ["leasehold"],
        layers: orderedLeaseholdLayers("unit"),
        component: markRaw(LandCoveringUnitLayeredRightDiagramColumn),
      },
      landCoveringUnits: {
        order: 5,
        loopKeyId: "land-covering-ownership-unit",
        name: "Unit",
        internalName: "Land Covering Ownership Units",
        rowClasses: "",
        outerRowHeight: "24px",
        dataFields: landCoveringUnitDataFields.value,
        component: markRaw(LandCoveringUnitRightDiagramColumn),
      },
      feeLeaseholds: {
        order: 6,
        loopKeyId: "fee-leasehold",
        name: "L'hold",
        internalName: "Fee Leaseholds",
        rowClasses: "",
        outerRowHeight: "auto",
        apiValue: ["leasehold"],
        layers: orderedLeaseholdLayers("fee"),
        component: markRaw(LayeredPropertyRights),
      },
      feeSimpleParcels: {
        order: 7,
        loopKeyId: "fee-parcel",
        name: "Fee",
        internalName: "Fee Parcels",
        rowClasses: "",
        outerRowHeight: "auto",
        dataFields: subTypedDataFields(["fee_simple"]),
        component: markRaw(PropertyRightDiagramColumn),
      },
      reciprocalEasements: {
        order: 8,
        loopKeyId: "reciprocal-easement",
        name: "REA",
        internalName: "Reciprocal Easement Agreements",
        rowClasses: "",
        outerRowHeight: "auto",
        dataFields: subTypedDataFields(["reciprocal_easement_agreement"]),
        component: markRaw(PropertyRightDiagramColumn),
      },
      // feeBackdrops: {
      //   order: 9,
      //   loopKeyId: "fee-parcel-backdrop",
      //   name: "",
      //   internalName: "Fee Backdrops",
      //   rowClasses: "",
      //   outerRowHeight: "auto",
      //   dataFields: subTypedDataFields(["fee_simple"]),
      //   component: markRaw(PropertyRightBackdropDiagramColumn),
      // },
    };
    const displayableRows = _.filter(rows, function (row, key) {
      const hasColumns = row.dataFields && row.dataFields.length > 0;
      const hasLayers = row.layers && row.layers.length > 0;
      const alwaysVisible = _.includes(
        ["controls", "landCoveringAboveGrade", "feeBackdrops"],
        key,
      );

      return alwaysVisible || hasColumns || hasLayers;
    });

    return _.orderBy(displayableRows, ["order"], ["asc"]);
  });

  function subTypedDataFields(fieldContentSubTypes) {
    return _.filter(diagramAllVisibleFields.value, function (dataField) {
      return _.includes(fieldContentSubTypes, dataField.fieldContentSubType);
    });
  }
  function leaseholdFor(feeSimpleId) {
    const leaseholds = _.filter(
      diagramAllVisibleFields.value,
      function (dataField) {
        return _.includes(["leasehold"], dataField.fieldContentSubType);
      },
    );
    return _.find(leaseholds, { decoratingContentId: feeSimpleId });
  }

  function orderedLeaseholdLayers(controllingType) {
    const lookupTypes =
      controllingType === "fee"
        ? ["Fee simple", "Unused Development Rights"]
        : ["Condominium", "Co-op"];
    const scopedFields = _.filter(
      diagramAllVisibleFields.value,
      function (dataField) {
        return (
          _.includes(["leasehold"], dataField.fieldContentSubType) &&
          _.includes(
            lookupTypes,
            dataField.fieldContent?.nearestControllingRightType,
          )
        );
      },
    );
    const layerCount = _.max(
      scopedFields.map(
        (dataField) => dataField.fieldContent.layersFromNearestControlling,
      ),
    );

    return _.times(layerCount, function (index) {
      const adjustedLayerNumber = index + 1;
      return {
        order: adjustedLayerNumber,
        dataFields: scopedFields.filter(
          (dataField) =>
            dataField.fieldContent.layersFromNearestControlling ===
            adjustedLayerNumber,
        ),
      };
    });
  }
  const renderableGridRows = computed(() => {
    return diagramGridRows.value.map((row) => {
      return _.merge({}, row, {
        gridColumnStyle: gridColumnStyle.value,
        columns: columns.value,
      });
    });
  });
  const gridRowHeights = computed(() => {
    return renderableGridRows.value.map((row) => row.outerRowHeight).join(" ");
  });
  const landCoveringLevelDataFieldsByCovering = computed(() =>
    _.groupBy(landCoveringLevelDataFields.value, function (df) {
      return df.decoratingContentId;
    }),
  );
  const maxBelowGradeHeight = computed(() => {
    if (
      _.isNil(landCoveringLevelDataFieldsByCovering.value) ||
      _.isEmpty(landCoveringLevelDataFieldsByCovering.value)
    ) {
      return null;
    } else {
      const coveringBelowGradeHeights = _.map(
        landCoveringLevelDataFieldsByCovering.value,
        function (levelDataFields, landCoveringId) {
          const landCoveringDataField = _.find(landCoveringDataFields.value, {
            fieldContentId: _.toNumber(landCoveringId),
          });
          if (landCoveringDataField) {
            return belowGradeHeightFor(
              levelDataFields,
              landCoveringDataField.fieldContent,
            );
          } else {
            return null;
          }
        },
      );

      return _.max(coveringBelowGradeHeights);
    }
  });
  function landCoveringRowHeights(gradeLevel) {
    const highest = _.max(verticalLevels.value);
    const lowest = Math.abs(_.min(verticalLevels.value));
    const totalLevels = highest + lowest;
    const aboveRatio = highest / totalLevels;
    const belowRatio = lowest / totalLevels;
    const belowToAboveRatio = belowRatio / aboveRatio;
    let rowHeight = "auto";

    if (gradeLevel === "above") {
      rowHeight = `1fr`;
    } else if (
      _.isNaN(belowToAboveRatio) ||
      _.isNull(maxBelowGradeHeight.value)
    ) {
      rowHeight = `auto`;
    } else {
      const roundedHeight = _.round(
        maxBelowGradeHeight.value / maxHeight.value,
        2,
      );
      rowHeight = roundedHeight ? `${roundedHeight}fr` : "auto";
      // rowHeight = `${belowToAboveRatio}fr`;
    }

    return rowHeight;
  }
  function belowGradeHeightFor(levelDataFields, landCoveringContent) {
    const coveringAboveGradeHeight = calculationHeightFor(landCoveringContent);
    const belowGradeLevels = levelDataFields.filter((df) => {
      return _.get(df, "fieldContent.verticalLevel", 0) < 0;
    });
    const allBelowGradeHaveHeight =
      belowGradeLevels.length > 0 &&
      _.every(belowGradeLevels, function (df) {
        const rawHeight = _.get(df, "fieldContent.height");

        return rawHeight && _.isNumber(rawHeight);
      });
    if (allBelowGradeHaveHeight) {
      return _.sumBy(belowGradeLevels, function (df) {
        return _.get(df, "fieldContent.height");
      });
    } else {
      // determine generalized height based on above-grade height
      const aboveGradeLevels = levelDataFields.filter((df) => {
        return _.get(df, "fieldContent.verticalLevel", 0) >= 0;
      });
      const slabLevels = slabToSlabLevels(aboveGradeLevels);
      const totalSlabHeight = totalSlabToSlabHeight(aboveGradeLevels);
      let generalizedLevelHeight = 12;

      if (aboveGradeLevels.length === slabLevels.length) {
        generalizedLevelHeight = totalSlabHeight / aboveGradeLevels.length;
      } else {
        const totalGeneralizedHeight =
          coveringAboveGradeHeight - totalSlabHeight;
        const generalizedLevels = aboveGradeLevels.length - slabLevels.length;
        generalizedLevelHeight = totalGeneralizedHeight / generalizedLevels;
      }

      return _.sumBy(belowGradeLevels, function (df) {
        const rawLevelHeight = _.get(df, "fieldContent.height");
        const levelHeight =
          rawLevelHeight && _.isNumber(rawLevelHeight) ? rawLevelHeight : null;

        return levelHeight || generalizedLevelHeight;
      });
    }
  }
  function rowObjectFor(internalName) {
    return _.find(diagramGridRows.value, {
      internalName: internalName,
    });
  }
  function scopedFieldsFor(internalName, propertyId = null) {
    const allFields = _.get(rowObjectFor(internalName), "dataFields", []);

    return propertyId
      ? allFields.filter((dataField) => {
          return dataField.joiningContentId === propertyId;
        })
      : allFields;
  }
  const propertyEnhancements = computed(() => {
    return diagramAllVisibleFields.value.filter((dataField) => {
      return dataField.fieldContentType === "PropertyEnhancement";
    });
  });
  const floorAreaDataFields = computed(() => {
    return diagramAllVisibleFields.value.filter((dataField) => {
      return dataField.fieldContentType === "FloorArea";
    });
  });
  const spaceUsageDataFields = computed(() => {
    return diagramAllVisibleFields.value.filter((dataField) => {
      return dataField.fieldContentType === "SpaceUsage";
    });
  });
  const landCoveringLevelDataFields = computed(() => {
    return diagramAllVisibleFields.value.filter((dataField) => {
      return dataField.fieldContentType === "LandCoveringLevel";
    });
  });
  const verticalLevels = computed(() => {
    return landCoveringLevelDataFields.value.map((dataField) => {
      return dataField.fieldContent.verticalLevel;
    });
  });
  const feeSimpleDataFields = computed(() => {
    const feeRow = _.find(diagramGridRows.value, {
      internalName: "Fee Parcels",
    });
    return _.get(feeRow, "dataFields", []);
  });
  const subordinateRightDataFields = computed(() => {
    return diagramAllVisibleFields.value.filter((dataField) => {
      return (
        dataField.fieldContentType === "PropertyRight" &&
        dataField.fieldContentSubType !== "fee_simple"
      );
    });
  });
  const landCoveringUnits = computed(() => {
    return diagramAllVisibleFields.value.filter((dataField) => {
      return (
        dataField.fieldContentType === "LandCovering" &&
        _.includes(
          ["Condominium", "Co-op", "condominium", "co_op"],
          dataField.decoratingContentSubType,
        )
      );
    });
  });
  const landCoveringUnitIds = computed(() => {
    return landCoveringUnits.value.map(
      (dataField) => dataField.decoratingContentId,
    );
  });
  const landCoveringUnitDataFields = computed(() => {
    return subordinateRightDataFields.value.filter((dataField) => {
      return _.includes(landCoveringUnitIds.value, dataField.fieldContentId);
    });
  });
  const landCoveringDataFields = computed(() => {
    return diagramAllVisibleFields.value.filter((dataField) => {
      return (
        dataField.fieldContentType === "LandCovering" &&
        _.includes(
          ["Fee simple", "Unused Development Rights"],
          dataField.decoratingContentSubType,
        )
      );
    });
  });
  const singleParcelLandCoverings = computed(() => {
    return landCoveringDataFields.value.filter((dataField) => {
      return _.get(dataField, "fieldContent.propertyRightIds", []).length === 1;
    });
  });
  const multiParcelLandCoverings = computed(() => {
    return landCoveringDataFields.value.filter((dataField) => {
      return _.get(dataField, "fieldContent.propertyRightIds", []).length > 1;
    });
  });

  const combinedCoveringsByWidth = computed(() => {
    return {
      single: singleParcelLandCoverings.value.map((df) => df.fieldContent),
      multi: multiParcelLandCoverings.value.map((df) => df.fieldContent),
    };
  });
  const allCoverings = computed(() => {
    return _.concat(
      combinedCoveringsByWidth.value.single,
      combinedCoveringsByWidth.value.multi,
    );
  });
  const allHeightCoverings = computed(() => {
    return allCoverings.value.filter((coveringObject) => {
      return _.isNumber(coveringObject.height);
    });
  });
  const coveringsWithPercentageHeights = computed(() => {
    return allCoverings.value.map((coveringObject) => {
      return _.merge({}, coveringObject, {
        aboveGradePercentageHeight: aboveGradeRoundedHeightFor(coveringObject),
        belowGradePercentageHeight:
          belowGradePercentageHeightFor(coveringObject),
      });
    });
  });
  const coveringsWithZIndexes = computed(() => {
    const sorted = _.sortBy(allCoverings.value, ["height"]);
    const uniqSorted = _.sortedUniqBy(sorted, "height");
    const reversed = _.reverse(uniqSorted);

    return allCoverings.value.map((coveringObject) => {
      const rankBasedZIndex =
        _.findIndex(reversed, function (obj) {
          return (
            obj.id === coveringObject.id &&
            obj.recordType === coveringObject.recordType
          );
        }) + 1;
      const childOfSelectedLandCovering =
        coveringObject.id ===
          _.get(propertyDiagramSelected.value, "landCovering.id", null) &&
        coveringObject.recordType === "LandCovering";
      const isDirectlySelected = selfSelected(
        coveringObject,
        propertyDiagramSelected.value,
      );
      const isHovering = selfSelected(coveringObject, hovering.value);
      const zIndex =
        childOfSelectedLandCovering || isDirectlySelected || isHovering
          ? 50
          : rankBasedZIndex;

      return _.merge({}, coveringObject, {
        zIndex,
      });
    });
  });

  const unlockableHeight = computed(() => {
    return _.some(allCoverings.value, function (obj) {
      return _.get(obj.height, "meteringStatus") === "unlicensed";
    });
  });

  const maxHeight = computed(() => {
    if (allHeightCoverings.value.length === allCoverings.value.length) {
      return _.maxBy(allCoverings.value, "height")?.height;
    } else if (unlockableHeight.value) {
      const fallbackHeight = _.maxBy(allCoverings.value, "defaultHeight");

      return fallbackHeight.defaultHeight;
    } else {
      const actualHeight = _.maxBy(allCoverings.value, "height");
      const fallbackHeight = _.maxBy(allCoverings.value, "defaultHeight");

      if (_.get(actualHeight?.height, "meteringStatus") === "unlicensed") {
        return fallbackHeight.defaultHeight;
      } else if (_.isNumber(actualHeight?.height)) {
        return actualHeight.height;
      } else {
        return null;
      }
    }
  });
  const landAreas = computed(() => {
    return propertyDiagramPropertyIds.value.flatMap((propertyId) => {
      return orderedFeeSimples(propertyId).map((dataField) => {
        const rawLandArea = _.get(dataField, "fieldContent.landArea", null);
        return {
          propertyId,
          landArea: rawLandArea ? _.toNumber(rawLandArea) : null,
        };
      });
    });
  });
  const totalArea = computed(() => _.sumBy(landAreas.value, "landArea"));
  const variableColumnWidth = computed(() => {
    return (
      landAreas.value.length > 0 &&
      _.every(landAreas.value, function (wrapper) {
        return !!wrapper.landArea;
      })
    );
  });
  const gridColumnPercentageEligible = computed(() => {
    return totalArea.value > 0 && variableColumnWidth.value;
  });

  const gridColumnStyle = computed(() => {
    let propertyColumns;

    if (gridColumnPercentageEligible.value) {
      propertyColumns = _.map(
        propertyDiagramPropertyDataFields.value,
        propertyAcreageColumnWidth,
      ).join(" ");
    } else {
      propertyColumns = _.map(
        propertyDiagramPropertyDataFields.value,
        function () {
          return "1fr";
        },
      ).join(" ");
    }

    return `${leftLabelColumnPixelWidth}px ${propertyColumns}`;
  });
  const columns = computed(() => {
    return _.concat(["rowNames"], _.sortBy(propertyDiagramPropertyIds.value));
  });

  function propertyAcreageColumnWidth(propertyDataField) {
    const propertyLandAreas = landAreas.value.filter(({ propertyId }) => {
      return propertyId === propertyDataField.fieldContentId;
    });
    const propertyTotalArea = _.sumBy(propertyLandAreas, "landArea");
    const percentage = propertyTotalArea / totalArea.value;
    const formattedPercentage = _.round(percentage * 100, 1);
    const propertyCount = _.size(propertyDiagramPropertyIds.value);
    const pixelAdjustment = leftLabelColumnPixelWidth / propertyCount;
    return `calc(${formattedPercentage}% - ${pixelAdjustment}px)`;
  }

  function calculationHeightFor(coveringObject) {
    const actualHeight = _.get(coveringObject, "height");
    const fallbackHeight = _.get(coveringObject, "defaultHeight");

    if (_.get(actualHeight, "meteringStatus") === "unlicensed") {
      return fallbackHeight;
    } else {
      return actualHeight;
    }
  }

  function aboveGradeRoundedHeightFor(coveringObject, precision = 1) {
    return _.round(
      (calculationHeightFor(coveringObject) / maxHeight.value) * 100,
      precision,
    );
  }

  function belowGradePercentageHeightFor(coveringObject, precision = 1) {
    const levelDataFields = _.find(
      landCoveringLevelDataFieldsByCovering.value,
      function (levelDataFields, landCoveringId) {
        return landCoveringId == coveringObject.id;
      },
    );
    if (levelDataFields) {
      const belowGradeHeight = belowGradeHeightFor(
        levelDataFields,
        coveringObject,
      );

      return _.round(
        (belowGradeHeight / maxBelowGradeHeight.value) * 100,
        precision,
      );
    } else {
      return _.round(100, precision);
    }
  }

  // PROPERTY-SPECIFIC FUNCTIONS

  function propertyLandAreas(propertyId) {
    return orderedFeeSimples(propertyId).map((dataField) => {
      return _.toNumber(_.get(dataField, "fieldContent.landArea", null));
    });
  }

  function propertyVariableWidth(propertyId) {
    return (
      propertyLandAreas(propertyId).length > 0 &&
      _.every(propertyLandAreas(propertyId))
    );
  }
  function propertyTotalArea(propertyId) {
    return propertyVariableWidth(propertyId)
      ? _.sum(propertyLandAreas(propertyId))
      : null;
  }
  function gridWidthsArray(propertyId) {
    return propertyLandAreas(propertyId).map((area) => {
      let percentage;

      if (propertyTotalArea(propertyId)) {
        percentage = area / propertyTotalArea(propertyId);
      } else {
        percentage = 1 / propertyLandAreas(propertyId).length;
      }

      const formattedPercentage = _.round(percentage * 100, 1);

      return formattedPercentage;
    });
  }
  function gridWidths(propertyId) {
    return gridWidthsArray(propertyId)
      .map((n) => `${_.round(n, 1)}%`)
      .join(" ");
  }

  function feeSimplesWithMultiParcelLandCoverings(propertyId) {
    return _.uniq(
      multiParcelLandCoverings.value
        .filter((dataField) => {
          return dataField.joiningContentId === propertyId;
        })
        .flatMap((dataField) => {
          return _.get(dataField, "fieldContent.propertyRightIds", []);
        }),
    );
  }
  function feeSingleParcelLandCoveringOrderings(propertyId) {
    const singleParcelLandCoveringGridRows = singleParcelGridRowsFor({
      propertyId,
    });
    const singleParcelGridBlocks = singleParcelLandCoveringGridRows.map(
      (gridBlockObject) => gridBlockObject.gridBlocks,
    );
    const populatedLandCoverings = singleParcelGridBlocks.map((gridBlocks) => {
      const populatedLandCovering = function (landCoveringObject) {
        return _.isNumber(landCoveringObject.coveringId);
      };

      return _.merge({}, _.find(gridBlocks, populatedLandCovering));
    });

    let parcelCollection = [];

    if (selectedPropertyIsAirLayer.value) {
      parcelCollection = orderedAirRights(propertyId);
    } else {
      parcelCollection = orderedFeeSimples(propertyId);
    }

    return parcelCollection.map((df) => {
      const landCoverings = _.filter(populatedLandCoverings, function (b) {
        return b.feeId === df.fieldContentId;
      });

      return {
        feeId: _.get(df, "fieldContentId", null),
        landCoverings,
      };
    });
  }
  function orderedAirRights(airLayerPropertyId) {
    if (propertyDataFieldFor(airLayerPropertyId)) {
      const propertyId =
        propertyDataFieldFor(airLayerPropertyId).fieldContent
          .groundLayerPropertyId;
      const airRightsFields = scopedFieldsFor("Air Rights", propertyId);
      const sorted = _.sortBy(airRightsFields, [
        function (df) {
          return _.get(df, "fieldContent.superiorId", null);
        },
        function (df) {
          return _.get(df, "fieldContent.order", null);
        },
      ]);

      return sorted.map((dataField, index) => {
        const hasMultiParcelLandCoverings = _.includes(
          feeSimplesWithMultiParcelLandCoverings(airLayerPropertyId),
          dataField.fieldContentId,
        );
        const unorderable = hasMultiParcelLandCoverings;
        let order;

        if (_.isNumber(_.get(dataField, "fieldContent.order", null))) {
          order = _.get(dataField, "fieldContent.order", null);
        } else if (unorderable) {
          order = null;
        } else {
          order = index;
        }

        return _.merge({}, dataField, { order });
      });
    } else {
      return [];
    }
  }
  function orderedFeeSimples(propertyId, visibility = "visible") {
    const operativePropertyId = propertyIsAirLayer(propertyId)
      ? propertyDataFieldFor(propertyId).fieldContent.groundLayerPropertyId
      : propertyId;
    let feeSimpleFields = [];
    if (visibility === "all") {
      feeSimpleFields = diagramAllFetchedFields.value.filter(
        ({ joiningContentId, fieldContentSubType }) =>
          joiningContentId === operativePropertyId &&
          _.includes(
            ["fee_simple", "unused_development_rights"],
            fieldContentSubType,
          ),
      );
    } else {
      feeSimpleFields = scopedFieldsFor("Fee Parcels", operativePropertyId);
    }
    const sorted = _.sortBy(feeSimpleFields, [
      function (df) {
        return _.get(df, "fieldContent.superiorId", null);
      },
      function (df) {
        return _.get(df, "fieldContent.order", null);
      },
    ]);
    const loneEasement = hasLoneEasement(propertyId);

    return sorted.map((dataField, index) => {
      const hasReciprocalEasement = !!_.get(
        dataField,
        "fieldContent.superiorId",
        null,
      );
      const hasMultiParcelLandCoverings = _.includes(
        feeSimplesWithMultiParcelLandCoverings(propertyId),
        dataField.fieldContentId,
      );
      const unorderable =
        (hasReciprocalEasement && !loneEasement) || hasMultiParcelLandCoverings;
      let order;

      if (_.isNumber(_.get(dataField, "fieldContent.order", null))) {
        order = _.get(dataField, "fieldContent.order", null);
      } else if (unorderable) {
        order = null;
      } else {
        order = index;
      }

      return _.merge({}, dataField, { order });
    });
  }
  function feesPlusWidths(propertyId) {
    if (propertyIsAirLayer(propertyId)) {
      return orderedFeeSimples(propertyId).flatMap((feeDataField, index) => {
        const airRightsParcelFields = orderedAirRights(propertyId).filter(
          (dataField) =>
            dataField.decoratingContentId === feeDataField.fieldContentId,
        );
        const feeGridWidth = gridWidthsArray(propertyId)[index];
        let airGridWidth;
        let airObjects;

        if (airRightsParcelFields.length > 0) {
          airGridWidth = _.round(
            feeGridWidth / airRightsParcelFields.length,
            1,
          );
          airObjects = airRightsParcelFields.map((dataField) => {
            return {
              feeId: _.get(dataField, "fieldContentId", null),
              gridWidth: airGridWidth,
            };
          });
        } else {
          airGridWidth = feeGridWidth;
          airObjects = [{ feeId: null, gridWidth: airGridWidth }];
        }
        return airObjects;
      });
    } else {
      return orderedFeeSimples(propertyId).map((feeDataField, index) => {
        return {
          feeId: _.get(feeDataField, "fieldContentId", null),
          gridWidth: gridWidthsArray(propertyId)[index],
        };
      });
    }
  }

  // PROPERTY-SPECIFIC LAND COVERINGS

  function gridZIndexFor(coveringDataField, recordType) {
    const coveringsObject = getCoveringByType(
      coveringsWithZIndexes.value,
      coveringDataField,
      recordType,
    );

    if (coveringsObject) {
      return coveringsObject.zIndex;
    } else {
      return 9;
    }
  }
  function gridHeightFor(coveringDataField, recordType, internalRowName) {
    const coveringsObject = getCoveringByType(
      coveringsWithPercentageHeights.value,
      coveringDataField,
      recordType,
    );

    if (coveringsObject) {
      switch (internalRowName) {
        case "Land Coverings (above-grade)":
          return `${coveringsObject.aboveGradePercentageHeight}%`;
        case "Land Coverings (below-grade)":
          return `${coveringsObject.belowGradePercentageHeight}%`;
        default:
          return "100%";
      }
    } else {
      return "100%";
    }
  }
  function getCoveringByType(collection, coveringDataField, recordType) {
    return _.find(collection, function (obj) {
      const idMatch =
        _.get(coveringDataField, "fieldContentId", null) === obj.id;
      const typeMatch = recordType === obj.recordType;

      return idMatch && typeMatch;
    });
  }
  function airLayerParcelWidths(columnDataFields, propertyId) {
    const feeIds = columnDataFields.map(
      (dataField) => dataField?.decoratingContentId,
    );

    const uniqueFeeIds = _.uniq(feeIds);
    const dynamicWidth = uniqueFeeIds.map((id, index) => {
      const rootFeeWidth = gridWidthsArray(propertyId)[index] / 100;
      const totalMatchingIds = feeIds.filter((feeId) => feeId === id);
      const eachWidth = rootFeeWidth / totalMatchingIds.length;

      return totalMatchingIds
        .map(() => `${_.round(eachWidth * 100, 1)}%`)
        .join(" ");
    });

    return dynamicWidth.join(" ");
  }
  function singleParcelGridRowsFor({
    collectionType = "LandCovering",
    internalRowName = "Land Coverings (above-grade)",
    propertyId,
  }) {
    const coveringCollection = singleParcelLandCoverings.value.filter(
      (dataField) => {
        return dataField.joiningContentId === propertyId;
      },
    );
    return coveringCollection.map((coveringDataField) => {
      let gridBlocks = [];

      feesPlusWidths(propertyId).forEach((feeObject) => {
        updateSingleParcelGridBlocks(
          feeObject,
          gridBlocks,
          coveringCollection,
          collectionType,
          coveringDataField,
          internalRowName,
        );
      });

      return {
        gridZIndex: gridZIndexFor(coveringDataField, collectionType),
        gridHeight: gridHeightFor(
          coveringDataField,
          collectionType,
          internalRowName,
        ),
        gridColumnWidths: gridBlocks
          .map((coveringObject) => `${coveringObject.gridWidth}%`)
          .join(" "),
        gridBlocks,
      };
    });
  }
  function updateSingleParcelGridBlocks(
    { feeId, gridWidth },
    gridBlocks,
    coveringCollection,
    coveringCollectionType,
    coveringDataField,
    internalRowName = null,
  ) {
    const coveringsOnThisParcel = coveringCollection.filter((df) => {
      const coveringParcelIds = _.get(df, "fieldContent.propertyRightIds", []);
      const singleParcelId = _.head(coveringParcelIds);

      return singleParcelId === feeId;
    });
    const targetCoveringParcelId = _.head(
      _.get(coveringDataField, "fieldContent.propertyRightIds", []),
    );
    const targetCoversWholeParcel =
      coveringsOnThisParcel.length === 1 && targetCoveringParcelId === feeId;
    const targetCoversPartialParcel =
      coveringsOnThisParcel.length > 1 && targetCoveringParcelId === feeId;
    const diagrammable =
      (internalRowName === "Land Coverings (below-grade)" &&
        _.get(
          coveringDataField,
          "fieldContent.hasBelowGradeLandCoveringLevels",
          false,
        )) ||
      (internalRowName && internalRowName === "Land Coverings (above-grade)");
    const coveringsAreOrdered = coveringsOnThisParcel.every((df) => {
      return _.isNumber(_.get(df, "fieldContent.order", null));
    });

    let order;

    if (coveringsAreOrdered) {
      order = _.get(coveringDataField, "fieldContent.order", null);
    } else {
      order = _.findIndex(coveringsOnThisParcel, function (df) {
        return (
          _.get(df, "fieldContentId", "nil") ===
          _.get(coveringDataField, "fieldContentId", null)
        );
      });
      if (order && order < 0) {
        order = null;
      }
    }

    if (diagrammable && targetCoversWholeParcel) {
      // TODO: Variable width
      gridBlocks.push({
        coveringId: _.get(coveringDataField, "fieldContentId", null),
        recordType: coveringCollectionType,
        coveringDataField,
        gridWidth,
        order,
        leftShiftable: false,
        rightShiftable: false,
        feeId,
      });
    } else if (diagrammable && targetCoversPartialParcel) {
      // TODO: Variable width
      const uniformWidth = _.round(gridWidth / coveringsOnThisParcel.length, 1);
      const coveringsAreOrdered = coveringsOnThisParcel.every((df) => {
        return _.isNumber(_.get(df, "fieldContent.order", null));
      });
      let renderableCoverings;

      if (coveringsAreOrdered) {
        renderableCoverings = _.sortBy(coveringsOnThisParcel, [
          function (df) {
            return _.get(df, "fieldContent.order", null);
          },
        ]);
      } else {
        renderableCoverings = coveringsOnThisParcel;
      }

      renderableCoverings.forEach((df) => {
        if (
          _.get(df, "fieldContentId", null) ===
          _.get(coveringDataField, "fieldContentId", null)
        ) {
          gridBlocks.push({
            coveringId: _.get(df, "fieldContentId", null),
            recordType: coveringCollectionType,
            coveringDataField: df,
            gridWidth: uniformWidth,
            order,
            leftShiftable: order !== 0,
            rightShiftable: order < renderableCoverings.length - 1,
            feeId,
          });
        } else {
          gridBlocks.push({
            coveringId: null,
            recordType: null,
            coveringDataField: null,
            gridWidth: uniformWidth,
          });
        }
      });
    } else {
      gridBlocks.push({
        coveringId: null,
        recordType: null,
        coveringDataField: null,
        gridWidth,
      });
    }
  }

  function multiParcelGridRowsFor({
    collectionType = "LandCovering",
    internalRowName = null,
    propertyId,
  }) {
    const coveringCollection = multiParcelLandCoverings.value.filter(
      (dataField) => {
        return dataField.joiningContentId === propertyId;
      },
    );
    return coveringCollection.map((coveringDataField) => {
      const coveredFeeRightIds = _.get(
        coveringDataField,
        "fieldContent.propertyRightIds",
        [],
      );
      let gridBlocks = [];

      feesPlusWidths(propertyId).forEach((feeObject) => {
        updateMultiParcelGridBlocks(
          feeObject,
          gridBlocks,
          coveredFeeRightIds,
          collectionType,
          coveringDataField,
          internalRowName,
        );
      });

      return {
        gridZIndex: gridZIndexFor(coveringDataField, collectionType),
        gridHeight: gridHeightFor(
          coveringDataField,
          collectionType,
          internalRowName,
        ),
        gridColumnWidths: gridBlocks
          .map((coveringObject) => `${coveringObject.gridWidth}%`)
          .join(" "),
        gridBlocks,
        coveredFeeRightIds,
      };
    });
  }
  function updateMultiParcelGridBlocks(
    { feeId, gridWidth },
    gridBlocks,
    coveredFeeRightIds,
    coveringCollectionType,
    coveringDataField,
    internalRowName = null,
  ) {
    let existing = _.find(gridBlocks, {
      coveringId: _.get(coveringDataField, "fieldContentId", null),
    });
    const diagrammable =
      (internalRowName === "Land Coverings (below-grade)" &&
        _.get(
          coveringDataField,
          "fieldContent.hasBelowGradeLandCoveringLevels",
          false,
        )) ||
      (internalRowName && internalRowName === "Land Coverings (above-grade)");

    if (diagrammable && _.includes(coveredFeeRightIds, feeId)) {
      if (existing) {
        existing.gridWidth = _.round(existing.gridWidth + gridWidth, 1);
      } else {
        gridBlocks.push({
          coveringId: _.get(coveringDataField, "fieldContentId", null),
          recordType: coveringCollectionType,
          coveringDataField,
          gridWidth,
        });
      }
    } else {
      gridBlocks.push({
        coveringId: null,
        recordType: null,
        coveringDataField: null,
        gridWidth,
      });
    }
  }

  // ADD/REMOVE FROM DIAGRAM

  function pushPropertyIdToDiagram(id) {
    if (id) {
      createGeographyIntent(id);
      const newIds = _.union(
        [_.toNumber(id)],
        propertyDiagramPropertyIds.value,
      );

      propertyDiagramPropertyIds.value = newIds;
    }
  }

  const debouncedNotifyAirRightsLayer = _.debounce(function () {
    notificationsStore.addNotification("airGroundDiagramOpened");
  }, 500);

  async function handleAirGroundLayerDisplay(dataField) {
    if (dataField.fieldContentType === "Property") {
      const { airLayerPropertyId, groundLayerPropertyId } =
        dataField.fieldContent;

      if (airLayerPropertyId) {
        // TODO: Flash message notifying user why we opened this
        await addPropertyToDiagram(airLayerPropertyId, false);
        clearSelect(airLayerPropertyId);
        debouncedNotifyAirRightsLayer();
      } else if (groundLayerPropertyId) {
        // TODO: Flash message notifying user why we opened this
        await addPropertyToDiagram(groundLayerPropertyId, false);
        clearSelect(groundLayerPropertyId);
        debouncedNotifyAirRightsLayer();
      }
    }
  }

  async function addPropertyDataFieldToDiagram(
    id,
    hidden = false,
    handleAirGround = true,
  ) {
    if (id) {
      const propertyResponse = await api.get(
        `properties/${_.toNumber(id)}?active_change_group_id=${
          activeValidationChangeGroupId.value
        }&scope=draft_visible&template=draft_visible`,
      );

      if (propertyResponse?.data) {
        const dataField = propertyResponse.data;

        if (dataField) {
          if (hidden) {
            hiddenGroundLayerPropertyDataFields.value[
              dataField.fieldContentId
            ] = dataField;
            createVisibilityProperty(dataField.fieldContentId);
          } else {
            propertyDiagramPropertyDataFields.value[dataField.fieldContentId] =
              dataField;
            createVisibilityProperty(dataField.fieldContentId);
            propertyFieldsStore.patchPropertyDataFields(dataField);

            if (propertyIsAirLayer(id)) {
              const groundLayerPropertyId =
                dataField?.fieldContent?.groundLayerPropertyId;
              if (
                !_.includes(
                  propertyDiagramPropertyIds.value,
                  groundLayerPropertyId,
                )
              ) {
                console.log("add ground layer property to hidden fields");
                addPropertyDataFieldToDiagram(groundLayerPropertyId, true);
              }
            }
          }

          const airGroundHandlerEnabled = false;

          if (airGroundHandlerEnabled && handleAirGround && !hidden) {
            await handleAirGroundLayerDisplay(dataField);
          }
        }
      }
    } else {
      return;
    }
  }

  async function createGeographyIntent(id) {
    if (signedIn.value) {
      api.post(`property_geography_intents/${_.toNumber(id)}`);
    } else {
      geographyIntents.value.push({
        propertyId: _.toNumber(id),
        createdAt: new Date(),
      });
    }
  }

  const router = useRouter();

  function navigateToDiagram(id, keepQuery = true, queryAddons = {}) {
    clearPropertyDiagramPropertyIds();
    pushPropertyIdToDiagram(id);
    addPropertyDataFieldToDiagram(id);
    switchToDiagramProperty(id);

    const newQuery = keepQuery
      ? _.merge({}, { ...route.query }, queryAddons)
      : _.merge({}, queryAddons);
    const horizontalTab = activeValidationChangeGroupId.value
      ? "Validations"
      : "Details";
    const verticalTab = activeValidationChangeGroupId.value
      ? "Available"
      : undefined;
    router.push({
      name: "PropertyShell",
      params: { propertyId: id },
      query: _.merge({}, newQuery, {
        propertyIds: propertyDiagramPropertyIds.value,
        horizontalTab,
        verticalTab,
      }),
    });
  }

  function switchToDiagramProperty(id) {
    if (propertyIdParam.value) {
      propertyDiagramSelectedPropertyId.value = id;
    }
  }

  async function addAirLayerPropertyToDiagram(groundLayerPropertyId) {
    const response = await api.get(
      `air_layer_properties/${groundLayerPropertyId}`,
    );

    if (response?.data) {
      const airLayerPropertyId = response.data;
      addPropertyToDiagram(airLayerPropertyId);
      clearSelect(airLayerPropertyId);
    }
  }

  async function addPropertyToDiagram(id, handleAirGround = true) {
    pushPropertyIdToDiagram(id);
    await addPropertyDataFieldToDiagram(id, false, handleAirGround);
    let newPath;

    if (propertyIdParamInDiagram.value) {
      newPath = router.path;
    } else {
      newPath = `/properties/${_.head(propertyDiagramPropertyIds.value)}`;
      propertyDiagramSelectedPropertyId.value = _.head(
        propertyDiagramPropertyIds.value,
      );
    }

    router.push({
      path: newPath,
      query: {
        ...route.query,
        propertyIds: propertyDiagramPropertyIds.value,
      },
    });
  }
  const hovering = ref(null);

  function bringForward(gridBlockObject) {
    setTimeout(() => {
      hovering.value = {
        dataField: _.get(gridBlockObject, "coveringDataField", null),
      };
    }, 200);
  }
  function revertZ() {
    hovering.value = null;
  }

  function cleanUpHiddenGroundLayers(maybeAirLayerId) {
    const dataField = propertyDataFieldFor(maybeAirLayerId);
    const groundLayerPropertyId =
      dataField?.fieldContent?.groundLayerPropertyId;

    if (
      groundLayerPropertyId &&
      !_.includes(propertyDiagramPropertyIds.value, groundLayerPropertyId)
    ) {
      console.log("clean up hidden ground layer", groundLayerPropertyId);
      delete hiddenGroundLayerPropertyDataFields.value[groundLayerPropertyId];
      delete diagramVisibilitySettings.value[groundLayerPropertyId];
    }
  }

  function removePropertyIdFromDiagram(id) {
    const newIds = _.difference(propertyDiagramPropertyIds.value, [
      _.toNumber(id),
    ]);

    propertyDiagramPropertyIds.value = newIds;
    if (neededByAirLayer(id, propertyDiagramPropertyDataFields.value)) {
      hiddenGroundLayerPropertyDataFields.value[id] =
        propertyDiagramPropertyDataFields.value[id];
    } else {
      // delete diagramVisibilitySettings.value[id];
    }
    // cleanUpHiddenGroundLayers(id);
    delete propertyDiagramPropertyDataFields.value[id];
  }

  function removePropertyFromDiagram(propertyId) {
    removePropertyIdFromDiagram(propertyId);
    // if (!hiddenGroundLayerPropertyDataFields.value[propertyId]) {
    //   dropPropertyFields(propertyId);
    // }
    let newPath;

    if (propertyIdParamInDiagram.value) {
      newPath = router.path;
    } else {
      newPath = `/properties/${_.head(propertyDiagramPropertyIds.value)}`;
      propertyDiagramSelectedPropertyId.value = _.head(
        propertyDiagramPropertyIds.value,
      );
      propertyDiagramSelectedLandCoveringId.value = null;
      propertyDiagramSelectedParcelId.value = null;
    }

    router.push({
      path: newPath,
      query: {
        ...route.query,
        propertyIds: propertyDiagramPropertyIds.value,
      },
    });
  }

  function pushToPropertyDiagramAdded(dataFields) {
    const newAdded = _.unionBy(
      propertyDiagramAdded.value,
      dataFields,
      "localId",
    );

    propertyDiagramAdded.value = newAdded;
    const { channel } =
      propertyChannelSubscriptions.value[
        propertyDiagramSelectedPropertyId.value
      ];

    channel.perform("update_diagram_added", {
      propertyId: propertyDiagramSelectedPropertyId.value,
      propertyDiagramAdded: propertyDiagramAdded.value,
    });
  }

  function clearPropertyDiagramPropertyIds() {
    // console.log("clear prop ids");
    propertyDiagramPropertyIds.value = [];
    // diagramVisibilitySettings.value = {};
    propertyDiagramPropertyDataFields.value = {};
    // hiddenGroundLayerPropertyDataFields.value = {};
    propertyDiagramSelectedPropertyId.value = null;
    propertyDiagramSelectedLandCoveringId.value = null;
    propertyDiagramSelectedParcelId.value = null;
    propertyDiagramSelected.value = null;
  }

  function clearSelect(propertyId = null) {
    propertyDiagramSelected.value = null;
    propertyDiagramSelectedPropertyId.value = propertyId;
    propertyDiagramSelectedLandCoveringId.value = null;
    propertyDiagramSelectedParcelId.value = null;
    propertyDiagramActionTrigger.value = null;
    clearEditAction();
  }

  function clearEditAction() {
    propertyDiagramEditAction.value = null;
    propertyDiagramAdded.value = [];
    propertyDiagramLastAdded.value = null;
  }

  watch(applyEditingLock, () => {
    if (applyEditingLock.value) lockedForEditing.value = true;
    else lockedForEditing.value = false;
  });

  return {
    legendLayer,
    fetchingDiagramData,
    innerContentDisplayable,
    allCoverings,
    allHeightCoverings,
    landCoveringDataFields,
    lockedForEditing,
    addedBlocks,
    addingBlocks,
    editingAction,
    selectableContent,
    propertyDiagramActionTrigger,
    propertyDiagramAdded,
    propertyDiagramAddedTypes,
    propertyDiagramAdding,
    propertyDiagramEditAction,
    propertyDiagramLastAdded,
    hiddenGroundLayerPropertyDataFields,
    diagramVisibilitySettings,
    propertyDiagramPropertyDataFields,
    propertyDiagramPropertyIds,
    propertyDiagramRefetch,
    propertyDiagramSelected,
    propertyDiagramSelectedLandCoveringId,
    propertyDiagramSelectedParcelId,
    propertyDiagramSelectedPropertyId,
    propertyIdParam,
    propertyIdParamInDiagram,
    selectedTimelineEvent,
    selectedPropertyIsAirLayer,
    propertySelectedRecordDataField,
    propertyDataField,
    diagramAllFetchedFields,
    diagramAllVisibleFields,
    diagramContentPagy,
    fetchedPopupFields,
    fetchedPopupKeys,
    propertyPatchableTimelineEvents,
    landAreas,
    renderableGridRows,
    gridRowHeights,
    landCoveringUnits,
    landCoveringUnitDataFields,
    feeSimpleDataFields,
    subordinateRightDataFields,
    coveringsWithPercentageHeights,
    coveringsWithZIndexes,
    singleParcelLandCoverings,
    multiParcelLandCoverings,
    propertyEnhancements,
    landCoveringLevelDataFields,
    floorAreaDataFields,
    spaceUsageDataFields,
    verticalLevels,
    maxHeight,
    maxBelowGradeHeight,
    editingMode,
    debouncedfetchDiagramData,
    viewDraftFields,
    authorIds,
    authors,
    authorDatapointCounts,
    fetchAuthor,
    removePropertyFromDiagram,
    resetDiagramPropertyIds,
    clearEditAction,
    scopedFieldsFor,
    bringForward,
    revertZ,
    hasLoneEasement,
    propertyDataFieldFor,
    propertyIsAirLayer,
    propertyLabelFor,
    patchDiagramFields,
    patchFieldContent,
    navigateToDiagram,
    addAirLayerPropertyToDiagram,
    addPropertyToDiagram,
    pushPropertyIdToDiagram,
    pushToPropertyDiagramAdded,
    addPropertyDataFieldToDiagram,
    clearPropertyDiagramPropertyIds,
    clearSelect,
    removeUnusedDiagramData,
    fetchDiagramData,
    interceptablePatch,
    postEditingPatch,
    dropPropertyFields,
    refetchDiagramDataField,
    orderedFeeSimples,
    orderedAirRights,
    feeSingleParcelLandCoveringOrderings,
    feeSimplesWithMultiParcelLandCoverings,
    gridWidths,
    gridWidthsArray,
    airLayerParcelWidths,
    singleParcelGridRowsFor,
    multiParcelGridRowsFor,
    subTypedDataFields,
    leaseholdFor,
    fetchFloorAreaPopupContext,
    diagramContentFor,
    pagyFor,
    loadPaginatedRecords,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePropertyDiagramStore, import.meta.hot),
  );
}
