<template>
  <div class="flex">
    <span class="isolate inline-flex -space-x-px rounded-md shadow-sm">
      <button
        v-for="(action, index) in inlineActions"
        :key="action.name"
        v-tooltip.top-end="action.name"
        type="button"
        class="relative inline-flex items-center bg-white p-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        :class="[index === 0 ? 'rounded-l-md' : '']"
      >
        <span class="sr-only">{{ action.name }}</span>
        <component :is="action.icon" class="size-5" aria-hidden="true" />
      </button>
      <VDropdown
        placement="left"
        class="relative inline-flex items-center"
        @show="emit('more-open')"
        @hide="emit('more-closed')"
      >
        <button
          v-tooltip.top-end="'More'"
          type="button"
          class="relative inline-flex items-center rounded-r-md bg-white p-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          <span class="sr-only">More</span>
          <EllipsisHorizontalIcon class="size-5" aria-hidden="true" />
        </button>
        <template #popper>
          <div
            class="rounded-md bg-white px-2 py-1 ring-1 ring-inset ring-gray-300 divide-y divide-gray-300"
          >
            <ul
              v-for="(menuActions, key) in groupedMoreActions"
              :key="key"
              class="gap-y-1 py-1"
            >
              <li
                v-for="action in menuActions"
                :key="action.name"
                class="w-full flex rounded-md p-1"
                :class="[
                  action.moreActionsSection === '02_confirmationActions'
                    ? 'hover:bg-red-100'
                    : 'hover:bg-gray-100',
                ]"
              >
                <button
                  type="button"
                  class="w-full flex items-center justify-between text-sm font-medium gap-x-6"
                  :class="[
                    action.moreActionsSection === '02_confirmationActions'
                      ? 'text-red-400'
                      : 'text-gray-400',
                  ]"
                  @click.prevent="action.handler"
                >
                  <div class="">{{ action.name }}</div>
                  <component
                    :is="action.icon"
                    class="size-5"
                    aria-hidden="true"
                  />
                </button>
              </li>
            </ul>
          </div>
        </template>
      </VDropdown>
    </span>
  </div>
</template>

<script setup>
import { EllipsisHorizontalIcon } from "@heroicons/vue/20/solid";
import { useWeMessageOutputStore } from "@/stores/weMessageOutput";
import { storeToRefs } from "pinia";

const props = defineProps(["message"]);
const emit = defineEmits(["more-open", "more-closed"]);
const weMessageOutputStore = useWeMessageOutputStore();
const { inlineActions, groupedMoreActions } = storeToRefs(weMessageOutputStore);
</script>
