<template>
  <section aria-labelledby="nearby-companies">
    <ContentHeader :help-article="nearbyCompanies" :no-margin="true">
      <template v-slot:title>Companies</template>
      <template v-slot:description>Discover key players in this area</template>
    </ContentHeader>

    <div class="p-2">
      <div
        v-if="zoom >= 13"
        :class="
          workspaceLayout === 'sideBySide'
            ? 'grid-cols-2'
            : 'grid-cols-6 grid-flow-row-dense'
        "
        class="grid gap-3"
      >
        <div
          v-if="pagysFetching && _.size(groupedInvolvements) === 0"
          :class="
            workspaceLayout === 'sideBySide' ? 'col-span-2' : 'col-span-6'
          "
          class="flex h-full w-full items-center justify-center"
        >
          <SquareLoader :loading="true" size="32px" color="#0d9488" />
        </div>
        <NearbyCompany
          v-for="{
            companyId,
            involvements,
            hunts,
            huntAdvisories,
            propertyGrouped,
          } in groupedInvolvements"
          :key="companyId"
          :company-id="companyId"
          :involvements="involvements"
          :hunts="hunts"
          :hunt-advisories="huntAdvisories"
          :property-grouped="propertyGrouped"
        />
        <section
          v-if="
            _.size(companyFetchData) > 0 &&
            allPagysLoaded &&
            _.size(groupedInvolvements) === 0
          "
          :class="
            workspaceLayout === 'sideBySide' ? 'col-span-2' : 'col-span-6'
          "
        >
          <div
            class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FunnelIcon class="mx-auto h-12 w-12 text-gray-400" />
            <span class="mt-2 block text-sm font-medium text-gray-900">
              No company events match your filters.
            </span>
          </div>
        </section>
        <section
          v-if="_.size(companyFetchData) > 0"
          :class="
            workspaceLayout === 'sideBySide' ? 'col-span-2' : 'col-span-6'
          "
          class="flex flex-col justify-center space-y-3"
        >
          <InfiniteLoading
            v-for="{ pagy, key, count } in combinedPagys"
            :key="key"
            @infinite="
              async ($state) =>
                loadCompanies({
                  state: $state,
                  somePagy: pagy,
                  key,
                  count,
                })
            "
            ><template #complete>...</template></InfiniteLoading
          >
        </section>
        <section
          v-if="
            activeFetchObject?.loadingEvents && _.size(groupedInvolvements) > 0
          "
          :class="
            workspaceLayout === 'sideBySide' ? 'col-span-2' : 'col-span-6'
          "
        >
          <div
            class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <SquareLoader :loading="true" size="28px" color="#0d9488" />
            <span class="mt-2 block text-sm font-medium text-gray-900">
              Loading company events...
            </span>
          </div>
        </section>
      </div>
      <section v-else>
        <div
          class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          :class="
            workspaceLayout === 'sideBySide' ? 'col-span-2' : 'col-span-6'
          "
        >
          <MagnifyingGlassPlusIcon class="mx-auto h-12 w-12 text-gray-400" />
          <span class="mt-2 block text-sm font-medium text-gray-900">
            Zoom in more
          </span>
        </div>
      </section>
    </div>
  </section>
</template>

<script setup>
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import { nearbyCompanies } from "@/assets/documentation/articles/nearbyCompanies";
import { useNearbyCompaniesStore } from "@/stores/nearbyCompanies";
import { useMainMapStore } from "@/stores/mainMap";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useNotificationsStore } from "@/stores/notifications";
import { storeToRefs } from "pinia";
import _ from "lodash";
import { onMounted, computed, watch } from "vue";
import NearbyCompany from "@/components/company-detail/NearbyCompany.vue";
import { FunnelIcon, MagnifyingGlassPlusIcon } from "@heroicons/vue/24/outline";
import api from "@/router/api";

const nearbyCompaniesStore = useNearbyCompaniesStore();
const {
  fetchedFieldIds,
  allPagysLoaded,
  pagysFetching,
  companyFetchData,
  groupedInvolvements,
  activeFetchObject,
} = storeToRefs(nearbyCompaniesStore);
const mapStore = useMainMapStore();
const { mapBoundaryMeta } = storeToRefs(mapStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const analyzePanelStore = useAnalyzePanelStore();
const { surveyCompanyCountProxy } = storeToRefs(analyzePanelStore);
const notificationsStore = useNotificationsStore();

const zoom = computed(() => mapBoundaryMeta.value.zoom);
const groupsCount = computed(() => _.size(groupedInvolvements.value));

const combinedPagys = computed(() => {
  const combined = _.map(
    companyFetchData.value,
    function ({ pagy, count }, key) {
      if (pagy?.next) {
        return { key, pagy, count };
      } else {
        return null;
      }
    },
  );

  return _.compact(combined);
});

watch(
  activeFetchObject,
  () => {
    // console.log("active fetch object", activeFetchObject.value);
  },
  { deep: true },
);

watch(groupsCount, () => {
  setUnlockableCount();
});

onMounted(() => {
  nearbyCompaniesStore.fetchCompanies();
  setUnlockableCount();
});

function setUnlockableCount() {
  if (zoom.value >= 13 && allPagysLoaded.value) {
    surveyCompanyCountProxy.value = groupsCount.value || 0;
  }
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}

async function loadCompanies({ state, somePagy, key, count = 5 }) {
  if (somePagy && key) {
    let existingData = null;
    let loadingEvents = false;
    existingData = companyFetchData.value[key];
    // console.log("paginate companies", key, existingData);
    loadingEvents = existingData.loadingEvents;

    if (somePagy?.next && !loadingEvents) {
      existingData.loadingEvents = true;
      const endpoint = cleanUrl(somePagy.next_url);
      const payload = { alreadyFetchedFieldIds: fetchedFieldIds.value };
      try {
        if (somePagy.next === 2) {
          notificationsStore.addNotification("paginatedDataLoading", 9999, {
            paginationKey: key,
            keyType: "nearbyCompanies",
          });
        }
        api.post(endpoint, payload).then((json) => {
          const { data, pagy } = json.data;
          // console.log("paginated", key, data);

          existingData.data = _.uniqBy(
            _.concat(existingData.data, data),
            function (maybeField) {
              if (_.isObject(maybeField)) {
                return maybeField.localId;
              } else {
                return maybeField;
              }
            },
          );
          existingData.pagy = pagy;

          if (data.length < count || !pagy.next) {
            state.complete();
            existingData.loadingEvents = false;
            existingData.loaded = true;
          } else {
            state.loaded();
            existingData.loadingEvents = false;
          }
        });
      } catch (error) {
        state.error();
        existingData.loadingEvents = false;
      }
    } else {
      state.complete();
      existingData.loaded = true;
    }
  }
}
</script>
