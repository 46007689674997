import { storeToRefs } from "pinia";
import parcelPopup from "@/components/property-diagram/parcelPopup";
import _ from "lodash";

export async function addPolygonToMap({
  mode = "store",
  dataField,
  mapStore,
  featureGroup,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  layoutStore,
  secondaryPanelStore,
  documentationStore,
  analyzePanelStore,
  horizontalIsAnalyze,
}) {
  const { map } = storeToRefs(mapStore);
  const { propertyDiagramSelected, propertyIdParam } =
    storeToRefs(propertyDiagramStore);
  const {
    investmentsChecked,
    loansChecked,
    spaceAvailabilitiesChecked,
    spaceUsagesChecked,
    checkedCategories,
    combinedFilteredPropertyIds,
    datedInvestmentPropertyIds,
    datedAvailabilityPropertyIds,
  } = storeToRefs(analyzePanelStore);

  const propertyRightType = _.get(
    propertyDiagramSelected.value,
    "dataField.fieldContentSubType",
    null,
  );
  const idComparisonPath =
    propertyRightType && propertyRightType === "fee_simple"
      ? "dataField.fieldContentId"
      : "dataField.decoratingContentId";
  const fieldContentType = _.get(
    propertyDiagramSelected.value,
    "dataField.fieldContentType",
    null,
  );
  const decoratingContentType = _.get(
    propertyDiagramSelected.value,
    "dataField.decoratingContentType",
    null,
  );
  const typeMatch =
    fieldContentType === "PropertyRight" ||
    decoratingContentType === "PropertyRight";

  let canDisplay = false;

  if (mode === "store") {
    const { minimapSize } = storeToRefs(mapStore);
    canDisplay = !propertyIdParam.value || minimapSize?.value === "compact";
  }

  const parcel = dataField.fieldContent;
  const idMatch =
    dataField.decoratingContentId ===
    _.get(propertyDiagramSelected.value, idComparisonPath, null);
  const excludedProperty =
    checkedCategories.value.length > 0 &&
    !_.includes(combinedFilteredPropertyIds.value, dataField.joiningContentId);

  const excludedInvestment =
    (investmentsChecked.value || loansChecked.value) &&
    !_.includes(datedInvestmentPropertyIds.value, dataField.joiningContentId);
  const excludedAvailability =
    (spaceAvailabilitiesChecked.value || spaceUsagesChecked.value) &&
    !_.includes(datedAvailabilityPropertyIds.value, dataField.joiningContentId);
  const visuallyExcluded =
    horizontalIsAnalyze &&
    (excludedProperty || (excludedInvestment && excludedAvailability));

  const color = typeMatch && idMatch ? "#FDBA74" : "#99F6E4"; // orange-300 : teal-200
  const polygon = L.polygon(parcel.coordinates, {
    interactive: mode === "store" && canDisplay,
    color: visuallyExcluded ? "#9ca3af" : color, // excluded: gray-400
    stroke: true,
    weight: map.value.getZoom() > 15 ? 3 : 1.5,
    fill: true,
  });

  featureGroup.addLayer(polygon);
  parcel.mapInternalId = featureGroup.getLayerId(polygon);
  if (mode === "store") {
    mapStore.setNearbyDataField({
      dataField,
      locationType: "ContentPolygon",
    });

    polygon.on("click", () => {
      const responsivePopup = parcelPopup({
        mapStore,
        propertyFieldsStore,
        propertyDiagramStore,
        changeGroupStore,
        modalStore,
        unlockerStore,
        userStore,
        tasksStore,
        taskListStore,
        reminderStore,
        guestProfileStore,
        layoutStore,
        secondaryPanelStore,
        documentationStore,
        parcelDataField: dataField,
      });

      if (idMatch && typeMatch) {
        console.log("no_op");
      } else {
        polygon.setStyle({
          color: visuallyExcluded ? "#6b7280" : "#2DD4BF", // gray-600 : teal-400
        });
      }

      polygon
        .unbindPopup()
        .bindPopup(responsivePopup, { minWidth: 230 })
        .openPopup();
    });

    polygon.on("popupclose", () => {
      if (idMatch && typeMatch) {
        return;
      } else {
        polygon.setStyle({
          color: visuallyExcluded ? "#9ca3af" : "#99F6E4", // gray-400 : teal-200
        });
      }
    });
  }
}
