import { storeToRefs } from "pinia";
import { unlockable } from "@/assets/dataFieldHelpers";
import _ from "lodash";

export const staticInvolvementGroupings = [
  {
    name: "Unlockable",
    borderColor: "border-gray-400",
    textColor: "text-gray-400",
    shadowColor: "shadow-gray-400/50",
    badge: "bg-gray-50 text-gray-600 ring-gray-500/10",
    emptyStateMessage:
      "Unlockable market activity for the company can appear here.",
  },
  {
    name: "Owner",
    borderColor: "border-yellow-400",
    textColor: "text-yellow-400",
    shadowColor: "shadow-yellow-400/50",
    badge: "bg-yellow-50 text-yellow-800 ring-yellow-600/10",
    emptyStateMessage:
      "Ownership interests and live deal pursuits can appear here",
  },
  {
    name: "User",
    borderColor: "border-sky-400",
    textColor: "text-sky-400",
    shadowColor: "shadow-sky-400/50",
    badge: "bg-sky-50 text-sky-800 ring-sky-600/10",
    emptyStateMessage: "Space usages can appear here",
  },
  {
    name: "Lender",
    borderColor: "border-blue-600",
    textColor: "text-blue-600",
    shadowColor: "shadow-blue-600/50",
    badge: "bg-blue-50 text-blue-700 ring-blue-700/10",
    emptyStateMessage: "Debt holdings can appear here.",
  },
  {
    name: "Advisor",
    borderColor: "border-teal-400",
    textColor: "text-teal-400",
    shadowColor: "shadow-teal-400/50",
    badge: "bg-teal-50 text-teal-600 ring-teal-700/10",
    emptyStateMessage: "Service-based client engagements can appear here.",
  },
  {
    name: "Hunting",
    borderColor: "border-rose-400",
    textColor: "text-rose-400",
    shadowColor: "shadow-rose-400/50",
    badge: "bg-rose-50 text-rose-600 ring-rose-700/10",
    emptyStateMessage: "Investment and/or space hunts can appear here.",
  },
];

export function analyzedInvolvements(
  involvementDataFields = [],
  analyzePanelStore,
  context = "mainMap",
) {
  const {
    spaceUsageAvailabilityLikeFilterable,
    investmentLikeFilterable,
    investmentsChecked,
    loansChecked,
    spaceAvailabilitiesChecked,
    spaceUsagesChecked,
    companiesChecked,
    selectedCompanyRoles,
    selectedCompanyEventTypes,
    valueFilteredHuntIds,
    occupierSizeFilteredHuntIds,
    useTypeFilteredHuntIds,
    investmentTypeFilteredHuntIds,
    polygonHuntIds,
    geographyFilterPolygon,
  } = storeToRefs(analyzePanelStore);

  return involvementDataFields.filter((dataField) => {
    // COMPANY ROLES
    const prospectSelected = _.includes(selectedCompanyRoles.value, "Prospect");
    const principalSelected = _.includes(
      selectedCompanyRoles.value,
      "Principal",
    );
    const principalRoleIncluded = principalSelected
      ? (isEquity(dataField) || isUser(dataField) || isDebt(dataField)) &&
        (prospectSelected ? true : !isProspect(dataField))
      : false;

    const advisorySelected = _.includes(selectedCompanyRoles.value, "Advisor");
    let advisorRoleIncluded = false;

    if (advisorySelected) {
      if (prospectSelected) {
        advisorRoleIncluded =
          isAdvisory(dataField) ||
          (isProspect(dataField) &&
            !isEquity(dataField) &&
            !isUser(dataField) &&
            !isDebt(dataField));
      } else {
        advisorRoleIncluded = isAdvisory(dataField) && !isProspect(dataField);
      }
    }

    // AVAILABILITIES
    const leasingDealEventsIncluded = _.includes(
      selectedCompanyEventTypes.value,
      "leasing",
    );
    const availabilityId = dataField.fieldContent?.availabilityId;
    const availabilityPortfolioId =
      dataField.fieldContent?.availability?.portfolioId;
    let filteredAvailabilityIncluded = leasingDealEventsIncluded;

    if (
      filteredAvailabilityIncluded &&
      (context === "detailPage" ||
        spaceAvailabilitiesChecked.value ||
        spaceUsagesChecked.value ||
        companiesChecked.value)
    ) {
      filteredAvailabilityIncluded =
        analyzePanelStore.combinedFilteredSpaceAvailabilityIncluded(
          availabilityId,
        );
      // console.log(
      //   "filtered availability check",
      //   availabilityId,
      //   filteredAvailabilityIncluded,
      // );
    }

    const sizeIncluded = spaceUsageAvailabilityLikeFilterable.value
      ? analyzePanelStore.spaceUsageAvailabilityLikeIncluded(
          availabilityId,
          availabilityPortfolioId,
        )
      : true;
    const availabilityFilterIncluded =
      filteredAvailabilityIncluded &&
      sizeIncluded &&
      (principalRoleIncluded || advisorRoleIncluded);

    // INVESTMENTS
    const capMarketsDealEventsIncluded = _.includes(
      selectedCompanyEventTypes.value,
      "capMarkets",
    );
    const investmentId = dataField.fieldContent?.investmentId;
    const portfolioId = dataField.fieldContent?.investment?.portfolioId;
    let filteredInvestmentIncluded = capMarketsDealEventsIncluded;

    if (
      filteredInvestmentIncluded &&
      (context === "detailPage" ||
        investmentsChecked.value ||
        loansChecked.value ||
        companiesChecked.value)
    ) {
      filteredInvestmentIncluded =
        analyzePanelStore.combinedFilteredInvestmentIncluded(investmentId);
      // console.log(
      //   "filtered investment check",
      //   investmentId,
      //   filteredInvestmentIncluded,
      // );
    }
    const valuationIncluded = investmentLikeFilterable.value
      ? analyzePanelStore.investmentLikeIncluded(investmentId, portfolioId)
      : true;
    const investmentFilterIncluded =
      filteredInvestmentIncluded &&
      valuationIncluded &&
      (principalRoleIncluded || advisorRoleIncluded);

    // HUNTS
    const capMarketsHuntEventsIncluded = _.includes(
      selectedCompanyEventTypes.value,
      "capMarketsHunt",
    );
    const leasingHuntEventsIncluded = _.includes(
      selectedCompanyEventTypes.value,
      "leasingHunt",
    );
    let huntLocationIncluded = true;

    if (huntIdFor(dataField) && geographyFilterPolygon.value) {
      huntLocationIncluded = _.includes(
        polygonHuntIds.value,
        huntIdFor(dataField),
      );
    }

    const huntUseIncluded =
      huntIdFor(dataField) &&
      _.includes(useTypeFilteredHuntIds.value, huntIdFor(dataField));
    let huntEventIncluded = false;

    if (
      huntUseIncluded &&
      investmentHunt(dataField) &&
      capMarketsHuntEventsIncluded
    ) {
      huntEventIncluded = true;
    } else if (
      huntUseIncluded &&
      occupierHunt(dataField) &&
      leasingHuntEventsIncluded
    ) {
      huntEventIncluded = true;
    }

    let huntCompanyRoleIncluded = false;
    if (isHuntAdvisory(dataField) && advisorySelected) {
      huntCompanyRoleIncluded = true;
    } else if (isHunt(dataField) && principalSelected) {
      huntCompanyRoleIncluded = true;
    }

    let huntOccupierSizeIncluded = true;

    if (
      huntIdFor(dataField) &&
      spaceUsageAvailabilityLikeFilterable.value &&
      occupierHunt(dataField)
    ) {
      huntOccupierSizeIncluded = _.includes(
        occupierSizeFilteredHuntIds.value,
        huntIdFor(dataField),
      );
    }

    let huntValuationIncluded = true;

    if (
      huntIdFor(dataField) &&
      investmentLikeFilterable.value &&
      investmentHunt(dataField)
    ) {
      huntValuationIncluded = _.includes(
        valueFilteredHuntIds.value,
        huntIdFor(dataField),
      );
    }

    let huntInvestmentTypeIncluded = true;

    if (
      huntUseIncluded &&
      investmentHunt(dataField) &&
      capMarketsHuntEventsIncluded
    ) {
      huntInvestmentTypeIncluded = _.includes(
        investmentTypeFilteredHuntIds.value,
        huntIdFor(dataField),
      );
    }

    let contentlessHunt =
      context === "detailPage" && huntIdFor(dataField) && emptyHunt(dataField);

    // COMBINING FILTERS
    const dealFilterIncluded =
      availabilityFilterIncluded || investmentFilterIncluded;
    const huntFilterIncluded =
      huntEventIncluded &&
      huntLocationIncluded &&
      huntCompanyRoleIncluded &&
      huntOccupierSizeIncluded &&
      huntValuationIncluded &&
      huntInvestmentTypeIncluded;

    // console.log(
    //   dataField.fieldContent?.name,
    //   "included event types",
    //   capMarketsDealEventsIncluded,
    //   leasingDealEventsIncluded,
    //   capMarketsHuntEventsIncluded,
    //   leasingHuntEventsIncluded,
    //   "meta",
    //   investmentLikeFilterable.value,
    //   spaceUsageAvailabilityLikeFilterable.value,
    //   dataField,
    //   "company role",
    //   principalSelected,
    //   advisorySelected,
    //   prospectSelected,
    //   principalRoleIncluded,
    //   advisorRoleIncluded,
    //   "availability",
    //   availabilityId,
    //   availabilityPortfolioId,
    //   filteredAvailabilityIncluded,
    //   sizeIncluded,
    //   availabilityFilterIncluded,
    //   "investment",
    //   investmentId,
    //   portfolioId,
    //   filteredInvestmentIncluded,
    //   valuationIncluded,
    //   investmentFilterIncluded,
    //   "hunt",
    //   huntUseIncluded,
    //   huntEventIncluded,
    //   huntLocationIncluded,
    //   huntCompanyRoleIncluded,
    //   huntOccupierSizeIncluded,
    //   huntValuationIncluded,
    //   huntInvestmentTypeIncluded,
    //   contentlessHunt,
    //   "overall",
    //   huntFilterIncluded,
    //   contentlessHunt,
    //   dealFilterIncluded,
    // );
    return huntFilterIncluded || contentlessHunt || dealFilterIncluded;
  });
}

export function propertyGrouped(involvementDataFields) {
  const grouped = _.groupBy(involvementDataFields, propertyIdFor);
  delete grouped.undefined;
  delete grouped.null;

  if (_.isEmpty(grouped)) {
    return null;
  } else {
    return grouped;
  }
}
export function uniqueEquity(involvementDataField) {
  return `${_.get(investmentFor(involvementDataField), "assetType")}${_.get(
    investmentFor(involvementDataField),
    "assetSubType",
  )}${_.get(investmentFor(involvementDataField), "assetId")}`;
}
export function isEquity(dataField) {
  const equityDealType = !_.includes(
    ["originateLoan", "refinance"],
    dataField.fieldContent?.investment?.dealAction,
  );
  const owner =
    dataField.decoratingContentType === "OwnershipInterest" &&
    dataField.fieldContentSubType !== "operational_advisor" &&
    dataField.fieldContent?.asset?.recordType !== "Loan";
  const prospect =
    dataField.decoratingContentType === "Investment" &&
    dataField.fieldContentSubType === "prospect";
  const equityPartner =
    dataField.decoratingContentType === "CompanyInvolvement" &&
    dataField.fieldContentSubType === "prospect_advisor_partner" &&
    _.some(dataField.fieldContent?.involvementRoles, function (roleField) {
      return roleField.fieldContent?.name === "Equity partner";
    });
  const internalCapitalMarkets =
    dataField.decoratingContentType === "Investment" &&
    dataField.fieldContentSubType !== "prospect" &&
    _.includes(
      ["internalDispositions", "internalAcquisitions"],
      dataField.fieldContent?.clientRole,
    );
  return (
    equityDealType &&
    (owner || prospect || equityPartner || internalCapitalMarkets)
  );
}
export function isDebt(dataField) {
  const debtDealType = _.includes(
    ["originateLoan", "refinance"],
    dataField.fieldContent?.investment?.dealAction,
  );
  const lender =
    dataField.decoratingContentType === "OwnershipInterest" &&
    dataField.fieldContentSubType !== "operational_advisor" &&
    (dataField.fieldContent?.asset?.recordType === "Loan" || debtDealType);
  const prospect =
    dataField.decoratingContentType === "Investment" &&
    dataField.fieldContentSubType === "prospect" &&
    debtDealType;
  return lender || prospect;
}
export function isAdvisory(dataField) {
  const operational =
    dataField.decoratingContentType === "OwnershipInterest" &&
    dataField.fieldContentSubType === "operational_advisor";
  const hunt = _.includes(
    ["Hunt", "HuntGeographyIntent"],
    dataField.decoratingContentType,
  );
  const availability = _.includes(
    ["SpaceAvailability"],
    dataField.decoratingContentType,
  ) &&
  !_.includes(
    ["internalRealEstate"],
    dataField.fieldContent?.clientRole,
  );
  const loan = _.includes(["Loan"], dataField.decoratingContentType);
  const hasClientRole = !!dataField.fieldContent?.clientRole;
  const capitalMarkets =
    dataField.decoratingContentType === "Investment" &&
    dataField.fieldContentSubType !== "prospect" &&
    !_.includes(
      ["internalDispositions", "internalAcquisitions"],
      dataField.fieldContent?.clientRole,
    );
  return (
    operational ||
    (hunt && hasClientRole) ||
    (availability && hasClientRole) ||
    (loan && hasClientRole) ||
    capitalMarkets
  );
}
export function isHuntAdvisory(dataField) {
  return (
    isAdvisory(dataField) &&
    _.includes(["Hunt", "HuntGeographyIntent"], dataField.decoratingContentType)
  );
}
export function isHunt(dataField) {
  const hunt = dataField.fieldContentType === "Hunt";
  const huntLink = _.includes(
    ["Hunt", "HuntGeographyIntent"],
    dataField.decoratingContentType,
  );
  return hunt || (huntLink && !isAdvisory(dataField));
}
function huntIdFor(dataField) {
  if (dataField.fieldContentType === "Hunt") {
    return dataField.fieldContentId;
  } else if (
    dataField.fieldContentType === "CompanyInvolvement" &&
    _.includes(["Hunt", "HuntGeographyIntent"], dataField.decoratingContentType)
  ) {
    return _.get(dataField, "fieldContent.huntId");
  } else {
    return null;
  }
}
function emptyHunt(dataField) {
  return dataField.fieldContent?.empty;
}
function investmentHunt(dataField) {
  return (
    dataField.fieldContent?.hasInvestmentCriteria ||
    dataField.fieldContent?.huntHasInvestmentCriteria
  );
}
function occupierHunt(dataField) {
  return (
    dataField.fieldContent?.hasSpaceRequirements ||
    dataField.fieldContent?.huntHasSpaceRequirements
  );
}
export function spaceUsageOnTheHunt(involvementDataField) {
  return (
    _.get(involvementDataField, "fieldContent.spaceUsageTriggeringHuntIds", [])
      .length > 0
  );
}
export function huntType(dataField) {
  const hunt = dataField.fieldContentType === "Hunt";
  if (hunt && !unlockable(dataField)) {
    const hasTriggeringUsages = _.get(
      dataField,
      "fieldContent.hasTriggeringUsages",
    );
    const hasSpaceRequirements = _.get(
      dataField,
      "fieldContent.hasSpaceRequirements",
    );
    const hasInvestmentCriteria = _.get(
      dataField,
      "fieldContent.hasInvestmentCriteria",
    );
    const investing =
      hasInvestmentCriteria && _.isBoolean(hasInvestmentCriteria);
    const leasing = hasSpaceRequirements || hasTriggeringUsages;
    let leasingLabel = "";

    if (leasing) {
      leasingLabel = hasTriggeringUsages ? "Relocation" : "Add-On Location";
    }

    let investingLabel = "";

    if (investing && leasing) {
      investingLabel = " (can Buy)";
    } else if (investing) {
      investingLabel = "Capital Markets";
    }

    return `${leasingLabel}${investingLabel}`;
  }
}
export function isUser(dataField) {
  const usage = _.includes(["SpaceUsage"], dataField.decoratingContentType);
  const availability = _.includes(
    ["SpaceAvailability"],
    dataField.decoratingContentType,
  );
  return usage || (availability && isProspect(dataField));
}
export function isProspect(involvementDataField) {
  return _.includes(
    ["prospect", "prospect_advisor_partner"],
    involvementSubTypeFor(involvementDataField),
  );
}
export function isLiveInvestment(involvementDataField) {
  return investmentStateFor(involvementDataField) === "live";
}
export function isUnderContractInvestment(involvementDataField) {
  return investmentStateFor(involvementDataField) === "under_contract";
}
export function isWithdrawnInvestment(involvementDataField) {
  return investmentStateFor(involvementDataField) === "withdrawn";
}
export function assetInPlay(involvementDataField) {
  return (
    (isEquity(involvementDataField) || isDebt(involvementDataField)) &&
    _.get(assetMetaFor(involvementDataField), "inPlay")
  );
}

function involvementRoleFieldsFor(involvementDataField) {
  return _.get(involvementDataField, "fieldContent.involvementRoles", []);
}
export function involvementRolesFor(involvementDataField) {
  return _.compact(
    involvementRoleFieldsFor(involvementDataField).map((field) => {
      const roleName = _.startCase(_.get(field, "fieldContent.name"));
      const dealAction =
        involvementDataField?.fieldContent?.investment?.dealAction;

      if (roleName === "Capital Markets Broker") {
        switch (dealAction) {
          case "originateLoan":
          case "refinance":
            return "Debt broker";
          default:
            return "Sales broker";
        }
      } else {
        return roleName;
      }
    }),
  );
}
function involvementSubTypeFor(involvementDataField) {
  return _.get(involvementDataField, "fieldContentSubType");
}
export function investmentFor(involvementDataField) {
  return _.get(involvementDataField, "fieldContent.investment");
}
export function availabilityFor(involvementDataField) {
  return _.get(involvementDataField, "fieldContent.availability");
}
export function clientNameFor(involvementDataField) {
  const name = _.get(involvementDataField, "fieldContent.huntClient.name");

  if (name) {
    return `Client: ${name}`;
  } else {
    return null;
  }
}
function investmentStateFor(involvementDataField) {
  return _.get(investmentFor(involvementDataField), "state");
}
export function spaceDataFieldFor(involvementDataField) {
  return _.get(availabilityFor(involvementDataField), "space");
}
export function assetDataFieldFor(involvementDataField) {
  return _.get(investmentFor(involvementDataField), "asset");
}
export function assetMetaFor(involvementDataField) {
  return _.get(involvementDataField, "fieldContent.asset");
}
export function assetTypeFor(involvementDataField) {
  return (
    _.get(assetDataFieldFor(involvementDataField), "fieldContentType") ||
    _.get(assetMetaFor(involvementDataField), "recordType")
  );
}
export function assetIdFor(involvementDataField) {
  return (
    _.get(assetDataFieldFor(involvementDataField), "fieldContentId") ||
    _.get(assetMetaFor(involvementDataField), "id")
  );
}

export function propertyIdFor(involvementDataField) {
  return (
    _.get(assetDataFieldFor(involvementDataField), "joiningContentId") ||
    _.get(assetMetaFor(involvementDataField), "propertyId") ||
    _.get(involvementDataField, "propertyId")
  );
}
export function isLoan(involvementDataField) {
  return _.get(assetMetaFor(involvementDataField), "recordType") === "Loan";
}
export function assetSubTypeFor(involvementDataField) {
  const assetType =
    _.get(assetDataFieldFor(involvementDataField), "fieldContentSubType") ||
    _.get(assetMetaFor(involvementDataField), "type");
  const underlyingRightType =
    assetType === "equity_entity"
      ? _.get(assetDataFieldFor(involvementDataField), "fieldContent.type")
      : assetType;

  if (isLoan(involvementDataField)) {
    return `${underlyingRightType || "Lender equity"} ${_.get(
      assetMetaFor(involvementDataField),
      "seniority",
      "",
    )} ${_.get(assetMetaFor(involvementDataField), "recordType", "")}`;
  } else {
    return assetType;
  }
}
function assetKeyFor(field) {
  if (isHunt(field)) {
    return `${field?.fieldContentType}${field?.fieldContentId}`;
  } else if (isHuntAdvisory(field) || isUser(field)) {
    return `${field?.decoratingContentType}${field?.decoratingContentId}`;
  } else {
    return `${assetSubTypeFor(field)}${assetTypeFor(field)}${assetIdFor(
      field,
    )}`;
  }
}
export function matchingInvolvement(fieldToTest, field2) {
  const keyMatch =
    fieldToTest && field2 && assetKeyFor(fieldToTest) === assetKeyFor(field2);
  const idMatch = isHunt(fieldToTest)
    ? fieldToTest?.decoratingContentId === field2?.decoratingContentId &&
      fieldToTest?.decoratingContentType === field2?.decoratingContentType
    : fieldToTest?.fieldContentId === field2?.fieldContentId;
  const combinedMatch = idMatch || keyMatch;

  return !!field2 && combinedMatch;
}
export function isFocalInvolvement(
  fieldToTest,
  hoveringField,
  storeSelectedField,
) {
  return (
    matchingInvolvement(fieldToTest, storeSelectedField) ||
    matchingInvolvement(fieldToTest, hoveringField)
  );
}
