import { ref, computed, watch, markRaw } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { useModalStore } from "@/stores/modal";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useNotificationsStore } from "@/stores/notifications";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import VerifyEmailPrompt from "@/components/users/subscribe-prompts/VerifyEmailPrompt.vue";
import api from "@/router/api";
import _ from "lodash";

export const useUserStore = defineStore("user", () => {
  const layoutStore = useWorkspaceLayoutStore();
  const { isDesktop } = storeToRefs(layoutStore);
  const notificationsStore = useNotificationsStore();
  const adminMode = ref(false);
  const currentUser = ref(null);
  const confirmationResent = ref(false);
  const userGeographyIntents = ref([]);
  const isAdmin = computed(
    () => !!_.get(currentUser.value, "admin") || adminMode.value,
  );
  const signedIn = computed(() => !!_.get(currentUser.value, "signedIn"));
  const emailUnverified = computed(
    () =>
      signedIn.value &&
      _.get(currentUser.value, "emailVerificationState") === "unverified",
  );
  const emailVerified = computed(
    () =>
      signedIn.value &&
      _.get(currentUser.value, "emailVerificationState") === "verified",
  );
  const firstName = computed(() => _.get(currentUser.value, "firstName"));
  const activeEasyDataInputPayloadItem = computed({
    get() {
      return _.get(currentUser.value, "activeEasyDataInputPayloadItem");
    },
    set(newItem) {
      currentUser.value.activeEasyDataInputPayloadItem = newItem;
    },
  });
  const activePayloadItemPaused = computed(
    () => activeEasyDataInputPayloadItem.value?.paused,
  );
  const availableBalance = computed({
    get() {
      return _.get(currentUser.value, "availableBalance");
    },
    set(newBalance) {
      currentUser.value.availableBalance = newBalance;
    },
  });
  const reputation = computed({
    get() {
      return _.get(currentUser.value, "reputation");
    },
    set(newReputation) {
      currentUser.value.reputation = newReputation;
    },
  });
  const reputationLevel = computed({
    get() {
      return _.get(currentUser.value, "reputationLevel");
    },
    set(newLevel) {
      currentUser.value.reputationLevel = newLevel;
    },
  });
  const reputable = computed({
    get() {
      return _.get(currentUser.value, "reputable");
    },
    set(newReputable) {
      currentUser.value.reputable = newReputable;
    },
  });
  const correctPercentage = computed({
    get() {
      return _.get(currentUser.value, "correctPercentage");
    },
    set(newCorrectPercentage) {
      currentUser.value.correctPercentage = newCorrectPercentage;
    },
  });
  const validationSkips = computed({
    get() {
      return _.get(currentUser.value, "validationSkips");
    },
    set(newSkips) {
      currentUser.value.validationSkips = newSkips;
    },
  });
  const sessionGeographyIntents = computed({
    get() {
      return _.get(currentUser.value, "sessionGeographyIntents");
    },
    set(newCount) {
      currentUser.value.sessionGeographyIntents = newCount;
    },
  });
  const linked = computed({
    get() {
      return _.get(currentUser.value, "linked");
    },
    set(bool) {
      currentUser.value.linked = bool;
    },
  });
  const linkRequestPending = computed({
    get() {
      return _.get(currentUser.value, "linkRequestPending");
    },
    set(bool) {
      currentUser.value.linkRequestPending = bool;
    },
  });
  const digestConsent = computed({
    get() {
      return _.get(currentUser.value, "digestConsent");
    },
    set(bool) {
      persistDigestConsent(bool);
    },
  });

  watch(activePayloadItemPaused, () => {
    if (activePayloadItemPaused.value) {
      api.post(`payload_item_inputter_pausings`);
    } else {
      api.delete(`payload_item_inputter_pausings`);
    }
  });

  const linkable = computed(() => !linked.value && !linkRequestPending.value);
  const availableCollectibleCardCount = ref(0);

  const guestProfile = useGuestProfileStore();
  const { geographyIntents, discoverySearches, completedCardIds } =
    storeToRefs(guestProfile);
  function fetchAvailableCollectibleCardCount() {
    return new Promise((resolve) => {
      const payload = {
        cardIds: signedIn.value ? [] : completedCardIds.value,
        geographyIntents: signedIn.value ? [] : geographyIntents.value,
        discoverySearches: signedIn.value ? [] : discoverySearches.value,
        mobile: !isDesktop.value,
      };
      api.post(`my_collectible_cards_count`, payload).then((json) => {
        availableCollectibleCardCount.value = json.data;
        resolve();
      });
    });
  }
  function resendConfirmation() {
    api.post("email_verifications").then(() => {
      confirmationResent.value = true;

      setTimeout(() => {
        confirmationResent.value = false;
      }, 30000);
    });
  }
  async function persistDigestConsent(bool) {
    const response = await api.patch(`digest_consents`, {
      digestConsent: bool,
    });

    if (
      (response?.data === true || response?.data === false) &&
      currentUser.value
    ) {
      currentUser.value.digestConsent = response.data;
      notificationsStore.addNotification("digestConsentUpdated");
    }
  }

  const emailRegex = RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

  const router = useRouter();
  const route = useRoute();

  function viewAccount() {
    router.push({
      name: route.name,
      query: {
        horizontalTab: "Account",
        verticalTab: "Settings",
      },
    });
  }
  const modalStore = useModalStore();
  const { modalPayload } = storeToRefs(modalStore);

  function promptToVerify() {
    modalPayload.value = {
      size: "base",
      theme: "light",
      component: markRaw(VerifyEmailPrompt),
      props: {},
    };
  }

  async function linkContact(contactId) {
    if (emailUnverified.value) {
      promptToVerify();
    } else if (!linked.value && !linkRequestPending.value) {
      const response = await api.post(`contact_user_link_requests`, {
        contactId,
      });

      if (response?.data) {
        linkRequestPending.value = true;
        notificationsStore.addNotification("linkRequestSubmitted");
      }
    }
  }

  async function refreshActiveInputPayloadItem() {
    if (activeEasyDataInputPayloadItem.value) {
      const response = await api.get(`my_active_easy_data_input_payload_item`);

      if (response?.data) {
        activeEasyDataInputPayloadItem.value = response.data;
      }
    }
  }

  function setAdminMode() {
    adminMode.value = localStorage.getItem("adminToken");
  }

  return {
    adminMode,
    currentUser,
    confirmationResent,
    firstName,
    isAdmin,
    signedIn,
    userGeographyIntents,
    emailUnverified,
    emailVerified,
    activeEasyDataInputPayloadItem,
    availableBalance,
    reputation,
    reputationLevel,
    reputable,
    linked,
    linkRequestPending,
    linkable,
    digestConsent,
    correctPercentage,
    validationSkips,
    sessionGeographyIntents,
    availableCollectibleCardCount,
    fetchAvailableCollectibleCardCount,
    refreshActiveInputPayloadItem,
    resendConfirmation,
    viewAccount,
    promptToVerify,
    linkContact,
    setAdminMode,
    emailRegex,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
