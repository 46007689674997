<template>
  <div
    :class="[expandedView ? 'justify-center' : 'flex-col space-y-1']"
    class="relative flex items-center py-2 bg-indigo-600"
  >
    <template v-if="expandedView">
      <div v-if="referrerIsDiscover" class="absolute left-2">
        <button
          type="button"
          class="rounded-md px-2 py-1 text-xs font-semibold text-gray-100 shadow-sm ring-1 ring-inset ring-gray-100 hover:bg-indigo-500"
        >
          &larr;&nbsp;&nbsp;Back
        </button>
      </div>
      <div class="flex items-center justify-center gap-x-1">
        <div class="text-sm/6 text-white">
          You are currently in preview mode.
        </div>
        <div class="flex mx-2">
          <button
            type="button"
            class="rounded-md px-2 py-1 text-xs font-bold text-gray-100 shadow-sm ring-1 ring-inset ring-gray-100 hover:bg-indigo-500"
          >
            Join {{ activeOption.name }}
          </button>
        </div>
        <div class="text-sm/6 text-white">
          to send messages and track updates.
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-sm/6 text-white">You are currently in preview mode.</div>
      <div class="flex items-center gap-6">
        <button
          v-if="referrerIsDiscover"
          type="button"
          class="rounded-md px-2 py-1 text-xs font-semibold text-gray-100 shadow-sm ring-1 ring-inset ring-gray-100 hover:bg-indigo-500"
          @click="back"
        >
          &larr;&nbsp;&nbsp;Back
        </button>
        <button
          type="button"
          class="rounded-md px-2 py-1 text-xs font-bold text-gray-100 shadow-sm ring-1 ring-inset ring-gray-100 hover:bg-indigo-500"
          @click="join"
        >
          Join {{ activeOption.name }}
        </button>
      </div>
    </template>
  </div>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const wePanelStore = useWePanelStore();
const { expandedView, activeOption } = storeToRefs(wePanelStore);

const referrerIsDiscover = computed(() => false);

function back() {
  console.log("TODO: BACK TO DISCOVERY")
}

function join() {
  console.log("TODO: JOIN WE HUB")
}
</script>
