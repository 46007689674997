import { defineStore, acceptHMRUpdate } from "pinia";
import { useMainMapStore } from "@/stores/mainMap";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useNotificationsStore } from "@/stores/notifications";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { ref, computed, watch, nextTick } from "vue";
import { useRoute } from "vue-router";
import stateAbbreviationsUS from "@/assets/stateAbbreviationsUS";
import {
  staticInvolvementGroupings,
  isEquity,
  isUser,
  isDebt,
  isAdvisory,
  isProspect,
  isHunt,
  isHuntAdvisory,
  assetTypeFor,
  assetIdFor,
  propertyIdFor,
  uniqueEquity,
  propertyGrouped,
  analyzedInvolvements,
} from "@/components/company-detail/companyInvolvementGroups";
import api from "@/router/api";
import _ from "lodash";
import moment from "moment";
import { usePropertyFieldsStore } from "./propertyFields";

export const useNearbyCompaniesStore = defineStore("nearbyCompanies", () => {
  const mapStore = useMainMapStore();
  const notificationsStore = useNotificationsStore();
  const { map, zoom, allPagysLoaded: mapPagysLoaded } = storeToRefs(mapStore);
  const timeTravelStore = useTimeTravelStore();
  const { asOfMilliseconds } = storeToRefs(timeTravelStore);
  const analyzePanelStore = useAnalyzePanelStore();
  const { companiesCategory } = storeToRefs(analyzePanelStore);
  const propertyFieldsStore = usePropertyFieldsStore();
  const route = useRoute();
  const routeName = computed(() => route.name);

  const fetchedPropertyDataFields = ref([]);
  const companyFields = ref([]);
  const heroImages = ref([]);
  const companyFetchData = ref({});
  const allPagysLoaded = computed(() =>
    _.every(companyFetchData.value, function (fetchObject) {
      if (fetchObject.loaded) {
        return true;
      } else {
        return false;
      }
    }),
  );
  const pagysLoading = computed(() =>
    _.some(companyFetchData.value, function (fetchObject) {
      return fetchObject.loadingEvents;
    }),
  );
  const pagysFetching = computed(() =>
    _.some(companyFetchData.value, function (fetchObject) {
      return fetchObject.loadingEvents && !fetchObject.pagy;
    }),
  );
  const fetchedInvolvementFields = computed(() => {
    const rawFetches = _.filter(companyFetchData.value, function (fetchData) {
      return fetchData.data;
    });

    const rawFields = rawFetches.flatMap(({ data }) => data);
    const filteredFields = rawFields.filter(
      (maybeField) => _.isObject(maybeField) && maybeField.fieldContentId,
    );

    return _.uniqBy(filteredFields, "fieldContentId");
  });
  const fetchedFieldIds = computed(() => {
    return fetchedInvolvementFields.value.map(({ localId }) => localId);
  });
  const activeFetchObject = computed(() => {
    const ordered = _.orderBy(
      companyFetchData.value,
      [
        function ({ fetchedAt }) {
          if (fetchedAt) {
            return fetchedAt.valueOf();
          } else {
            return -100;
          }
        },
      ],
      ["desc"],
    );

    return _.head(ordered);
  });
  const deduplicatedActiveFetchInvolvements = computed(() => {
    if (activeFetchObject.value?.data) {
      const composited = activeFetchObject.value.data.map((maybeField) => {
        if (_.isObject(maybeField)) {
          return maybeField;
        } else {
          return _.find(fetchedInvolvementFields.value, {
            localId: maybeField,
          });
        }
      });

      return _.compact(composited);
    } else {
      return [];
    }
  });
  const rawFilteredInvolvements = computed(() => {
    return analyzedInvolvements(
      deduplicatedActiveFetchInvolvements.value,
      analyzePanelStore,
      "mainMap",
    );
  });
  const surveyUnlockableCompanyFieldIds = computed(() => {
    return rawFilteredInvolvements.value.flatMap(
      (dataField) =>
        dataField.fieldContent?.relatedFieldIds || dataField.localId,
    );
  });
  const rawGroupedInvolvements = computed(() => {
    return _.groupBy(rawFilteredInvolvements.value, function (field) {
      if (field.unmasked) {
        return field.fieldContent.companyId;
      } else {
        return "Unlockable";
      }
    });
  });
  const groupedInvolvements = computed(() => {
    const mappedObj = _.mapValues(
      rawGroupedInvolvements.value,
      function (fields, label) {
        if (label === "Unlockable") {
          return fields;
        } else {
          const rawEquityFields = fields.filter((field) => {
            return isEquity(field);
          });
          const orderedEquityFields = _.orderBy(
            rawEquityFields,
            ["fieldContentSubType", "decoratingContentType"],
            ["desc", "desc"],
          );
          const equityFields = _.uniqBy(orderedEquityFields, uniqueEquity);
          const nonEquityFields = fields.filter((field) => {
            return (
              isHunt(field) ||
              isUser(field) ||
              isDebt(field) ||
              isAdvisory(field) ||
              (isProspect(field) && !isEquity(field))
            );
          });

          return _.concat(equityFields, nonEquityFields);
        }
      },
    );
    const mappedArr = _.map(mappedObj, function (fields, label) {
      const hunts = fields.filter((field) => isHunt(field));
      const huntAdvisories = fields.filter((field) => isHuntAdvisory(field));
      const propertyGroupable = fields.filter(
        (field) => !isHunt(field) && !isHuntAdvisory(field),
      );
      return {
        companyId: label,
        involvements: fields,
        hunts,
        huntAdvisories,
        propertyGrouped:
          label === "Unlockable" ? null : propertyGrouped(propertyGroupable),
      };
    });
    return _.orderBy(
      mappedArr,
      [
        function (obj) {
          return obj.companyId === "Unlockable" ? 1 : 0;
        },
        function (obj) {
          return obj.involvements.length;
        },
        "companyId",
      ],
      ["desc", "desc", "desc"],
    );
  });

  const displayable = computed(() => routeName.value === "MainMap");

  watch(asOfMilliseconds, async () => {
    debouncedRefetchCompanies();
  });
  watch(mapPagysLoaded, () => {
    if (mapPagysLoaded.value) {
      debouncedRefetchCompanies();
    }
  });

  const debouncedRefetchCompanies = _.debounce(function () {
    fetchCompanies();
  }, 1000);

  function groupFor(field) {
    if (!field.unmasked) {
      return stylingPropFor("Unlockable", "name");
    } else if (isEquity(field)) {
      return stylingPropFor("Owner", "name");
    } else if (isUser(field)) {
      return stylingPropFor("User", "name");
    } else if (isDebt(field)) {
      return stylingPropFor("Lender", "name");
    } else if (isAdvisory(field) || (isProspect(field) && !isEquity(field))) {
      return stylingPropFor("Advisor", "name");
    } else if (isHunt(field)) {
      return stylingPropFor("Hunting", "name");
    }
  }

  function badgeFor(field) {
    if (!field.unmasked) {
      return stylingPropFor("Unlockable", "badge");
    } else if (isEquity(field)) {
      return stylingPropFor("Owner", "badge");
    } else if (isUser(field)) {
      return stylingPropFor("User", "badge");
    } else if (isDebt(field)) {
      return stylingPropFor("Lender", "badge");
    } else if (isAdvisory(field) || (isProspect(field) && !isEquity(field))) {
      return stylingPropFor("Advisor", "badge");
    } else if (isHunt(field)) {
      return stylingPropFor("Hunting", "badge");
    }
  }

  function shadowColorFor(field) {
    if (!field.unmasked) {
      return stylingPropFor("Unlockable", "shadowColor");
    } else if (isEquity(field)) {
      return stylingPropFor("Owner", "shadowColor");
    } else if (isUser(field)) {
      return stylingPropFor("User", "shadowColor");
    } else if (isDebt(field)) {
      return stylingPropFor("Lender", "shadowColor");
    } else if (isAdvisory(field) || (isProspect(field) && !isEquity(field))) {
      return stylingPropFor("Advisor", "shadowColor");
    } else if (isHunt(field)) {
      return stylingPropFor("Hunting", "shadowColor");
    }
  }
  function borderColorFor(field) {
    if (!field.unmasked) {
      return stylingPropFor("Unlockable", "borderColor");
    } else if (isEquity(field)) {
      return stylingPropFor("Owner", "borderColor");
    } else if (isUser(field)) {
      return stylingPropFor("User", "borderColor");
    } else if (isDebt(field)) {
      return stylingPropFor("Lender", "borderColor");
    } else if (isAdvisory(field) || (isProspect(field) && !isEquity(field))) {
      return stylingPropFor("Advisor", "borderColor");
    } else if (isHunt(field)) {
      return stylingPropFor("Hunting", "borderColor");
    }
  }

  function stylingPropFor(group, styleName) {
    const staticGroup = _.find(staticInvolvementGroupings, { name: group });

    if (staticGroup) {
      return staticGroup[styleName];
    } else {
      return "";
    }
  }

  function patchCompanyDataFields(newFields) {
    companyFields.value = _.unionBy(newFields, companyFields.value, "localId");
  }
  function patchHeroImages(newFields) {
    heroImages.value = _.unionBy(newFields, heroImages.value, "localId");
  }
  async function propertyDataFieldFor(involvementDataField) {
    const field = await propertyFieldsStore.fetchPropertyDataField(
      propertyIdFor(involvementDataField),
    );

    return field;
  }
  async function propertyNameFor(involvementDataField) {
    const field = await propertyDataFieldFor(involvementDataField);

    return _.get(field, "fieldContent.name") || "Unlock";
  }
  async function propertyLocationFor(involvementDataField) {
    const field = await propertyDataFieldFor(involvementDataField);
    const city = _.get(
      field,
      "fieldContent.locationDataField.fieldContent.city",
    );
    const state = _.get(
      field,
      "fieldContent.locationDataField.fieldContent.state",
    );
    const abbreviatedState = stateAbbreviationsUS[state];
    const combined =
      city && state ? `${city}, ${abbreviatedState || state}` : "to reveal";

    return combined;
  }
  function companyFieldFor(maybeId) {
    if (maybeId === "Unlockable") {
      return null;
    } else {
      return _.find(companyFields.value, {
        fieldContentId: _.toNumber(maybeId),
      });
    }
  }
  function heroImageFor(dataField) {
    if (assetTypeFor(dataField)) {
      let heroImageField = null;
      let photo = null;
      heroImageField = _.find(heroImages.value, {
        decoratingContentType: assetTypeFor(dataField),
        decoratingContentId: assetIdFor(dataField),
      });

      if (heroImageField) {
        photo = _.get(heroImageField, "fieldContent");
      } else if (propertyIdFor(dataField)) {
        heroImageField = _.find(heroImages.value, {
          joiningContentType: "Property",
          joiningContentId: propertyIdFor(dataField),
        });
        photo = _.get(heroImageField, "fieldContent");
      }

      return _.get(photo, "link");
    } else {
      return null;
    }
  }
  async function fetchCompany(maybeId) {
    if (maybeId !== "Unlockable" && !companyFieldFor(maybeId)) {
      const response = await api.get(`companies/${maybeId}`);

      if (response?.data) {
        patchCompanyDataFields([response.data]);
      }
    }
  }
  async function fetchHeroImage(isVisible, dataField) {
    if (isVisible && assetTypeFor(dataField)) {
      const heroResponse = await api.get(
        `newest_content_photo/${assetTypeFor(dataField)}/${assetIdFor(
          dataField,
        )}?asset_hero=true`,
      );

      if (heroResponse.data) {
        patchHeroImages([heroResponse.data]);
      }
      return true;
    } else {
      return false;
    }
  }

  async function fetchCompanies() {
    if (
      displayable.value &&
      companiesCategory.value?.checked &&
      zoom.value >= 13
    ) {
      // console.log("fetch companies");
      const bounds = map.value.getBounds();
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();
      const northeastLatLng = [northeast.lat, northeast.lng];
      const southwestLatLng = [southwest.lat, southwest.lng];
      const payload = {
        alreadyFetchedFieldIds: fetchedFieldIds.value,
      };
      const key = `${northeastLatLng}_${southwestLatLng}`;
      let fetchObject = companyFetchData.value[key];
      if (!fetchObject) {
        companyFetchData.value[key] = {
          loadingEvents: false,
          loaded: false,
          count: 5,
          data: [],
          pagy: null,
          fetchedAt: null,
        };
        fetchObject = companyFetchData.value[key];
      }

      if (mapPagysLoaded.value && !fetchObject.loaded) {
        fetchObject.loadingEvents = true;
        try {
          const response = await api.post(
            `nearby_companies/${southwestLatLng}/${northeastLatLng}?zoom=${zoom.value}&as_of=${asOfMilliseconds.value}`,
            payload,
          );
          const { data, pagy } = response.data;
          // console.log("fetched", data);
          fetchObject.loadingEvents = false;
          fetchObject.loaded = !pagy.next;
          fetchObject.data = data;
          fetchObject.pagy = pagy;
          fetchObject.fetchedAt = moment();

          await nextTick();
        } catch (error) {
          console.error(error);
          notificationsStore.addNotification("anErrorOccurred");
        } finally {
          // console.log("finally fetched companies");
        }
      } else {
        console.log("fetch not actionable.");
      }
    }
  }

  function actionableFetchObjectFor(key) {
    return companyFetchData.value[key];
  }
  function fetchProgressFor(key) {
    const fetchObject = actionableFetchObjectFor(key);

    if (fetchObject?.pagy && !fetchObject.loaded) {
      const { pagy } = fetchObject;
      const { page, last } = pagy;
      const rawPercentage = page / last;

      return _.round(rawPercentage * 100);
    } else {
      return null;
    }
  }
  function fetchLabelFor(key) {
    if (fetchProgressFor(key)) {
      const { pagy } = actionableFetchObjectFor(key);
      const { count } = pagy;

      return `${fetchProgressFor(key)}% (${count} datapoints)`;
    } else {
      return null;
    }
  }

  return {
    rawFilteredInvolvements,
    surveyUnlockableCompanyFieldIds,
    groupedInvolvements,
    debouncedRefetchCompanies,
    fetchedPropertyDataFields,
    companyFetchData,
    allPagysLoaded,
    pagysLoading,
    pagysFetching,
    fetchedInvolvementFields,
    fetchedFieldIds,
    activeFetchObject,
    deduplicatedActiveFetchInvolvements,
    fetchCompanies,
    fetchCompany,
    fetchHeroImage,
    companyFieldFor,
    propertyDataFieldFor,
    propertyNameFor,
    propertyLocationFor,
    heroImageFor,
    patchCompanyDataFields,
    shadowColorFor,
    borderColorFor,
    groupFor,
    badgeFor,
    actionableFetchObjectFor,
    fetchProgressFor,
    fetchLabelFor,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNearbyCompaniesStore, import.meta.hot),
  );
}
