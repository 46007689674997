<template>
  <div class="">
    <div class="flex items-start px-2 py-1 mb-3 bg-white gap-x-2">
      <button
        type="button"
        class="inline-flex items-center p-2 gap-x-1.5 rounded-full bg-gray-100 text-indigo-600 text-sm font-semibold shadow-sm hover:bg-gray-50 hover:text-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PlusCircleIcon class="size-6" aria-hidden="true" />
      </button>
      <button
        v-if="inputFocused && threadable && threadOption"
        type="button"
        class="inline-flex items-center p-2 gap-x-1.5 rounded-full bg-gray-100 text-indigo-600 text-sm font-semibold shadow-sm hover:bg-gray-50 hover:text-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        @click="threadOption.handler"
      >
        <component :is="threadOption.icon" class="size-6" aria-hidden="true" />
      </button>
      <textarea
        ref="messageInput"
        v-observe-visibility="{ callback: focusInput, once: true }"
        rows="1"
        name="message"
        class="flex-grow resize-none overflow-y-hidden border-none text-sm/6 text-indigo-600 placeholder:text-indigo-400 focus:ring-0"
        placeholder="Message [CHANNELNAME]"
        @keydown.enter.exact.prevent="weMessageInputStore.sendMessage()"
        @input="weMessageInputStore.resizeTextArea($event)"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <button
        type="button"
        class="rounded-full inline-flex items-center p-2 gap-x-1.5 bg-indigo-600 text-white text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        @click="weMessageInputStore.sendMessage()"
      >
        <PaperAirplaneIcon class="size-5" aria-hidden="true" />
      </button>
    </div>
    <input
      ref="messageInputFilesInput"
      type="file"
      :multiple="true"
      :accept="'image/*,' + all.join(',')"
      :data-direct-upload-url="fileUploadUrl"
      class="hidden"
      name="Files"
      @change="handleInputFiles"
      data-test="upload-files-input"
    />
  </div>
</template>

<script setup>
import Uploader from "@/components/crowdsourcing/uploader";
import { all } from "@/assets/documentFileTypes";
import { PaperAirplaneIcon, PlusCircleIcon } from "@heroicons/vue/20/solid";
import { useWeMessageInputStore } from "@/stores/weMessageInput";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { ref, computed, watch, nextTick } from "vue";
import _ from "lodash";

const weMessageInputStore = useWeMessageInputStore();
const { creationOptions, threadable } = storeToRefs(weMessageInputStore);
const userStore = useUserStore();
const { emailUnverified } = storeToRefs(userStore);

const inputFocused = ref(false);
function handleFocus() {
  inputFocused.value = true;
}
function handleBlur() {
  inputFocused.value = false;
}

const messageInput = ref(null);
const messageInputFilesInput = ref(null);
const files = ref([]);
const uploadedFiles = ref([]);
const pendingFileCount = ref(0);
const fileUploadUrl = "/rails/active_storage/direct_uploads";
const uploading = ref(false);
const uploadingProgress = ref(null);
const uploadingTotal = ref(null);

const threadOption = computed(() =>
  _.find(creationOptions.value, { name: "Create Thread" }),
);

const fileNames = computed(() => uploadedFiles.value.map((f) => f.name));
const fileSizeLimit = computed(() => 100);

const filesToPersist = computed(() =>
  _.differenceBy(files.value, uploadedFiles.value, "signedId"),
);
const readyToPersist = computed(() => {
  return (
    filesToPersist.value.length > 0 &&
    pendingFileCount.value === 0 &&
    filesToPersist.value.every((file) => !file.errorMessage)
  );
});

watch(filesToPersist, async () => {
  if (readyToPersist.value) {
    console.log(
      "TODO: INLINE MESSAGING FILES READY TO PERSIST IN WEPANEL STORE. SEND TO SERVER WITH MESSAGE SUBMIT.",
    );
  }
});

async function focusInput(isVisible) {
  if (!isVisible) return; // Only focus when visible

  await nextTick(); // Wait for the DOM to update after visibility changes

  if (messageInput.value) {
    messageInput.value.focus();
  }
}
function handleInputFiles() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    const toLoad = Array.from(messageInputFilesInput.value.files);

    pendingFileCount.value = toLoad.length;
    toLoad.forEach(loadFiles);
    messageInputFilesInput.value.value = null;
  }
}
async function loadFiles(file) {
  if (_.includes(fileNames, file.name)) return;

  await processFile(file);
}
async function processFile(file) {
  const fileSize = (file.size / 1024 / 1024).toFixed(4); // MB

  if (fileSize > fileSizeLimit.value) {
    files.value.push(
      Object.assign(
        {},
        {
          name: file.name,
          size: file.size,
          signedId: null,
          errorMessage: `is > ${fileSizeLimit.value}MB.`,
        },
      ),
    );
    pendingFileCount.value--;
    setTimeout(() => {
      remove(file);
    }, 3500);
  } else {
    await uploadFile(file);
  }
}
async function uploadFile(file) {
  const uploader = new Uploader(
    file,
    fileUploadUrl,
    files,
    pendingFileCount,
    uploading,
    uploadingProgress,
    uploadingTotal,
  );
  const reader = new FileReader();

  reader.addEventListener("load", () => {
    // console.log("loaded");
  });

  reader.readAsDataURL(file);

  await uploader.directUpload();
}
function remove(file) {
  const newFiles = files.value.filter((f) => f.name !== file.name);

  files.value = newFiles;

  attemptDirectRemove(file);
}
let channelMessageFiles = null;
function attemptDirectRemove(file) {
  // TODO: Store should maintain message files state on a per-channel basis.
  if (channelMessageFiles) {
    const newFiles = channelMessageFiles.filter((f) => f.name !== file.name);

    channelMessageFiles = newFiles;
  }
}
</script>
