<template>
  <div
    id="lists-panel-container"
    class="relative flex flex-col"
    :class="
      _.includes(['Analyze', 'We'], selectedTab?.name)
        ? 'overflow-hidden'
        : 'overflow-y-auto'
    "
  >
    <div class="sticky top-0 bg-white" style="z-index: 9999">
      <div class="px-2 border-b border-gray-300">
        <nav
          class="-mb-px pt-2 flex space-x-4"
          aria-label="Tabs"
          data-test="horizontal-nav-parent"
        >
          <div
            v-for="tab in secondaryPanelTypedTabs"
            :key="tab.name"
            :class="[
              selectedTab?.name === tab.name
                ? 'border-indigo-600'
                : 'border-transparent hover:border-gray-500',
              'pb-2 px-1 border-b-4 flex items-center space-x-1',
            ]"
          >
            <a
              @click.prevent="selectedTab = tab"
              :href="tab.href"
              :class="[
                selectedTab?.name === tab.name
                  ? 'text-indigo-600'
                  : 'text-gray-600 hover:text-gray-800',
                'whitespace-nowrap font-bold',
                pulseMeTab && tab.name === 'Me' ? 'pulse-me-tab' : '',
                pulseDetailsTab && tab.name === 'Details'
                  ? 'pulse-details-tab'
                  : '',
              ]"
              :aria-current="
                selectedTab?.name === tab.name ? 'page' : undefined
              "
              :data-test="`${_.kebabCase(tab.tabQuery)}-nav`"
            >
              {{ nameFor(tab) }}
            </a>
            <button
              v-if="tab.name === `Sign in`"
              @click="closeAuthenticate"
              type="button"
              class="flex items-center justify-center text-gray-600 hover:text-gray-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <button
              v-if="tab.name === 'My Account'"
              @click="closeMyAccount"
              type="button"
              class="flex items-center justify-center text-gray-600 hover:text-gray-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <button
              v-if="tab.name === 'Validations'"
              @click="secondaryPanelStore.closeValidations()"
              type="button"
              class="flex items-center justify-center text-gray-600 hover:text-gray-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <button
              v-else-if="tab.name === 'Deal Hub'"
              @click="closeDealBuilder"
              type="button"
              class="flex items-center justify-center text-gray-600 hover:text-gray-800"
              data-test="close-deal-builder-button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <button
              v-else-if="tab.name === 'Space Hub'"
              @click="closeSpaceUsageBuilder"
              type="button"
              class="flex items-center justify-center text-gray-600 hover:text-gray-800"
              data-test="close-space-usage-builder-button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <template v-else-if="tab.name === 'Help'">
              <router-link
                :to="helpCenterPath"
                target="_blank"
                v-tooltip="'Open in new tab'"
                class="p-1 inline-flex justify-center items-center text-gray-600 hover:text-gray-800"
              >
                <ArrowTopRightOnSquareIcon class="h-4 w-4" aria-hidden="true" />
              </router-link>
              <button
                @click="closeHelp"
                type="button"
                class="flex items-center justify-center text-gray-600 hover:text-gray-800"
                data-test="close-help-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </template>
          </div>
        </nav>
      </div>
    </div>
    <div
      id="lists-panel-component-container"
      class="flex-grow"
      :class="
        _.includes(['Analyze', 'We'], selectedTab?.name)
          ? 'overflow-hidden'
          : ''
      "
    >
      <component :is="selectedTabComponent" v-bind="selectedTabProperties" />
    </div>
  </div>
</template>

<script setup>
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useTasksChannelStore } from "@/stores/tasksChannel";
import { useDocumentationStore } from "@/stores/documentation";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useNotificationsStore } from "@/stores/notifications";
import { useUserStore } from "@/stores/user";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import AuthenticateTab from "@/components/main-layout/secondary-panel/AuthenticateTab.vue";
import AccountTab from "@/components/main-layout/secondary-panel/AccountTab.vue";
import WeTab from "@/components/main-layout/secondary-panel/WeTab.vue";
import HelpTab from "@/components/main-layout/secondary-panel/HelpTab.vue";
import MeTab from "@/components/main-layout/secondary-panel/MeTab.vue";
import DealBuilder from "@/components/deal-builder/DealBuilder.vue";
import SpaceUsageBuilder from "@/components/space-usage-builder/SpaceUsageBuilder.vue";
import ComingSoon from "@/components/ComingSoon.vue";
import _ from "lodash";
import api from "@/router/api";
import ValidationsTab from "@/components/main-layout/secondary-panel/ValidationsTab.vue";
import AnalyzeTab from "@/components/main-layout/secondary-panel/AnalyzeTab.vue";

const secondaryPanelStore = useSecondaryPanelStore();
const {
  selectedSecondaryPanelTab,
  secondaryPanelTypedTabs,
  secondaryPanelDetailsComponent,
  myAccountActive,
  helpActive,
} = storeToRefs(secondaryPanelStore);
const userStore = useUserStore();
const { firstName, signedIn } = storeToRefs(userStore);
const notificationsStore = useNotificationsStore();
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder } = storeToRefs(spaceUsageBuilderStore);
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  propertyIdParam,
  propertyDiagramSelected,
  propertyDiagramPropertyDataFields,
  propertyDiagramSelectedPropertyId,
} = storeToRefs(propertyDiagramStore);
const companyDetailStore = useCompanyDetailStore();
const { companyIdParam, companySelectedRecordDataField } =
  storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const { contactIdParam, contactSelectedRecordDataField } =
  storeToRefs(contactDetailStore);
const tasksChannelStore = useTasksChannelStore();
const { tasksChannelDataQueue } = storeToRefs(tasksChannelStore);
const documentationStore = useDocumentationStore();
const { helpCenterPath } = storeToRefs(documentationStore);

const pulseMeTab = ref(false);
const pulseDetailsTab = ref(false);
const propertyDataField = computed(() => {
  if (
    propertyIdParam.value &&
    _.size(propertyDiagramPropertyDataFields.value) > 0
  ) {
    return _.find(propertyDiagramPropertyDataFields.value, function (df, id) {
      return id == propertyDiagramSelectedPropertyId.value;
    });
  } else {
    return null;
  }
});

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);
const queryAssetFieldIds = computed(() => _.get(query.value, "assetFieldIds"));
const hasQueryAssets = computed(
  () => _.isArray(queryAssetFieldIds.value) || queryAssetFieldIds.value,
);
const numericAssetFieldIdsFromQuery = computed(() => {
  if (hasQueryAssets.value) {
    if (_.isArray(queryAssetFieldIds.value)) {
      return queryAssetFieldIds.value.map((id) => _.toNumber(id));
    } else {
      return [_.toNumber(queryAssetFieldIds.value)];
    }
  } else return [];
});

const querySpaceFieldIds = computed(() => _.get(query.value, "spaceFieldIds"));
const hasQuerySpaces = computed(
  () => _.isArray(querySpaceFieldIds.value) || querySpaceFieldIds.value,
);
const numericSpaceFieldIdsFromQuery = computed(() => {
  if (hasQuerySpaces.value) {
    if (_.isArray(querySpaceFieldIds.value)) {
      return querySpaceFieldIds.value.map((id) => _.toNumber(id));
    } else {
      return [_.toNumber(querySpaceFieldIds.value)];
    }
  } else return [];
});

watch(tasksChannelDataQueue, () => {
  const data = _.last(tasksChannelDataQueue.value);
  if (data.pulse === "Me") {
    pulseMeTab.value = true;

    setTimeout(() => {
      pulseMeTab.value = false;
    }, 1600);
  }
});

watch(propertyDiagramSelected, () => {
  pulseDetailsTab.value = true;

  setTimeout(() => {
    pulseDetailsTab.value = false;
  }, 1600);
});

onMounted(async () => {
  if (signedIn.value && _.get(query.value, "easyDataInputJobInvitationToken")) {
    await fetchEasyDataInputJobInvitation();
  }

  if (!_.get(query.value, "horizontalTab")) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: selectedSecondaryPanelTab.value.tabQuery,
        verticalTab: undefined,
      },
    });
  } else if (
    numericAssetFieldIdsFromQuery.value.length > 0 &&
    !dealBuilder.value
  ) {
    await dealBuilderStore.populateDealBuilderFromQuery(
      numericAssetFieldIdsFromQuery.value,
    );
    selectedSecondaryPanelTab.value = {
      name: "Deal Hub",
      tabQuery: "Deals",
      href: "",
    };
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: selectedSecondaryPanelTab.value.tabQuery,
        verticalTab: undefined,
      },
    });
  }

  if (
    numericSpaceFieldIdsFromQuery.value.length > 0 &&
    !spaceUsageBuilder.value
  ) {
    await spaceUsageBuilderStore.populateBuilderFromQuery(
      numericSpaceFieldIdsFromQuery.value,
    );

    if (numericAssetFieldIdsFromQuery.value.length === 0) {
      selectedSecondaryPanelTab.value = {
        name: "Space Hub",
        tabQuery: "Spaces",
        href: "",
      };
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: selectedSecondaryPanelTab.value.tabQuery,
          verticalTab: undefined,
        },
      });
    }
  }

  if (
    signedIn.value &&
    _.get(query.value, "horizontalTab") === "Authenticate"
  ) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Me",
        verticalTab: "News",
      },
    });
  }
});

const selectedTab = computed({
  get() {
    return selectedSecondaryPanelTab.value;
  },
  set(newTab) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: newTab.tabQuery,
        verticalTab: undefined,
      },
    });
  },
});

const selectedTabComponent = computed(() => {
  switch (selectedTab.value?.name) {
    case "Analyze":
      return AnalyzeTab;
    case "We":
      return WeTab;
    case "Details":
      return secondaryPanelDetailsComponent.value;
    case "Deal Hub":
      return DealBuilder;
    case "Space Hub":
      return SpaceUsageBuilder;
    case "Help":
      return HelpTab;
    case "Validations":
      return ValidationsTab;
    case "My Account":
      return AccountTab;
    case "Sign in":
      return AuthenticateTab;
    default:
      if (selectedTab.value?.tabQuery === "Me") return MeTab;
      else return ComingSoon;
  }
});
const selectedDataField = computed(() => {
  if (contactIdParam.value) {
    return contactSelectedRecordDataField.value;
  } else if (companyIdParam.value) {
    return companySelectedRecordDataField.value;
  } else if (propertyDiagramSelected.value) {
    return propertyDiagramSelected.value.dataField;
  } else if (propertyDataField.value) {
    return propertyDataField.value;
  } else {
    return null;
  }
});
const selectedTabProperties = computed(() => {
  switch (selectedTab.value?.name) {
    case "We":
      return {
        dataField: selectedDataField.value,
      };
    case "Details":
      return {
        dataField: selectedDataField.value,
      };
    default:
      return {};
  }
});
function nameFor(tab) {
  if (tab.tabQuery === "Me" && firstName.value) {
    return "Me"; // firstName.value;
  } else {
    return tab.name;
  }
}
function closeAuthenticate() {
  secondaryPanelStore.setAuthenticate(false);
  secondaryPanelStore.revertPanel();
}
async function closeDealBuilder() {
  dealBuilderStore.clearDealBuilder();
  await optionalTabReset();
}
async function closeSpaceUsageBuilder() {
  spaceUsageBuilderStore.clearBuilder();
  await optionalTabReset();
}

async function optionalTabReset() {
  if (!propertyIdParam.value) {
    propertyDiagramStore.clearPropertyDiagramPropertyIds();
  }
  secondaryPanelStore.revertPanel();
  await nextTick();
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Tasks",
      assetFieldIds: undefined,
      spaceFieldIds: undefined,
    },
  });
}
function closeMyAccount() {
  myAccountActive.value = false;
  secondaryPanelStore.revertPanel();
}
async function closeHelp() {
  helpActive.value = false;
  secondaryPanelStore.revertPanel();
  await nextTick();
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Tasks",
      articleId: undefined,
      sectionName: undefined,
    },
  });
}

async function fetchEasyDataInputJobInvitation() {
  const response = await api.get(
    `easy_data_input_job_invitations/${route.query.easyDataInputJobInvitationToken}`,
  );

  if (response?.data) {
    await acceptEasyDataInputJobInvitation(response.data.token);
  }
}

async function acceptEasyDataInputJobInvitation(token) {
  const response = await api.patch(`easy_data_input_job_invitations/${token}`);

  notificationsStore.addNotification("easyDataInviteAccepted");

  return response;
}
</script>

<style scoped>
.pulse-me-tab {
  animation: pulse-me-tab 1.5s;
  box-shadow: 0 0 0 2em transparent;
}
@keyframes pulse-me-tab {
  0% {
    box-shadow: 0 0 0 0 #22c55e;
  }
}

.pulse-details-tab {
  animation: pulse-details-tab 1.5s;
  box-shadow: 0 0 0 2em transparent;
}
@keyframes pulse-details-tab {
  0% {
    box-shadow: 0 0 0 0 #6366f1;
  }
}
</style>
