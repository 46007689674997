<template>
  <h2 class="w-full p-2 flex items-center justify-between">
    <div class="flex items-center gap-2">
      <button v-if="!isDesktop" @click="expanded = !expanded">
        <component :is="showHideIcon" class="size-5 text-gray-700" />
      </button>
      <button
        class="text-gray-700 font-semibold text-sm"
        @click="expanded = !expanded"
      >
        {{ category.name }}
      </button>
      <button v-if="isDesktop" @click="expanded = !expanded">
        <component :is="showHideIcon" class="size-5 text-gray-700" />
      </button>
    </div>
    <button
      v-if="isDesktop && canManage"
      v-tooltip.top-end="'Create Channel'"
      type="button"
      class="inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
    >
      <PlusIcon class="size-5" />
    </button>
  </h2>
</template>

<script setup>
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { computed, markRaw, ref } from "vue";
import _ from "lodash";

const props = defineProps(["category"]);

const wePanelStore = useWePanelStore();
const { categories } = storeToRefs(wePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const expanded = computed({
  get() {
    return props.category.expanded;
  },
  set(bool) {
    const storeCategory = _.find(categories.value, { id: props.category.id });

    if (storeCategory) storeCategory.expanded = bool;
  },
});
const showHideIcon = computed(() => {
  if (expanded.value) {
    return markRaw(ChevronDownIcon);
  } else {
    return markRaw(ChevronRightIcon);
  }
});
const canManage = computed(() => true);
</script>
