<template>
  <div
    v-if="propertyIdParam || refetchable"
    class="overflow-hidden rounded-lg p-3 shadow bg-gray-100"
  >
    <dt class="truncate text-sm font-medium text-gray-500">Buildings</dt>
    <div
      v-if="(refreshing || fetchingData) && !propertyIdParam"
      class="mt-1 w-full flex"
    >
      <SquareLoader :loading="true" size="24px" color="#9ca3af" />
    </div>
    <dd v-else class="mt-1 text-xl font-semibold tracking-tight text-gray-900">
      <DataField
        calculation-name="building_count"
        :intercept-calculation="interceptCalculation"
        :calculation-value="buildingCount"
        :bundle-field-ids="bundleFieldIds"
        text-classes="text-xl font-semibold tracking-tight"
        :analyze="true"
      />
    </dd>
  </div>
</template>

<script setup>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useMainMapStore } from "@/stores/mainMap";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCalculationFieldsStore } from "@/stores/calculationFields";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useAvailableValidationsChannelStore } from "@/stores/availableValidationsChannel";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted } from "vue";
import api from "@/router/api";
import _ from "lodash";

const calculationFieldsStore = useCalculationFieldsStore();
const { asOfMilliseconds } = storeToRefs(useTimeTravelStore());
const mapStore = useMainMapStore();
const { refreshing, zoom, nearbyPropertyDataFields } = storeToRefs(mapStore);
const companyDetailStore = useCompanyDetailStore();
const { companyIdParam, companyFetchedLocationDataFields } =
  storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const { contactIdParam, contactFetchedLocationDataFields } =
  storeToRefs(contactDetailStore);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  propertyIdParam,
  propertyDiagramPropertyIds,
  propertyDataField,
  diagramAllFetchedFields,
} = storeToRefs(propertyDiagramStore);
const availableValidationsChannelStore = useAvailableValidationsChannelStore();
const { activeValidationChangeGroupId } = storeToRefs(
  availableValidationsChannelStore,
);
const analyzePanelStore = useAnalyzePanelStore();
const {
  calculationData,
  checkedCategories,
  combinedFilteredPropertyIds,
  combinedFilteredLandCoveringIds,
  latLngMismatch,
  timeMismatch,
  zoomMismatch,
  focalDataField,
  unselectedPropertyDiagram,
  propertySelectedPropertyDiagram,
  fetchingAnalyzePanelData,
} = storeToRefs(analyzePanelStore);

const fetchingData = ref(false);

const interceptCalculation = computed(() => {
  return calculationData.value.buildingCount?.interceptableFields?.length > 0;
});
const mapGroupingDataFields = computed({
  get() {
    return calculationData.value.buildingCount.mapGroupingDataFields;
  },
  set(fields) {
    calculationData.value.buildingCount.mapGroupingDataFields = fields;
  },
});
const mapLandCoveringDataFields = computed({
  get() {
    return calculationData.value.buildingCount.mapLandCoveringDataFields;
  },
  set(fields) {
    calculationData.value.buildingCount.mapLandCoveringDataFields = fields;
  },
});
const bundleFieldIds = computed(() => {
  return _.concat(
    landCoveringDataFields.value.map((df) => df.localId),
    landCoveringGroupingDataFields.value.map((df) => df.localId),
  );
});
const focalPropertyRight = computed(() =>
  _.includes(["PropertyRight"], focalDataField.value?.fieldContentType),
);

const fetchedPropertyCollection = computed(() => {
  if (companyIdParam.value) {
    return companyFetchedLocationDataFields.value;
  } else if (contactIdParam.value) {
    return contactFetchedLocationDataFields.value;
  } else {
    return nearbyPropertyDataFields.value;
  }
});
const fetchedPropertyIds = computed(() => {
  const airGroundIds = _.map(fetchedPropertyCollection.value, function (df) {
    return _.compact([
      df.decoratingContentId,
      df.fieldContent?.airLayerPropertyId,
    ]);
  });

  return _.flatten(airGroundIds);
});
function propertyMatch(dataField) {
  if (propertySelectedPropertyDiagram.value) {
    return (
      dataField.joiningContentType === "Property" &&
      dataField.joiningContentId === propertyDataField.value.fieldContentId
    );
  } else if (unselectedPropertyDiagram.value) {
    return (
      dataField.joiningContentType === "Property" &&
      _.includes(propertyDiagramPropertyIds.value, dataField.joiningContentId)
    );
  } else {
    return true;
  }
}
const landCoveringDataFields = computed(() => {
  return propertyIdParam.value
    ? diagramAllFetchedFields.value.filter((dataField) => {
        return (
          dataField.fieldContentType === "LandCovering" &&
          propertyMatch(dataField)
        );
      })
    : mapLandCoveringDataFields.value;
});
const landCoveringGroupingDataFields = computed(() => {
  return propertyIdParam.value
    ? diagramAllFetchedFields.value.filter((dataField) => {
        return (
          dataField.fieldContentType === "LandCoveringGrouping" &&
          propertyMatch(dataField)
        );
      })
    : mapGroupingDataFields.value;
});
const buildingCount = computed(() => {
  const groupings = landCoveringGroupingDataFields.value;
  const groupingComponentCoverings = groupings.flatMap(
    (df) => df?.fieldContent?.landCoveringIds,
  );
  const standaloneCoverings = landCoveringDataFields.value.filter(
    (df) =>
      df.fieldContentSubType === "building" &&
      !_.includes(groupingComponentCoverings, df.fieldContentId),
  );

  if (contactIdParam.value || companyIdParam.value) {
    const filteredCoverings =
      calculationData.value.buildingCount.propertyRightLandCoveringDataFields.filter(
        (dataField) => {
          if (checkedCategories.value.length > 0) {
            return (
              _.includes(
                combinedFilteredPropertyIds.value,
                dataField.joiningContentId,
              ) &&
              _.includes(
                combinedFilteredLandCoveringIds.value,
                dataField.fieldContentId,
              )
            );
          } else {
            return true;
          }
        },
      );
    return _.size(filteredCoverings);
  } else if (focalPropertyRight.value) {
    const propertyRightId =
      focalDataField.value.fieldContent.feeSimpleId ||
      focalDataField.value.fieldContentId;
    const filteredCoverings = landCoveringDataFields.value.filter((df) => {
      return _.includes(df.fieldContent?.propertyRightIds, propertyRightId);
    });
    const filteredGroupings = groupings.filter((df) => {
      const overlap = _.intersection(
        df.fieldContent.landCoveringIds,
        filteredCoverings.map((df) => df.fieldContentId),
      );
      return overlap.length > 0;
    });
    const filteredGroupingComponentCoverings = filteredGroupings.flatMap(
      (df) => df?.fieldContent?.landCoveringIds,
    );
    const filteredStandaloneCoverings = filteredCoverings.filter(
      (df) =>
        df.fieldContentSubType === "building" &&
        !_.includes(filteredGroupingComponentCoverings, df.fieldContentId),
    );

    return _.size(filteredGroupings) + _.size(filteredStandaloneCoverings);
  } else if (checkedCategories.value.length > 0) {
    const includedGroupings = groupings.filter((dataField) => {
      return _.includes(
        combinedFilteredPropertyIds.value,
        dataField.joiningContentId,
      );
    });
    const includedGroupingComponents = includedGroupings
      .flatMap((df) => df?.fieldContent?.landCoveringIds)
      .filter((landCoveringId) => {
        return _.includes(
          combinedFilteredLandCoveringIds.value,
          landCoveringId,
        );
      });
    const includedStandalones = standaloneCoverings
      .filter((dataField) => {
        return (
          _.includes(
            combinedFilteredPropertyIds.value,
            dataField.joiningContentId,
          ) &&
          _.includes(
            combinedFilteredLandCoveringIds.value,
            dataField.fieldContentId,
          )
        );
      })
      .map((dataField) => dataField.fieldContentId);

    return _.size(
      _.uniq(_.concat(includedGroupingComponents, includedStandalones)),
    );
  } else {
    return _.size(groupings) + _.size(standaloneCoverings);
  }
});

watch(latLngMismatch, (val) => {
  if (val) refetchData();
});

watch(timeMismatch, (val) => {
  if (val) refetchData();
});

watch(zoomMismatch, async (val) => {
  if (val) refetchData();
});

watch(refreshing, (val, oldVal) => {
  if (!propertyIdParam.value && !val && oldVal) {
    refetchData();
  }
});

watch(
  combinedFilteredLandCoveringIds,
  () => {
    debouncedrefetchData();
  },
  { deep: true },
);

onMounted(() => {});

const debouncedrefetchData = _.debounce(function () {
  refetchData();
}, 1000);

async function fetchLandCoveringGroupings() {
  fetchingData.value = true;
  return new Promise((resolve) => {
    const payload = {
      propertyIds: fetchedPropertyIds.value,
      activeChangeGroupId: activeValidationChangeGroupId.value,
    };

    api.post(`property_land_covering_groupings`, payload).then((json) => {
      mapGroupingDataFields.value = json.data;
      fetchingData.value = false;
      resolve();
    });
  });
}

function propertyAsOfPayload() {
  return {
    asOf: asOfMilliseconds.value,
    fieldName: "LandCovering",
    propertyIds: fetchedPropertyIds.value,
    activeChangeGroupId: activeValidationChangeGroupId.value,
  };
}
async function fetchLandCoverings() {
  fetchingData.value = true;
  const response = await api.post(
    `diagram_data_as_of_date`,
    propertyAsOfPayload(),
  );
  mapLandCoveringDataFields.value = response.data;
  fetchingData.value = false;
}

function propertyRightAsOfPayload() {
  return {
    asOf: asOfMilliseconds.value,
    contentType: focalDataField.value?.fieldContentType,
    contentId: focalDataField.value?.fieldContentId,
    activeChangeGroupId: activeValidationChangeGroupId.value,
  };
}

async function fetchPropertyRightCoverings() {
  const payload = propertyRightAsOfPayload();
  const key = `building_count-${payload.contentType}-${payload.contentId}-${payload.asOf}`;
  fetchingAnalyzePanelData.value = true;
  fetchingData.value = true;
  const response = await calculationFieldsStore.calculationDataInitialFetch({
    payload,
    key,
    endpoint: `property_right_land_coverings`,
    calculationName: "building_count",
    paginationCount: 10,
  });
  fetchingAnalyzePanelData.value = false;
  fetchingData.value = false;

  return response;
}

const refetchable = computed(() => {
  if (companyIdParam.value || contactIdParam.value) {
    return true;
  } else if (!propertyIdParam.value) {
    return zoom.value >= 16;
  } else {
    return false;
  }
});

async function refetchData() {
  if (refetchable.value) {
    if (companyIdParam.value || contactIdParam.value) {
      await fetchPropertyRightCoverings();
    } else {
      await fetchLandCoveringGroupings();
      await fetchLandCoverings();
    }
  }
}
</script>
