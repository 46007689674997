<template>
  <div
    v-if="dataField?.localId"
    v-observe-visibility="{ callback: fetchContextDataFields, once: true }"
    class="relative isolate flex gap-2"
  >
    <div class="relative aspect-square w-64 shrink-0">
      <HeroImage :override-data-field="dataField" />
    </div>
    <div class="pr-2">
      <div class="flex items-center justify-between text-xs">
        <div
          class="relative z-10 mr-2 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100 truncate"
        >
          {{ displayableFieldType }}
        </div>
        <time
          :datetime="moment(dataField.updatedAt).format('YYYY-MM-DD')"
          class="text-gray-500"
          >{{ moment(dataField.updatedAt).fromNow() }}</time
        >
      </div>
      <h3 class="mt-2">
        <DataField
          :data-field="dataField"
          text-classes="text-lg font-semibold"
          text-styles=""
          @unlocked="unlocked"
          @completed="completed"
          @open-sourced="completed"
        />
      </h3>
      <div v-if="displayableContext.component" class="mt-2 p-1">
        <component
          :is="displayableContext.component"
          v-bind="displayableContext.props"
        />
      </div>
      <div class="mt-2 flex">
        <div class="flex-shrink-0 p-1 w-full bg-white">
          <UnlockSelector
            context="info-popup"
            :data-field="dataField"
            @unlocked="unlocked"
            class=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import HeroImage from "@/components/property-diagram/HeroImage.vue";
import UnlockSelector from "@/components/crowdsourcing/UnlockSelector.vue";
import ComingSoon from "@/components/ComingSoon.vue";
import FloorAreaPopUpContext from "@/components/property-diagram/pop-up-context/FloorAreaPopUpContext.vue";
import PropertyRightPopUpContext from "@/components/property-diagram/pop-up-context/PropertyRightPopUpContext.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { storeToRefs } from "pinia";
import { computed, markRaw } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import moment from "moment";

const props = defineProps(["dataField", "customContent", "mapStore"]);

const dealBuilderStore = useDealBuilderStore();
const propertyDiagramStore = usePropertyDiagramStore();
const {
  propertyDiagramRefetch,
  propertyDiagramSelected,
  propertySelectedRecordDataField,
  fetchedPopupFields,
} = storeToRefs(propertyDiagramStore);
const { legendLayer } = storeToRefs(propertyDiagramStore);

const router = useRouter();
const route = useRoute();
const routeName = computed(() => route.name);
const query = computed(() => route.query);

const selectedFieldType = computed(() => {
  const rawType =
    _.get(props.dataField, "fieldContentType", null) ||
    _.get(props.dataField, "fieldName", null);

  return rawType;
});
const displayableFieldType = computed(() =>
  _.startCase(selectedFieldType.value),
);
const horizontalIsDetails = computed(
  () => _.get(query.value, "horizontalTab") === "Details",
);
const mainTabs = computed(() =>
  horizontalIsDetails.value ? { horizontalTab: "Me", verticalTab: "News" } : {},
);

function unlocked() {
  propertyDiagramRefetch.value = propertySelectedRecordDataField.value;
  dealBuilderStore.clearDealBuilder(false);
}

function completed(json) {
  const responsePayload = json.data;
  const directPropertyAction =
    responsePayload.dataFields.length === 1 &&
    _.head(responsePayload.dataFields).decoratingContentType === null &&
    _.head(responsePayload.dataFields).fieldContentType === "Property";
  const updatedSelected =
    responsePayload.dataFields.length === 1 &&
    _.head(responsePayload.dataFields).localId ===
      propertySelectedRecordDataField.value?.localId;

  if (directPropertyAction) {
    console.log("direct property action");
    router.push({
      name: "MainMap",
      query: _.merge(
        {},
        { ...route.query, propertyIds: undefined },
        mainTabs.value,
      ),
    });
  } else if (updatedSelected) {
    propertyDiagramStore.postEditingPatch(json);
    propertyDiagramSelected.value.dataField = _.head(
      responsePayload.dataFields,
    );
  } else if (routeName.value === "MainMap" && props.mapStore) {
    props.mapStore.tapView();
  } else {
    propertyDiagramRefetch.value = propertySelectedRecordDataField.value;
  }
}

const displayableContext = computed(() => {
  switch (selectedFieldType.value) {
    case "FloorArea": {
      const spaceUsers =
        fetchedPopupFields.value[
          `FloorArea${props.dataField.fieldContentId}_SpaceUsers`
        ] || [];
      const spaceUsages = _.compact(
        spaceUsers.map(({ decoratingContentId: spaceUsageId }) => {
          return fetchedPopupFields.value[
            `FloorArea${props.dataField.fieldContentId}_SpaceUsage${spaceUsageId}`
          ];
        }),
      );
      return {
        component:
          spaceUsers.length > 0 ? markRaw(FloorAreaPopUpContext) : null,
        props: {
          dataField: props.dataField,
          spaceUsers,
          spaceUsages,
        },
      };
    }
    case "PropertyRight": {
      return {
        component: markRaw(PropertyRightPopUpContext),
        props: {
          dataField: props.dataField,
        },
      };
    }
    default:
      return {};
  }
});

async function fetchContextDataFields() {
  switch (selectedFieldType.value) {
    case "FloorArea":
      propertyDiagramStore.fetchFloorAreaPopupContext(
        props.dataField.fieldContentId,
      );
      break;
    default:
      console.log(
        "No context currently being fetched for:",
        selectedFieldType.value,
      );
  }
}
</script>
