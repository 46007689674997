<template>
  <div
    class="flex flex-col divide-y divide-gray-200"
    :data-test="`investment-group-${
      investmentGroup.id || investmentGroup.placeholderId
    }-container`"
  >
    <div class="p-2 flex items-center justify-between text-sm">
      <div class="flex items-center space-x-1">
        <button
          @click.prevent="expanded = !expanded"
          type="button"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
        >
          <ChevronDownIcon class="h-5 w-5" />
        </button>

        <DataField
          v-if="investmentGroupDataField"
          :data-field="investmentGroupDataField"
          text-classes="text-sm font-medium"
          text-styles=""
          @completed="fetchInvestmentGroupDataField"
          @dismiss="closeGroup"
        />
        <a
          v-else
          @click.prevent="expanded = !expanded"
          href=""
          class="text-gray-700 hover:text-gray-900 font-bold"
          >Portfolio {{ order }}: {{ _.startCase(investmentGroup.dealAction)
          }}<template v-if="loanSeniority"
            >&nbsp;({{ loanSeniority }} Loan)</template
          ></a
        >

        <div
          v-tooltip="'Property diagram property right outline color'"
          class="h-4 w-4 rounded-md ring-1 ring-inset ring-black ring-opacity-0"
          :class="[investmentGroup.diagramLegendColor]"
        />
      </div>
      <div class="flex items-center text-gray-500 space-x-2">
        <div
          :data-test="`investment-group-${
            investmentGroup.id || investmentGroup.placeholderId
          }-asset-count`"
        >
          {{ pluralize("Asset", investmentsLength, true) }}
        </div>
        <button
          v-if="investmentsLength > 0 && !investmentGroup.id"
          @click="saveGroup"
          type="button"
          :data-test="`save-investment-group-${investmentGroup.placeholderId}-button`"
          :disabled="actionInProgress"
          class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PulseLoader
            v-if="actionInProgress"
            :loading="true"
            size="4px"
            color="#f3f4f6"
          />
          <template v-else>Save</template>
        </button>
        <button
          @click="closeGroup"
          type="button"
          v-tooltip="investmentGroup.id ? 'Exit' : 'Cancel'"
          class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <i class="fas fa-times" />
        </button>
      </div>
    </div>
    <div v-if="expanded" class="flex flex-1">
      <!-- Narrow sidebar-->
      <nav
        aria-label="Sidebar"
        class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
      >
        <div class="relative flex flex-col space-y-2">
          <a
            v-for="tab in contextedTabs"
            @click.prevent="selectedTab = tab"
            :key="tab.name"
            :href="tab.href"
            v-tooltip="tab.name"
            :class="[
              selectedTab.name === tab.name
                ? 'text-gray-700 bg-gray-300'
                : 'text-gray-500 hover:bg-gray-200',
              'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
            ]"
            class=""
            :data-test="`investment-group-${_.kebabCase(tab.name)}-nav`"
          >
            <span class="sr-only">{{ tab.name }}</span>
            <svg
              :class="selectedTab.name === tab.name ? '' : ''"
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                :d="tab.svgPath"
              />
            </svg>
          </a>
        </div>
      </nav>

      <div class="flex-grow h-80 overflow-y-auto">
        <component :is="selectedTab.component" v-bind="selectedTabProperties" />
      </div>
    </div>
  </div>
</template>

<script setup>
import ContentAppearance from "@/components/crowdsourcing/ContentAppearance.vue";
import ContentFiles from "@/components/crowdsourcing/ContentFiles.vue";
import InvestmentGroupPlayers from "@/components/deal-builder/InvestmentGroupPlayers.vue";
import InvestmentGroupTiming from "@/components/deal-builder/InvestmentGroupTiming.vue";
import InvestmentsList from "@/components/deal-builder/InvestmentsList.vue";
import ValuationsDetail from "@/components/deal-builder/ValuationsDetail.vue";
import LoansDetail from "@/components/deal-builder/LoansDetail.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { ref, computed, watch, onMounted, markRaw } from "vue";
import api from "@/router/api";
import _ from "lodash";
import pluralize from "pluralize";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";

const props = defineProps(["investmentGroup", "order"]);

const dealBuilderStore = useDealBuilderStore();

const investmentGroupDataField = ref(null);

const actionInProgress = ref(false);
const tabs = ref([
  {
    name: "Assets",
    order: 1,
    href: "",
    svgPath:
      "M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4",
    component: markRaw(InvestmentsList),
  },
  {
    name: "Valuations",
    order: 3,
    href: "",
    svgPath:
      "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    component: markRaw(ValuationsDetail),
  },
  {
    name: "Players",
    order: 4,
    href: "",
    svgPath:
      "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
    component: markRaw(InvestmentGroupPlayers),
  },
  {
    name: "Timing",
    order: 5,
    href: "",
    svgPath: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z",
    component: markRaw(InvestmentGroupTiming),
  },
  {
    name: "Files",
    order: 6,
    href: "",
    svgPath:
      "M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z",
    component: markRaw(ContentFiles),
  },
  {
    name: "Appearance",
    order: 7,
    href: "",
    svgPath:
      "M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2",
    component: markRaw(ContentAppearance),
  },
]);
const selectedTab = ref({
  name: "Assets",
  order: 1,
  href: "",
  svgPath:
    "M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4",
  component: markRaw(InvestmentsList),
});

const selectedTabProperties = computed(() => {
  return {
    existingGroupId: props.investmentGroup.id,
    placeholderGroupId: props.investmentGroup.placeholderId,
    fieldContent: props.investmentGroup,
    fieldContentType: "InvestmentGroup",
    dataField: {
      fieldContent: props.investmentGroup,
      fieldContentType: "InvestmentGroup",
    },
    optional: true,
  };
});
const expanded = computed({
  get() {
    return props.investmentGroup.expanded;
  },
  set(bool) {
    dealBuilderStore.setDealBuilderInvestmentGroupExpanded({
      groupId: props.investmentGroup.id || props.investmentGroup.placeholderId,
      expanded: bool,
    });
  },
});
const existingGroupId = computed(() => {
  return _.get(props.investmentGroup, "id");
});
const investments = computed(() => props.investmentGroup.investments);
const investmentsLength = computed(() => {
  return _.size(investments.value);
});
const loanSeniority = computed(() => {
  if (props.investmentGroup?.investments) {
    const allLoans = _.every(
      props.investmentGroup.investments,
      function (invObject) {
        const existingLoan =
          invObject.existingInvestment?.investmentType === "Loan";
        const draftLoan =
          invObject.investmentFieldContent?.fieldContent?.assetType === "Loan";
        return existingLoan || draftLoan;
      },
    );

    if (allLoans) {
      const seniorities = _.map(
        props.investmentGroup.investments,
        function (invObject) {
          const existingSeniority = invObject.existingInvestment?.loanSeniority;
          const draftSeniority =
            invObject.investmentFieldContent?.fieldContent?.assetSeniority;
          return existingSeniority || draftSeniority;
        },
      );
      const sameSeniority = _.uniq(_.compact(seniorities)).length === 1;

      return sameSeniority ? _.startCase(_.head(_.compact(seniorities))) : null;
    } else {
      return null;
    }
  } else {
    return null;
  }
});
const contentTypedTabs = computed(() => {
  switch (props.investmentGroup.dealAction) {
    case "refinance":
    case "originateLoan": {
      const newTabs = _.concat(
        [
          {
            name: "Loan",
            order: 2,
            href: "",
            svgPath:
              "M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z",
            component: markRaw(LoansDetail),
          },
        ],
        tabs.value,
      );

      return _.sortBy(newTabs, "order");
    }
    default:
      return tabs.value;
  }
});
const contextedTabs = computed(() => {
  if (props.investmentGroup.id) {
    return contentTypedTabs.value;
  } else {
    return contentTypedTabs.value.filter((tab) => {
      return _.includes(
        ["Assets", "Loan", "Valuations", "Players", "Timing"],
        tab.name,
      );
    });
  }
});

watch(existingGroupId, (id, oldId) => {
  if (id !== oldId) {
    fetchInvestments();
  }
});
watch(selectedTab, (tab, oldTab) => {
  if (tab?.name !== oldTab?.name && tab?.name === "Assets") {
    dealBuilderStore.clearDealBuilder(false);
  }
});

onMounted(() => {
  if (existingGroupId.value) {
    fetchInvestments();
    fetchInvestmentGroupDataField();
  }
});

function saveGroup() {
  actionInProgress.value = true;
  dealBuilderStore.saveInvestmentGroup({
    placeholderId: props.investmentGroup.placeholderId,
    successCallback: async (json) => {
      actionInProgress.value = false;
      const newGroup = _.find(json.data.dataFields, {
        fieldContentType: "InvestmentGroup",
      });

      dealBuilderStore.refreshInvestmentGroup({
        closingGroupId: props.investmentGroup.placeholderId,
        groupId: newGroup?.fieldContent?.id,
        dealAction: newGroup?.fieldContent?.dealAction,
        isExistingGroup: false,
      });
    },
  });
}
function closeGroup() {
  dealBuilderStore.removeDealBuilderInvestmentGroup({
    groupId: props.investmentGroup.id || props.investmentGroup.placeholderId,
    existing: !!props.investmentGroup.id,
  });
}
function fetchInvestments() {
  dealBuilderStore.fetchInvestmentGroupInvestments(props.investmentGroup.id);
}
async function fetchInvestmentGroupDataField() {
  if (props.investmentGroup.id) {
    const json = await api.get(`investment_groups/${props.investmentGroup.id}`);

    if (json?.data) {
      investmentGroupDataField.value = json.data;
    }
  }
}
</script>
