<template>
  <HeaderContainer>
    <header>{{ mainContent.content?.name }}</header>
  </HeaderContainer>
</template>

<script setup>
import HeaderContainer from "@/components/we/main-content/HeaderContainer.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";

const wePanelStore = useWePanelStore();
const { mainContent } = storeToRefs(wePanelStore);
</script>
