import { defineStore, acceptHMRUpdate } from "pinia";
import { useMainMapStore } from "@/stores/mainMap";
import { useUserStore } from "@/stores/user";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useCalculationFieldsStore } from "@/stores/calculationFields";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useNotificationsStore } from "@/stores/notifications";
import { assetDataFieldFor } from "@/components/company-detail/companyInvolvementGroups";
import { storeToRefs } from "pinia";
import { ref, computed, watch, markRaw, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import milestones from "@/assets/contentMilestones";
import useTypes from "@/assets/useTypes";
import delay from "@/assets/delay";
import DataCoverage from "@/components/analyze/layers/DataCoverage.vue";
import api from "@/router/api";
import _ from "lodash";
/* global L */

export const useAnalyzePanelStore = defineStore("analyzePanel", () => {
  const calculationFieldsStore = useCalculationFieldsStore();
  const notificationsStore = useNotificationsStore();
  const userStore = useUserStore();
  const { signedIn } = storeToRefs(userStore);
  const mapStore = useMainMapStore();
  const {
    map,
    zoom,
    centerLatLng,
    nearbyPropertyDataFields,
    nearbyHuntDataFields,
    compositeMapMeta,
    allPagysLoaded: mapPagysLoaded,
  } = storeToRefs(mapStore);
  const timeTravelStore = useTimeTravelStore();
  const {
    asOfMilliseconds,
    combinedTimelineInvestmentFields,
    combinedInvestmentIds,
    combinedTimelineSpaceAvailabilityFields,
    combinedSpaceAvailabilityIds,
    mainTimelineCustomLookbackDate,
    allPagysLoaded: timelinePagysLoaded,
  } = storeToRefs(timeTravelStore);
  const propertyDiagramStore = usePropertyDiagramStore();
  const {
    propertyIdParam,
    propertyDiagramPropertyIds,
    propertyDiagramSelected,
    propertySelectedRecordDataField,
    propertyDiagramPropertyDataFields,
    propertyDataField,
    editingMode,
  } = storeToRefs(propertyDiagramStore);
  const companyDetailStore = useCompanyDetailStore();
  const {
    companyIdParam,
    companyDetailSelected,
    companySelectedRecordDataField,
    companyFetchedPolygonDataFields,
    companyFetchedLocationDataFields,
  } = storeToRefs(companyDetailStore);
  const contactDetailStore = useContactDetailStore();
  const {
    contactIdParam,
    contactDetailSelected,
    contactSelectedRecordDataField,
    contactFetchedPolygonDataFields,
    contactFetchedLocationDataFields,
  } = storeToRefs(contactDetailStore);

  const router = useRouter();
  const route = useRoute();
  const routeName = computed(() => route.name);
  const query = computed(() => route.query);
  const horizontalIsAnalyze = computed(
    () =>
      _.includes(
        ["CompanyShell", "ContactShell", "PropertyShell"],
        routeName.value,
      ) || _.get(query.value, "horizontalTab") === "Analyze",
  );
  const asyncReady = computed(
    () =>
      horizontalIsAnalyze.value &&
      mapPagysLoaded.value &&
      timelinePagysLoaded.value,
  );
  const fetchingAnalyzePanelData = ref(false);
  const layers = ref([
    {
      id: 1,
      title: "Base",
      description: "Standard coloring for properties, buildings, and parcels",
      component: null,
    },
    {
      id: 2,
      title: "Data Coverage",
      description:
        "Which properties have: Leasing, Investments, Key Dates, Uses, and Sizes",
      component: markRaw(DataCoverage),
    },
  ]);
  const selectedLayer = ref(layers.value[0]);
  const hoveringTableRowContent = ref(null);
  const mapSearchShortcutAction = ref(null);
  const emptySpaceUsageStates = () => {
    const rawStates = _.get(milestones, "SpaceUsage", []);

    return rawStates.map((stateObject) => {
      return {
        value: stateObject.value,
        label: stateObject.statusLabel,
        openDateFieldName: stateObject.openDateFieldName,
        closeDateFieldName: stateObject.closeDateFieldName,
        checked: _.includes(["terminated", "expired"], stateObject.value)
          ? false
          : true,
      };
    });
  };
  const emptyLoanStates = () => {
    const rawStates = _.get(milestones, "Loan", []);

    return rawStates.map((stateObject) => {
      return {
        value: stateObject.value,
        label: stateObject.statusLabel,
        openDateFieldName: stateObject.openDateFieldName,
        closeDateFieldName: stateObject.closeDateFieldName,
        checked: stateObject.value === "retired" ? false : true,
      };
    });
  };
  const emptyInvestmentStates = () => {
    const rawStates = _.get(milestones, "Investment", []);

    return rawStates.map((stateObject) => {
      return {
        value: stateObject.value,
        label: stateObject.statusLabel,
        openDateFieldName: stateObject.openDateFieldName,
        closeDateFieldName: stateObject.closeDateFieldName,
        checked: stateObject.value === "withdrawn" ? false : true,
      };
    });
  };
  const emptySpaceAvailabilityStates = () => {
    const rawStates = _.get(milestones, "SpaceAvailability", []);

    return rawStates.map((stateObject) => {
      return {
        value: stateObject.value,
        label: stateObject.name,
        openDateFieldName: stateObject.openDateFieldName,
        closeDateFieldName: stateObject.closeDateFieldName,
        checked: _.includes(["withdrawn"], stateObject.value) ? false : true,
      };
    });
  };
  const emptyLiveSpaceAvailabilityStates = () => {
    const rawStates = _.get(milestones, "SpaceAvailability", []);

    return rawStates.map((stateObject) => {
      return {
        value: stateObject.value,
        label: stateObject.name,
        openDateFieldName: stateObject.openDateFieldName,
        closeDateFieldName: stateObject.closeDateFieldName,
        checked: _.includes(["live"], stateObject.value) ? true : false,
      };
    });
  };
  const emptyUseTypes = () => {
    const rawTypes = useTypes;

    return rawTypes.map((useTypeObject) => {
      return {
        value: useTypeObject.value,
        label: useTypeObject.value,
        checked: true,
      };
    });
  };
  const filters = ref({
    category: [
      { value: "hunts", label: "Hunts", checked: false },
      { value: "companies", label: "Companies", checked: false },
      {
        value: "space-availabilities",
        label: "Space Availabilities",
        checked: false,
      },
      { value: "space-usages", label: "Leases/Licenses", checked: false },
      { value: "investments", label: "Investments", checked: false },
      { value: "loans", label: "Loans", checked: false },
      { value: "sizes-uses", label: "Sizes & Uses", checked: false },
    ],
    dates: [{ label: "From" }, { label: "To" }],
    geography: [
      { value: "boundaries", label: "Map Boundaries" },
      { value: "polygon", label: "Polygon", polygon: null, editing: false },
    ],
    landCoveringUseType: emptyUseTypes(),
    landCoveringState: [
      {
        value: ["hypothetical"],
        label: "Hypothetical",
        checked: false,
      },
      {
        value: ["construction_proposed"],
        label: "Proposed",
        checked: false,
      },
      {
        value: ["construction_approved", "construction_permitted"],
        label: "Approved",
        checked: false,
      },
      {
        value: ["construction_commenced"],
        label: "Under Construction",
        checked: true,
      },
      {
        value: [
          "demolition_proposed",
          "demolition_permitted",
          "construction_completed",
          "remeasurement_created",
        ],
        label: "Completed",
        checked: true,
      },
    ],
    companyEventType: [
      { value: "capMarkets", label: "Capital Markets Deal", checked: true },
      { value: "leasing", label: "Leasing Deal", checked: true },
      { value: "capMarketsHunt", label: "Capital Markets Hunt", checked: true },
      { value: "leasingHunt", label: "Occupier Hunt", checked: true },
    ],
    companyRole: [
      { value: "Principal", label: "Principal", checked: true },
      { value: "Advisor", label: "Advisor", checked: true },
      { value: "Prospect", label: "Include Prospects?", checked: true },
    ],
    loanState: emptyLoanStates(),
    loanMaturityDate: [
      {
        value: "maturity_date",
        label: "On or before",
        date: null,
        editing: false,
      },
    ],
    spaceUsageState: emptySpaceUsageStates(),
    spaceUsageExpirationDate: [
      {
        value: "expired_date",
        label: "On or before",
        date: null,
        editing: false,
      },
    ],
    spaceAvailabilitySpaceType: [
      { value: "FloorArea", label: "Floor Area", checked: true },
      { value: "LayoutPartition", label: "Room", checked: false },
      {
        value: "PropertyEnhancement",
        label: "Property Feature",
        checked: false,
      },
    ],
    spaceAvailabilityState: emptySpaceAvailabilityStates(),
    spaceAvailabilitySize: [{ label: "Area (SF)", min: null, max: null }],
    investmentState: emptyInvestmentStates(),
    investmentValuation: [{ label: "Value", min: null, max: null }],
    investmentType: [
      {
        value: ["sellOutright", "foreclosureSellOutright"],
        label: "Sale",
        checked: true,
      },
      {
        value: [
          "sellEntireInterest",
          "sellPartialInterest",
          "sellMultipleInterests",
        ],
        label: "Recap",
        checked: true,
      },
      {
        value: ["originateLoan", "refinance"],
        label: "Financing",
        checked: false,
      },
    ],
    investmentAssetType: [
      { value: "Fee simple", label: "Fee parcel", checked: true },
      { value: "Leasehold", label: "Leasehold", checked: true },
      { value: "Condominium", label: "Condominium", checked: true },
      { value: "Co-op", label: "Co-op", checked: true },
      {
        value: "Unused Development Rights",
        label: "Air Rights",
        checked: true,
      },
      { value: "senior", label: "Senior Loan", checked: true },
      { value: "junior", label: "Junior Loan", checked: true },
    ],
  });
  const polygonPropertyLocations = ref([]);
  const polygonInvestmentIds = ref([]);
  const polygonAvailabilityIds = ref([]);
  const polygonPropertyIds = computed(() =>
    translatePropertyIds(
      polygonPropertyLocations.value.map(
        (locationField) => locationField.decoratingContentId,
      ),
    ),
  );
  const selectedGeography = ref(
    _.find(filters.value.geography, { value: "boundaries" }),
  );
  const geographyFilterPolygon = computed(
    () =>
      _.find(filters.value.geography, {
        value: "polygon",
      }).polygon,
  );
  const editingPolygon = computed(() => {
    return (
      selectedGeography.value?.value === "polygon" &&
      _.find(filters.value.geography, {
        value: "polygon",
      }).editing
    );
  });
  const polygonArea = computed(() => {
    if (geographyFilterPolygon.value) {
      const latLngs = geographyFilterPolygon.value.getLatLngs()[0];
      return L.GeometryUtil.geodesicArea(latLngs);
    } else {
      return null;
    }
  });
  function getPolygonCoordinates() {
    if (geographyFilterPolygon.value) {
      return geographyFilterPolygon.value.getLatLngs()[0].map((c) => {
        return { lat: c.lat, lng: c.lng };
      });
    }
  }
  const emptyCategoriesFetchable = computed(
    () =>
      _.includes(["CompanyShell", "ContactShell"], routeName.value) ||
      checkedCategories.value.length > 0,
  );
  const triggerClearMapPolygon = ref(false);
  const filterCategories = computed(() => {
    switch (routeName.value) {
      case "MainMap":
        return filters.value.category;
      case "CompanyShell":
      case "ContactShell":
        return filters.value.category.filter((category) => {
          return _.includes(
            [
              "space-availabilities",
              "space-usages",
              "sizes-uses",
              "investments",
              "loans",
            ],
            category.value,
          );
        });
      case "PropertyShell":
      default:
        return filters.value.category.filter((category) => {
          return _.includes(
            ["space-availabilities", "space-usages", "sizes-uses"],
            category.value,
          );
        });
    }
  });
  const filterDates = computed(() => {
    switch (routeName.value) {
      case "MainMap": {
        if (
          spaceAvailabilitiesChecked.value ||
          spaceUsagesChecked.value ||
          investmentsChecked.value ||
          loansChecked.value
        ) {
          return filters.value.dates;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell":
      default:
        return null;
    }
  });
  const filterGeography = computed(() => {
    switch (routeName.value) {
      case "MainMap":
      case "CompanyShell":
      case "ContactShell": {
        if (checkedCategories.value.length > 0) {
          return filters.value.geography;
        } else {
          return null;
        }
      }
      default:
        return null;
    }
  });
  const filterLandCoveringUseTypes = computed(() => {
    switch (routeName.value) {
      case "PropertyShell":
      case "MainMap": {
        if (checkedCategories.value.length > 0) {
          return filters.value.landCoveringUseType;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell":
        return filters.value.landCoveringUseType;
      default:
        return null;
    }
  });
  const filterLandCoveringStates = computed(() => {
    switch (routeName.value) {
      case "PropertyShell":
      case "MainMap": {
        if (checkedCategories.value.length > 0) {
          return filters.value.landCoveringState;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell":
        return filters.value.landCoveringState;
      default:
        return null;
    }
  });
  const filterSpaceAvailabilitySpaceTypes = computed(() => {
    switch (routeName.value) {
      case "PropertyShell":
      case "MainMap": {
        if (
          spaceAvailabilitiesChecked.value ||
          companiesChecked.value ||
          spaceUsagesChecked.value
        ) {
          return filters.value.spaceAvailabilitySpaceType;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell":
        return filters.value.spaceAvailabilitySpaceType;
      default:
        return null;
    }
  });
  const filterInvestmentAssetTypes = computed(() => {
    switch (routeName.value) {
      case "PropertyShell":
      case "MainMap": {
        if (
          investmentsChecked.value ||
          companiesChecked.value ||
          loansChecked.value
        ) {
          return filters.value.investmentAssetType;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell":
        return filters.value.investmentAssetType;
      default:
        return null;
    }
  });
  const filterSpaceUsageExpirationDate = computed(() => {
    switch (routeName.value) {
      case "MainMap": {
        if (spaceUsagesChecked.value) {
          return filters.value.spaceUsageExpirationDate;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell": {
        if (spaceUsagesChecked.value) {
          return filters.value.spaceUsageExpirationDate;
        } else {
          return null;
        }
      }
      default:
        return null;
    }
  });
  const filterSpaceAvailabilityStates = computed(() => {
    switch (routeName.value) {
      case "PropertyShell":
      case "MainMap": {
        if (spaceAvailabilitiesChecked.value || companiesChecked.value) {
          return filters.value.spaceAvailabilityState;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell": {
        return filters.value.spaceAvailabilityState;
      }
      default:
        return null;
    }
  });
  const filterSpaceUsageStates = computed(() => {
    switch (routeName.value) {
      case "PropertyShell":
      case "MainMap": {
        if (spaceUsagesChecked.value || companiesChecked.value) {
          return filters.value.spaceUsageState;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell": {
        return filters.value.spaceUsageState;
      }
      default:
        return null;
    }
  });
  const filterLoanMaturityDate = computed(() => {
    switch (routeName.value) {
      case "MainMap": {
        if (loansChecked.value) {
          return filters.value.loanMaturityDate;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell": {
        if (loansChecked.value) {
          return filters.value.loanMaturityDate;
        } else {
          return null;
        }
      }
      default:
        return null;
    }
  });
  const filterLoanStates = computed(() => {
    switch (routeName.value) {
      case "MainMap": {
        if (loansChecked.value) {
          return filters.value.loanState;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell": {
        if (loansChecked.value) {
          return filters.value.loanState;
        } else {
          return null;
        }
      }
      default:
        return null;
    }
  });
  const filterInvestmentStates = computed(() => {
    switch (routeName.value) {
      case "PropertyShell":
      case "MainMap": {
        if (investmentsChecked.value || companiesChecked.value) {
          return filters.value.investmentState;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell":
        return filters.value.investmentState;
      default:
        return null;
    }
  });
  const filterCompanyEventTypes = computed(() => {
    switch (routeName.value) {
      case "MainMap": {
        if (companiesChecked.value) {
          return filters.value.companyEventType;
        } else if (huntsChecked.value) {
          return filters.value.companyEventType.filter(({ value }) =>
            _.includes(["capMarketsHunt", "leasingHunt"], value),
          );
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell":
        return filters.value.companyEventType;
      default:
        return null;
    }
  });
  const filterCompanyRoles = computed(() => {
    switch (routeName.value) {
      case "MainMap": {
        if (companiesChecked.value) {
          return filters.value.companyRole;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell": {
        return filters.value.companyRole;
      }
      default:
        return null;
    }
  });
  const filterInvestmentTypes = computed(() => {
    switch (routeName.value) {
      case "PropertyShell":
      case "MainMap": {
        if (
          huntsChecked.value &&
          _.includes(selectedCompanyEventTypes.value, "capMarketsHunt")
        ) {
          return filters.value.investmentType;
        } else if (investmentsChecked.value || companiesChecked.value) {
          return filters.value.investmentType;
        } else {
          return null;
        }
      }
      case "CompanyShell":
      case "ContactShell":
        return filters.value.investmentType;
      default:
        return null;
    }
  });
  const filterValuations = computed(() => {
    if (
      huntsChecked.value &&
      _.includes(selectedCompanyEventTypes.value, "capMarketsHunt")
    ) {
      return filters.value.investmentValuation;
    } else if (
      _.includes(["CompanyShell", "ContactShell"], routeName.value) ||
      investmentsChecked.value ||
      companiesChecked.value ||
      loansChecked.value
    ) {
      return filters.value.investmentValuation;
    } else {
      return null;
    }
  });
  const filterSpaceAvailabilitySizes = computed(() => {
    if (
      huntsChecked.value &&
      _.includes(selectedCompanyEventTypes.value, "leasingHunt")
    ) {
      return filters.value.spaceAvailabilitySize;
    } else if (
      _.includes(["CompanyShell", "ContactShell"], routeName.value) ||
      spaceAvailabilitiesChecked.value ||
      spaceUsagesChecked.value ||
      companiesChecked.value
    ) {
      return filters.value.spaceAvailabilitySize;
    } else {
      return null;
    }
  });

  const spaceUsageExpirationDateFilterInput = computed(
    () =>
      _.find(filters.value.spaceUsageExpirationDate, {
        value: "expired_date",
      }).date,
  );
  const editingSpaceUsageExpirationDate = computed(() => {
    return _.find(filters.value.spaceUsageExpirationDate, {
      value: "expired_date",
    }).editing;
  });
  const fetchingSpaceUsageExpirationDateFilterData = ref(false);
  const filteredSpaceUsageExpirationDateFields = ref([]);

  const fetchingSpaceAvailabilityStateFilterData = ref(false);
  const filteredSpaceAvailabilityStateFields = ref([]);
  const filteredSpaceAvailabilityStateIds = computed(() => {
    if (selectedSpaceAvailabilityStates.value.length > 0) {
      return filteredSpaceAvailabilityStateFields.value.map(
        (dataFieldStub) => dataFieldStub.decoratingContentId,
      );
    } else {
      return [];
    }
  });

  const fetchingSpaceAvailabilitySpaceTypeFilterData = ref(false);
  const filteredSpaceAvailabilitySpaceTypeFields = ref([]);
  const filteredSpaceAvailabilitySpaceTypeIds = computed(() => {
    if (selectedSpaceTypes.value.length > 0) {
      return filteredSpaceAvailabilitySpaceTypeFields.value.map(
        (dataFieldStub) => dataFieldStub.fieldContentId,
      );
    } else {
      return [];
    }
  });

  const fetchingSpaceUsageStateFilterData = ref(false);
  const filteredSpaceUsageStateFields = ref([]);
  const filteredSpaceUsageStateSpaceUsageIds = computed(() => {
    if (selectedSpaceUsageStates.value.length > 0) {
      return filteredSpaceUsageStateFields.value.map(
        (dataFieldStub) => dataFieldStub.decoratingContentId,
      );
    } else {
      return [];
    }
  });

  const filteredSpaceAvailabilityLikes = computed(() => {
    const sizeArray = spaceAvailabilitySizeFilterApplied.value
      ? filteredStandaloneSpaceAvailabilitySizeFields.value
      : null;
    const eligibleArrays = _.compact([sizeArray]);
    const mapped = eligibleArrays.map((mixedFormatRecords) => {
      const rawMapping = mixedFormatRecords.map((record) => {
        if (record.availabilityId) {
          return {
            id: record.availabilityId,
            type: "SpaceAvailability",
          };
        } else if (record.availabilityGroupId) {
          return {
            id: record.availabilityGroupId,
            type: "SpaceAvailabilityGroup",
          };
        } else {
          return null;
        }
      });

      return _.compact(rawMapping);
    });

    if (_.size(eligibleArrays) > 0) {
      return _.intersectionBy(...mapped, function ({ id, type }) {
        return `${type}${id}`;
      });
    } else {
      return _.flatten(mapped);
    }
  });

  const filteredSpaceUsageAvailabilityLikes = computed(() => {
    const sizeArray = spaceAvailabilitySizeFilterApplied.value
      ? filteredStandaloneSpaceUsageAvailabilitySizeFields.value
      : null;
    const expirationArray = spaceUsageExpirationDateFilterInput.value
      ? filteredSpaceUsageExpirationDateFields.value
      : null;
    const eligibleArrays = _.compact([sizeArray, expirationArray]);
    const mapped = eligibleArrays.map((mixedFormatRecords) => {
      const rawMapping = mixedFormatRecords.map((record) => {
        if (record.spaceAvailabilityLikeId) {
          return {
            id: record.spaceAvailabilityLikeId,
            type: record.spaceAvailabilityLikeType,
          };
        } else if (record.availabilityId) {
          return {
            id: record.availabilityId,
            type: "SpaceAvailability",
          };
        } else if (record.availabilityGroupId) {
          return {
            id: record.availabilityGroupId,
            type: "SpaceAvailabilityGroup",
          };
        } else {
          return null;
        }
      });

      return _.compact(rawMapping);
    });

    if (_.size(eligibleArrays) > 0) {
      return _.intersectionBy(...mapped, function ({ id, type }) {
        return `${type}${id}`;
      });
    } else {
      return _.flatten(mapped);
    }
  });
  const combinedFilteredSpaceUsageAvailabilityIds = computed(() => {
    const eligibleIdArrays = [polygonAvailabilityIds.value].filter(
      (arr) => arr.length > 0,
    );
    const combinedIdArrays = _.concat(
      [
        filteredSpaceUsageAvailabilityIds.value,
        landCoveringSpaceAvailabilityIds.value,
      ],
      eligibleIdArrays,
    );

    return _.intersection(...combinedIdArrays);
  });
  const combinedFilteredSpaceAvailabilityIds = computed(() => {
    const eligibleIdArrays = [polygonAvailabilityIds.value].filter(
      (arr) => arr.length > 0,
    );
    const combinedIdArrays = _.concat(
      [
        filteredSpaceAvailabilityStateIds.value,
        filteredSpaceAvailabilitySpaceTypeIds.value,
        landCoveringSpaceAvailabilityIds.value,
      ],
      eligibleIdArrays,
    );

    return _.intersection(...combinedIdArrays);
  });
  function combinedFilteredSpaceAvailabilityIncluded(availabilityId) {
    // console.log(
    //   "filtered state ids",
    //   filteredSpaceAvailabilityStateIds.value,
    //   "filtered space type ids",
    //   filteredSpaceAvailabilitySpaceTypeIds.value,
    //   "land covering availability ids",
    //   landCoveringSpaceAvailabilityIds.value,
    //   "filtered availability ids",
    //   combinedFilteredSpaceAvailabilityIds.value,
    //   "filtered usage availability ids",
    //   combinedFilteredSpaceUsageAvailabilityIds.value,
    // );
    const availabilityIncluded =
      combinedFilteredSpaceAvailabilityIds.value.length > 0 &&
      _.includes(combinedFilteredSpaceAvailabilityIds.value, availabilityId);
    const usageAvailabilityIncluded =
      combinedFilteredSpaceUsageAvailabilityIds.value.length > 0 &&
      _.includes(
        combinedFilteredSpaceUsageAvailabilityIds.value,
        availabilityId,
      );

    return availabilityIncluded || usageAvailabilityIncluded;
  }
  const spaceAvailabilityLikeFilterable = computed(
    () => spaceAvailabilitySizeFilterApplied.value,
  );
  function spaceAvailabilityLikeIncluded(availabilityId, portfolioId) {
    if (spaceAvailabilityLikeFilterable.value && portfolioId) {
      return _.some(
        filteredSpaceAvailabilityLikes.value,
        function ({ id, type }) {
          return type === "SpaceAvailabilityGroup" && id === portfolioId;
        },
      );
    } else if (spaceAvailabilityLikeFilterable.value && availabilityId) {
      return _.some(
        filteredSpaceAvailabilityLikes.value,
        function ({ id, type }) {
          return type === "SpaceAvailability" && id === availabilityId;
        },
      );
    } else {
      return true;
    }
  }

  const spaceUsageAvailabilityLikeFilterable = computed(
    () =>
      spaceAvailabilitySizeFilterApplied.value ||
      spaceUsageExpirationDateFilterInput.value,
  );
  function spaceUsageAvailabilityLikeIncluded(availabilityId, portfolioId) {
    if (spaceUsageAvailabilityLikeFilterable.value && portfolioId) {
      return _.some(
        filteredSpaceUsageAvailabilityLikes.value,
        function ({ id, type }) {
          return type === "SpaceAvailabilityGroup" && id === portfolioId;
        },
      );
    } else if (spaceUsageAvailabilityLikeFilterable.value && availabilityId) {
      return _.some(
        filteredSpaceUsageAvailabilityLikes.value,
        function ({ id, type }) {
          return type === "SpaceAvailability" && id === availabilityId;
        },
      );
    } else {
      return true;
    }
  }

  const loanMaturityDateFilterInput = computed(
    () =>
      _.find(filters.value.loanMaturityDate, {
        value: "maturity_date",
      }).date,
  );
  const editingLoanMaturityDate = computed(() => {
    return _.find(filters.value.loanMaturityDate, {
      value: "maturity_date",
    }).editing;
  });
  const fetchingLoanMaturityDateFilterData = ref(false);
  const filteredLoanMaturityDateFields = ref([]);

  const fetchingLoanStateFilterData = ref(false);
  const filteredLoanStateFields = ref([]);
  const filteredLoanStateLoanIds = computed(() => {
    if (selectedLoanStates.value.length > 0) {
      return filteredLoanStateFields.value.map(
        (dataFieldStub) => dataFieldStub.decoratingContentId,
      );
    } else {
      return [];
    }
  });
  const fetchingInvestmentStateFilterData = ref(false);
  const filteredInvestmentStateFields = ref([]);
  const filteredInvestmentStateIds = computed(() => {
    if (selectedInvestmentStates.value.length > 0) {
      return filteredInvestmentStateFields.value;
    } else {
      return [];
    }
  });
  const filteredInvestmentAssetTypeFields = ref([]);
  const filteredAssetTypedInvestmentIds = computed(() => {
    if (selectedInvestmentAssetTypes.value.length > 0) {
      return filteredInvestmentAssetTypeFields.value;
    } else {
      return [];
    }
  });
  const fetchingInvestmentAssetTypeFilterData = ref(false);

  const fetchingLandCoveringUseTypeFilterData = ref(false);
  const filteredLandCoveringUseTypeFields = ref({
    floorAreas: [],
    landCoverings: [],
  });

  const useTypeFilteredLandCoveringIds = computed(() => {
    if (
      checkedCategories.value.length > 0 &&
      selectedLandCoveringUseTypes.value.length > 0
    ) {
      return filteredLandCoveringUseTypeFields.value.landCoverings.map(
        (dataFieldStub) => dataFieldStub.fieldContentId,
      );
    } else {
      return [];
    }
  });

  const fetchingLandCoveringStateFilterData = ref(false);
  const filteredLandCoveringStateFields = ref({
    stateFilteredPropertyEnhancements: [],
    stateFilteredLayoutPartitions: [],
    stateFilteredFloorAreas: [],
    landCoveringFloorAreas: [], // extract LandCoverings from these
    allFloorAreas: [],
  });
  const stateFilteredPropertyEnhancementFields = computed(() => {
    return _.concat(
      filteredLandCoveringStateFields.value.stateFilteredPropertyEnhancements,
    );
  });
  const stateFilteredLayoutPartitionFields = computed(() => {
    return _.concat(
      filteredLandCoveringStateFields.value.stateFilteredLayoutPartitions,
    );
  });
  const stateFilteredFloorAreaFields = computed(() => {
    return _.concat(
      filteredLandCoveringStateFields.value.landCoveringFloorAreas,
      filteredLandCoveringStateFields.value.stateFilteredFloorAreas,
      filteredLandCoveringStateFields.value.allFloorAreas,
    );
  });

  const combinedFilteredLandCoveringFloorAreaFields = computed(() => {
    const intersection = _.intersectionBy(
      filteredLandCoveringUseTypeFields.value.floorAreas,
      stateFilteredFloorAreaFields.value,
      function (dataFieldStub) {
        if (dataFieldStub.decoratingContentType === "FloorArea") {
          return dataFieldStub.decoratingContentId;
        } else if (dataFieldStub.fieldContentType === "FloorArea") {
          return dataFieldStub.fieldContentId;
        } else {
          return null;
        }
      },
    );

    return _.compact(intersection);
  });

  const combinedFilteredLandCoveringFloorAreaPropertyIds = computed(() =>
    translatePropertyIds(
      combinedFilteredLandCoveringFloorAreaFields.value.map(
        (dataFieldStub) => dataFieldStub.propertyId,
      ),
    ),
  );

  const stateFilteredLandCoveringIds = computed(() => {
    if (
      checkedCategories.value.length > 0 &&
      selectedLandCoveringStates.value.length > 0
    ) {
      const raw = stateFilteredFloorAreaFields.value.map((dataFieldStub) => {
        const landCoveringStub = _.find(
          filteredLandCoveringStateFields.value.allFloorAreas,
          function (allFieldsStub) {
            if (dataFieldStub.decoratingContentType === "FloorArea") {
              return (
                allFieldsStub.fieldContentId ===
                dataFieldStub.decoratingContentId
              );
            } else if (dataFieldStub.fieldContentType === "FloorArea") {
              return (
                allFieldsStub.fieldContentId === dataFieldStub.fieldContentId
              );
            } else {
              return null;
            }
          },
        );

        if (landCoveringStub?.joiningContentType === "LandCovering") {
          return landCoveringStub.joiningContentId;
        } else {
          return landCoveringStub?.decoratingContentId;
        }
      });

      return _.uniq(raw);
    } else {
      return [];
    }
  });

  const combinedFilteredLandCoveringIds = computed(() => {
    const eligibleIdArrays = [].filter((arr) => arr.length > 0);
    const combinedIdArrays = _.concat(
      [
        stateFilteredLandCoveringIds.value,
        useTypeFilteredLandCoveringIds.value,
      ],
      eligibleIdArrays,
    );

    return _.intersection(...combinedIdArrays);
  });

  const stateFilteredLandCoveringPropertyIds = computed(() => {
    if (
      checkedCategories.value.length > 0 &&
      selectedLandCoveringStates.value.length > 0
    ) {
      return translatePropertyIds(
        stateFilteredFloorAreaFields.value.map(
          (dataFieldStub) => dataFieldStub.propertyId,
        ),
      );
    } else {
      return [];
    }
  });
  const contextedPropertiesArray = computed(() => {
    let array = _.toArray(nearbyPropertyDataFields.value);

    if (unselectedPropertyDiagram.value) {
      array = _.toArray(propertyDiagramPropertyDataFields.value);
    } else if (propertySelectedPropertyDiagram.value) {
      array = [propertyDataField.value];
    }

    return array;
  });
  const propertyTranslationIds = computed(() => {
    const translations = contextedPropertiesArray.value.map((df) => {
      const propertyId =
        df.decoratingContentType === "Property"
          ? df.decoratingContentId
          : df.fieldContentId;
      if (df.fieldContent.airLayerPropertyId) {
        return [
          {
            propertyId,
            translatedIds: [
              df.decoratingContentId,
              df.fieldContent.airLayerPropertyId,
            ],
          },
          {
            propertyId: df.fieldContent.airLayerPropertyId,
            translatedIds: [propertyId, df.fieldContent.airLayerPropertyId],
          },
        ];
      } else {
        return {
          propertyId,
          translatedIds: [propertyId],
        };
      }
    });

    return _.flatten(translations);
  });
  function translatePropertyIds(ids) {
    const translated = _.flatten(
      ids.map(
        (id) =>
          _.find(propertyTranslationIds.value, { propertyId: id })
            ?.translatedIds,
      ),
    );

    // console.log(propertyTranslationIds.value, ids, translated);

    return _.uniq(_.compact(translated));
  }
  const combinedFilteredPropertyIds = computed(() => {
    const eligibleIdArrays = [polygonPropertyIds.value].filter(
      (arr) => arr.length > 0,
    );
    const investmentPropertyIds =
      investmentsChecked.value || loansChecked.value
        ? [datedInvestmentPropertyIds.value]
        : [];
    const spaceAvailabilityPropertyIds =
      spaceAvailabilitiesChecked.value || spaceUsagesChecked.value
        ? [datedAvailabilityPropertyIds.value]
        : [];
    const combinedInvestmentArrays = _.concat(
      [combinedFilteredLandCoveringFloorAreaPropertyIds.value],
      investmentPropertyIds,
      eligibleIdArrays,
    );
    const combinedSpaceAvailabilityArrays = _.concat(
      [combinedFilteredLandCoveringFloorAreaPropertyIds.value],
      spaceAvailabilityPropertyIds,
      eligibleIdArrays,
    );
    const combinedSizeArrays = _.concat(
      [combinedFilteredLandCoveringFloorAreaPropertyIds.value],
      eligibleIdArrays,
    );

    if (filterGeography.value) {
      const intersectedInvestments =
        investmentsChecked.value || loansChecked.value || companiesChecked.value
          ? _.intersection(...combinedInvestmentArrays)
          : [];
      const intersectedSpaceAvailabilities =
        spaceAvailabilitiesChecked.value ||
        spaceUsagesChecked.value ||
        companiesChecked.value
          ? _.intersection(...combinedSpaceAvailabilityArrays)
          : [];
      const intersectedSizes = sizesChecked.value
        ? _.intersection(...combinedSizeArrays)
        : [];

      // console.log(
      //   "raw property ids",
      //   datedInvestmentPropertyIds.value,
      //   datedAvailabilityPropertyIds.value,
      //   "combined filtered property ids",
      //   intersectedSizes,
      //   intersectedInvestments,
      //   intersectedSpaceAvailabilities,
      // );

      return _.uniq(
        _.concat(
          intersectedSizes,
          intersectedInvestments,
          intersectedSpaceAvailabilities,
        ),
      );
    } else {
      return _.uniq(
        _.union(
          ...combinedSizeArrays,
          ...combinedInvestmentArrays,
          ...combinedSpaceAvailabilityArrays,
        ),
      );
    }
  });

  const fetchingLoanInvestmentFields = ref(false);
  const loanInvestmentFields = ref([]);
  const multiPropertyPortfolioLoanFields = computed(() =>
    loanInvestmentFields.value.filter(
      (loanStub) =>
        loanStub.loanCategory === "portfolio" && !loanStub.propertyId,
    ),
  );
  const singlePropertyPortfolioLoanFields = computed(() =>
    loanInvestmentFields.value.filter(
      (loanStub) =>
        loanStub.loanCategory === "portfolio" && loanStub.propertyId,
    ),
  );
  const portfolioInvestmentFields = computed(() =>
    loanInvestmentFields.value.filter(
      (loanStub) => loanStub.loanCategory === "group_investment",
    ),
  );
  const individualLoanInvestmentFields = computed(() =>
    loanInvestmentFields.value.filter(
      (loanStub) => !loanStub.loanCategory && loanStub.loanId,
    ),
  );
  const portfolioAssetLoans = computed(() =>
    individualLoanInvestmentFields.value.filter((loanStub) =>
      _.find(portfolioInvestmentFields.value, {
        investmentId: loanStub.investmentId,
      }),
    ),
  );
  const standaloneAssetLoans = computed(() =>
    individualLoanInvestmentFields.value.filter(
      (loanStub) =>
        !_.find(portfolioInvestmentFields.value, {
          investmentId: loanStub.investmentId,
        }),
    ),
  );
  const filterableLoans = computed(() =>
    _.concat(portfolioAssetLoans.value, standaloneAssetLoans.value),
  );
  const filteredLoanInvestmentIds = computed(() => {
    return filterableLoans.value.map(
      (dataFieldStub) => dataFieldStub.investmentId,
    );
  });
  const filteredPortfolioAssetLoans = computed(() => {
    if (loansChecked.value) {
      return portfolioAssetLoans.value.filter(loanInvestmentIncluded);
    } else {
      return [];
    }
  });
  const filteredStandaloneAssetLoans = computed(() => {
    if (loansChecked.value) {
      return standaloneAssetLoans.value.filter(loanInvestmentIncluded);
    } else {
      return [];
    }
  });
  function loanInvestmentIncluded(loanStub) {
    const loanIncluded = _.includes(
      filteredLoanStateLoanIds.value,
      loanStub.loanId,
    );
    const ids = _.intersection(
      combinedInvestmentIds.value,
      combinedFilteredLoanInvestmentIds.value,
    );
    const nonValuationIncluded = _.includes(ids, loanStub.investmentId);
    let valuationIncluded = true;

    if (investmentLikeFilterable.value) {
      const valuationMatches = _.intersectionBy(
        [{ id: loanStub.investmentId, type: "Investment" }],
        filteredInvestmentLikes.value,
        function ({ id, type }) {
          return `${type}${id}`;
        },
      );
      valuationIncluded = _.size(valuationMatches) > 0;
    }

    return loanIncluded && nonValuationIncluded && valuationIncluded;
  }
  function filteredPortfolioLoanStubs(portfolioLoans = []) {
    return portfolioLoans.filter(({ investmentGroupId, loanId }) => {
      const loanIncluded = _.includes(filteredLoanStateLoanIds.value, loanId);
      const includedLoans = portfolioInvestmentFields.value.filter(
        (loanStub) => loanStub.investmentGroupId === investmentGroupId,
      );
      const assetLoanIncluded =
        _.size(
          _.intersectionBy(
            includedLoans,
            filteredPortfolioAssetLoans.value,
            "investmentId",
          ),
        ) > 0;
      let valuationIncluded = false;

      if (investmentLikeFilterable.value) {
        const valuationMatches = _.intersectionBy(
          [
            {
              id: investmentGroupId,
              type: "InvestmentGroup",
            },
          ],
          filteredInvestmentLikes.value,
          function ({ id, type }) {
            return `${type}${id}`;
          },
        );
        valuationIncluded = _.size(valuationMatches) > 0;
      }

      // console.log(
      //   loanId,
      //   investmentGroupId,
      //   loanIncluded,
      //   assetLoanIncluded,
      //   valuationIncluded,
      // );

      return loanIncluded && (assetLoanIncluded || valuationIncluded);
    });
  }
  const loansTableAssemblyContent = computed(() => {
    return {
      multiPropertyPortfolioLoans: filteredPortfolioLoanStubs(
        multiPropertyPortfolioLoanFields.value,
      ),
      singlePropertyPortfolioLoans: filteredPortfolioLoanStubs(
        singlePropertyPortfolioLoanFields.value,
      ),
      propertyGroupedStandaloneAssetLoans: _.groupBy(
        filteredStandaloneAssetLoans.value,
        "propertyId",
      ),
    };
  });
  const displayableLoanTableRows = computed(() => {
    const multiPropertyPortfolioLoans =
      loansTableAssemblyContent.value.multiPropertyPortfolioLoans.map(
        (loanStub) => {
          return {
            rowType: "multiPropertyPortfolioLoan",
            loanStub,
          };
        },
      );
    const singlePropertyPortfolioLoans =
      loansTableAssemblyContent.value.singlePropertyPortfolioLoans.map(
        (loanStub) => {
          return {
            rowType: "singlePropertyPortfolioLoan",
            loanStub,
          };
        },
      );
    const standalonePropertyLoans = _.map(
      loansTableAssemblyContent.value.propertyGroupedStandaloneAssetLoans,
      function (loanStubs, propertyId) {
        return {
          rowType: "singlePropertyLoan",
          propertyId:
            propertyId === "unlicensed" ? propertyId : _.toNumber(propertyId),
          loanStubs,
        };
      },
    );

    return _.orderBy(
      _.concat(
        multiPropertyPortfolioLoans,
        singlePropertyPortfolioLoans,
        standalonePropertyLoans,
      ),
      ["localId"],
      ["desc"],
    );
  });

  const loanCount = computed(() => {
    if (loansChecked.value) {
      const combined = _.concat(
        loansTableAssemblyContent.value.multiPropertyPortfolioLoans,
        loansTableAssemblyContent.value.singlePropertyPortfolioLoans,
        _.values(
          loansTableAssemblyContent.value.propertyGroupedStandaloneAssetLoans,
        ),
      );
      return _.size(_.flatten(combined));
    } else {
      return 0;
    }
  });

  const fetchingSpaceUsageAvailabilityFields = ref(false);
  const spaceUsageAvailabilityFields = ref([]);

  const portfolioSpaceAvailabilityFields = computed(() =>
    spaceUsageAvailabilityFields.value.filter(
      (spaceUsageStub) =>
        spaceUsageStub.spaceUsageCategory === "group_availability",
    ),
  );
  const individualSpaceUsageAvailabilityFields = computed(() =>
    spaceUsageAvailabilityFields.value.filter(
      (spaceUsageStub) =>
        !spaceUsageStub.spaceUsageCategory && spaceUsageStub.spaceUsageId,
    ),
  );
  const portfolioSpaceTypeSpaceUsages = computed(
    () => portfolioSpaceAvailabilityFields.value,
  );
  const standaloneSpaceUsages = computed(() =>
    individualSpaceUsageAvailabilityFields.value.filter(
      (spaceUsageStub) =>
        !_.find(portfolioSpaceAvailabilityFields.value, {
          availabilityId: spaceUsageStub.availabilityId,
        }),
    ),
  );
  const filterableSpaceUsages = computed(() =>
    _.concat(portfolioSpaceTypeSpaceUsages.value, standaloneSpaceUsages.value),
  );
  const filteredSpaceUsageAvailabilityIds = computed(() => {
    if (spaceUsagesChecked.value) {
      return filterableSpaceUsages.value.map(
        (dataFieldStub) => dataFieldStub.availabilityId,
      );
    } else {
      return [];
    }
  });
  const filteredPortfolioSpaceUsages = computed(() => {
    if (spaceUsagesChecked.value) {
      return portfolioSpaceTypeSpaceUsages.value.filter(
        spaceUsageAvailabilityIncluded,
      );
    } else {
      return [];
    }
  });
  const filteredStandaloneSpaceTypeSpaceUsages = computed(() => {
    if (spaceUsagesChecked.value) {
      return standaloneSpaceUsages.value.filter(spaceUsageAvailabilityIncluded);
    } else {
      return [];
    }
  });

  function spaceUsageAvailabilityIncluded(spaceUsageStub) {
    const spaceUsageIncluded = _.includes(
      filteredSpaceUsageStateSpaceUsageIds.value,
      spaceUsageStub.spaceUsageId,
    );
    const ids = _.intersection(
      combinedSpaceAvailabilityIds.value,
      combinedFilteredSpaceUsageAvailabilityIds.value,
    );

    const nonSizeIncluded = _.includes(ids, spaceUsageStub.availabilityId);
    let sizeIncluded = true;

    if (spaceAvailabilityLikeFilterable.value) {
      const valuationMatches = _.intersectionBy(
        [{ id: spaceUsageStub.availabilityId, type: "SpaceAvailability" }],
        filteredSpaceUsageAvailabilityLikes.value,
        function ({ id, type }) {
          return `${type}${id}`;
        },
      );
      sizeIncluded = _.size(valuationMatches) > 0;
    }

    // console.log(
    //   "availability included",
    //   spaceUsageStub,
    //   filteredSpaceUsageStateSpaceUsageIds.value,
    //   combinedSpaceAvailabilityIds.value,
    //   combinedFilteredSpaceUsageAvailabilityIds.value,
    //   filteredSpaceUsageAvailabilityLikes.value,
    //   sizeIncluded,
    // );

    return spaceUsageIncluded && nonSizeIncluded && sizeIncluded;
  }
  function filteredPortfolioSpaceUsageStubs(portfolioUsages = []) {
    const uniqueGroups = _.uniqBy(portfolioUsages, "availabilityGroupId");
    return uniqueGroups.filter(({ availabilityGroupId, spaceUsageId }) => {
      const spaceUsageIncluded = _.includes(
        filteredSpaceUsageStateSpaceUsageIds.value,
        spaceUsageId,
      );
      const includedSpaceUsages = portfolioSpaceAvailabilityFields.value.filter(
        (spaceUsageStub) =>
          spaceUsageStub.availabilityGroupId === availabilityGroupId,
      );
      const spaceTypeSpaceUsageIncluded =
        _.size(
          _.intersectionBy(
            includedSpaceUsages,
            filteredPortfolioSpaceUsages.value,
            "availabilityId",
          ),
        ) > 0;

      let sizeIncluded = false;

      if (spaceAvailabilityLikeFilterable.value) {
        const sizeMatches = _.intersectionBy(
          [
            {
              id: availabilityGroupId,
              type: "SpaceAvailabilityGroup",
            },
          ],
          filteredSpaceUsageAvailabilityLikes.value,
          function ({ id, type }) {
            return `${type}${id}`;
          },
        );
        sizeIncluded = _.size(sizeMatches) > 0;
      }

      // console.log(
      //   "portfolio included",
      //   spaceUsageId,
      //   availabilityGroupId,
      //   spaceUsageIncluded,
      //   spaceTypeSpaceUsageIncluded,
      //   filteredSpaceUsageAvailabilityLikes.value,
      //   sizeIncluded,
      // );

      return (
        spaceUsageIncluded && (spaceTypeSpaceUsageIncluded || sizeIncluded)
      );
    });
  }

  const spaceUsagesTableAssemblyContent = computed(() => {
    return {
      singlePropertyPortfolioSpaceUsages: filteredPortfolioSpaceUsageStubs(
        portfolioSpaceTypeSpaceUsages.value,
      ),
      propertyGroupedStandaloneSpaceTypeSpaceUsages: _.groupBy(
        filteredStandaloneSpaceTypeSpaceUsages.value,
        "propertyId",
      ),
    };
  });

  const displayableSpaceUsageTableRows = computed(() => {
    const singlePropertyPortfolioSpaceUsages =
      spaceUsagesTableAssemblyContent.value.singlePropertyPortfolioSpaceUsages.map(
        (spaceUsageStub) => {
          const stubs = portfolioSpaceAvailabilityFields.value.filter(
            ({ availabilityGroupId }) =>
              availabilityGroupId === spaceUsageStub.availabilityGroupId,
          );
          return {
            rowType: "singlePropertyPortfolioSpaceUsage",
            propertyId:
              spaceUsageStub.propertyId === "unlicensed"
                ? spaceUsageStub.propertyId
                : _.toNumber(spaceUsageStub.propertyId),
            spaceUsageStubs: stubs,
          };
        },
      );
    const standalonePropertySpaceUsages = _.map(
      spaceUsagesTableAssemblyContent.value
        .propertyGroupedStandaloneSpaceTypeSpaceUsages,
      function (spaceUsageStubs, propertyId) {
        return {
          rowType: "singlePropertySpaceUsage",
          propertyId:
            propertyId === "unlicensed" ? propertyId : _.toNumber(propertyId),
          spaceUsageStubs,
        };
      },
    );

    return _.orderBy(
      _.concat(
        singlePropertyPortfolioSpaceUsages,
        standalonePropertySpaceUsages,
      ),
      ["localId"],
      ["desc"],
    );
  });

  const combinedFilteredSpaceUsages = computed(() => {
    const portfolioStubs =
      spaceUsagesTableAssemblyContent.value.singlePropertyPortfolioSpaceUsages.map(
        (spaceUsageStub) => {
          const stubs = portfolioSpaceAvailabilityFields.value.filter(
            ({ availabilityGroupId }) =>
              availabilityGroupId === spaceUsageStub.availabilityGroupId,
          );

          return stubs;
        },
      );
    const combined = _.concat(
      portfolioStubs,
      _.values(
        spaceUsagesTableAssemblyContent.value
          .propertyGroupedStandaloneSpaceTypeSpaceUsages,
      ),
    );

    return _.flatten(combined);
  });
  const combinedFilteredSpaceUsageIds = computed(() => {
    return _.uniq(
      combinedFilteredSpaceUsages.value.map(({ spaceUsageId }) => spaceUsageId),
    );
  });

  const spaceUsageCount = computed(() => {
    if (spaceUsagesChecked.value) {
      return _.size(combinedFilteredSpaceUsages.value);
    } else {
      return 0;
    }
  });
  const landCoveringSpaceAvailabilityIds = ref([]);
  const landCoveringInvestmentIds = ref([]);
  const filteredInvestmentTypeFields = ref([]);
  const filteredTypedInvestmentIds = computed(() => {
    if (selectedInvestmentTypes.value.length > 0) {
      return filteredInvestmentTypeFields.value;
    } else {
      return [];
    }
  });

  const spaceAvailabilitySizeFilterApplied = ref(false);
  const fetchingSpaceAvailabilitySizeFilterData = ref(false);
  const minSpaceAvailabilitySize = computed({
    get() {
      return _.find(filters.value.spaceAvailabilitySize, { label: "Area (SF)" })
        .min;
    },
    set(newMin) {
      let spaceAvailabilitySizesFilter = _.find(
        filters.value.spaceAvailabilitySize,
        {
          label: "Area (SF)",
        },
      );

      spaceAvailabilitySizesFilter.min = newMin;

      filters.value.spaceAvailabilitySize = _.unionBy(
        [spaceAvailabilitySizesFilter],
        filters.value.spaceAvailabilitySize,
      );
    },
  });
  const maxSpaceAvailabilitySize = computed({
    get() {
      return _.find(filters.value.spaceAvailabilitySize, { label: "Area (SF)" })
        .max;
    },
    set(newMax) {
      let spaceAvailabilitySizesFilter = _.find(
        filters.value.spaceAvailabilitySize,
        {
          label: "Area (SF)",
        },
      );

      spaceAvailabilitySizesFilter.max = newMax;

      filters.value.spaceAvailabilitySize = _.unionBy(
        [spaceAvailabilitySizesFilter],
        filters.value.spaceAvailabilitySize,
      );
    },
  });
  const validMinSpaceAvailabilitySize = computed(() => {
    if (minSpaceAvailabilitySize.value && maxSpaceAvailabilitySize.value) {
      return minSpaceAvailabilitySize.value < maxSpaceAvailabilitySize.value;
    } else if (minSpaceAvailabilitySize.value) {
      return minSpaceAvailabilitySize.value > 0;
    } else {
      return true;
    }
  });
  const validMaxSpaceAvailabilitySize = computed(() => {
    if (minSpaceAvailabilitySize.value && maxSpaceAvailabilitySize.value) {
      return maxSpaceAvailabilitySize.value > minSpaceAvailabilitySize.value;
    } else if (maxSpaceAvailabilitySize.value) {
      return maxSpaceAvailabilitySize.value > 0;
    } else {
      return true;
    }
  });
  const validSpaceAvailabilitySizeRange = computed(() => {
    return (
      validMaxSpaceAvailabilitySize.value && validMinSpaceAvailabilitySize.value
    );
  });
  const validSpaceAvailabilitySizeFilters = computed(() => {
    return validSpaceAvailabilitySizeRange.value;
  });
  const filteredStandaloneSpaceAvailabilitySizeFields = ref([]);
  const filteredStandaloneSpaceUsageAvailabilitySizeFields = ref([]);

  const fetchingInvestmentTypeFilterData = ref(false);
  const valueFilterApplied = ref(false);
  const fetchingValuationFilterData = ref(false);
  const minValue = computed({
    get() {
      return _.find(filters.value.investmentValuation, { label: "Value" }).min;
    },
    set(newMin) {
      let valuationsFilter = _.find(filters.value.investmentValuation, {
        label: "Value",
      });

      valuationsFilter.min = newMin;

      filters.value.investmentValuation = _.unionBy(
        [valuationsFilter],
        filters.value.investmentValuation,
      );
    },
  });
  const maxValue = computed({
    get() {
      return _.find(filters.value.investmentValuation, { label: "Value" }).max;
    },
    set(newMax) {
      let valuationsFilter = _.find(filters.value.investmentValuation, {
        label: "Value",
      });

      valuationsFilter.max = newMax;

      filters.value.investmentValuation = _.unionBy(
        [valuationsFilter],
        filters.value.investmentValuation,
      );
    },
  });
  const validMinValue = computed(() => {
    if (minValue.value && maxValue.value) {
      return minValue.value < maxValue.value;
    } else if (minValue.value) {
      return minValue.value > 0;
    } else {
      return true;
    }
  });
  const validMaxValue = computed(() => {
    if (minValue.value && maxValue.value) {
      return maxValue.value > minValue.value;
    } else if (maxValue.value) {
      return maxValue.value > 0;
    } else {
      return true;
    }
  });
  const validValueRange = computed(() => {
    return validMaxValue.value && validMinValue.value;
  });
  const validValuationFilters = computed(() => {
    return validValueRange.value;
  });
  const filteredStandaloneValueFields = ref([]);
  const filteredInvestmentLikes = computed(() => {
    const valueArray = valueFilterApplied.value
      ? filteredStandaloneValueFields.value
      : null;
    const maturityArray = loanMaturityDateFilterInput.value
      ? filteredLoanMaturityDateFields.value
      : null;
    const eligibleArrays = _.compact([valueArray, maturityArray]);
    const mapped = eligibleArrays.map((dataFields) => {
      return dataFields.map((dataField) => {
        return {
          id: dataField.investmentLikeId,
          type: dataField.investmentLikeType,
        };
      });
    });

    if (_.size(eligibleArrays) > 0) {
      return _.intersectionBy(...mapped, function ({ id, type }) {
        return `${type}${id}`;
      });
    } else {
      return _.flatten(mapped);
    }
  });
  const combinedFilteredOccupierHuntIds = computed(() => {
    const eligibleIdArrays = [polygonHuntIds.value].filter(
      (arr) => arr.length > 0,
    );
    const sizeIds = spaceAvailabilitySizeFilterApplied.value
      ? [occupierSizeFilteredHuntIds.value]
      : [];
    const combinedOccupierIdArrays = _.concat(
      [useTypeFilteredHuntIds.value],
      sizeIds,
      eligibleIdArrays,
    );

    return _.intersection(...combinedOccupierIdArrays);
  });
  const combinedFilteredInvestorHuntIds = computed(() => {
    const eligibleIdArrays = [polygonHuntIds.value].filter(
      (arr) => arr.length > 0,
    );
    const valueIds = valueFilterApplied.value
      ? [valueFilteredHuntIds.value]
      : [];
    const combinedInvestmentIdArrays = _.concat(
      [investmentTypeFilteredHuntIds.value, useTypeFilteredHuntIds.value],
      valueIds,
      eligibleIdArrays,
    );

    return _.intersection(...combinedInvestmentIdArrays);
  });
  function combinedFilteredHuntIncluded(huntId) {
    const fetchedDataField = _.find(nearbyHuntDataFields.value, {
      fieldContentId: huntId,
    });
    const capMarketsHuntEventsIncluded = _.includes(
      selectedCompanyEventTypes.value,
      "capMarketsHunt",
    );
    const leasingHuntEventsIncluded = _.includes(
      selectedCompanyEventTypes.value,
      "leasingHunt",
    );

    let capMarketsEventIncluded = false;
    let leasingEventIncluded = false;
    let filtersIncluded = false;

    if (
      fetchedDataField &&
      capMarketsHuntEventsIncluded &&
      fetchedDataField.fieldContent?.hasInvestmentCriteria
    ) {
      capMarketsEventIncluded = true;
      filtersIncluded =
        combinedFilteredInvestorHuntIds.value.length > 0 &&
        _.includes(combinedFilteredInvestorHuntIds.value, huntId);
    }
    if (
      fetchedDataField &&
      leasingHuntEventsIncluded &&
      fetchedDataField.fieldContent?.hasSpaceRequirements
    ) {
      leasingEventIncluded = true;
      filtersIncluded =
        combinedFilteredOccupierHuntIds.value.length > 0 &&
        _.includes(combinedFilteredOccupierHuntIds.value, huntId);
    }

    const eventIncluded = capMarketsEventIncluded || leasingEventIncluded;

    // console.log(
    //   "filtered type ids",
    //   investmentTypeFilteredHuntIds.value,
    //   "filtered use ids",
    //   useTypeFilteredHuntIds.value,
    //   "filtered value ids",
    //   valueFilteredHuntIds.value,
    //   "filtered occupier ids",
    //   occupierSizeFilteredHuntIds.value,
    //   "combined",
    //   combinedFilteredOccupierHuntIds.value,
    //   combinedFilteredInvestorHuntIds.value,
    //   "field",
    //   fetchedDataField,
    //   "included",
    //   filtersIncluded,
    //   "eventIncluded",
    //   eventIncluded,
    // );

    return filtersIncluded && eventIncluded;
  }
  const combinedFilteredLoanInvestmentIds = computed(() => {
    const eligibleIdArrays = [polygonInvestmentIds.value].filter(
      (arr) => arr.length > 0,
    );
    const combinedIdArrays = _.concat(
      [filteredAssetTypedInvestmentIds.value, filteredLoanInvestmentIds.value],
      eligibleIdArrays,
    );

    return _.intersection(...combinedIdArrays);
  });
  const combinedFilteredInvestmentIds = computed(() => {
    const eligibleIdArrays = [polygonInvestmentIds.value].filter(
      (arr) => arr.length > 0,
    );
    const combinedIdArrays = _.concat(
      [
        filteredInvestmentStateIds.value,
        filteredTypedInvestmentIds.value,
        filteredAssetTypedInvestmentIds.value,
        landCoveringInvestmentIds.value,
      ],
      eligibleIdArrays,
    );

    return _.intersection(...combinedIdArrays);
  });
  function combinedFilteredInvestmentIncluded(investmentId) {
    // console.log(
    //   "filtered state ids",
    //   filteredInvestmentStateIds.value,
    //   "filtered type ids",
    //   filteredTypedInvestmentIds.value,
    //   "asset type ids",
    //   filteredAssetTypedInvestmentIds.value,
    //   "land covering investment ids",
    //   landCoveringInvestmentIds.value,
    //   "filtered investment ids",
    //   combinedFilteredInvestmentIds.value,
    //   "filtered loan investment ids",
    //   combinedFilteredLoanInvestmentIds.value,
    // );
    const investmentIncluded =
      combinedFilteredInvestmentIds.value.length > 0 &&
      _.includes(combinedFilteredInvestmentIds.value, investmentId);
    const loanInvestmentIncluded =
      combinedFilteredLoanInvestmentIds.value.length > 0 &&
      _.includes(combinedFilteredLoanInvestmentIds.value, investmentId);

    return investmentIncluded || loanInvestmentIncluded;
  }
  const investmentLikeFilterable = computed(
    () => valueFilterApplied.value || loanMaturityDateFilterInput.value,
  );
  function investmentLikeIncluded(investmentId, portfolioId) {
    if (investmentLikeFilterable.value && portfolioId) {
      return _.some(filteredInvestmentLikes.value, function ({ id, type }) {
        return type === "InvestmentGroup" && id === portfolioId;
      });
    } else if (investmentLikeFilterable.value && investmentId) {
      return _.some(filteredInvestmentLikes.value, function ({ id, type }) {
        return type === "Investment" && id === investmentId;
      });
    } else {
      return true;
    }
  }

  function clearValueRange() {
    minValue.value = null;
    maxValue.value = null;
    valueFilterApplied.value = false;
    valueFilteredHuntIds.value = [];
  }
  function clearValuationFilters() {
    clearValueRange();
    filteredStandaloneValueFields.value = [];
  }
  function clearSpaceAvailabilitySizeRange() {
    minSpaceAvailabilitySize.value = null;
    maxSpaceAvailabilitySize.value = null;
    spaceAvailabilitySizeFilterApplied.value = false;
  }
  function clearSpaceAvailabilitySizeFilters() {
    clearSpaceAvailabilitySizeRange();
    filteredStandaloneSpaceAvailabilitySizeFields.value = [];
    filteredStandaloneSpaceUsageAvailabilitySizeFields.value = [];
  }
  function clearInvestmentTypeFilters() {
    fetchingInvestmentTypeFilterData.value = false;
    filteredInvestmentTypeFields.value = [];
    investmentTypeFilteredHuntIds.value = [];
    filters.value.investmentType = [
      {
        value: ["sellOutright", "foreclosureSellOutright"],
        label: "Sale",
        checked: true,
      },
      {
        value: [
          "sellEntireInterest",
          "sellPartialInterest",
          "sellMultipleInterests",
        ],
        label: "Recap",
        checked: true,
      },
      {
        value: ["originateLoan", "refinance"],
        label: "Financing",
        checked: false,
      },
    ];
  }
  function clearLandCoveringStateFilters() {
    landCoveringInvestmentIds.value = [];
    fetchingLandCoveringUseTypeFilterData.value = false;
    filteredLandCoveringUseTypeFields.value = {
      floorAreas: [],
      landCoverings: [],
    };
    fetchingLandCoveringStateFilterData.value = false;
    filteredLandCoveringStateFields.value = {
      stateFilteredPropertyEnhancements: [],
      stateFilteredLayoutPartitions: [],
      stateFilteredFloorAreas: [],
      landCoveringFloorAreas: [],
      allFloorAreas: [],
    };
    filters.value.landCoveringUseType = emptyUseTypes();
    useTypeFilteredHuntIds.value = [];
    filters.value.landCoveringState = [
      {
        value: ["hypothetical"],
        label: "Hypothetical",
        checked: false,
        openDateFieldName: ["hypothetical_date"],
        closeDateFieldName: [
          "construction_proposed_date",
          "demolition_proposed_date",
          "construction_approved_date",
          "construction_permitted_date",
          "construction_commenced_date",
          "demolition_permitted_date",
          "construction_completed_date",
          "remeasurement_created_date",
          "remeasurement_retired_date",
          "demolished_date",
          "withdrawn_date",
          "destroyed_date",
        ],
      },
      {
        value: ["construction_proposed"],
        label: "Proposed",
        checked: false,
        openDateFieldName: ["construction_proposed_date"],
        closeDateFieldName: [
          "demolition_proposed_date",
          "construction_approved_date",
          "construction_permitted_date",
          "construction_commenced_date",
          "demolition_permitted_date",
          "construction_completed_date",
          "remeasurement_created_date",
          "remeasurement_retired_date",
          "demolished_date",
          "withdrawn_date",
          "destroyed_date",
        ],
      },
      {
        value: ["construction_approved", "construction_permitted"],
        label: "Approved",
        checked: false,
        openDateFieldName: [
          "construction_approved_date",
          "construction_permitted_date",
        ],
        closeDateFieldName: [
          "construction_commenced_date",
          "demolition_permitted_date",
          "construction_completed_date",
          "remeasurement_created_date",
          "remeasurement_retired_date",
          "demolished_date",
          "withdrawn_date",
          "destroyed_date",
        ],
      },
      {
        value: ["construction_commenced"],
        label: "Under Construction",
        checked: true,
        openDateFieldName: ["construction_commenced_date"],
        closeDateFieldName: [
          "demolition_permitted_date",
          "construction_completed_date",
          "remeasurement_created_date",
          "remeasurement_retired_date",
          "demolished_date",
          "withdrawn_date",
          "destroyed_date",
        ],
      },
      {
        value: [
          "demolition_proposed",
          "demolition_permitted",
          "construction_completed",
          "remeasurement_created_date",
          "created",
        ],
        label: "Completed",
        checked: true,
        openDateFieldName: [
          "demolition_proposed_date",
          "demolition_permitted_date",
          "construction_completed_date",
          "remeasurement_created_date",
          "created_date",
        ],
        closeDateFieldName: [
          "remeasurement_retired_date",
          "demolished_date",
          "withdrawn_date",
          "destroyed_date",
        ],
      },
    ];
  }
  function clearSpaceUsageStates() {
    fetchingSpaceUsageStateFilterData.value = false;
    filteredSpaceUsageStateFields.value = [];
    filters.value.spaceUsageState = emptySpaceUsageStates();
  }
  function clearSpaceAvailabilityStates() {
    fetchingSpaceAvailabilityStateFilterData.value = false;
    filteredSpaceAvailabilityStateFields.value = [];
    filters.value.spaceAvailabilityState = emptySpaceAvailabilityStates();
  }
  function clearSpaceAvailabilitySpaceTypeFilters() {
    fetchingSpaceAvailabilitySpaceTypeFilterData.value = false;
    filteredSpaceAvailabilitySpaceTypeFields.value = [];
    filters.value.spaceAvailabilitySpaceType = [
      { value: "FloorArea", label: "Floor Areas", checked: true },
      { value: "LayoutPartition", label: "Rooms", checked: false },
      {
        value: "PropertyEnhancement",
        label: "Property Enhancements",
        checked: false,
      },
    ];
  }
  function clearLoanStates() {
    fetchingLoanStateFilterData.value = false;
    filteredLoanStateFields.value = [];
    filters.value.loanState = emptyLoanStates();
  }
  function clearInvestmentStates() {
    fetchingInvestmentStateFilterData.value = false;
    filteredInvestmentStateFields.value = [];
    filters.value.investmentState = emptyInvestmentStates();
  }
  function clearInvestmentAssetTypeFilters() {
    fetchingInvestmentAssetTypeFilterData.value = false;
    filteredInvestmentAssetTypeFields.value = [];
    filters.value.investmentAssetType = [
      { value: "Fee simple", label: "Fee parcel", checked: true },
      { value: "Leasehold", label: "Leasehold", checked: true },
      { value: "Condominium", label: "Condominium", checked: true },
      { value: "Co-op", label: "Co-op", checked: true },
      {
        value: "Unused Development Rights",
        label: "Air Rights",
        checked: true,
      },
      { value: "senior", label: "Senior Loan", checked: true },
      { value: "junior", label: "Junior Loan", checked: true },
    ];
  }
  const huntsCategory = computed(() =>
    _.find(filterCategories.value, { value: "hunts" }),
  );
  const huntsChecked = computed(() => huntsCategory.value?.checked);
  const spaceAvailabilitiesCategory = computed(() =>
    _.find(filterCategories.value, { value: "space-availabilities" }),
  );
  const spaceAvailabilitiesChecked = computed(
    () => spaceAvailabilitiesCategory.value?.checked,
  );
  const spaceUsagesCategory = computed(() =>
    _.find(filterCategories.value, { value: "space-usages" }),
  );
  const spaceUsagesChecked = computed(() => spaceUsagesCategory.value?.checked);
  const sizesCategory = computed(() =>
    _.find(filterCategories.value, { value: "sizes-uses" }),
  );
  const sizesChecked = computed(() => sizesCategory.value?.checked);
  const companiesCategory = computed(() =>
    _.find(filterCategories.value, { value: "companies" }),
  );
  const companiesChecked = computed(() => companiesCategory.value?.checked);
  const investmentsCategory = computed(() =>
    _.find(filterCategories.value, { value: "investments" }),
  );
  const investmentsChecked = computed(() => investmentsCategory.value?.checked);
  const loansCategory = computed(() =>
    _.find(filterCategories.value, { value: "loans" }),
  );
  const loansChecked = computed(() => loansCategory.value?.checked);
  const checkedCategories = computed(() =>
    filterCategories.value.filter((category) => category.checked),
  );
  const selectedFilterCategories = computed(() => {
    const hasPolygon = geographyFilterPolygon.value ? 1 : 0;
    const hasValueFilter = valueFilterApplied.value ? 1 : 0;
    const hasSizeFilter = spaceAvailabilitySizeFilterApplied.value ? 1 : 0;
    const hasMaturityDateFilter = loanMaturityDateFilterInput.value ? 1 : 0;
    const hasExpirationDateFilter = spaceUsageExpirationDateFilterInput.value
      ? 1
      : 0;
    const hasDateFilter = mainTimelineCustomLookbackDate.value ? 1 : 0;
    const eventTypesCount = filterCompanyEventTypes.value
      ? selectedCompanyEventTypes.value.length
      : 0;
    const rolesCount = filterCompanyRoles.value
      ? selectedCompanyRoles.value.length
      : 0;
    const spaceTypesCount = filterSpaceAvailabilitySpaceTypes.value
      ? selectedSpaceTypes.value.length
      : 0;
    const assetTypesCount = filterInvestmentAssetTypes.value
      ? selectedInvestmentAssetTypes.value.length
      : 0;
    const spaceUsageStatesCount = filterSpaceUsageStates.value
      ? selectedSpaceUsageStates.value.length
      : 0;
    const availabilityStatesCount = filterSpaceAvailabilityStates.value
      ? selectedSpaceAvailabilityStates.value.length
      : 0;
    const loanStatesCount = filterLoanStates.value
      ? selectedLoanStates.value.length
      : 0;
    const investmentStatesCount = filterInvestmentStates.value
      ? selectedInvestmentStates.value.length
      : 0;
    const investmentTypesCount = filterInvestmentTypes.value
      ? filters.value.investmentType.filter((invType) => invType.checked).length
      : 0;
    const landStatesCount = filterLandCoveringStates.value
      ? filters.value.landCoveringState.filter(
          (developmentState) => developmentState.checked,
        ).length
      : 0;
    const landUsesCount = filterLandCoveringUseTypes.value
      ? selectedLandCoveringUseTypes.value.length
      : 0;

    return (
      checkedCategories.value.length +
      hasPolygon +
      hasValueFilter +
      hasSizeFilter +
      hasMaturityDateFilter +
      hasExpirationDateFilter +
      hasDateFilter +
      eventTypesCount +
      rolesCount +
      spaceTypesCount +
      assetTypesCount +
      loanStatesCount +
      availabilityStatesCount +
      spaceUsageStatesCount +
      investmentStatesCount +
      investmentTypesCount +
      landStatesCount +
      landUsesCount
    );
  });
  const selectedCompanyEventTypes = computed(() => {
    return filters.value.companyEventType
      .filter((eventType) => eventType.checked)
      .map((eventType) => eventType.value);
  });
  const selectedCompanyRoles = computed(() => {
    return filters.value.companyRole
      .filter((role) => role.checked)
      .map((role) => role.value);
  });
  const selectedLoanStates = computed(() => {
    return filters.value.loanState
      .filter((loanState) => loanState.checked)
      .map((loanState) => loanState.value);
  });
  const selectedLoanStateFilteringMetadata = computed(() => {
    return filters.value.loanState
      .filter((loanState) => loanState.checked)
      .map((loanState) => {
        return {
          openDateFieldName: loanState.openDateFieldName,
          closeDateFieldName: loanState.closeDateFieldName,
        };
      });
  });
  const selectedSpaceUsageStates = computed(() => {
    return filters.value.spaceUsageState
      .filter((spaceUsageState) => spaceUsageState.checked)
      .map((spaceUsageState) => spaceUsageState.value);
  });
  const selectedSpaceUsageStateFilteringMetadata = computed(() => {
    return filters.value.spaceUsageState
      .filter((spaceUsageState) => spaceUsageState.checked)
      .map((spaceUsageState) => {
        return {
          openDateFieldName: spaceUsageState.openDateFieldName,
          closeDateFieldName: spaceUsageState.closeDateFieldName,
        };
      });
  });
  const selectedSpaceAvailabilityStates = computed(() => {
    return filters.value.spaceAvailabilityState
      .filter((availState) => availState.checked)
      .map((availState) => availState.value);
  });
  const selectedSpaceAvailabilityStateFilteringMetadata = computed(() => {
    return filters.value.spaceAvailabilityState
      .filter((availState) => availState.checked)
      .map((availState) => {
        return {
          openDateFieldName: availState.openDateFieldName,
          closeDateFieldName: availState.closeDateFieldName,
        };
      });
  });
  const selectedInvestmentStates = computed(() => {
    return filters.value.investmentState
      .filter((invState) => invState.checked)
      .map((invState) => invState.value);
  });
  const selectedInvestmentStateFilteringMetadata = computed(() => {
    return filters.value.investmentState
      .filter((invState) => invState.checked)
      .map((invState) => {
        return {
          openDateFieldName: invState.openDateFieldName,
          closeDateFieldName: invState.closeDateFieldName,
        };
      });
  });
  const selectedLandCoveringUseTypes = computed(() => {
    return filters.value.landCoveringUseType
      .filter((useType) => useType.checked)
      .map((useType) => useType.value);
  });
  const selectedLandCoveringStates = computed(() => {
    return filters.value.landCoveringState
      .filter((developmentState) => developmentState.checked)
      .flatMap((developmentState) => developmentState.value);
  });
  const selectedLandCoveringStateFilteringMetadata = computed(() => {
    return filters.value.landCoveringState
      .filter((coveringState) => coveringState.checked)
      .map((coveringState) => {
        return {
          openDateFieldName: coveringState.openDateFieldName,
          closeDateFieldName: coveringState.closeDateFieldName,
        };
      });
  });
  const selectedInvestmentTypeLabels = computed(() => {
    return filters.value.investmentType
      .filter((invType) => invType.checked)
      .map((invType) => invType.label);
  });
  const selectedInvestmentTypes = computed(() => {
    return filters.value.investmentType
      .filter((invType) => invType.checked)
      .flatMap((invType) => invType.value);
  });
  const selectedInvestmentAssetTypes = computed(() => {
    return filters.value.investmentAssetType
      .filter((invType) => invType.checked)
      .map((assetType) => assetType.value);
  });
  const selectedSpaceTypes = computed(() => {
    return filters.value.spaceAvailabilitySpaceType
      .filter((spaceType) => spaceType.checked)
      .map((spaceType) => spaceType.value);
  });

  const exportRowData = ref([]);

  function addExportRow(data) {
    exportRowData.value = _.unionBy(data, exportRowData.value, "propertyId");
  }

  const dataEmptyState = {
    buildingCount: {
      interceptableFields: [],
      unlicensedFields: [],
      mapGroupingDataFields: [],
      mapLandCoveringDataFields: [],
      propertyRightLandCoveringDataFields: [],
    },
    sizesUses: {
      fetchRequests: [],
      interceptableFields: [],
      unlicensedFields: [],
      floorAreaDataFields: [],
      propertyUseDataFields: [],
      standardizedAreaDataFields: [],
      useBasedAreaDataFields: [],
    },
    investmentVolume: {
      fetchRequests: [],
      interceptableFields: [],
      unlicensedFields: [],
      investmentFields: [],
      investmentDataFields: [],
      investmentGroupDataFields: [],
      investmentGroupValuationDataFields: [],
      investmentValuationDataFields: [],
      valueDataFields: [],
      loanAmountDataFields: [],
    },
  };
  const optionsEmptyState = {
    investmentVolume: {
      valueTypes: ["Final", "Accepted Offer", "Offer", "Guidance"],
    },
    buildingCount: {},
    sizesUses: {
      includeAirRights: true,
    },
    pricePerArea: {
      denominator: "topLevel",
    },
    excludedUses: {
      useTypes: ["Common Area", "Mechanical", "Parking"],
    },
  };

  const surveyInvestmentCountProxy = ref(0);
  const surveyCompanyCountProxy = ref(0);
  const surveySizeCountProxy = ref(0);
  const surveyLoanCountProxy = ref(0);
  const surveySpaceUsageCountProxy = ref(0);
  const surveySpaceAvailabilityCountProxy = ref(0);
  const surveyHuntCountProxy = ref(0);
  const surveyUnlockableBundleFields = ref({
    standaloneFields: [],
  });
  const surveyUnlockableBundleFieldIds = computed(() => {
    if (checkedCategories.value.length > 0) {
      const hasInvestments =
        investmentsChecked.value && surveyInvestmentCountProxy.value > 0;
      const hasCompanies =
        companiesChecked.value && surveyCompanyCountProxy.value > 0;
      const hasSizes = sizesChecked.value && surveySizeCountProxy.value > 0;
      const hasLoans = loansChecked.value && surveyLoanCountProxy.value > 0;
      const hasSpaceUsages =
        spaceUsagesChecked.value && surveySpaceUsageCountProxy.value > 0;
      const hasSpaceAvailabilities =
        spaceAvailabilitiesChecked.value &&
        surveySpaceAvailabilityCountProxy.value > 0;
      const hasHunts =
        huntsChecked.value &&
        (combinedFilteredInvestorHuntIds.value.length > 0 ||
          combinedFilteredOccupierHuntIds.value.length > 0);

      if (
        hasInvestments ||
        hasCompanies ||
        hasSizes ||
        hasLoans ||
        hasSpaceUsages ||
        hasSpaceAvailabilities ||
        hasHunts
      ) {
        const combinedFetchedFields = [
          analyzePropertyFields.value,
          analyzeHuntFields.value,
          analyzeSpaceUsageFields.value,
          analyzeLoanFields.value,
          analyzeInvestmentFields.value,
          analyzeSpaceAvailabilityFields.value,
        ];
        const combinedIds = combinedFetchedFields.flatMap((objCollection) => {
          return _.map(_.compact(objCollection), function (dataField) {
            return dataField.fieldContent?.relatedFieldIds || dataField.localId;
          });
        });
        const combinedCalcIds = _.map(
          calculationData.value,
          function (calcFieldContainerObject) {
            return _.map(calcFieldContainerObject, function (fields, key) {
              if (key === "fetchRequests") {
                return [];
              } else {
                return fields.flatMap(
                  (dataField) =>
                    dataField.fieldContent?.relatedFieldIds ||
                    dataField.localId,
                );
              }
            });
          },
        );
        return _.concat(
          _.flattenDeep(combinedIds),
          _.flattenDeep(combinedCalcIds),
        );
      } else {
        return [];
      }
    } else {
      return [];
    }
  });
  function patchUnlockableIds(fields, fieldType) {
    const ids = _.uniq(_.compact(fields.map((df) => df.localId)));
    const relatedFieldIds = _.uniq(
      _.compact(fields.flatMap((df) => df.fieldContent?.relatedFieldIds || [])),
    );
    surveyUnlockableBundleFields.value[fieldType] = _.union(
      ids,
      relatedFieldIds,
      surveyUnlockableBundleFields.value[fieldType],
    );
  }

  function dropUnlockableFieldType(fieldType) {
    surveyUnlockableBundleFields.value[fieldType] = [];
  }

  function dropUnlockableIds(fields) {
    const ids = fields.map((df) => df.localId);
    _.forEach(
      surveyUnlockableBundleFields.value,
      function (existingIds, fieldType) {
        surveyUnlockableBundleFields.value[fieldType] = _.difference(
          surveyUnlockableBundleFields.value[fieldType],
          ids,
        );
      },
    );
  }

  const postpendableFetchKey = computed(
    () => `${asOfMilliseconds.value}_${compositeMapMeta.value}`,
  );

  const calculationData = ref(dataEmptyState);
  const contentCalculationData = ref({});
  const calculationOptions = ref(optionsEmptyState);
  const lastCheckedMapMeta = ref({
    lat: null,
    lng: null,
    zoom: null,
  });
  const lastCheckedTime = ref(null);
  const lastCheckedPolygonArea = ref(null);
  const sizesSurveyFetched = computed(() =>
    alreadyFetched("sizesUses", "survey"),
  );
  const investmentVolumeSurveyFetched = computed(() =>
    alreadyFetched("investmentVolume", "survey"),
  );
  const analyzePropertyFields = ref({});
  const analyzeHuntFields = ref({});
  const fetchedHuntKeys = computed(() => _.keys(analyzeHuntFields.value));
  const analyzeSpaceUsageFields = ref({});
  const fetchedSpaceUsageKeys = computed(() =>
    _.keys(analyzeSpaceUsageFields.value),
  );
  const fetchedSpaceUsageUserFieldKeys = computed(() => {
    return fetchedSpaceUsageKeys.value.filter((key) => _.includes(key, "User"));
  });
  const fetchedSpaceUsageUserFields = computed(() => {
    return _.filter(analyzeSpaceUsageFields.value, function (dataField, key) {
      return _.includes(fetchedSpaceUsageUserFieldKeys.value, key);
    });
  });
  const fetchedUsageFloorAreaLayoutFieldKeys = computed(() => {
    return fetchedSpaceUsageKeys.value.filter(
      (key) =>
        (_.includes(key, "FloorArea") || _.includes(key, "LayoutPartition")) &&
        _.includes(key, "Layout"),
    );
  });
  const fetchedUsageFloorAreaLayoutFields = computed(() => {
    return _.filter(analyzeSpaceUsageFields.value, function (dataField, key) {
      return _.includes(fetchedUsageFloorAreaLayoutFieldKeys.value, key);
    });
  });
  const stateFilteredFloorAreaIds = computed(() =>
    stateFilteredFloorAreaFields.value.map((dataFieldStub) => {
      if (dataFieldStub.decoratingContentType === "FloorArea") {
        return dataFieldStub.decoratingContentId;
      } else if (dataFieldStub.fieldContentType === "FloorArea") {
        return dataFieldStub.fieldContentId;
      } else {
        return null;
      }
    }),
  );
  const useTypeFilteredFloorAreaIds = computed(() =>
    filteredLandCoveringUseTypeFields.value.floorAreas.map(
      (dataField) => dataField.fieldContentId,
    ),
  );
  const stateFilteredEnhancementIds = computed(() =>
    stateFilteredPropertyEnhancementFields.value.map(
      (dataFieldStub) => dataFieldStub.decoratingContentId,
    ),
  );
  const stateFilteredPartitionIds = computed(() =>
    stateFilteredLayoutPartitionFields.value.map(
      (dataFieldStub) => dataFieldStub.fieldContentId,
    ),
  );

  const analyzeLoanFields = ref({});
  const analyzeInvestmentFields = ref({});
  const fetchedInvestmentKeys = computed(() =>
    _.keys(analyzeInvestmentFields.value),
  );
  const fetchedInvestmentFieldKeys = computed(() => {
    return fetchedInvestmentKeys.value.filter((key) =>
      _.includes(key, "Investment"),
    );
  });
  const fetchedInvestmentGroupFieldKeys = computed(() => {
    return fetchedInvestmentKeys.value.filter((key) =>
      _.includes(key, "InvestmentGroup"),
    );
  });
  const fetchedInvestmentFields = computed(() => {
    return _.filter(analyzeInvestmentFields.value, function (dataField, key) {
      return _.includes(fetchedInvestmentFieldKeys.value, key);
    });
  });
  const fetchedInvestmentGroupFields = computed(() => {
    return _.filter(analyzeInvestmentFields.value, function (dataField, key) {
      return (
        _.includes(fetchedInvestmentGroupFieldKeys.value, key) &&
        !!dataField.fieldContent?.propertyIds
      );
    });
  });
  const datedInvestmentPropertyIds = computed(() => {
    const filteredInvestmentIds = combinedTimelineInvestmentFields.value
      .filter((dataField) => {
        return (
          investmentLikeIncluded(
            dataField.fieldContentId,
            dataField.portfolioId,
          ) && combinedFilteredInvestmentIncluded(dataField.fieldContentId)
        );
      })
      .map((dataField) => dataField.fieldContentId);
    const rawIds = fetchedInvestmentFields.value
      .filter((dataField) =>
        _.includes(filteredInvestmentIds, dataField.fieldContentId),
      )
      .map((dataField) => dataField.fieldContent?.topLevelPropertyId);
    const rawPortfolioPropertyIds = fetchedInvestmentGroupFields.value
      .filter(
        (dataField) =>
          _.intersection(
            filteredInvestmentIds,
            dataField.fieldContent?.investmentIds || [],
          ).length > 0,
      )
      .flatMap((dataField) => dataField.fieldContent?.propertyIds);
    const rawLoanInvestmentIds = _.filter(
      analyzeLoanFields.value,
      function (dataField) {
        return !!dataField?.fieldContent?.originationInvestmentId;
      },
    )
      .filter((dataField) =>
        _.includes(
          filteredInvestmentIds,
          dataField?.fieldContent?.originationInvestmentId,
        ),
      )
      .map((dataField) => dataField?.fieldContent?.propertyId);

    const combinedPropertyIds = _.uniq(
      _.concat(
        translatePropertyIds(_.compact(rawIds)),
        translatePropertyIds(_.compact(rawPortfolioPropertyIds)),
        translatePropertyIds(_.compact(rawLoanInvestmentIds)),
      ),
    );

    return combinedPropertyIds;
  });

  const analyzeSpaceAvailabilityFields = ref({});
  const fetchedSpaceAvailabilityKeys = computed(() =>
    _.keys(analyzeSpaceAvailabilityFields.value),
  );
  const fetchedSpaceAvailabilityFieldKeys = computed(() => {
    return fetchedSpaceAvailabilityKeys.value.filter((key) =>
      _.includes(key, "SpaceAvailability"),
    );
  });
  const fetchedSpaceAvailabilityGroupFieldKeys = computed(() => {
    return fetchedSpaceAvailabilityKeys.value.filter((key) =>
      _.includes(key, "SpaceAvailabilityGroup"),
    );
  });
  const fetchedSpaceAvailabilityFields = computed(() => {
    return _.filter(
      analyzeSpaceAvailabilityFields.value,
      function (dataField, key) {
        return _.includes(fetchedSpaceAvailabilityFieldKeys.value, key);
      },
    );
  });
  const fetchedSpaceAvailabilityGroupFields = computed(() => {
    return _.filter(
      analyzeSpaceAvailabilityFields.value,
      function (dataField, key) {
        return (
          _.includes(fetchedSpaceAvailabilityGroupFieldKeys.value, key) &&
          !!dataField.fieldContent?.propertyIds
        );
      },
    );
  });

  const fetchedAvailabilityFloorAreaLayoutFieldKeys = computed(() => {
    return fetchedSpaceAvailabilityKeys.value.filter(
      (key) =>
        (_.includes(key, "FloorArea") || _.includes(key, "LayoutPartition")) &&
        _.includes(key, "Layout"),
    );
  });
  const fetchedAvailabilityFloorAreaLayoutFields = computed(() => {
    return _.filter(
      analyzeSpaceAvailabilityFields.value,
      function (dataField, key) {
        return _.includes(
          fetchedAvailabilityFloorAreaLayoutFieldKeys.value,
          key,
        );
      },
    );
  });

  const datedAvailabilityPropertyIds = computed(() => {
    const filteredSpaceAvailabilityIds =
      combinedTimelineSpaceAvailabilityFields.value
        .filter((dataField) => {
          return (
            spaceAvailabilityLikeIncluded(
              dataField.fieldContentId,
              dataField.portfolioId,
            ) &&
            combinedFilteredSpaceAvailabilityIncluded(dataField.fieldContentId)
          );
        })
        .map((dataField) => dataField.fieldContentId);
    const rawIds = fetchedSpaceAvailabilityFields.value
      .filter((dataField) =>
        _.includes(filteredSpaceAvailabilityIds, dataField.fieldContentId),
      )
      .map((dataField) => dataField.fieldContent?.topLevelPropertyId);
    const rawPortfolioPropertyIds = fetchedSpaceAvailabilityGroupFields.value
      .filter(
        (dataField) =>
          _.intersection(
            filteredSpaceAvailabilityIds,
            dataField.fieldContent.availabilityIds,
          ).length > 0,
      )
      .flatMap((dataField) => dataField.fieldContent?.propertyIds);
    const rawUsageAvailabilityIds = _.filter(
      analyzeSpaceUsageFields.value,
      function (dataField) {
        return !!dataField.fieldContent?.availabilityId;
      },
    )
      .filter((dataField) =>
        _.includes(
          filteredSpaceAvailabilityIds,
          dataField.fieldContent.availabilityId,
        ),
      )
      .map((dataField) => dataField.fieldContent?.space?.propertyId);

    const combinedPropertyIds = _.uniq(
      _.concat(
        translatePropertyIds(_.compact(rawIds)),
        translatePropertyIds(_.compact(rawPortfolioPropertyIds)),
        translatePropertyIds(_.compact(rawUsageAvailabilityIds)),
      ),
    );

    return combinedPropertyIds;
  });

  // CONTENT CALCULATION DATA

  const fetchedCalculationKeys = computed(() =>
    _.keys(contentCalculationData.value),
  );

  async function fetchContentCalculationData({
    contentType,
    contentId,
    override = false,
  }) {
    const contentKey = `${contentType}${contentId}`;
    if (alreadyFetchedCalculations(contentKey) && !override) {
      console.log("already fetched", contentKey);
    } else if (contentType && contentId) {
      try {
        const payload = {
          contentType,
          contentIds: [contentId],
          calculationName: "sizes_uses",
          bundle: "calculation",
          asOf: asOfMilliseconds.value,
        };
        const key = `sizes_uses-${payload.contentType}-${payload.contentIds}-${payload.asOf}`;
        fetchingAnalyzePanelData.value = true;

        const calculationFields =
          await calculationFieldsStore.calculationDataInitialFetch({
            payload,
            key,
            endpoint: `calculation_data_fields`,
            calculationName: payload.calculationName,
            analyzePanelSet: false,
            paginationCount: 10,
          });
        let bundleResponse = null;

        if (calculationFields?.data) {
          const bundlePayload = {
            bundleFieldIds: calculationFields.data.map(
              ({ localId }) => localId,
            ),
            bundle: "calculation",
          };
          bundleResponse = await api.post(
            `crowdsourced_data_field_calculation_bundles`,
            bundlePayload,
          );
        }

        if (bundleResponse?.data) {
          contentCalculationData.value[contentKey] = {
            allFieldsData: bundleResponse.data,
            sizesUses: structuredCalculationDataFor(
              "sizes_uses",
              calculationFields.data,
            ),
          };
        }
      } catch (error) {
        console.error(error);
        notificationsStore.addNotification("anErrorOccurred");
      } finally {
        fetchingAnalyzePanelData.value = false;
      }
    }
  }

  function structuredCalculationDataFor(calculationName, rawFields) {
    switch (calculationName) {
      case "sizes_uses": {
        let tempState = {
          interceptableFields: [],
          floorAreaDataFields: [],
          propertyUseDataFields: [],
          standardizedAreaDataFields: [],
          useBasedAreaDataFields: [],
        };

        const interceptableFields = rawFields.filter(
          (df) => df.meteringStatus === "prompt_to_subscribe",
        );
        const floorAreaDataFields = rawFields.filter(
          (df) => df.fieldContentType === "FloorArea",
        );
        const propertyUseDataFields = rawFields.filter(
          (df) => df.fieldContentType === "ContentPropertyUse",
        );
        const standardizedAreaDataFields = rawFields.filter((df) =>
          _.includes(
            ["standardized_area", "minimum_subdivision_standardized_area"],
            df.fieldName,
          ),
        );
        const useBasedAreaDataFields = rawFields.filter(
          (df) => df.fieldName === "use_based_area",
        );

        tempState.interceptableFields = interceptableFields;
        tempState.floorAreaDataFields = floorAreaDataFields;
        tempState.propertyUseDataFields = propertyUseDataFields;
        tempState.standardizedAreaDataFields = standardizedAreaDataFields;
        tempState.useBasedAreaDataFields = useBasedAreaDataFields;

        return tempState;
      }
    }
  }

  function alreadyFetchedCalculations(contentKey) {
    return _.includes(fetchedCalculationKeys.value, contentKey);
  }

  function dropContentCalculationData({ contentType, contentId }) {
    const contentKey = `${contentType}${contentId}`;

    if (alreadyFetchedCalculations(contentKey)) {
      delete contentCalculationData.value[contentKey];
    }
  }

  function contentCalculationDataByKey(contentKey) {
    return _.get(contentCalculationData.value, `[${contentKey}]`, {});
  }

  // END CONTENT CALCULATION DATA ^^

  function alreadyFetched(dataPath, fetchRequestKey) {
    if (calculationData.value) {
      const keys = calculationData.value[dataPath].fetchRequests.map(
        (request) => request.key,
      );

      return _.includes(keys, fetchRequestKey);
    }
  }

  function alreadyFetchedFieldIdsFor(dataPath, fetchRequestKey) {
    if (calculationData.value) {
      const request = _.find(calculationData.value[dataPath].fetchRequests, {
        key: fetchRequestKey,
      });

      if (request) {
        return request.fieldIds.map((id) => {
          return { localId: id };
        });
      } else {
        return [];
      }
    }
  }

  function registerFetchRequest(dataPath, requestKey, dataFields) {
    if (calculationData.value) {
      const payload = {
        key: requestKey,
        fieldIds: dataFields.map((field) => field.localId),
      };

      calculationData.value[dataPath].fetchRequests = _.unionBy(
        [payload],
        calculationData.value[dataPath].fetchRequests,
        "key",
      );
    }
  }

  const latLngMismatch = computed(() => {
    const { lat, lng } = lastCheckedMapMeta.value;

    return (
      centerLatLng.value &&
      (lat !== centerLatLng.value[0] || lng !== centerLatLng.value[1])
    );
  });
  const zoomMismatch = computed(() => {
    const localZoom = lastCheckedMapMeta.value.zoom;

    return zoom.value && localZoom !== zoom.value;
  });
  const polygonMismatch = computed(() => {
    return (
      polygonArea.value &&
      !editingPolygon.value &&
      lastCheckedPolygonArea.value !== polygonArea.value
    );
  });
  const timeMismatch = computed(() => {
    return (
      asOfMilliseconds.value && lastCheckedTime.value !== asOfMilliseconds.value
    );
  });

  const unselectedPropertyDiagram = computed(() => {
    return (
      propertyIdParam.value &&
      !propertySelectedRecordDataField.value &&
      propertyDiagramPropertyIds.value.length > 1
    );
  });
  const propertySelectedPropertyDiagram = computed(() => {
    return (
      propertyIdParam.value &&
      propertyDataField.value &&
      !propertyDiagramSelected.value
    );
  });
  const unselectedCompanyDetail = computed(
    () => companyIdParam.value && !companyDetailSelected.value,
  );
  const unselectedContactDetail = computed(
    () => contactIdParam.value && !contactDetailSelected.value,
  );
  const countable = computed(
    () =>
      _.includes(["PropertyRight"], focalDataField.value?.fieldContentType) ||
      propertySelectedPropertyDiagram.value ||
      unselectedPropertyDiagram.value ||
      unselectedCompanyDetail.value ||
      unselectedContactDetail.value,
  );
  const sizeable = computed(
    () =>
      _.includes(
        ["LandCovering", "LandCoveringGrouping", "PropertyRight"],
        focalDataField.value?.fieldContentType,
      ) ||
      propertySelectedPropertyDiagram.value ||
      unselectedPropertyDiagram.value ||
      unselectedCompanyDetail.value ||
      unselectedContactDetail.value ||
      spaceAvailabilitiesChecked.value ||
      spaceUsagesChecked.value,
  );

  const focalDataField = computed(() => {
    if (contactIdParam.value) {
      if (
        contactSelectedRecordDataField.value?.fieldContentType ===
        "CompanyInvolvement"
      ) {
        return assetDataFieldFor(contactSelectedRecordDataField.value);
      } else {
        return contactSelectedRecordDataField.value;
      }
    } else if (companyIdParam.value) {
      if (
        companySelectedRecordDataField.value?.fieldContentType ===
        "CompanyInvolvement"
      ) {
        return assetDataFieldFor(companySelectedRecordDataField.value);
      } else {
        return companySelectedRecordDataField.value;
      }
    } else if (propertyDiagramSelected.value) {
      return propertyDiagramSelected.value.dataField;
    } else {
      return null;
    }
  });
  const analyzeDisplayable = computed(
    () =>
      routeName.value === "MainMap" ||
      propertySelectedPropertyDiagram.value ||
      unselectedPropertyDiagram.value ||
      countable.value ||
      sizeable.value ||
      contactIdParam.value ||
      companyIdParam.value,
  );

  function selectFilters() {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Analyze",
        verticalTab: "Filter",
      },
    });
  }
  function selectLayers() {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Analyze",
        verticalTab: "Layer",
      },
    });
  }
  function zoomIn(to = 15) {
    if (zoom.value < to) {
      map.value.setZoom(to);
    }
  }

  watch(mapSearchShortcutAction, async () => {
    if (mapSearchShortcutAction.value) {
      if (_.includes(mapSearchShortcutAction.value, "analyze")) {
        resetFilterCategories();
        selectFilters();
        await delay(0.25);
        zoomIn();
        await nextTick();
        switch (mapSearchShortcutAction.value) {
          case "analyzeHunts":
            _.find(filters.value.category, { value: "hunts" }).checked = true;
            break;
          case "analyzeCompanies":
            _.find(filters.value.category, {
              value: "companies",
            }).checked = true;
            break;
          case "analyzeSpaceAvailabilities":
            _.find(filters.value.category, {
              value: "space-availabilities",
            }).checked = true;
            break;
          case "analyzeSpaceUsages":
            _.find(filters.value.category, {
              value: "space-usages",
            }).checked = true;
            break;
          case "analyzeInvestments":
            _.find(filters.value.category, {
              value: "investments",
            }).checked = true;
            break;
          case "analyzeLoans":
            _.find(filters.value.category, { value: "loans" }).checked = true;
            break;
          case "analyzeSizesUses":
            _.find(filters.value.category, {
              value: "sizes-uses",
            }).checked = true;
            break;
          default:
            break;
        }
      } else if (_.includes(mapSearchShortcutAction.value, "layer")) {
        switch (mapSearchShortcutAction.value) {
          case "layerDataCoverage":
            selectLayers();
            await nextTick();
            selectedLayer.value = _.find(layers.value, {
              title: "Data Coverage",
            });
            break;
          default:
            break;
        }
      }
      mapSearchShortcutAction.value = null;
    }
  });

  watch(unselectedPropertyDiagram, () => {
    if (unselectedPropertyDiagram.value) {
      editingMode.value = false;
    }
  });

  watch(
    checkedCategories,
    () => {
      if (checkedCategories.value.length === 0) {
        resetUnlockableSurveyIds();
      }
    },
    { deep: true },
  );

  watch(sizesChecked, () => {
    if (sizesChecked.value) {
      if (asyncReady.value) {
        debouncedLandCoveringStateFilter();
        debouncedLandCoveringUseTypeFilter();
      }
    } else {
      resetSizeFilter();
    }
  });

  watch(investmentsChecked, () => {
    if (investmentsChecked.value) {
      if (asyncReady.value) {
        debouncedInvestmentStateFilter();
        debouncedInvestmentTypeFilter();
        debouncedInvestmentAssetTypeFilter();
        debouncedLandCoveringStateFilter();
        debouncedLandCoveringUseTypeFilter();
      }
    } else {
      resetInvestmentFilter();
    }
  });

  watch(companiesChecked, () => {
    if (companiesChecked.value) {
      if (asyncReady.value) {
        debouncedInvestmentStateFilter();
        debouncedSpaceAvailabilityStateFilter();
        debouncedSpaceAvailabilitySpaceTypeFilter();
        debouncedInvestmentTypeFilter();
        debouncedHuntInvestmentTypeFilter();
        debouncedInvestmentAssetTypeFilter();
        debouncedLandCoveringStateFilter();
        debouncedLandCoveringUseTypeFilter();
        debouncedHuntUseTypeFilter();
      }
    } else {
      resetCompanyFilter();
    }
  });

  watch(huntsChecked, () => {
    if (huntsChecked.value) {
      if (asyncReady.value) {
        debouncedHuntInvestmentTypeFilter();
        debouncedLandCoveringStateFilter();
        debouncedLandCoveringUseTypeFilter();
        debouncedHuntUseTypeFilter();
      }
    } else {
      resetHuntFilter();
    }
  });

  watch(loansChecked, () => {
    if (loansChecked.value) {
      if (asyncReady.value) {
        debouncedLoanStateFilter();
        debouncedLoanMaturityDateFilter();
        debouncedInvestmentAssetTypeFilter();
        debouncedLandCoveringStateFilter();
        debouncedLandCoveringUseTypeFilter();
      }
    } else {
      resetLoanFilter();
    }
  });

  watch(spaceAvailabilitiesChecked, () => {
    if (spaceAvailabilitiesChecked.value) {
      filters.value.spaceAvailabilityState = emptyLiveSpaceAvailabilityStates();
      if (asyncReady.value) {
        debouncedSpaceAvailabilityStateFilter();
        debouncedSpaceAvailabilitySpaceTypeFilter();
        debouncedLandCoveringStateFilter();
        debouncedLandCoveringUseTypeFilter();
      }
    } else {
      resetSpaceAvailabilityFilter();
    }
  });

  watch(spaceUsagesChecked, () => {
    if (spaceUsagesChecked.value) {
      if (asyncReady.value) {
        debouncedSpaceUsageStateFilter();
        debouncedSpaceUsageExpirationDateFilter();
        debouncedSpaceAvailabilitySpaceTypeFilter();
        debouncedLandCoveringStateFilter();
        debouncedLandCoveringUseTypeFilter();
      }
    } else {
      resetSpaceUsageFilter();
    }
  });

  watch(loanCount, () => {
    surveyLoanCountProxy.value = loanCount.value;
  });

  watch(loanMaturityDateFilterInput, () => {
    if (loanMaturityDateFilterInput.value && asyncReady.value) {
      debouncedLoanMaturityDateFilter();
    }
  });

  watch(
    selectedLoanStates,
    () => {
      if (asyncReady.value) {
        debouncedLoanStateFilter();
      }
    },
    { deep: true },
  );

  watch(spaceUsageCount, () => {
    surveySpaceUsageCountProxy.value = spaceUsageCount.value;
  });

  watch(spaceUsageExpirationDateFilterInput, () => {
    if (spaceUsageExpirationDateFilterInput.value && asyncReady.value) {
      debouncedSpaceUsageExpirationDateFilter();
    }
  });

  watch(
    selectedSpaceUsageStates,
    () => {
      if (asyncReady.value) {
        debouncedSpaceUsageStateFilter();
      }
    },
    { deep: true },
  );

  watch(
    selectedSpaceTypes,
    () => {
      if (asyncReady.value) {
        debouncedSpaceAvailabilitySpaceTypeFilter();
        debouncedLandCoveringSpaceAvailabilitiesFetch();
        debouncedSpaceUsagesAvailabilitiesFetch();
      }
    },
    { deep: true },
  );

  watch(
    selectedSpaceAvailabilityStates,
    () => {
      if (asyncReady.value) {
        debouncedSpaceAvailabilityStateFilter();
      }
    },
    { deep: true },
  );

  watch(
    selectedInvestmentStates,
    () => {
      if (asyncReady.value) {
        debouncedInvestmentStateFilter();
      }
    },
    { deep: true },
  );

  watch(
    selectedLandCoveringUseTypes,
    () => {
      if (asyncReady.value) {
        debouncedLandCoveringUseTypeFilter();
        debouncedHuntUseTypeFilter();
      }
    },
    { deep: true },
  );

  watch(
    selectedLandCoveringStates,
    () => {
      if (asyncReady.value) {
        debouncedLandCoveringStateFilter();
      }
    },
    { deep: true },
  );

  watch(
    selectedInvestmentTypes,
    () => {
      if (asyncReady.value) {
        debouncedInvestmentTypeFilter();
        debouncedHuntInvestmentTypeFilter();
      }
    },
    { deep: true },
  );

  watch(
    selectedInvestmentAssetTypes,
    () => {
      if (asyncReady.value) {
        debouncedInvestmentAssetTypeFilter();
      }
    },
    { deep: true },
  );

  watch(combinedSpaceAvailabilityIds, (val, oldVal) => {
    if (!_.isEqual(val, oldVal) && asyncReady.value) {
      debouncedSpaceAvailabilityStateFilter();
      debouncedSpaceAvailabilitySpaceTypeFilter();
      debouncedLandCoveringSpaceAvailabilitiesFetch();
      debouncedSpaceUsagesAvailabilitiesFetch();
    }
  });

  watch(
    combinedInvestmentIds,
    (val, oldVal) => {
      if (!_.isEqual(val, oldVal) && asyncReady.value) {
        debouncedInvestmentStateFilter();
        debouncedInvestmentTypeFilter();
        debouncedInvestmentAssetTypeFilter();
        debouncedLandCoveringInvestmentsFetch();
        debouncedLoansInvestmentsFetch();
      }
    },
    { deep: true },
  );

  watch(
    combinedFilteredLandCoveringIds,
    (val, oldVal) => {
      if (!_.isEqual(val, oldVal) && asyncReady.value) {
        debouncedLandCoveringInvestmentsFetch();
        debouncedLandCoveringSpaceAvailabilitiesFetch();
        debouncedLoansInvestmentsFetch();
        debouncedSpaceUsagesAvailabilitiesFetch();
      }
    },
    { deep: true },
  );

  function locationRefetch() {
    if (asyncReady.value) {
      console.log("all pagys loaded...", postpendableFetchKey.value);
      debouncedUpdateLastChecked();
      debouncedSpaceAvailabilityStateFilter();
      debouncedSpaceAvailabilitySpaceTypeFilter();
      debouncedSpaceUsageStateFilter();
      debouncedLoanStateFilter();
      debouncedInvestmentStateFilter();
      debouncedInvestmentTypeFilter();
      debouncedInvestmentAssetTypeFilter();
      debouncedLandCoveringStateFilter();
      debouncedSpaceUsageExpirationDateFilter();
      debouncedLandCoveringUseTypeFilter();
      debouncedHuntUseTypeFilter();
      debouncedHuntInvestmentTypeFilter();
      debouncedLoanMaturityDateFilter();
      debouncedSpaceUsageExpirationDateFilter();
    }
  }

  watch(mapPagysLoaded, () => {
    locationRefetch();
  });
  watch(asyncReady, () => {
    locationRefetch();
  });
  watch(
    companyFetchedLocationDataFields,
    (val, oldVal) => {
      if (!_.isEqual(val, oldVal) && asyncReady.value) {
        debouncedLandCoveringStateFilter();
        debouncedLandCoveringUseTypeFilter();
      }
    },
    { deep: true },
  );
  watch(
    contactFetchedLocationDataFields,
    (val, oldVal) => {
      if (!_.isEqual(val, oldVal) && asyncReady.value) {
        debouncedLandCoveringStateFilter();
        debouncedLandCoveringUseTypeFilter();
      }
    },
    { deep: true },
  );
  watch(
    companyFetchedPolygonDataFields,
    (val, oldVal) => {
      if (!_.isEqual(val, oldVal) && asyncReady.value) {
        debouncedHuntUseTypeFilter();
        debouncedHuntInvestmentTypeFilter();
      }
    },
    { deep: true },
  );
  watch(
    contactFetchedPolygonDataFields,
    (val, oldVal) => {
      if (!_.isEqual(val, oldVal) && asyncReady.value) {
        debouncedHuntUseTypeFilter();
        debouncedHuntInvestmentTypeFilter();
      }
    },
    { deep: true },
  );
  watch(companyIdParam, (val, oldVal) => {
    if (val && val !== oldVal) {
      debouncedUpdateLastChecked();
      debouncedSpaceAvailabilityStateFilter();
      debouncedSpaceAvailabilitySpaceTypeFilter();
      debouncedSpaceUsageStateFilter();
      debouncedLoanStateFilter();
      debouncedInvestmentStateFilter();
      debouncedInvestmentTypeFilter();
      debouncedInvestmentAssetTypeFilter();
      debouncedLandCoveringStateFilter();
      debouncedSpaceUsageExpirationDateFilter();
      debouncedLandCoveringUseTypeFilter();
      debouncedHuntUseTypeFilter();
      debouncedHuntInvestmentTypeFilter();
    }
  });

  watch(contactIdParam, (val, oldVal) => {
    if (val && val !== oldVal) {
      debouncedUpdateLastChecked();
      debouncedSpaceAvailabilityStateFilter();
      debouncedSpaceAvailabilitySpaceTypeFilter();
      debouncedSpaceUsageStateFilter();
      debouncedLoanStateFilter();
      debouncedInvestmentStateFilter();
      debouncedInvestmentTypeFilter();
      debouncedInvestmentAssetTypeFilter();
      debouncedLandCoveringStateFilter();
      debouncedSpaceUsageExpirationDateFilter();
      debouncedLandCoveringUseTypeFilter();
      debouncedHuntUseTypeFilter();
      debouncedHuntInvestmentTypeFilter();
    }
  });

  watch(signedIn, async () => {
    if (!editingPolygon.value && geographyFilterPolygon.value) {
      debouncedUpdateLastChecked();
      fetchPolygonPropertyLocations();
    }
  });

  watch(editingPolygon, () => {
    if (!editingPolygon.value && geographyFilterPolygon.value) {
      debouncedUpdateLastChecked();
      fetchPolygonPropertyLocations();
    }
  });
  watch(minValue, () => {
    valueFilterApplied.value = false;
  });
  watch(maxValue, () => {
    valueFilterApplied.value = false;
  });
  watch(minSpaceAvailabilitySize, () => {
    spaceAvailabilitySizeFilterApplied.value = false;
  });
  watch(maxSpaceAvailabilitySize, () => {
    spaceAvailabilitySizeFilterApplied.value = false;
  });

  watch(asOfMilliseconds, async () => {
    debouncedUpdateLastChecked();
    debouncedLandCoveringUseTypeFilter();
    debouncedHuntUseTypeFilter();
    debouncedSpaceUsageStateFilter();
    debouncedLandCoveringStateFilter();
    debouncedInvestmentStateFilter();
    debouncedInvestmentTypeFilter();
    debouncedInvestmentAssetTypeFilter();
    debouncedSpaceAvailabilityStateFilter();
    debouncedSpaceAvailabilitySpaceTypeFilter();
    debouncedLoanStateFilter();
  });
  watch(routeName, () => {
    resetFilterCategories();
  });

  const debouncedUpdateLastChecked = _.debounce(function () {
    updateLastChecked();
  }, 2000);

  function resetFilterCategories() {
    _.find(filters.value.category, {
      value: "space-availabilities",
    }).checked = false;
    _.find(filters.value.category, { value: "space-usages" }).checked = false;
    _.find(filters.value.category, { value: "sizes-uses" }).checked = false;
    _.find(filters.value.category, { value: "companies" }).checked = false;
    _.find(filters.value.category, { value: "investments" }).checked = false;
    _.find(filters.value.category, { value: "loans" }).checked = false;
    _.find(filters.value.category, { value: "hunts" }).checked = false;
    setSelectedGeography("boundaries");
    clearLandCoveringStateFilters();
    polygonPropertyLocations.value = [];
    polygonHuntIds.value = [];
    resetInvestmentFilter();
    resetSpaceAvailabilityFilter();
    resetCompanyFilter();
    resetHuntFilter();
    resetSizeFilter();
    resetCalculationData();
  }
  function setSelectedGeography(filterType) {
    selectedGeography.value = _.find(filters.value.geography, {
      value: filterType,
    });
  }
  function resetInvestmentFilter() {
    const emptyInvestments = _.merge({}, dataEmptyState.investmentVolume);
    calculationData.value.investmentVolume = emptyInvestments;
    // mainTimelineCustomForwardDate.value = null;
    // mainTimelineCustomLookbackDate.value = null;
    surveyInvestmentCountProxy.value = 0;
    clearInvestmentStates();
    clearInvestmentAssetTypeFilters();
    clearInvestmentTypeFilters();
    clearValuationFilters();
  }

  function setSpaceUsageExpirationDate(date) {
    _.find(filters.value.spaceUsageExpirationDate, {
      value: "expired_date",
    }).date = date;
  }
  function clearSpaceUsageExpirationDate() {
    setSpaceUsageExpirationDateEditing(false);
    setSpaceUsageExpirationDate(null);
    fetchingSpaceUsageExpirationDateFilterData.value = false;
    filteredSpaceUsageExpirationDateFields.value = [];

    setTimeout(() => {
      setSpaceUsageExpirationDateEditing(true);
    }, 100);
  }
  function setSpaceUsageExpirationDateEditing(bool) {
    _.find(filters.value.spaceUsageExpirationDate, {
      value: "expired_date",
    }).editing = bool;
  }
  function resetSpaceUsageFilter() {
    clearSpaceUsageExpirationDate();
    clearSpaceUsageStates();
    if (!spaceAvailabilitiesChecked.value) {
      clearSpaceAvailabilitySpaceTypeFilters();
    }
    if (!investmentsChecked.value) {
      clearInvestmentAssetTypeFilters();
    }
    surveySpaceUsageCountProxy.value = 0;
  }

  function resetSpaceAvailabilityFilter() {
    const emptyAvailabilities = _.merge(
      {},
      dataEmptyState.spaceAvailabilityVolume,
    );
    calculationData.value.spaceAvailabilityVolume = emptyAvailabilities;
    surveySpaceAvailabilityCountProxy.value = 0;
    clearSpaceAvailabilityStates();
    clearSpaceAvailabilitySizeFilters();
    clearSpaceAvailabilitySpaceTypeFilters();
  }

  function setLoanMaturityDate(date) {
    _.find(filters.value.loanMaturityDate, {
      value: "maturity_date",
    }).date = date;
  }
  function clearLoanMaturityDate() {
    setLoanMaturityDateEditing(false);
    setLoanMaturityDate(null);
    fetchingLoanMaturityDateFilterData.value = false;
    filteredLoanMaturityDateFields.value = [];

    setTimeout(() => {
      setLoanMaturityDateEditing(true);
    }, 100);
  }
  function setLoanMaturityDateEditing(bool) {
    _.find(filters.value.loanMaturityDate, {
      value: "maturity_date",
    }).editing = bool;
  }
  function resetLoanFilter() {
    clearLoanMaturityDate();
    clearLoanStates();
    if (!investmentsChecked.value) {
      clearInvestmentAssetTypeFilters();
    }
    surveyLoanCountProxy.value = 0;
  }
  function resetCompanyFilter() {
    filters.value.companyRole.forEach((role) => (role.checked = true));
    filters.value.companyEventType.forEach(
      (eventType) => (eventType.checked = true),
    );
    filters.value.spaceAvailabilityState = emptySpaceAvailabilityStates();
    surveyCompanyCountProxy.value = 0;
    valueFilteredHuntIds.value = [];
  }
  function resetHuntFilter() {
    filters.value.companyRole.forEach((role) => (role.checked = true));
    filters.value.companyEventType.forEach(
      (eventType) => (eventType.checked = true),
    );
    filters.value.spaceAvailabilityState = emptySpaceAvailabilityStates();
    surveyCompanyCountProxy.value = 0;
    valueFilteredHuntIds.value = [];
  }
  function resetSizeFilter() {
    const emptyBuildings = _.merge({}, dataEmptyState.buildingCount);
    const emptySizes = _.merge({}, dataEmptyState.sizesUses);
    calculationData.value.sizesUses = emptySizes;
    calculationData.value.buildingCount = emptyBuildings;
    analyzePropertyFields.value = {};
    surveySizeCountProxy.value = 0;
    occupierSizeFilteredHuntIds.value = [];
  }
  function resetPolygonFilter() {
    clearPolygon();
    setPolygonEditing(false);
  }
  function setPolygon(layer) {
    _.find(filters.value.geography, {
      value: "polygon",
    }).polygon = layer;
  }
  function clearPolygon() {
    _.find(filters.value.geography, {
      value: "polygon",
    }).polygon = null;
    lastCheckedPolygonArea.value = null;
    polygonPropertyLocations.value = [];
    polygonHuntIds.value = [];
    polygonInvestmentIds.value = [];
    polygonAvailabilityIds.value = [];
  }
  function setPolygonEditing(bool) {
    _.find(filters.value.geography, {
      value: "polygon",
    }).editing = bool;
  }
  function resetCalculationData() {
    calculationData.value = _.merge({}, dataEmptyState);
    lastCheckedTime.value = null;
    lastCheckedPolygonArea.value = null;
    lastCheckedMapMeta.value = {
      lat: null,
      lng: null,
      zoom: null,
    };
  }
  function resetUnlockableSurveyIds() {
    surveyUnlockableBundleFields.value = {
      standaloneFields: [],
    };
  }

  function unlockableFieldsFor(rawFields) {
    return rawFields.filter(
      (df) => df.meteringStatus === "prompt_to_subscribe",
    );
  }

  function unlicensedFieldsFor(rawFields) {
    return rawFields.filter((df) => !!df.price);
  }

  function setCalculationDataByName(calculationName, rawFields, requestKey) {
    const interceptableFields = unlockableFieldsFor(rawFields);
    const unlicensedFields = unlicensedFieldsFor(rawFields);

    switch (calculationName) {
      case "sizes_uses": {
        const floorAreaDataFields = rawFields.filter(
          (df) => df.fieldContentType === "FloorArea",
        );
        const propertyUseDataFields = rawFields.filter(
          (df) => df.fieldContentType === "ContentPropertyUse",
        );
        const standardizedAreaDataFields = rawFields.filter((df) =>
          _.includes(
            ["standardized_area", "minimum_subdivision_standardized_area"],
            df.fieldName,
          ),
        );
        const useBasedAreaDataFields = rawFields.filter(
          (df) => df.fieldName === "use_based_area",
        );

        if (requestKey)
          registerFetchRequest("sizesUses", requestKey, rawFields);

        if (requestKey === "survey") {
          calculationData.value.sizesUses.interceptableFields =
            interceptableFields;
          calculationData.value.sizesUses.unlicensedFields = unlicensedFields;
          calculationData.value.sizesUses.floorAreaDataFields =
            floorAreaDataFields;
          calculationData.value.sizesUses.propertyUseDataFields =
            propertyUseDataFields;
          calculationData.value.sizesUses.standardizedAreaDataFields =
            standardizedAreaDataFields;
          calculationData.value.sizesUses.useBasedAreaDataFields =
            useBasedAreaDataFields;
        } else {
          const staleFieldIds = alreadyFetchedFieldIdsFor(
            "sizesUses",
            requestKey,
          );

          const remainingInterceptable = _.differenceBy(
            calculationData.value.sizesUses.interceptableFields,
            staleFieldIds,
            "localId",
          );
          calculationData.value.sizesUses.interceptableFields = _.unionBy(
            interceptableFields,
            remainingInterceptable,
            "localId",
          );

          const remainingUnlicensed = _.differenceBy(
            calculationData.value.sizesUses.unlicensedFields,
            staleFieldIds,
            "localId",
          );
          calculationData.value.sizesUses.unlicensedFields = _.unionBy(
            unlicensedFields,
            remainingUnlicensed,
            "localId",
          );

          const remainingFloorArea = _.differenceBy(
            calculationData.value.sizesUses.floorAreaDataFields,
            staleFieldIds,
            "localId",
          );
          calculationData.value.sizesUses.floorAreaDataFields = _.unionBy(
            floorAreaDataFields,
            remainingFloorArea,
            "localId",
          );

          const remainingPropertyUse = _.differenceBy(
            calculationData.value.sizesUses.propertyUseDataFields,
            staleFieldIds,
            "localId",
          );
          calculationData.value.sizesUses.propertyUseDataFields = _.unionBy(
            propertyUseDataFields,
            remainingPropertyUse,
            "localId",
          );

          const remainingStandardized = _.differenceBy(
            calculationData.value.sizesUses.standardizedAreaDataFields,
            staleFieldIds,
            "localId",
          );
          calculationData.value.sizesUses.standardizedAreaDataFields =
            _.unionBy(
              standardizedAreaDataFields,
              remainingStandardized,
              "localId",
            );

          const remainingUseBased = _.differenceBy(
            calculationData.value.sizesUses.useBasedAreaDataFields,
            staleFieldIds,
            "localId",
          );
          calculationData.value.sizesUses.useBasedAreaDataFields = _.unionBy(
            useBasedAreaDataFields,
            remainingUseBased,
            "localId",
          );
        }

        break;
      }
      case "building_count": {
        const landCoveringDataFields = rawFields.filter(
          (df) => df.fieldContentType === "LandCovering",
        );

        calculationData.value.buildingCount.interceptableFields =
          interceptableFields;
        calculationData.value.buildingCount.unlicensedFields = unlicensedFields;
        calculationData.value.buildingCount.propertyRightLandCoveringDataFields =
          landCoveringDataFields;

        break;
      }
      case "investment_volume": {
        const investmentDataFields = rawFields.filter(
          (df) => df.fieldContentType === "Investment",
        );
        const investmentGroupDataFields = rawFields.filter(
          (df) => df.fieldContentType === "InvestmentGroup",
        );
        const investmentGroupValuationDataFields = rawFields.filter(
          (df) =>
            df.decoratingContentType === "InvestmentGroup" &&
            df.fieldContentType === "Valuation",
        );
        const investmentValuationDataFields = rawFields.filter(
          (df) =>
            df.decoratingContentType === "Investment" &&
            df.fieldContentType === "Valuation",
        );
        const valueDataFields = rawFields.filter(
          (df) => df.fieldName === "value",
        );
        const loanAmountDataFields = rawFields.filter(
          (df) => df.fieldName === "fully_funded_loan_amount",
        );

        if (requestKey)
          registerFetchRequest("investmentVolume", requestKey, rawFields);

        calculationData.value.investmentVolume.interceptableFields =
          interceptableFields;
        calculationData.value.investmentVolume.unlicensedFields =
          unlicensedFields;
        calculationData.value.investmentVolume.investmentDataFields =
          investmentDataFields;
        calculationData.value.investmentVolume.investmentGroupDataFields =
          investmentGroupDataFields;
        calculationData.value.investmentVolume.investmentGroupValuationDataFields =
          investmentGroupValuationDataFields;
        calculationData.value.investmentVolume.investmentValuationDataFields =
          investmentValuationDataFields;
        calculationData.value.investmentVolume.valueDataFields =
          valueDataFields;
        calculationData.value.investmentVolume.loanAmountDataFields =
          loanAmountDataFields;
        break;
      }
    }
  }

  async function updateLastChecked() {
    if (timeMismatch.value || latLngMismatch.value || zoomMismatch.value) {
      if (centerLatLng.value) {
        lastCheckedMapMeta.value = {
          lat: centerLatLng.value[0],
          lng: centerLatLng.value[1],
          zoom: zoom.value,
        };
      }
      if (asOfMilliseconds.value) {
        lastCheckedTime.value = asOfMilliseconds.value;
      }
    }
  }

  // ANALYZE HUNT DATA FETCHING

  async function fetchHuntDataField(huntId) {
    const outerJson = await api.get(
      `hunts/${huntId}?field_output_format=outer_field`,
    );

    if (outerJson?.data) {
      analyzeHuntFields.value[`Hunt${huntId}`] = outerJson.data;
    }

    const companyJson = await api.get(
      `hunts/${huntId}?field_output_format=company_field`,
    );

    if (companyJson?.data) {
      analyzeHuntFields.value[
        `Hunt${huntId}SourceCompanyInvolvement${companyJson.data.decoratingContentId}`
      ] = companyJson.data;
    }
  }

  async function fetchHuntGeographyIntents(huntId) {
    const json = await api.get(
      `crowdsourced_data_fields/Hunt/${huntId}?field_name=HuntGeographyIntent`,
    );

    if (json?.data) {
      _.forEach(json.data, function (dataField) {
        const fieldKey = `Hunt${huntId}HuntGeographyIntent${dataField.fieldContentId}`;
        analyzeHuntFields.value[fieldKey] = dataField;

        analyzeHuntFields.value[fieldKey] = dataField;
      });
    }
  }

  // ANALYZE LOAN DATA FETCHING

  async function fetchLoanDataField(loanId) {
    const json = await api.get(
      `loans/${loanId}?field_output_format=outer_field`,
    );

    if (json?.data) {
      const loanFetchKey = `Loan${loanId}`;
      analyzeLoanFields.value[loanFetchKey] = json.data;
    }
  }

  // ANALYZE SPACEUSAGE DATA FETCHING

  async function fetchSpaceUsageDataField(spaceUsageId) {
    const json = await api.get(
      `space_usages/${spaceUsageId}?field_output_format=outer_field&json_template=details_page`,
    );

    if (json?.data) {
      const spaceUsageFetchKey = `SpaceUsage${spaceUsageId}`;
      analyzeSpaceUsageFields.value[spaceUsageFetchKey] = json.data;
    }
  }

  async function fetchSpaceUsageUserDataFields(spaceUsageId) {
    const json = await api.get(`space_usage_timeless_users/${spaceUsageId}`);

    if (json?.data) {
      _.forEach(json.data, function (dataField) {
        const fieldKey = `SpaceUsage${spaceUsageId}User${dataField.fieldContentId}`;
        analyzeSpaceUsageFields.value[fieldKey] = dataField;
      });
    }
  }

  async function fetchFloorAreaLayouts({
    floorAreaId = null,
    layoutPartitionId = null,
    collection = "spaceAvailabilityFields",
  }) {
    let idParam = null;

    if (floorAreaId) {
      idParam = `floor_area_id=${floorAreaId}`;
    } else if (layoutPartitionId) {
      idParam = `layout_partition_id=${layoutPartitionId}`;
    }

    if (idParam) {
      const json = await api.get(
        `floor_area_layouts/?${idParam}&as_of=${asOfMilliseconds.value}`,
      );

      if (json?.data) {
        _.forEach(json.data, function (dataField) {
          let fieldKey = null;

          if (floorAreaId) {
            fieldKey = `FloorArea${floorAreaId}Layout${dataField.fieldContentId}`;
          } else if (layoutPartitionId) {
            fieldKey = `LayoutPartition${layoutPartitionId}Layout${dataField.fieldContentId}`;
          }

          analyzeSpaceUsageFields.value[fieldKey] = dataField;

          switch (collection) {
            case "spaceAvailabilityFields":
              analyzeSpaceAvailabilityFields.value[fieldKey] = dataField;
              break;
            case "spaceUsageFields":
              analyzeSpaceUsageFields.value[fieldKey] = dataField;
              break;
          }
        });
      }
    }
  }

  // ANALYZE SPACEAVAILABILITY DATA FETCHING

  async function fetchSpaceAvailabilityDataField(
    availabilityId,
    availabilityFetchKey,
  ) {
    const json = await api.get(
      `space_availabilities/${availabilityId}?json_template=table_row`,
    );

    if (json?.data) {
      analyzeSpaceAvailabilityFields.value[availabilityFetchKey] = json.data;
    }
  }

  async function fetchSpaceAvailabilityGroupDataField(
    availabilityGroupId,
    availabilityGroupFetchKey,
    collection = "spaceAvailabilityFields",
  ) {
    const json = await api.get(
      `space_availability_groups/${availabilityGroupId}?field_output_format=outer_field`,
    );

    if (json?.data) {
      switch (collection) {
        case "spaceAvailabilityFields":
          analyzeSpaceAvailabilityFields.value[availabilityGroupFetchKey] =
            json.data;
          break;
        case "spaceUsageFields":
          analyzeSpaceUsageFields.value[availabilityGroupFetchKey] = json.data;
          break;
      }
    }
  }

  async function fetchSpaceAvailabilityGroupTimingDataFields(
    availabilityGroupId,
    collection = "spaceAvailabilityFields",
  ) {
    const json = await api.get(
      `availability_group_timing_fields/${availabilityGroupId}`,
    );

    if (json?.data) {
      _.forEach(json.data, function (dataField, i) {
        const availabilityGroupFetchKey = `SpaceAvailabilityGroup${availabilityGroupId}Timing${
          i + 1
        }`;
        switch (collection) {
          case "spaceAvailabilityFields":
            analyzeSpaceAvailabilityFields.value[availabilityGroupFetchKey] =
              dataField;
            break;
          case "spaceUsageFields":
            analyzeLoanFields.value[availabilityGroupFetchKey] = dataField;
            break;
        }
      });
    }
  }

  // ANALYZE INVESTMENT DATA FETCHING

  async function fetchInvestmentDataField(investmentId, investmentFetchKey) {
    const json = await api.get(`investments/${investmentId}`);

    if (json?.data) {
      analyzeInvestmentFields.value[investmentFetchKey] = json.data;
    }
  }

  async function fetchInvestmentGroupDataField(
    investmentGroupId,
    investmentGroupFetchKey,
    collection = "investmentFields",
  ) {
    const json = await api.get(
      `investment_groups/${investmentGroupId}?field_output_format=outer_field`,
    );

    if (json?.data) {
      switch (collection) {
        case "investmentFields":
          analyzeInvestmentFields.value[investmentGroupFetchKey] = json.data;
          break;
        case "loanFields":
          analyzeLoanFields.value[investmentGroupFetchKey] = json.data;
          break;
      }
    }
  }

  async function fetchInvestmentGroupTimingDataFields(
    investmentGroupId,
    collection = "investmentFields",
  ) {
    const json = await api.get(
      `investment_group_timing_fields/${investmentGroupId}`,
    );

    if (json?.data) {
      _.forEach(json.data, function (dataField, i) {
        const investmentGroupFetchKey = `InvestmentGroup${investmentGroupId}Timing${
          i + 1
        }`;
        switch (collection) {
          case "investmentFields":
            analyzeInvestmentFields.value[investmentGroupFetchKey] = dataField;
            break;
          case "loanFields":
            analyzeLoanFields.value[investmentGroupFetchKey] = dataField;
            break;
        }
      });
    }
  }

  async function fetchValuations(contentType, contentId, valuationFetchKey) {
    const json = await api.get(
      `content_valuations/${contentType}/${contentId}`,
    );

    if (json?.data) {
      _.forEach(json.data, function (dataField) {
        const fieldKey = `${valuationFetchKey}${dataField.fieldContentId}`;
        analyzeInvestmentFields.value[fieldKey] = dataField;
      });
    }
  }

  async function fetchValueFor(contentType, contentId, valuationId) {
    const fetchKey = `Valuation${valuationId}value`;
    if (!analyzeInvestmentFields.value[fetchKey]) {
      const json = await api.get(
        `crowdsourced_data_fields/Valuation/${valuationId}?field_name=value`,
      );
      if (json?.data) {
        analyzeInvestmentFields.value[fetchKey] = json.data;
        fetchContentCalculationData({
          contentType: contentType,
          contentId: contentId,
          override: false,
        });
      }
    }
  }

  async function fetchLoanAmountFor(
    contentType,
    contentId,
    loanId,
    collection = "investmentFields",
  ) {
    const fetchKey = `Loan${loanId}fully_funded_loan_amount`;
    switch (collection) {
      case "investmentFields": {
        if (!analyzeInvestmentFields.value[fetchKey]) {
          const json = await api.get(
            `crowdsourced_data_fields/Loan/${loanId}?field_name=fully_funded_loan_amount`,
          );

          if (json?.data) {
            analyzeInvestmentFields.value[fetchKey] = json.data;
            if (contentType && contentId) {
              fetchContentCalculationData({
                contentType: contentType,
                contentId: contentId,
                override: false,
              });
            }
          }
        }
        break;
      }
      case "loanFields": {
        if (!analyzeLoanFields.value[fetchKey]) {
          const json = await api.get(
            `crowdsourced_data_fields/Loan/${loanId}?field_name=fully_funded_loan_amount`,
          );

          if (json?.data) {
            analyzeLoanFields.value[fetchKey] = json.data;
          }
        }
        break;
      }
    }
  }

  async function fetchSpaceUsageCommencedDateFor(
    contentType,
    contentId,
    spaceUsageId,
    collection = "spaceUsageFields",
  ) {
    const fetchKey = `SpaceUsage${spaceUsageId}commenced_date`;
    switch (collection) {
      case "spaceUsageFields": {
        if (!analyzeSpaceUsageFields.value[fetchKey]) {
          const json = await api.get(
            `crowdsourced_data_fields/SpaceUsage/${spaceUsageId}?field_name=commenced_date`,
          );

          if (json?.data) {
            analyzeSpaceUsageFields.value[fetchKey] = json.data;
            if (contentType && contentId) {
              fetchContentCalculationData({
                contentType: contentType,
                contentId: contentId,
                override: false,
              });
            }
          }
        }
        break;
      }
    }
  }

  async function fetchPropertyDataField(
    propertyId,
    collection = "investmentFields",
  ) {
    if (propertyId) {
      const fetchKey = `Property${propertyId}`;
      const json = await api.get(`properties/${propertyId}`);
      if (json?.data) {
        switch (collection) {
          case "spaceUsageFields":
            analyzeSpaceUsageFields.value[fetchKey] = json.data;
            break;
          case "spaceAvailabilityFields":
            analyzeSpaceAvailabilityFields.value[fetchKey] = json.data;
            break;
          case "investmentFields":
            analyzeInvestmentFields.value[fetchKey] = json.data;
            break;
          case "loanFields":
            analyzeLoanFields.value[fetchKey] = json.data;
            break;
        }
      }
    }
  }

  // ANALYZE INVESTMENT DATA FETCHING END

  async function fetchPolygonSpaceAvailabilities() {
    if (polygonPropertyIds.value.length > 0) {
      const payload = { propertyIds: polygonPropertyIds.value };
      const response = await api.post(`space_availability_properties`, payload);

      if (response?.data) {
        polygonAvailabilityIds.value = response.data;
        lastCheckedPolygonArea.value = polygonArea.value;
      }
    }
  }

  async function fetchPolygonInvestments() {
    if (polygonPropertyIds.value.length > 0) {
      const payload = { propertyIds: polygonPropertyIds.value };
      const response = await api.post(`investment_properties`, payload);

      if (response?.data) {
        polygonInvestmentIds.value = response.data;
        lastCheckedPolygonArea.value = polygonArea.value;
      }
    }
  }

  async function fetchPolygonPropertyLocations() {
    if (geographyFilterPolygon.value) {
      const payload = {
        coordinates: getPolygonCoordinates(),
        zoom: map.value.getZoom(),
      };

      const response = await api.post(
        `polygon_filtered_property_locations`,
        payload,
      );

      if (response?.data) {
        polygonPropertyLocations.value = response.data;

        await fetchPolygonInvestments();
        await fetchPolygonSpaceAvailabilities();
        await fetchPolygonHunts();
      }
    }
  }

  const polygonHuntIds = ref([]);

  async function fetchPolygonHunts() {
    if (geographyFilterPolygon.value) {
      const payload = {
        coordinates: getPolygonCoordinates(),
        zoom: map.value.getZoom(),
      };

      const response = await api.post(`polygon_filtered_hunts`, payload);

      if (response?.data) {
        polygonHuntIds.value = response.data;

        await fetchPolygonInvestments();
        await fetchPolygonSpaceAvailabilities();
      }
    }
  }

  const propertySources = computed(() => {
    const rawSources = contextedPropertiesArray.value.map((df) => {
      const contentId =
        df.decoratingContentType === "Property"
          ? df.decoratingContentId
          : df.fieldContentId;
      if (df.fieldContent.airLayerPropertyId) {
        return [
          {
            contentId,
          },
          {
            contentId: df.fieldContent.airLayerPropertyId,
          },
        ];
      } else {
        return {
          contentId,
        };
      }
    });

    return _.flatten(rawSources);
  });

  const debouncedSpaceUsagesAvailabilitiesFetch = _.debounce(function () {
    fetchSpaceUsageAvailabilities();
  }, 2000);

  async function fetchSpaceUsageAvailabilities() {
    if (spaceUsagesChecked.value) {
      spaceUsageAvailabilityFields.value = [];
      fetchingSpaceUsageAvailabilityFields.value = true;

      try {
        const payload = {
          enhancementIds: _.includes(
            selectedSpaceTypes.value,
            "PropertyEnhancement",
          )
            ? stateFilteredEnhancementIds.value
            : [],
          partitionIds: _.includes(selectedSpaceTypes.value, "LayoutPartition")
            ? stateFilteredPartitionIds.value
            : [],
          floorAreaIds: _.includes(selectedSpaceTypes.value, "FloorArea")
            ? _.intersection(
                stateFilteredFloorAreaIds.value,
                useTypeFilteredFloorAreaIds.value,
              )
            : [],
          propertySources: propertySources.value,
        };
        const response = await api.post(
          `filtered_space_usage_availabilities`,
          payload,
        );

        if (response?.data) {
          spaceUsageAvailabilityFields.value = response.data;
        }
      } catch (error) {
        console.error(error);
        notificationsStore.addNotification("anErrorOccurred");
      } finally {
        fetchingSpaceUsageAvailabilityFields.value = false;
      }
    }
  }

  const debouncedLandCoveringSpaceAvailabilitiesFetch = _.debounce(function () {
    fetchLandCoveringSpaceAvailabilities();
  }, 2000);

  async function fetchLandCoveringSpaceAvailabilities() {
    if (emptyCategoriesFetchable.value) {
      landCoveringSpaceAvailabilityIds.value = [];
      const payload = {
        enhancementIds: _.includes(
          selectedSpaceTypes.value,
          "PropertyEnhancement",
        )
          ? stateFilteredEnhancementIds.value
          : [],
        partitionIds: _.includes(selectedSpaceTypes.value, "LayoutPartition")
          ? stateFilteredPartitionIds.value
          : [],
        floorAreaIds: _.includes(selectedSpaceTypes.value, "FloorArea")
          ? _.intersection(
              stateFilteredFloorAreaIds.value,
              useTypeFilteredFloorAreaIds.value,
            )
          : [],
        propertySources: propertySources.value,
      };
      const response = await api.post(
        `land_covering_space_availabilities`,
        payload,
      );

      if (response?.data) {
        landCoveringSpaceAvailabilityIds.value = response.data;
      }
    }
  }

  const debouncedLoansInvestmentsFetch = _.debounce(function () {
    fetchLoanInvestments();
  }, 2000);

  async function fetchLoanInvestments() {
    if (emptyCategoriesFetchable.value) {
      loanInvestmentFields.value = [];
      fetchingLoanInvestmentFields.value = true;

      try {
        const payload = {
          floorAreaIds: _.intersection(
            stateFilteredFloorAreaIds.value,
            useTypeFilteredFloorAreaIds.value,
          ),
          propertySources: propertySources.value,
        };
        const response = await api.post(`loan_investments`, payload);

        if (response?.data) {
          loanInvestmentFields.value = response.data;
        }
      } catch (error) {
        console.error(error);
        notificationsStore.addNotification("anErrorOccurred");
      } finally {
        fetchingLoanInvestmentFields.value = false;
      }
    }
  }
  const debouncedLandCoveringInvestmentsFetch = _.debounce(function () {
    fetchLandCoveringInvestments();
  }, 2000);

  async function fetchLandCoveringInvestments() {
    if (emptyCategoriesFetchable.value) {
      landCoveringInvestmentIds.value = [];
      const payload = {
        floorAreaIds: _.intersection(
          stateFilteredFloorAreaIds.value,
          useTypeFilteredFloorAreaIds.value,
        ),
        propertySources: propertySources.value,
      };
      const response = await api.post(`land_covering_investments`, payload);

      if (response?.data) {
        landCoveringInvestmentIds.value = response.data;
      }
    }
  }

  async function fetchValuationFilteredInvestments() {
    if (validValuationFilters.value && !valueFilterApplied.value) {
      filteredStandaloneValueFields.value = [];
      fetchingValuationFilterData.value = true;
      const payload = {
        minValue: minValue.value,
        maxValue: maxValue.value,
        propertySources: propertySources.value,
      };
      fetchValuationFilteredHunts();
      const response = await api.post(
        `valuation_filtered_investments`,
        payload,
      );

      if (response?.data) {
        filteredStandaloneValueFields.value = response.data;
        valueFilterApplied.value = true;
        fetchingValuationFilterData.value = false;
      }
    }
  }

  async function fetchSizeFilteredSpaceAvailabilities() {
    if (
      validSpaceAvailabilitySizeFilters.value &&
      !spaceAvailabilitySizeFilterApplied.value
    ) {
      fetchingSpaceAvailabilitySizeFilterData.value = true;

      if (spaceAvailabilitiesChecked.value) {
        filteredStandaloneSpaceAvailabilitySizeFields.value = [];

        const payload = {
          minSpaceAvailabilitySize: minSpaceAvailabilitySize.value,
          maxSpaceAvailabilitySize: maxSpaceAvailabilitySize.value,
          propertySources: propertySources.value,
          calculationType: "minimum",
        };
        const response = await api.post(
          `size_filtered_space_availabilities`,
          payload,
        );

        if (response?.data) {
          // [{ availabilityId: X }, { availabilityGroupId: Y }]
          filteredStandaloneSpaceAvailabilitySizeFields.value = response.data;
        }
      }

      if (
        spaceUsagesChecked.value ||
        companiesChecked.value ||
        huntsChecked.value ||
        _.includes(["CompanyShell", "ContactShell"], routeName.value)
      ) {
        filteredStandaloneSpaceUsageAvailabilitySizeFields.value = [];

        const payload = {
          minSpaceAvailabilitySize: minSpaceAvailabilitySize.value,
          maxSpaceAvailabilitySize: maxSpaceAvailabilitySize.value,
          propertySources: propertySources.value,
          calculationType: "actual",
        };
        fetchOccupierSizeFilteredHunts();
        const response = await api.post(
          `size_filtered_space_availabilities`,
          payload,
        );

        if (response?.data) {
          // [{ availabilityId: X }, { availabilityGroupId: Y }]
          filteredStandaloneSpaceUsageAvailabilitySizeFields.value =
            response.data;
        }
      }

      spaceAvailabilitySizeFilterApplied.value = true;
      fetchingSpaceAvailabilitySizeFilterData.value = false;
    }
  }

  const debouncedLoanMaturityDateFilter = _.debounce(function () {
    if (horizontalIsAnalyze.value) fetchMaturityDateFilteredLoans();
  }, 1000);

  async function fetchMaturityDateFilteredLoans() {
    if (filterLoanMaturityDate.value && loanMaturityDateFilterInput.value) {
      fetchingLoanMaturityDateFilterData.value = true;
      const payload = {
        maturityDate: loanMaturityDateFilterInput.value.clone().toDate(),
        propertySources: propertySources.value,
      };
      const response = await api.post(`maturity_date_filtered_loans`, payload);

      if (response?.data) {
        filteredLoanMaturityDateFields.value = response.data;
        fetchingLoanMaturityDateFilterData.value = false;
      }
    }
  }

  const debouncedLoanStateFilter = _.debounce(function () {
    fetchStateFilteredLoans();
  }, 1000);

  async function fetchStateFilteredLoans() {
    if (filterLoanStates.value && selectedLoanStates.value.length > 0) {
      fetchingLoanStateFilterData.value = true;
      const payload = {
        states: selectedLoanStates.value,
        filteringMetadata: selectedLoanStateFilteringMetadata.value,
        propertySources: propertySources.value,
        asOf: asOfMilliseconds.value,
      };
      const response = await api.post(`state_filtered_loans`, payload);

      if (response?.data) {
        filteredLoanStateFields.value = response.data;
        fetchingLoanStateFilterData.value = false;
      }
    }
  }

  const debouncedSpaceUsageExpirationDateFilter = _.debounce(function () {
    if (horizontalIsAnalyze.value) fetchExpirationDateFilteredLoans();
  }, 1000);

  async function fetchExpirationDateFilteredLoans() {
    if (
      filterSpaceUsageExpirationDate.value &&
      spaceUsageExpirationDateFilterInput.value
    ) {
      fetchingSpaceUsageExpirationDateFilterData.value = true;
      const payload = {
        expiredDate: spaceUsageExpirationDateFilterInput.value.clone().toDate(),
        propertySources: propertySources.value,
      };
      const response = await api.post(
        `expiration_date_filtered_space_usages`,
        payload,
      );

      if (response?.data) {
        filteredSpaceUsageExpirationDateFields.value = response.data;
        fetchingSpaceUsageExpirationDateFilterData.value = false;
      }
    }
  }

  const debouncedSpaceUsageStateFilter = _.debounce(function () {
    fetchStateFilteredSpaceUsages();
  }, 1000);

  async function fetchStateFilteredSpaceUsages() {
    if (
      filterSpaceUsageStates.value &&
      selectedSpaceUsageStates.value.length > 0
    ) {
      fetchingSpaceUsageStateFilterData.value = true;
      const payload = {
        states: selectedSpaceUsageStates.value,
        filteringMetadata: selectedSpaceUsageStateFilteringMetadata.value,
        propertySources: propertySources.value,
        asOf: asOfMilliseconds.value,
      };
      const response = await api.post(`state_filtered_space_usages`, payload);

      if (response?.data) {
        filteredSpaceUsageStateFields.value = response.data;
        fetchingSpaceUsageStateFilterData.value = false;
      }
    }
  }

  const debouncedSpaceAvailabilityStateFilter = _.debounce(function () {
    fetchStateFilteredSpaceAvailabilities();
  }, 1000);

  async function fetchStateFilteredSpaceAvailabilities() {
    if (
      filterSpaceAvailabilityStates.value &&
      selectedSpaceAvailabilityStates.value.length > 0
    ) {
      fetchingSpaceAvailabilityStateFilterData.value = true;
      const payload = {
        states: selectedSpaceAvailabilityStates.value,
        filteringMetadata:
          selectedSpaceAvailabilityStateFilteringMetadata.value,
        propertySources: propertySources.value,
        asOf: asOfMilliseconds.value,
      };
      const response = await api.post(
        `state_filtered_space_availabilities`,
        payload,
      );

      if (response?.data) {
        filteredSpaceAvailabilityStateFields.value = response.data;
        fetchingSpaceAvailabilityStateFilterData.value = false;
      }
    }
  }

  const debouncedSpaceAvailabilitySpaceTypeFilter = _.debounce(function () {
    fetchSpaceTypeFilteredSpaceAvailabilities();
  }, 1000);

  async function fetchSpaceTypeFilteredSpaceAvailabilities() {
    if (
      filterSpaceAvailabilitySpaceTypes.value &&
      selectedSpaceTypes.value.length > 0
    ) {
      fetchingSpaceAvailabilitySpaceTypeFilterData.value = true;
      const payload = {
        spaceTypes: selectedSpaceTypes.value,
        propertySources: propertySources.value,
      };
      const response = await api.post(
        `space_type_filtered_space_availabilities`,
        payload,
      );

      if (response?.data) {
        filteredSpaceAvailabilitySpaceTypeFields.value = response.data;
        fetchingSpaceAvailabilitySpaceTypeFilterData.value = false;
      }
    }
  }

  const debouncedInvestmentStateFilter = _.debounce(function () {
    fetchStateFilteredInvestments();
  }, 1000);

  async function fetchStateFilteredInvestments() {
    if (
      filterInvestmentStates.value &&
      selectedInvestmentStates.value.length > 0
    ) {
      fetchingInvestmentStateFilterData.value = true;
      const payload = {
        states: selectedInvestmentStates.value,
        filteringMetadata: selectedInvestmentStateFilteringMetadata.value,
        propertySources: propertySources.value,
        asOf: asOfMilliseconds.value,
      };
      const response = await api.post(`state_filtered_investments`, payload);

      if (response?.data) {
        filteredInvestmentStateFields.value = response.data;
        fetchingInvestmentStateFilterData.value = false;
      }
    }
  }

  const debouncedLandCoveringUseTypeFilter = _.debounce(function () {
    fetchUseTypeFilteredLandCoverings();
  }, 1000);

  async function fetchUseTypeFilteredLandCoverings() {
    if (filterLandCoveringUseTypes.value) {
      fetchingLandCoveringUseTypeFilterData.value = true;
      const payload = {
        useTypes: selectedLandCoveringUseTypes.value,
        propertySources: propertySources.value,
        asOf: asOfMilliseconds.value,
      };
      const response = await api.post(
        `use_type_filtered_land_coverings`,
        payload,
      );

      if (response?.data) {
        filteredLandCoveringUseTypeFields.value = response.data;
        fetchingLandCoveringUseTypeFilterData.value = false;
        debouncedLandCoveringInvestmentsFetch();
        debouncedLandCoveringSpaceAvailabilitiesFetch();
        debouncedSpaceUsagesAvailabilitiesFetch();
        debouncedLoansInvestmentsFetch();
      }
    }
  }

  const debouncedLandCoveringStateFilter = _.debounce(function () {
    fetchStateFilteredLandCoverings();
  }, 1000);

  async function fetchStateFilteredLandCoverings() {
    if (
      filterLandCoveringStates.value &&
      selectedLandCoveringStates.value.length > 0
    ) {
      fetchingLandCoveringStateFilterData.value = true;
      const payload = {
        states: selectedLandCoveringStates.value,
        filteringMetadata: selectedLandCoveringStateFilteringMetadata.value,
        propertySources: propertySources.value,
        asOf: asOfMilliseconds.value,
      };
      const response = await api.post(`state_filtered_land_coverings`, payload);

      if (response?.data) {
        filteredLandCoveringStateFields.value = response.data;
        fetchingLandCoveringStateFilterData.value = false;
        debouncedLandCoveringInvestmentsFetch();
        debouncedLandCoveringSpaceAvailabilitiesFetch();
        debouncedSpaceUsagesAvailabilitiesFetch();
        debouncedLoansInvestmentsFetch();
      }
    }
  }

  const debouncedInvestmentTypeFilter = _.debounce(function () {
    fetchTypeFilteredInvestments();
  }, 1000);

  async function fetchTypeFilteredInvestments() {
    if (
      filterInvestmentTypes.value &&
      selectedInvestmentTypes.value.length > 0
    ) {
      fetchingInvestmentTypeFilterData.value = true;
      const payload = {
        dealActions: selectedInvestmentTypes.value,
        propertySources: propertySources.value,
      };
      const response = await api.post(`type_filtered_investments`, payload);

      if (response?.data) {
        filteredInvestmentTypeFields.value = response.data;
        fetchingInvestmentTypeFilterData.value = false;
      }
    }
  }

  const debouncedInvestmentAssetTypeFilter = _.debounce(function () {
    fetchAssetTypeFilteredInvestments();
  }, 1000);

  async function fetchAssetTypeFilteredInvestments() {
    if (
      filterInvestmentAssetTypes.value &&
      selectedInvestmentAssetTypes.value.length > 0
    ) {
      fetchingInvestmentAssetTypeFilterData.value = true;
      const payload = {
        assetTypes: selectedInvestmentAssetTypes.value,
        propertySources: propertySources.value,
      };
      const response = await api.post(
        `asset_type_filtered_investments`,
        payload,
      );

      if (response?.data) {
        filteredInvestmentAssetTypeFields.value = response.data;
        fetchingInvestmentAssetTypeFilterData.value = false;
      }
    }
  }

  const valueFilteredHuntIds = ref([]);

  async function fetchValuationFilteredHunts() {
    if (validValuationFilters.value) {
      valueFilteredHuntIds.value = [];

      const bounds = map.value.getBounds();
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();
      const northeastLatLng = [northeast.lat, northeast.lng];
      const southwestLatLng = [southwest.lat, southwest.lng];
      const payload = {
        minValue: minValue.value,
        maxValue: maxValue.value,
      };
      const response = await api.post(
        `valuation_filtered_hunts/${southwestLatLng}/${northeastLatLng}?zoom=${zoom.value}`,
        payload,
      );

      if (response?.data) {
        valueFilteredHuntIds.value = response.data;
      }
    }
  }

  const occupierSizeFilteredHuntIds = ref([]);

  async function fetchOccupierSizeFilteredHunts() {
    if (validSpaceAvailabilitySizeFilters.value) {
      occupierSizeFilteredHuntIds.value = [];

      const bounds = map.value.getBounds();
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();
      const northeastLatLng = [northeast.lat, northeast.lng];
      const southwestLatLng = [southwest.lat, southwest.lng];
      const payload = {
        minValue: minSpaceAvailabilitySize.value,
        maxValue: maxSpaceAvailabilitySize.value,
      };
      const response = await api.post(
        `size_filtered_hunts/${southwestLatLng}/${northeastLatLng}?zoom=${zoom.value}`,
        payload,
      );

      if (response?.data) {
        occupierSizeFilteredHuntIds.value = response.data;
      }
    }
  }

  const useTypeFilteredHuntIds = ref([]);

  const debouncedHuntUseTypeFilter = _.debounce(function () {
    fetchUseTypeFilteredHunts();
  }, 1000);

  async function fetchUseTypeFilteredHunts() {
    if (filterLandCoveringUseTypes.value) {
      useTypeFilteredHuntIds.value = [];

      const bounds = map.value.getBounds();
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();
      const northeastLatLng = [northeast.lat, northeast.lng];
      const southwestLatLng = [southwest.lat, southwest.lng];
      const payload = {
        useTypes: selectedLandCoveringUseTypes.value,
      };
      const response = await api.post(
        `use_type_filtered_hunts/${southwestLatLng}/${northeastLatLng}?zoom=${zoom.value}`,
        payload,
      );

      if (response?.data) {
        useTypeFilteredHuntIds.value = response.data;
      }
    }
  }

  const investmentTypeFilteredHuntIds = ref([]);

  const debouncedHuntInvestmentTypeFilter = _.debounce(function () {
    fetchInvestmentTypeFilteredHunts();
  }, 1000);

  async function fetchInvestmentTypeFilteredHunts() {
    if (filterInvestmentTypes.value) {
      investmentTypeFilteredHuntIds.value = [];

      const serverSideFormattedTypes = selectedInvestmentTypeLabels.value.map(
        (rawInvestmentType) => {
          switch (rawInvestmentType) {
            case "Sale":
            case "Recap":
              return "hunts_equity";
            case "Financing":
              return "hunts_debt";
            default:
              return null;
          }
        },
      );

      const bounds = map.value.getBounds();
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();
      const northeastLatLng = [northeast.lat, northeast.lng];
      const southwestLatLng = [southwest.lat, southwest.lng];
      const payload = {
        investmentTypes: _.uniq(_.compact(serverSideFormattedTypes)),
      };
      const response = await api.post(
        `investment_type_filtered_hunts/${southwestLatLng}/${northeastLatLng}?zoom=${zoom.value}`,
        payload,
      );

      if (response?.data) {
        investmentTypeFilteredHuntIds.value = response.data;
      }
    }
  }

  return {
    calculationData,
    calculationOptions,
    timeMismatch,
    latLngMismatch,
    zoomMismatch,
    polygonMismatch,
    lastCheckedPolygonArea,
    unselectedPropertyDiagram,
    propertySelectedPropertyDiagram,
    contextedPropertiesArray,
    unselectedCompanyDetail,
    unselectedContactDetail,
    countable,
    sizeable,
    sizesSurveyFetched,
    investmentVolumeSurveyFetched,
    focalDataField,
    mapSearchShortcutAction,
    analyzeDisplayable,
    fetchingAnalyzePanelData,
    filters,
    filterCategories,
    filterDates,
    filterGeography,
    filterLandCoveringUseTypes,
    filterLandCoveringStates,
    filterValuations,
    filterCompanyEventTypes,
    filterCompanyRoles,
    filterLoanStates,
    filterSpaceUsageStates,
    filterSpaceAvailabilityStates,
    filterSpaceAvailabilitySpaceTypes,
    filterSpaceAvailabilitySizes,
    filterInvestmentStates,
    filterInvestmentTypes,
    filterInvestmentAssetTypes,
    investmentsChecked,
    loansChecked,
    sizesChecked,
    spaceUsagesChecked,
    spaceAvailabilitiesChecked,
    companiesChecked,
    huntsChecked,
    checkedCategories,
    minValue,
    maxValue,
    valueFilteredHuntIds,
    occupierSizeFilteredHuntIds,
    useTypeFilteredHuntIds,
    investmentTypeFilteredHuntIds,
    validValueRange,
    valueFilterApplied,
    minSpaceAvailabilitySize,
    maxSpaceAvailabilitySize,
    validMinSpaceAvailabilitySize,
    validMaxSpaceAvailabilitySize,
    validSpaceAvailabilitySizeFilters,
    spaceAvailabilitySizeFilterApplied,
    investmentLikeFilterable,
    spaceAvailabilityLikeFilterable,
    spaceUsageAvailabilityLikeFilterable,
    fetchingValuationFilterData,
    fetchingSpaceAvailabilitySizeFilterData,
    fetchingSpaceAvailabilitySpaceTypeFilterData,
    fetchingLoanStateFilterData,
    fetchingSpaceUsageStateFilterData,
    fetchingInvestmentStateFilterData,
    fetchingInvestmentTypeFilterData,
    fetchingInvestmentAssetTypeFilterData,
    fetchingLandCoveringStateFilterData,
    fetchingLandCoveringUseTypeFilterData,
    validMaxValue,
    validMinValue,
    validValuationFilters,
    filteredStandaloneValueFields,
    filteredInvestmentLikes,
    filteredSpaceAvailabilityLikes,
    filteredSpaceUsageAvailabilityLikes,
    filteredLoanStateLoanIds,
    filteredSpaceUsageStateSpaceUsageIds,
    selectedGeography,
    editingPolygon,
    geographyFilterPolygon,
    polygonPropertyLocations,
    polygonHuntIds,
    filteredTypedInvestmentIds,
    filteredAssetTypedInvestmentIds,
    combinedFilteredInvestmentIds,
    combinedFilteredSpaceAvailabilityIds,
    combinedFilteredSpaceUsageIds,
    stateFilteredLandCoveringIds,
    stateFilteredLandCoveringPropertyIds,
    combinedFilteredLandCoveringIds,
    combinedFilteredPropertyIds,
    polygonArea,
    triggerClearMapPolygon,
    sizesCategory,
    companiesCategory,
    investmentsCategory,
    selectedFilterCategories,
    selectedCompanyRoles,
    selectedCompanyEventTypes,
    exportRowData,
    dataEmptyState,
    analyzePropertyFields,
    analyzeLoanFields,
    analyzeHuntFields,
    analyzeInvestmentFields,
    analyzeSpaceAvailabilityFields,
    analyzeSpaceUsageFields,
    fetchedInvestmentKeys,
    fetchedHuntKeys,
    fetchedSpaceAvailabilityKeys,
    fetchedInvestmentFields,
    fetchedInvestmentGroupFields,
    fetchedSpaceAvailabilityFields,
    fetchedSpaceAvailabilityGroupFields,
    fetchedSpaceUsageUserFields,
    fetchedAvailabilityFloorAreaLayoutFields,
    fetchedUsageFloorAreaLayoutFields,
    datedInvestmentPropertyIds,
    datedAvailabilityPropertyIds,
    contentCalculationData,
    selectedLayer,
    layers,
    resetCalculationData,
    setCalculationDataByName,
    unlockableFieldsFor,
    unlicensedFieldsFor,
    alreadyFetched,
    alreadyFetchedFieldIdsFor,
    addExportRow,
    fetchContentCalculationData,
    dropContentCalculationData,
    contentCalculationDataByKey,
    fetchLoanDataField,
    fetchInvestmentDataField,
    fetchHuntDataField,
    fetchHuntGeographyIntents,
    fetchInvestmentGroupDataField,
    fetchInvestmentGroupTimingDataFields,
    fetchSpaceAvailabilityGroupTimingDataFields,
    fetchSpaceAvailabilityDataField,
    fetchSpaceAvailabilityGroupDataField,
    fetchSpaceUsageDataField,
    fetchSpaceUsageUserDataFields,
    fetchFloorAreaLayouts,
    fetchValuations,
    fetchValueFor,
    fetchLoanAmountFor,
    fetchSpaceUsageCommencedDateFor,
    fetchPropertyDataField,
    resetPolygonFilter,
    setPolygonEditing,
    setPolygon,
    setSelectedGeography,
    clearPolygon,
    resetFilterCategories,
    getPolygonCoordinates,
    combinedFilteredInvestmentIncluded,
    combinedFilteredSpaceAvailabilityIncluded,
    combinedFilteredHuntIncluded,
    investmentLikeIncluded,
    spaceAvailabilityLikeIncluded,
    spaceUsageAvailabilityLikeIncluded,
    fetchValuationFilteredInvestments,
    clearValueRange,
    clearValuationFilters,
    fetchSizeFilteredSpaceAvailabilities,
    clearSpaceAvailabilitySizeRange,
    clearSpaceAvailabilitySizeFilters,
    fetchTypeFilteredInvestments,
    surveyInvestmentCountProxy,
    surveySpaceAvailabilityCountProxy,
    surveySpaceUsageCountProxy,
    surveyCompanyCountProxy,
    surveySizeCountProxy,
    surveyLoanCountProxy,
    surveyHuntCountProxy,
    surveyUnlockableBundleFieldIds,
    patchUnlockableIds,
    dropUnlockableFieldType,
    dropUnlockableIds,
    loansTableAssemblyContent,
    loanInvestmentFields,
    portfolioAssetLoans,
    filteredPortfolioAssetLoans,
    filteredPortfolioSpaceUsages,
    portfolioSpaceTypeSpaceUsages,
    portfolioInvestmentFields,
    combinedFilteredLoanInvestmentIds,
    displayableLoanTableRows,
    loanCount,
    spaceUsageCount,
    fetchingLoanInvestmentFields,
    fetchingSpaceUsageAvailabilityFields,
    loanMaturityDateFilterInput,
    editingLoanMaturityDate,
    filterLoanMaturityDate,
    setLoanMaturityDate,
    clearLoanMaturityDate,
    setLoanMaturityDateEditing,
    spaceUsageAvailabilityFields,
    displayableSpaceUsageTableRows,
    filterSpaceUsageExpirationDate,
    spaceUsageExpirationDateFilterInput,
    editingSpaceUsageExpirationDate,
    setSpaceUsageExpirationDate,
    clearSpaceUsageExpirationDate,
    setSpaceUsageExpirationDateEditing,
    hoveringTableRowContent,
    routeName,
    asyncReady,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useAnalyzePanelStore, import.meta.hot),
  );
}
