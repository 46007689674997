<template>
  <div
    v-if="
      _.includes(
        ['MainMap', 'CompanyShell', 'ContactShell', 'PropertyShell'],
        routeName,
      )
    "
    class="col-start-1 row-start-1"
  >
    <div
      :class="[workspaceLayout === 'sideBySide' ? 'px-2' : 'px-4']"
      class="mx-auto justify-end flex"
    >
      <div class="w-72 inline-flex rounded-md shadow-sm min-h-[100px]">
        <UnlockSelector
          v-if="combinedUnlockableIds.length > 0 && displayable"
          context="analyze-panel-filter-settings"
          :bundle-field-ids="combinedUnlockableIds"
          class="flex-grow rounded-l-md px-3 py-2 ring-1 ring-inset ring-gray-300"
          :analyze="true"
        />
        <div
          v-else-if="combinedUnlockableIds.length > 0"
          class="text-center flex-grow flex flex-col justify-center items-center rounded-md px-3 py-2 ring-1 ring-inset ring-gray-300"
        >
          <FunnelIcon class="mx-auto h-6 w-6 text-gray-400" />
          <span class="mt-2 block text-sm font-medium text-gray-900">
            Datapoint fetching in progress.
          </span>
        </div>
        <div
          v-else-if="checkedCategories.length > 0"
          class="text-center flex-grow flex flex-col justify-center items-center rounded-md px-3 py-2 ring-1 ring-inset ring-gray-300"
        >
          <FunnelIcon class="mx-auto h-6 w-6 text-gray-400" />
          <span class="mt-2 block text-sm font-medium text-gray-900">
            No matching results.
          </span>
        </div>
        <div
          v-else
          class="text-center flex-grow flex flex-col justify-center items-center rounded-md px-3 py-2 ring-1 ring-inset ring-gray-300"
        >
          <FunnelIcon class="mx-auto h-6 w-6 text-gray-400" />
          <span class="mt-2 block text-sm font-medium text-gray-900">
            Choose filter categories to get started.
          </span>
        </div>
        <VDropdown
          v-if="combinedUnlockableIds.length > 0"
          placement="bottom-end"
          class="relative -ml-px block"
        >
          <slot name="button">
            <button
              type="button"
              class="h-full relative -mr-1 inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <span class="sr-only">Open options</span>
              <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </slot>
          <template #popper>
            <div
              class="w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <ul class="py-1">
                <li v-for="item in actionMenuItems" :key="item.name">
                  <a
                    @click.prevent
                    href=""
                    class="block px-4 py-2 text-sm text-gray-700 hover:text-gray-900"
                    >{{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
          </template>
        </VDropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useCalculationFieldsStore } from "@/stores/calculationFields";
import { useNearbyCompaniesStore } from "@/stores/nearbyCompanies";
import UnlockSelector from "@/components/crowdsourcing/UnlockSelector.vue";
import { ChevronDownIcon, FunnelIcon } from "@heroicons/vue/20/solid";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { computed, ref } from "vue";
import _ from "lodash";

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  surveyUnlockableBundleFieldIds,
  checkedCategories,
  companiesChecked,
  asyncReady,
} = storeToRefs(analyzePanelStore);
const calculationFieldsStore = useCalculationFieldsStore();
const { allPagysLoaded: calculationPagysLoaded } = storeToRefs(
  calculationFieldsStore,
);
const nearbyCompaniesStore = useNearbyCompaniesStore();
const {
  surveyUnlockableCompanyFieldIds,
  allPagysLoaded: nearbyCompanyPagysLoaded,
} = storeToRefs(nearbyCompaniesStore);

const route = useRoute();
const routeName = computed(() => route.name);

const displayable = computed(() => {
  return asyncReady.value && calculationPagysLoaded.value && nearbyCompanyPagysLoaded.value;
});

const combinedUnlockableIds = computed(() =>
  _.uniq(
    _.concat(
      surveyUnlockableBundleFieldIds.value,
      companiesChecked.value ? surveyUnlockableCompanyFieldIds.value : [],
    ),
  ),
);

const actionMenuItems = ref([
  {
    name: "Export",
  },
]);
</script>
