<template>
  <li
    class="w-full flex px-2"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <a
      href=""
      class="w-full px-1 py-2 rounded-md flex items-center justify-between hover:bg-gray-300 gap-x-2"
      :class="[wePanelStore.activeSelectionOptionClass(region)]"
      @click.prevent="view"
    >
      <div
        class="max-w-[90%] flex-shrink-0 flex items-center space-x-2 text-gray-700 font-medium hover:text-gray-600"
      >
        <i class="fa-solid fa-draw-polygon"></i>
        <div class="truncate">{{ region.name }}</div>
      </div>
      <button
        v-if="isHovered || wePanelStore.selectionIsActive(region)"
        type="button"
        class="flex-shrink-0 inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
        @click.stop.prevent="wePanelStore.hideRegion(region)"
      >
        <XMarkIcon class="size-5" />
      </button>
    </a>
  </li>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { markRaw, ref } from "vue";
import RegionMessagesContainer from "@/components/we/main-content-views/RegionMessagesContainer.vue";

const props = defineProps(["region"]);
const wePanelStore = useWePanelStore();
const { rightEdgeComponent, mainContent } = storeToRefs(wePanelStore);

const isHovered = ref(false);

function view() {
  rightEdgeComponent.value = markRaw(RegionMessagesContainer);
  mainContent.value = props.region;
}
</script>
