<template>
  <div class="h-full flex flex-col">
    <WePreviewModeBanner v-if="activeOption.previewMode" />
    <div class="h-full flex-grow">
      <ExpandedWeShell
        v-if="expandedView"
        :selection-component="selectionComponent"
      />
      <CompactWeShell v-else :selection-component="selectionComponent" />
    </div>
  </div>
</template>

<script setup>
import WePreviewModeBanner from "@/components/we/WePreviewModeBanner.vue";
import CompactWeShell from "@/components/we/CompactWeShell.vue";
import ExpandedWeShell from "@/components/we/ExpandedWeShell.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";

defineProps(["selectionComponent"]);

const wePanelStore = useWePanelStore();
const { expandedView, activeOption } = storeToRefs(wePanelStore);
</script>
