<template>
  <div
    v-if="!compactStyle && (propertyIdParam || refetchable)"
    class="overflow-hidden bg-gray-100 rounded-lg p-3 shadow"
    :class="[
      hasOutputs && fullyLicensed && !interceptCalculation ? 'col-span-1' : '',
    ]"
  >
    <ProgressBar
      v-if="fetchProgress"
      :value="fetchProgress"
      pt:root="h-auto bg-teal-200 rounded-md"
      pt:value="bg-teal-600 rounded-l-md"
      pt:label="text-teal-100 text-[10px] w-full text-center truncate"
      >{{ fetchLabel }}</ProgressBar
    >
    <dt class="truncate text-sm font-medium text-gray-500">Sizes & Uses</dt>
    <dd class="mt-1 text-2xl font-semibold tracking-tight text-gray-900">
      <DataField
        calculation-name="sizes_uses"
        :calculation-option-data="calculationOptions"
        :intercept-calculation="interceptCalculation"
        :fully-licensed="fullyLicensed"
        :calculation-value="outputData?.standardizedArea"
        :calculation-content-type="calculationContentType"
        :calculation-content-ids="calculationContentIds"
        :calculation-is-survey="!standaloneContentType"
        :text-classes="
          compactStyle
            ? 'text-sm font-semibold tracking-tight'
            : 'text-xl font-semibold tracking-tight'
        "
        :analyze="true"
      />
    </dd>
    <CalculationWarning
      v-if="hasOutputs && outputData?.warnings.length > 0"
      :warning-data="outputData?.warnings"
      class="mt-1"
    />
  </div>
  <div
    v-else-if="
      compactStyle &&
      (propertyIdParam || refetchable) &&
      (_.size(outputData?.useTypes) > 1 || !outputData)
    "
    class="overflow-hidden bg-gray-100 rounded-md p-2"
    :class="[
      hasOutputs && fullyLicensed && !interceptCalculation ? 'col-span-1' : '',
    ]"
  >
    <dt class="truncate text-xs font-medium text-gray-500">Total</dt>
    <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-900">
      <DataField
        calculation-name="sizes_uses"
        :calculation-option-data="calculationOptions"
        :intercept-calculation="interceptCalculation"
        :fully-licensed="fullyLicensed"
        :calculation-value="outputData?.standardizedArea"
        :calculation-content-type="calculationContentType"
        :calculation-content-ids="calculationContentIds"
        :calculation-is-survey="!standaloneContentType"
        text-classes="text-sm font-semibold tracking-tight"
        :analyze="true"
      />
    </dd>
    <CalculationWarning
      v-if="hasOutputs && outputData?.warnings.length > 0"
      :warning-data="outputData?.warnings"
      class="mt-1"
    />
  </div>
  <div
    v-for="useType in outputData?.useTypes"
    :key="useType.name"
    :class="[
      compactStyle
        ? 'rounded-md p-2'
        : 'col-span-1 rounded-lg px-3 py-4 shadow',
    ]"
    class="overflow-hidden bg-gray-100"
  >
    <dt
      :class="[
        compactStyle ? 'text-xs' : 'text-sm',
        useType.excluded ? 'line-through decoration-2 decoration-red-500' : '',
      ]"
      class="truncate font-medium text-gray-500"
      v-tooltip="
        useType.excluded ? 'SF excluded (adjust via calculation options)' : ''
      "
    >
      {{ useType.name }}
    </dt>
    <template v-if="useType.useBasedAreaType">
      <dd class="mt-1">
        <DataField
          :calculation-name="`${useType.name}_use_based_area`"
          :calculation-value="useType.useBasedArea"
          :bundle-field-ids="useType.useBasedBundleFieldIds"
          :text-classes="
            compactStyle
              ? 'text-sm font-semibold tracking-tight'
              : 'text-xl font-semibold tracking-tight'
          "
          :analyze="true"
        />
      </dd>
      <dd v-if="!useType.excluded" class="mt-0.5">
        <DataField
          :calculation-name="`${useType.name}_standardized_area`"
          :calculation-value="useType.standardizedArea"
          :bundle-field-ids="useType.standardizedBundleFieldIds"
          :text-classes="
            compactStyle
              ? 'text-xs font-medium tracking-tight'
              : 'font-medium tracking-tight'
          "
          :analyze="true"
        />
      </dd>
    </template>
    <template v-else>
      <dd v-if="!useType.excluded" class="mt-1">
        <DataField
          :calculation-name="`${useType.name}_standardized_area`"
          :calculation-value="useType.standardizedArea"
          :bundle-field-ids="useType.standardizedBundleFieldIds"
          :text-classes="
            compactStyle
              ? 'text-sm font-semibold tracking-tight'
              : 'text-xl font-semibold tracking-tight'
          "
          :analyze="true"
        />
      </dd>
      <dd
        v-if="
          !useType.excluded &&
          standaloneContentType === 'SpaceAvailability' &&
          useType.numericMinimumSubdivisionArea !==
            useType.numericStandardizedArea
        "
        class="mt-0.5"
      >
        <DataField
          :calculation-name="`${useType.name}_minimum_subdivision_standardized_area`"
          :calculation-value="`Subdivides to ${useType.minimumSubdivisionArea}`"
          :bundle-field-ids="useType.minimumSubdivisionBundleFieldIds"
          :text-classes="
            compactStyle
              ? 'text-xs font-medium tracking-tight'
              : 'font-medium tracking-tight'
          "
          :analyze="true"
        />
      </dd>
    </template>
    <CalculationWarning
      v-if="useType.warnings.length > 0"
      :warning-data="useType.warnings"
      class="mt-1"
    />
  </div>
</template>

<script setup>
import ProgressBar from "primevue/progressbar";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useMainMapStore } from "@/stores/mainMap";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCalculationFieldsStore } from "@/stores/calculationFields";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import sizeUseOutputs from "@/components/analyze/calculations/sizesUses";
import { computed, watch, onMounted } from "vue";
import _ from "lodash";
import CalculationWarning from "@/components/analyze/calculations/CalculationWarning.vue";

const props = defineProps([
  "standaloneContentType",
  "standaloneContentIds",
  "standaloneContentDataField",
  "context",
]);
const emit = defineEmits(["numeric-area"]);

const mapStore = useMainMapStore();
const { refreshing, zoom, propertyZoomTrigger, nearbyPropertyDataFields } =
  storeToRefs(mapStore);
const timeTravelStore = useTimeTravelStore();
const { combinedSpaceAvailabilityIds, asOfMilliseconds } =
  storeToRefs(timeTravelStore);
const companyDetailStore = useCompanyDetailStore();
const { companyIdParam, companyFetchedLocationDataFields } =
  storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const { contactIdParam, contactFetchedLocationDataFields } =
  storeToRefs(contactDetailStore);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  propertyIdParam,
  propertyDataField,
  propertyDiagramPropertyIds,
  propertyDiagramSelectedPropertyId,
  propertyDiagramPropertyDataFields,
} = storeToRefs(propertyDiagramStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  calculationData,
  calculationOptions,
  latLngMismatch,
  timeMismatch,
  zoomMismatch,
  checkedCategories,
  combinedFilteredPropertyIds,
  combinedFilteredSpaceUsageIds,
  focalDataField,
  unselectedPropertyDiagram,
  propertySelectedPropertyDiagram,
  sizesSurveyFetched,
  spaceAvailabilitiesChecked,
  spaceUsagesChecked,
  combinedFilteredSpaceAvailabilityIds,
} = storeToRefs(analyzePanelStore);
const calculationFieldsStore = useCalculationFieldsStore();

const compactStyle = computed(
  () => props.standaloneContentType && props.standaloneContentIds,
);
const calculationKey = computed(() => `sizes_uses_${calculationContentType.value}_${calculationContentIds.value}_${asOfMilliseconds.value}`)
const fetchProgress = computed(() => {
  return calculationFieldsStore.fetchProgressFor(calculationKey.value);
});
const fetchLabel = computed(() => {
  return calculationFieldsStore.fetchLabelFor(calculationKey.value);
});
const includeAirRights = computed(
  () => calculationOptions.value.sizesUses.includeAirRights,
);
const dataEmptyState = {
  buildingCount: {
    interceptableFields: [],
    unlicensedFields: [],
    mapGroupingDataFields: [],
    mapLandCoveringDataFields: [],
    propertyRightLandCoveringDataFields: [],
  },
  sizesUses: {
    fetchRequests: [],
    interceptableFields: [],
    unlicensedFields: [],
    floorAreaDataFields: [],
    propertyUseDataFields: [],
    standardizedAreaDataFields: [],
    useBasedAreaDataFields: [],
  },
};

const scopedCalculationData = computed(() => {
  const needsScope =
    _.includes(
      ["Property", "LandCovering", "SpaceUsage", "SpaceAvailability"],
      props.standaloneContentType,
    ) || checkedCategories.value.length > 0;

  if (needsScope) {
    let scopeIds = [];

    if (props.standaloneContentType === "Property") {
      scopeIds = props.standaloneContentIds;
    } else if (
      _.includes(
        ["LandCovering", "SpaceUsage", "SpaceAvailability", "SpaceUsage"],
        props.standaloneContentType,
      )
    ) {
      const requestKey = `${
        props.standaloneContentType
      }_${props.standaloneContentIds.join("_")}`;
      scopeIds = analyzePanelStore
        .alreadyFetchedFieldIdsFor("sizesUses", requestKey)
        .map(({ localId }) => localId);
    } else if (checkedCategories.value.length > 0) {
      scopeIds = combinedFilteredPropertyIds.value;
    } else if (propertyIds.value.length > 0) {
      scopeIds = propertyIds.value;
    }

    let scopedFloorAreas = [];

    if (
      _.includes(
        ["SpaceAvailability", "SpaceUsage"],
        props.standaloneContentType,
      ) &&
      props.standaloneContentDataField
    ) {
      if (
        props.standaloneContentDataField.fieldContentType ===
        "SpaceAvailabilityGroup"
      ) {
        const isFloorArea = _.every(
          props.standaloneContentDataField.fieldContent.availabilities,
          function ({ topLevelSpace }) {
            const checkType =
              topLevelSpace?.fieldContentType || topLevelSpace?.recordType;
            return checkType === "FloorArea";
          },
        );

        if (isFloorArea) {
          const spaceIds =
            props.standaloneContentDataField.fieldContent.availabilities.map(
              ({ topLevelSpace }) =>
                topLevelSpace.fieldContentId || topLevelSpace.id,
            );
          scopedFloorAreas =
            calculationData.value?.sizesUses?.floorAreaDataFields?.filter(
              (field) => {
                return _.includes(spaceIds, field.fieldContentId);
              },
            );
        }
      } else {
        const space = props.standaloneContentDataField.fieldContent?.space;
        let spaceType = null;

        if (space?.fieldContentSubType === "subordinate_space") {
          spaceType = space.fieldContent?.spaceType;
        } else {
          spaceType = space?.fieldContentType || space?.recordType;
        }

        if (spaceType === "FloorArea") {
          let spaceId = null;

          if (space?.fieldContentSubType === "subordinate_space") {
            spaceId = space.fieldContent?.spaceId;
          } else {
            spaceId = space?.fieldContentId || space?.id;
          }
          scopedFloorAreas =
            calculationData.value?.sizesUses?.floorAreaDataFields?.filter(
              (field) => {
                return _.includes([spaceId], field.fieldContentId);
              },
            );
        }
      }
    } else if (
      _.includes(["LandCovering", "SpaceUsage"], props.standaloneContentType)
    ) {
      scopedFloorAreas =
        calculationData.value?.sizesUses?.floorAreaDataFields?.filter(
          (field) => {
            return _.includes(scopeIds, field.localId);
          },
        );
    } else {
      scopedFloorAreas =
        calculationData.value?.sizesUses?.floorAreaDataFields?.filter(
          (field) => {
            return _.includes(scopeIds, field.fieldContent.propertyId);
          },
        );
    }

    const matchFloorAreas = (field) => {
      return (
        field.decoratingContentType === "FloorArea" &&
        _.includes(
          scopedFloorAreas.map((field) => field.fieldContentId),
          field.decoratingContentId,
        )
      );
    };
    const scopedPropertyUses =
      calculationData.value?.sizesUses?.propertyUseDataFields?.filter(
        matchFloorAreas,
      );
    const scopedStandardizedAreas =
      calculationData.value?.sizesUses?.standardizedAreaDataFields?.filter(
        matchFloorAreas,
      );
    const scopedUseBasedAreas =
      calculationData.value?.sizesUses?.useBasedAreaDataFields?.filter(
        matchFloorAreas,
      );
    const allFields = _.concat(
      scopedFloorAreas,
      scopedPropertyUses,
      scopedStandardizedAreas,
      scopedUseBasedAreas,
    );

    return {
      buildingCount: calculationData.value?.buildingCount,
      sizesUses: {
        interceptableFields: analyzePanelStore.unlockableFieldsFor(allFields),
        unlicensedFields: analyzePanelStore.unlicensedFieldsFor(allFields),
        floorAreaDataFields: scopedFloorAreas,
        propertyUseDataFields: scopedPropertyUses,
        standardizedAreaDataFields: scopedStandardizedAreas,
        useBasedAreaDataFields: scopedUseBasedAreas,
      },
    };
  } else if (sizesSurveyFetched.value) {
    return calculationData.value;
  } else {
    return dataEmptyState;
  }
});

const refetchable = computed(() => {
  if (companyIdParam.value || contactIdParam.value) {
    return true;
  } else if (!propertyIdParam.value) {
    return zoom.value >= propertyZoomTrigger.value;
  } else {
    return false;
  }
});
const fetchedPropertyCollection = computed(() => {
  if (companyIdParam.value) {
    return companyFetchedLocationDataFields.value;
  } else if (contactIdParam.value) {
    return contactFetchedLocationDataFields.value;
  } else {
    return nearbyPropertyDataFields.value;
  }
});
const calculationContentType = computed(() => {
  if (props.standaloneContentType) {
    return props.standaloneContentType;
  } else if (spaceAvailabilitiesChecked.value) {
    return "SpaceAvailability";
  } else if (spaceUsagesChecked.value) {
    return "SpaceUsage";
  } else if (focalDataField.value) {
    return focalDataField.value.fieldContentType;
  } else {
    return "Property";
  }
});
const calculationContentIds = computed(() => {
  if (props.standaloneContentIds) {
    return props.standaloneContentIds;
  } else if (spaceAvailabilitiesChecked.value) {
    return _.intersection(
      combinedSpaceAvailabilityIds.value,
      combinedFilteredSpaceAvailabilityIds.value,
    );
  } else if (spaceUsagesChecked.value) {
    return combinedFilteredSpaceUsageIds.value;
  } else if (focalDataField.value) {
    return _.compact([
      focalDataField.value.fieldContentId,
      includeAirRights.value
        ? focalDataField.value.fieldContent.airLayerPropertyId
        : null,
    ]);
  } else if (propertySelectedPropertyDiagram.value) {
    return _.compact([
      propertyDataField.value.fieldContentId,
      includeAirRights.value
        ? propertyDataField.value.fieldContent.airLayerPropertyId
        : null,
    ]);
  } else if (unselectedPropertyDiagram.value) {
    const propertyFields = propertyDiagramPropertyDataFields.value;
    const propertyIds = _.compact(
      _.map(propertyFields, function ({ fieldContentId }) {
        return fieldContentId;
      }),
    );
    const airLayerIds = includeAirRights.value
      ? _.compact(
          _.map(propertyFields, function (df) {
            return df.fieldContent.airLayerPropertyId;
          }),
        )
      : [];
    return _.concat(propertyIds, airLayerIds);
  } else {
    return propertyIds.value;
  }
});
const propertyIds = computed(() => {
  if (propertyIdParam.value) {
    return propertyDiagramPropertyIds.value;
  } else {
    const airGroundIds = _.map(fetchedPropertyCollection.value, function (df) {
      return _.compact([
        df.decoratingContentId,
        includeAirRights.value ? df.fieldContent?.airLayerPropertyId : null,
      ]);
    });

    return _.flatten(airGroundIds);
  }
});
const hasOutputs = computed(() => {
  return _.some(scopedCalculationData.value.sizesUses, function (fields) {
    return !_.isEmpty(fields);
  });
});
const interceptCalculation = computed(() => {
  return scopedCalculationData.value.sizesUses?.interceptableFields?.length > 0;
});
const fullyLicensed = computed(() => {
  return scopedCalculationData.value.sizesUses?.unlicensedFields?.length === 0;
});
const outputData = computed(() => {
  if (hasOutputs.value && fullyLicensed.value && !interceptCalculation.value) {
    return sizeUseOutputs(
      calculationContentType.value,
      scopedCalculationData.value,
      calculationOptions.value,
    );
  } else {
    return null;
  }
});

watch(
  combinedFilteredPropertyIds,
  () => {
    if (checkedCategories.value.length > 0) {
      debouncedRefetchData();
    }
  },
  { deep: true },
);

watch(
  combinedFilteredSpaceAvailabilityIds,
  () => {
    if (checkedCategories.value.length > 0) {
      debouncedRefetchData();
    }
  },
  { deep: true },
);

watch(
  combinedFilteredSpaceUsageIds,
  () => {
    if (checkedCategories.value.length > 0) {
      debouncedRefetchData();
    }
  },
  { deep: true },
);

watch(propertyDiagramSelectedPropertyId, () => {
  debouncedRefetchData();
});

watch(focalDataField, () => {
  debouncedRefetchData();
});

watch(latLngMismatch, (val) => {
  if (val) debouncedRefetchData();
});

watch(timeMismatch, (val) => {
  if (val) debouncedRefetchData();
});

watch(zoomMismatch, async (val) => {
  if (val) debouncedRefetchData();
});

watch(
  () => props.standaloneContentIds,
  (arr, oldArr) => {
    if (arr && oldArr && !_.isEqual(arr, oldArr)) debouncedRefetchData();
  },
);
watch(propertyIdParam, (val, oldVal) => {
  if (val && !oldVal) debouncedRefetchData();
});
watch(
  outputData,
  () => {
    emitArea();
  },
  { deep: true },
);

watch(refreshing, (val, oldVal) => {
  if (!propertyIdParam.value && !val && oldVal) {
    debouncedRefetchData();
  }
});

onMounted(() => {
  if (!compactStyle.value || props.context === "availability-group-cashflow")
    debouncedRefetchData();

  emitArea();
});

function emitArea() {
  if (
    _.includes(
      ["SpaceUsage", "SpaceAvailability"],
      props.standaloneContentType,
    ) &&
    outputData.value?.numericStandardizedArea
  ) {
    emit("numeric-area", outputData.value?.numericStandardizedArea);
  }
}

const debouncedRefetchData = _.debounce(function () {
  refetchData();
}, 2000);

function refetchData() {
  calculationData.value.sizesUses = {
    fetchRequests: [],
    floorAreaDataFields: [],
    propertyUseDataFields: [],
    standardizedAreaDataFields: [],
    useBasedAreaDataFields: [],
  };
}
</script>
