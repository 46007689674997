<template>
  <HeaderContainer>
    <header class="flex items-center">
      <div class="text-gray-700 font-semibold">{{ mainContent.name || mainContent }}</div>
    </header>
  </HeaderContainer>
</template>

<script setup>
import HeaderContainer from "@/components/we/main-content/HeaderContainer.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { ref, markRaw } from "vue";

const wePanelStore = useWePanelStore();
const { mainContent } = storeToRefs(wePanelStore);
</script>
