<template>
  <div v-if="subsidizingTeam" class="rounded-md bg-green-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-green-800">Team billing</h3>
        <div class="mt-2 text-sm text-green-700">
          <p>
            {{ subsidizingTeam.name }} is paying for your use of Tower Hunt.
          </p>
        </div>
        <div class="mt-4">
          <div class="-mx-2 -my-1.5 flex">
            <button
              @click="viewTeam"
              type="button"
              class="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
            >
              View teams
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CheckCircleIcon } from "@heroicons/vue/20/solid";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/router/api";

const subsidizingTeam = ref(null);

onMounted(() => fetchTeamSubsidyStatus());

async function fetchTeamSubsidyStatus() {
  api.get(`team_subsidy_statuses`).then((json) => {
    subsidizingTeam.value = json.data;
  });
}

const route = useRoute();
const router = useRouter();

function viewTeam() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "We",
      verticalTab: "Teams",
    },
  });
}
</script>
