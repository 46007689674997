import { ref, computed, toRaw } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { createChannel, removeChannel } from "@/channels/channel";
import _ from "lodash";
import api from "@/router/api";

export const usePropertyUsersChannelsStore = defineStore(
  "propertyUsersChannel",
  () => {
    const userStore = useUserStore();
    const { currentUser, signedIn } = storeToRefs(userStore);
    const propertyDiagramStore = usePropertyDiagramStore();
    const propertyChannelSubscriptions = ref({});

    const editingBorderColors = [
      "sky",
      "teal",
      "lime",
      "amber",
      "orange",
      "pink",
    ];

    const editingActions = computed(() => {
      const allActions = _.flatMap(
        propertyChannelSubscriptions.value,
        function (channelObject, id) {
          return channelObject.editingActions || [];
        },
      );

      return _.uniqBy(allActions, "userId");
    });

    const editingSelections = computed(() => {
      const allSelections = _.flatMap(
        propertyChannelSubscriptions.value,
        function (channelObject, id) {
          return channelObject.editingSelections || [];
        },
      );

      return _.uniqBy(allSelections, "userId");
    });

    const users = computed(() => {
      const allUsers = _.flatMap(
        propertyChannelSubscriptions.value,
        function (channelObject, id) {
          return channelObject.users || [];
        },
      );

      const unique = _.uniqBy(allUsers, "userId");

      return _.sortBy(unique, ["sortOrder", "userId"]);
    });

    const editingUsers = computed(() =>
      users.value
        .filter(({ status }) => status === "editing")
        .map((user, index) => {
          return _.merge({}, user, {
            borderColor: editingBorderColors[index % 6],
          });
        }),
    );

    const thirdPartyEditingUsersCount = computed(() =>
      _.size(
        editingUsers.value.filter(
          ({ userId }) => userId !== currentUser.value?.id,
        ),
      ),
    );

    async function unsubscribeFromProperties() {
      for (const [id, { channel }] of Object.entries(
        propertyChannelSubscriptions.value,
      )) {
        unsubscribeFrom(id, channel);
      }
    }

    function unsubscribeFrom(id, channel) {
      const rawChannel = toRaw(channel);
      channel.perform("disappear", { propertyId: id });
      channel.uninstall();
      removeChannel(rawChannel);
      selfDestruct(id);
    }

    function subscribeToProperty(propertyId) {
      if (signedIn.value && !propertyChannelSubscriptions.value[propertyId]) {
        propertyChannelSubscriptions.value[propertyId] = {
          channel: null,
          users: [],
          editingActions: [],
          editingSelections: [],
          status: "here",
        };

        const channel = createChannel(
          {
            channel: "PropertyUsersChannel",
            propertyId,
          },
          {
            initialized() {
              this.update = this.update.bind(this);
            },
            connected() {
              this.install();
              this.update();
            },
            async received(data) {
              // console.log("received", data.action);
              // HANDLE CHANNEL ACTIONS
              if (
                data?.action === "origination_successful" &&
                data.propertyId &&
                data.userId
              ) {
                const focalChannel =
                  propertyChannelSubscriptions.value[data.propertyId];
                propertyDiagramStore.postEditingPatch(data.originationJson);
                const filteredActions = focalChannel.editingActions.filter(
                  ({ userId }) => {
                    return userId !== data.userId;
                  },
                );
                const filteredSelections =
                  focalChannel.editingSelections.filter(({ userId }) => {
                    return userId !== data.userId;
                  });

                focalChannel.editingActions = filteredActions;
                focalChannel.editingSelections = filteredSelections;
              } else if (
                data?.action === "update_diagram_added" &&
                data.propertyId &&
                data.userId
              ) {
                const focalChannel =
                  propertyChannelSubscriptions.value[data.propertyId];
                const focalEditingAction = _.find(focalChannel.editingActions, {
                  userId: data.userId,
                });

                if (focalEditingAction) {
                  focalEditingAction.propertyDiagramAdded =
                    data.propertyDiagramAdded;
                }
              } else if (
                data?.action === "diagram_content_selected" &&
                data.propertyId &&
                data.userId
              ) {
                const focalChannel =
                  propertyChannelSubscriptions.value[data.propertyId];
                const focalEditingSelection = _.find(
                  focalChannel.editingSelections,
                  {
                    userId: data.userId,
                  },
                );

                if (focalEditingSelection) {
                  focalEditingSelection.propertyDiagramSelected =
                    data.propertyDiagramSelected;
                  focalEditingSelection.propertyDiagramSelectedParcelId =
                    data.propertyDiagramSelectedParcelId;
                  focalEditingSelection.propertyDiagramSelectedLandCoveringId =
                    data.propertyDiagramSelectedLandCoveringId;
                } else {
                  const selectionData = {
                    userId: data.userId,
                    propertyId: data.propertyId,
                    action: data.action,
                    propertyDiagramSelectedParcelId:
                      data.propertyDiagramSelectedParcelId,
                    propertyDiagramSelectedLandCoveringId:
                      data.propertyDiagramSelectedLandCoveringId,
                    propertyDiagramSelected: data.propertyDiagramSelected,
                  };
                  focalChannel.editingSelections.push(selectionData);
                }
              } else if (
                data?.action === "action_selected" &&
                data.propertyId
              ) {
                const focalChannel =
                  propertyChannelSubscriptions.value[data.propertyId];

                switch (data.propertyDiagramActionTrigger.action) {
                  case "cancelDiagramAdding": {
                    const filteredActions = focalChannel.editingActions.filter(
                      ({ userId }) => {
                        return userId !== data.userId;
                      },
                    );
                    const filteredSelections =
                      focalChannel.editingSelections.filter(({ userId }) => {
                        return userId !== data.userId;
                      });

                    focalChannel.editingActions = filteredActions;
                    focalChannel.editingSelections = filteredSelections;
                    break;
                  }
                  default: {
                    const actionData = {
                      userId: data.userId,
                      propertyId: data.propertyId,
                      action: data.action,
                      focalAction: data.focalAction,
                      propertyDiagramActionTrigger:
                        data.propertyDiagramActionTrigger,
                      propertyDiagramAddedTypes: data.propertyDiagramAddedTypes,
                      propertyDiagramAdded: data.propertyDiagramAdded,
                      propertyDiagramAdding: data.propertyDiagramAdding,
                      propertyDiagramEditAction: data.propertyDiagramEditAction,
                      selectedPropertyIsAirLayer:
                        data.selectedPropertyIsAirLayer,
                    };
                    const selectionData = {
                      userId: data.userId,
                      propertyId: data.propertyId,
                      action: data.action,
                      propertyDiagramSelectedParcelId:
                        data.propertyDiagramSelectedParcelId,
                      propertyDiagramSelectedLandCoveringId:
                        data.propertyDiagramSelectedLandCoveringId,
                      propertyDiagramSelected: data.propertyDiagramSelected,
                    };
                    focalChannel.editingActions.push(actionData);
                    focalChannel.editingSelections.push(selectionData);
                    break;
                  }
                }
              }

              // REFETCH PRESENT USERS
              const usersResponse = await api.get(
                `user_appearances/Property/${propertyId}`,
              );
              if (usersResponse?.data) {
                if (propertyChannelSubscriptions.value[propertyId]?.users) {
                  propertyChannelSubscriptions.value[propertyId].users =
                    usersResponse.data;

                  for (const user of propertyChannelSubscriptions.value[
                    propertyId
                  ].users) {
                    if (user.status !== "editing") {
                      const filteredActions =
                        propertyChannelSubscriptions.value[
                          propertyId
                        ].editingActions.filter((action) => {
                          return action.userId !== user.userId;
                        });
                      const filteredSelections =
                        propertyChannelSubscriptions.value[
                          propertyId
                        ].editingSelections.filter((action) => {
                          return action.userId !== user.userId;
                        });

                      propertyChannelSubscriptions.value[
                        propertyId
                      ].editingActions = filteredActions;
                      propertyChannelSubscriptions.value[
                        propertyId
                      ].editingSelections = filteredSelections;
                    }
                  }
                }

                if (currentUser.value) {
                  const myUser = _.find(usersResponse.data, {
                    userId: currentUser.value.id,
                  });
                  if (
                    myUser?.status &&
                    propertyChannelSubscriptions.value[propertyId]?.status
                  ) {
                    propertyChannelSubscriptions.value[propertyId].status =
                      myUser.status;
                  }
                }
              }
            },
            // Called when the WebSocket connection is closed.
            disconnected() {
              this.perform("disappear", { propertyId });
              this.uninstall();
              selfDestruct(propertyId);
            },

            // Called when the subscription is rejected by the server.
            rejected() {
              this.perform("disappear", { propertyId });
              this.uninstall();
              selfDestruct(propertyId);
            },

            update() {
              const active =
                document.visibilityState === "visible" && document.hasFocus();
              active ? this.appear() : this.away();
            },
            appear(override = false) {
              // Calls `appear(data)` on the server.
              if (
                propertyChannelSubscriptions.value[propertyId]?.status !==
                  "editing" ||
                override
              ) {
                this.perform("appear", { propertyId });
              }
            },

            away() {
              // Calls `away` on the server.
              if (
                propertyChannelSubscriptions.value[propertyId]?.status !==
                "editing"
              ) {
                this.perform("away");
              }
            },

            editing() {
              // Calls `editing` on the server.
              this.perform("editing");
            },

            install() {
              window.addEventListener("focus", this.update);
              window.addEventListener("blur", this.update);
              document.addEventListener("visibilitychange", this.update);
            },

            uninstall() {
              window.removeEventListener("focus", this.update);
              window.removeEventListener("blur", this.update);
              document.removeEventListener("visibilitychange", this.update);
            },
          },
        );

        propertyChannelSubscriptions.value[propertyId].channel = channel;
      }
    }

    function selfDestruct(propertyId) {
      delete propertyChannelSubscriptions.value[propertyId];
    }

    return {
      propertyChannelSubscriptions,
      users,
      editingUsers,
      editingActions,
      editingSelections,
      thirdPartyEditingUsersCount,
      unsubscribeFromProperties,
      unsubscribeFrom,
      subscribeToProperty,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePropertyUsersChannelsStore, import.meta.hot),
  );
}
