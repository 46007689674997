<template>
  <div
    class="grid"
    :class="[
      expanded ? 'mb-2' : 'mb-8',
      context === 'main-map' ? 'rounded-lg bg-gray-300/75' : '',
      outerPadding,
    ]"
    style="grid-template-columns: 3rem 1fr 10%"
    :style="bottomPaddingStyle"
  >
    <div class="flex flex-col items-center justify-between">
      <div class="flex relative">
        <div class="w-12 text-gray-600 text-xs font-medium tracking-wide">
          Events
        </div>
        <InfiniteLoading
          class="absolute right-0 top-0"
          v-if="localDatedPagyContainer?.pagy?.next"
          @infinite="
            async ($state) =>
              loadTimelineEvents({
                state: $state,
                fetchDated: true,
              })
          "
        >
          <template #complete>...</template>
        </InfiniteLoading>
      </div>
      <div class="flex flex-col items-center space-y-1">
        <GridLoader
          v-if="fetchingTimeline || refreshingMainTimeline"
          :loading="true"
          size="3px"
          color="#4f46e5"
        />
        <template v-else>
          <button
            v-if="timeTravelTo"
            @click="clearTimeTravel"
            v-tooltip.right="'View as of today'"
            type="button"
            class="h-5 w-5 p-1 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-gray-700 hover:text-gray-800"
          >
            <i class="fas fa-calendar-day"></i>
          </button>
          <button
            @click="expanded = !expanded"
            v-tooltip.right="expanded ? 'Minimize' : 'Expand'"
            type="button"
            class="h-5 w-5 p-1 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-gray-700 hover:text-gray-800"
          >
            <i v-if="expanded" class="fas fa-compress" />
            <i v-else class="fas fa-expand" />
          </button>
          <button @click="getHelp" type="button" v-tooltip="'Get help'">
            <QuestionMarkCircleIcon class="h-5 w-5 text-gray-700" />
          </button>
        </template>
      </div>
    </div>

    <div
      class="px-1"
      @click.stop.prevent="viewTimeTravel"
      @mousemove.stop="setPointerDate"
      @mouseleave="clearPointerDate"
    >
      <div
        v-observe-visibility="{
          callback: setEventsAreaElement,
          once: true,
        }"
        :id="eventsAreaId"
        class="relative flex justify-end items-end w-full border-b border-r border-gray-300"
      >
        <template v-if="eventsAreaElement">
          <div class="-mb-1.5 flex flex-col space-y-1 z-0">
            <div v-for="box in heightBufferArray" :key="box" class="w-1 h-3" />
          </div>

          <div
            @mousemove.stop="clearPointerDate"
            v-for="collection in datedGroupings"
            :key="dateToString(collection.date)"
            class="absolute h-10 flex flex-col justify-end space-y-1"
            :class="_.includes(['main-map'], context) ? '-mb-1.5' : ''"
            :style="`right: ${dateToX(collection.date) - 4}px; z-index: 15;`"
          >
            <VDropdown
              v-if="collection.events.length > eventRollUpThreshold"
              class="flex-shrink-0 h-[12px]"
            >
              <button
                type="button"
                :class="`w-3 h-3 bg-indigo-400 hover:bg-indigo-500`"
              />
              <template #popper>
                <div class="w-42 p-1 flex flex-wrap space-x-1">
                  <DatedTimelineEventMarker
                    v-for="event in collection.events"
                    :key="event.localId"
                    :event="event"
                    :marker-color="markerColor(event)"
                    :context="context"
                    :date="collection.date"
                    class="flex-shrink-0"
                    @clear-marker-hover="clearMarkerHover"
                    @debounce-pulse="debouncePulse(event)"
                    @fallback-click="
                      viewEvent(event, event.date || event.fieldValue)
                    "
                  />
                </div>
              </template>
            </VDropdown>
            <DatedTimelineEventMarker
              v-else
              v-for="event in collection.events"
              :key="event.localId"
              class="flex-shrink-0 h-[12px]"
              :event="event"
              :marker-color="markerColor(event)"
              :context="context"
              :date="collection.date"
              @clear-marker-hover="clearMarkerHover"
              @debounce-pulse="debouncePulse(event)"
              @fallback-click="viewEvent(event, event.date || event.fieldValue)"
            />
          </div>

          <template v-if="pointerDate">
            <div
              class="pointer-events-none absolute -mb-2 flex flex-col justify-end"
              :style="`left: ${
                pointerDate.left - 8
              }px; z-index: 11; height: calc(${
                heightBufferArray.length
              } * 12px);`"
            >
              <div v-if="!expanded" class="relative">
                <div
                  class="absolute -right-8 mt-0.5 text-gray-50 p-2 text-center text-xs whitespace-nowrap rounded-md shadow-sm bg-gray-800"
                >
                  View as of: {{ moment(pointerDate.date).format("MMM YYYY") }}
                </div>
              </div>
            </div>
            <div
              class="pointer-events-none absolute flex flex-col justify-end"
              :style="`left: ${
                pointerDate.left - 8
              }px; z-index: 10; height: calc(${
                heightBufferArray.length
              } * 12px);`"
            >
              <div
                class="mx-2 h-full border-dashed border-l-2 border-gray-300"
                style="width: 1px"
              />
            </div>
          </template>

          <div
            v-if="futureBound && futureBound > 0"
            class="pointer-events-none absolute flex flex-col justify-end"
            :style="`right: ${dateToX(currentDate) - 8}px; height: calc(${
              heightBufferArray.length
            } * 12px);`"
          >
            <div
              :class="timeTravelTo ? 'border-indigo-400' : 'border-orange-600'"
              class="mx-2 h-full border-dashed border-l-2"
              style="width: 1px; z-index: 10"
            />
            <div v-if="!timeTravelTo" class="relative">
              <div
                class="absolute"
                :style="`top: calc(${heightBufferArray.length} * -13px); left: 15px`"
              >
                <div
                  :class="timeTravelTo ? 'text-indigo-400' : 'text-orange-600'"
                  class="whitespace-nowrap text-xs font-medium tracking-wide"
                  style="z-index: 20"
                >
                  {{ moment(currentDate).format("MMM YYYY") }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="timeTravelTo"
            class="pointer-events-none absolute flex flex-col justify-end"
            :style="`right: ${dateToX(timeTravelTo) - 8}px; height: calc(${
              heightBufferArray.length
            } * 12px);`"
          >
            <div
              class="mx-2 h-full border-dashed border-l-2 border-orange-600"
              style="width: 1px; z-index: 10"
            />
            <div class="relative">
              <div
                class="absolute"
                :style="`top: calc(${heightBufferArray.length} * -13px); left: 15px`"
              >
                <div
                  class="text-orange-600 whitespace-nowrap text-xs font-medium tracking-wide"
                  style="z-index: 20"
                >
                  Viewing: {{ moment(timeTravelTo).format("MMM YYYY") }}
                </div>
              </div>
            </div>
          </div>

          <div
            @mousemove.stop="clearPointerDate"
            class="absolute flex flex-col justify-end"
            :style="`right: 0px; z-index: 10;`"
          >
            <div class="relative">
              <div class="absolute" :style="`top: 11px; right: 0px`">
                <TimelineCustomDateField
                  v-if="uncachedCustomDate.dateType === 'customForwardDate'"
                  class="mt-6"
                  :existing-date="uncachedCustomDate.existingDate"
                  :date-type="uncachedCustomDate.dateType"
                  :time-travel-store="timeTravelStore"
                  @cancel="resetUncachedCustomDate"
                />
                <a
                  v-else
                  @click.prevent.stop="chooseCustomDate('customForwardDate')"
                  href=""
                  class="text-gray-500 hover:text-gray-900 whitespace-nowrap text-xs font-medium tracking-wide"
                  style="z-index: 20"
                  >{{ moment(newestDate).format("YYYY") }}</a
                >
              </div>
            </div>
          </div>

          <div
            @mousemove.stop="clearPointerDate"
            class="absolute flex flex-col justify-end"
            :style="`left: 0px; z-index: 10;`"
          >
            <div class="relative">
              <div class="absolute" :style="`top: 11px; left: 0px`">
                <TimelineCustomDateField
                  v-if="uncachedCustomDate.dateType === 'customLookbackDate'"
                  class="mt-6"
                  :existing-date="uncachedCustomDate.existingDate"
                  :date-type="uncachedCustomDate.dateType"
                  :time-travel-store="timeTravelStore"
                  @cancel="resetUncachedCustomDate"
                />
                <a
                  v-else
                  @click.prevent.stop="chooseCustomDate('customLookbackDate')"
                  href=""
                  class="text-gray-500 hover:text-gray-900 whitespace-nowrap text-xs font-medium tracking-wide"
                  style="z-index: 20"
                  >{{ moment(oldestDate).format("YYYY") }}</a
                >
              </div>
            </div>
          </div>

          <div
            v-for="{ label, x } in xAxisLabels().filter((y) => y.draw)"
            :key="label"
            class="absolute flex flex-col justify-end"
            :style="`right: ${x}px; z-index: 10;`"
          >
            <div class="relative">
              <div
                class="absolute w-[1.5px] h-[8px] border border-gray-500 bg-gray-500"
                :style="`top: 0px; right: 0px`"
              />
              <div class="absolute" :style="`top: 11px; right: -14px`">
                <a
                  @click.prevent
                  href=""
                  class="text-gray-500 hover:text-gray-900 whitespace-nowrap text-xs font-medium tracking-wide"
                  style="z-index: 20"
                >
                  {{ label }}
                </a>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div
        v-if="expanded"
        class="relative w-full h-24 border-dashed border-r border-gray-300"
      >
        <template v-if="pointerDate">
          <div
            class="pointer-events-none absolute h-24 flex flex-col justify-end"
            :style="`left: ${pointerDate.left - 8}px; z-index: 10;`"
          >
            <div
              class="mx-2 h-full border-dashed border-l-2 border-gray-300"
              style="width: 1px"
            />
            <div class="relative">
              <div
                class="absolute -right-8 mt-0.5 text-gray-50 p-2 text-center text-xs whitespace-nowrap rounded-md shadow-sm bg-gray-800"
              >
                {{ dateTooltip ? "New event" : "View as of" }}:
                {{ moment(pointerDate.date).format("MMM YYYY") }}
              </div>
            </div>
          </div>
        </template>

        <div
          v-if="futureBound && futureBound > 0"
          class="pointer-events-none absolute h-24 flex flex-col justify-end"
          :style="`right: ${dateToX(currentDate) - 8}px; z-index: 10;`"
        >
          <div
            :class="timeTravelTo ? 'border-indigo-400' : 'border-orange-600'"
            class="mx-2 h-full border-dashed border-l-2"
            style="width: 1px"
          />
        </div>

        <div
          v-if="timeTravelTo"
          class="pointer-events-none absolute h-24 flex flex-col justify-end"
          :style="`right: ${dateToX(timeTravelTo) - 8}px; z-index: 10;`"
        >
          <div
            class="mx-2 h-full border-dashed border-l-2 border-orange-600"
            style="width: 1px"
          />
        </div>
      </div>
    </div>

    <div class="px-1 flex flex-col justify-between">
      <VDropdown v-if="combinedUndated.length > undatedRollUpThreshold">
        <button
          type="button"
          :class="`w-3 h-3 bg-gray-500 hover:bg-indigo-600`"
        />
        <template #popper>
          <div class="w-42 p-1 flex flex-wrap space-x-1">
            <DatedTimelineEventMarker
              v-for="dataField in combinedUndated"
              :key="dataField.localId"
              :event="dataField"
              :date="null"
              marker-color="bg-gray-400 hover:bg-gray-500"
              :context="context"
              @clear-marker-hover="clearMarkerHover"
              @fallback-click="viewEvent(dataField)"
            />
          </div>
        </template>
      </VDropdown>
      <div v-else class="flex flex-wrap space-x-1" style="margin-top: 14px">
        <DatedTimelineEventMarker
          v-for="dataField in combinedUndated"
          :key="dataField.localId"
          :event="dataField"
          :date="null"
          marker-color="bg-gray-400 hover:bg-gray-500"
          :context="context"
          @clear-marker-hover="clearMarkerHover"
          @fallback-click="viewEvent(dataField)"
        />
      </div>
      <div class="mt-1 flex items-center space-x-1">
        <div class="text-gray-600 text-xs font-medium tracking-wide">
          Undated
        </div>
        <InfiniteLoading
          v-for="{ pagy, key, count, fetchDated } in combinedPagys"
          :key="key"
          @infinite="
            async ($state) =>
              loadTimelineEvents({
                state: $state,
                fetchDated,
                somePagy: pagy,
                key,
                count,
              })
          "
          ><template #complete>...</template></InfiniteLoading
        >
        <InfiniteLoading
          v-if="localUndatedPagyContainer?.pagy?.next"
          @infinite="
            async ($state) =>
              loadTimelineEvents({
                state: $state,
                fetchDated: false,
              })
          "
        >
          <template #complete>...</template>
        </InfiniteLoading>
      </div>
    </div>
  </div>
</template>

<script setup>
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { ref, computed, onMounted, watch, nextTick } from "vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useTopLevelContentFieldsStore } from "@/stores/topLevelContentFields";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useDocumentationStore } from "@/stores/documentation";
import { useNotificationsStore } from "@/stores/notifications";
import { useMainMapStore } from "@/stores/mainMap";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { timeline } from "@/assets/documentation/articles/timeline";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import DatedTimelineEventMarker from "@/components/time-travel/DatedTimelineEventMarker.vue";
import TimelineCustomDateField from "@/components/main-layout/TimelineCustomDateField.vue";
import api from "@/router/api";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import moment from "moment";
import _ from "lodash";

const props = defineProps([
  "sources",
  "forwardMonths",
  "lookbackYears",
  "context",
  "leftEdgeId",
  "mapStore",
  "timeTravelStore",
  "propertyDiagramStore",
  "dealBuilderStore",
  "analyzePanelStore",
  "documentationStore",
  "notificationsStore",
]);
const fetchingTimeline = ref(false);
const expanded = ref(false);
const uncachedCustomDate = ref({
  existingDate: null,
  dateType: null,
});
const localForwardDate = ref(null);
const localLookbackDate = ref(null);
function resetUncachedCustomDate() {
  uncachedCustomDate.value = {
    existingDate: null,
    dateType: null,
  };
}

const pointerDate = ref(null);
const eventsAreaElement = ref(null);
const dateTooltip = ref(false);
const localDatedPagyContainer = ref({
  data: [],
  pagy: null,
});
const localDated = computed({
  get() {
    return localDatedPagyContainer.value.data;
  },
  set(arr) {
    localDatedPagyContainer.value.data = arr;
  },
});
const localUndatedPagyContainer = ref({
  data: [],
  pagy: null,
});
const localUndated = computed({
  get() {
    return localUndatedPagyContainer.value.data;
  },
  set(arr) {
    localUndatedPagyContainer.value.data = arr;
  },
});

const {
  timelineFetchData,
  fetchedEventIds,
  timeTravelTo,
  chosenDate,
  hoveringMarkerField,
  refreshingMainTimeline,
  mainTimelineDated,
  mainTimelineUndated,
  mainTimelineCustomForwardDate,
  mainTimelineCustomLookbackDate,
} = storeToRefs(props.timeTravelStore || useTimeTravelStore());
const analyzePanelStore = props.analyzePanelStore || useAnalyzePanelStore();
const {
  investmentLikeFilterable,
  spaceUsageAvailabilityLikeFilterable,
  checkedCategories,
  combinedFilteredPropertyIds,
  investmentsChecked,
  loansChecked,
  companiesChecked,
  spaceAvailabilitiesChecked,
  spaceUsagesChecked,
  huntsChecked,
} = storeToRefs(analyzePanelStore);
const notificationsStore = props.notificationsStore || useNotificationsStore();
const propertyFieldsStore = usePropertyFieldsStore();
const topLevelContentFieldsStore = useTopLevelContentFieldsStore();
const companyDetailStore = useCompanyDetailStore();
const contactDetailStore = useContactDetailStore();
const layoutStore = useWorkspaceLayoutStore();
const { workspaceResized } = storeToRefs(layoutStore);
const dealStore = props.dealBuilderStore || useDealBuilderStore();
const { refetchDealBuilderEditor, crossInteraction } = storeToRefs(dealStore);
const { selectedTimelineEvent, propertyPatchableTimelineEvents } = storeToRefs(
  props.propertyDiagramStore || usePropertyDiagramStore(),
);
const {
  propertyMarkerPulseId,
  map,
  allPagysLoaded: mapPagysLoaded,
} = storeToRefs(props.mapStore || useMainMapStore());
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { refetchSpaceUsageBuilderEditor } = storeToRefs(spaceUsageBuilderStore);

const usingStore = computed(() =>
  _.includes(
    ["main-map", "company-detail", "contact-detail", "property-diagram"],
    props.context,
  ),
);
const dated = computed(() =>
  usingStore.value ? mainTimelineDated.value : localDated.value,
);
const undated = computed(() =>
  usingStore.value ? mainTimelineUndated.value : localUndated.value,
);
const customForwardDate = computed(() =>
  usingStore.value
    ? mainTimelineCustomForwardDate.value
    : localForwardDate.value,
);
const customLookbackDate = computed(() =>
  usingStore.value
    ? mainTimelineCustomLookbackDate.value
    : localLookbackDate.value,
);
const outerPadding = computed(() => {
  if (props.context === "main-map") {
    if (datedGroupings.value.length > 0) {
      return `pt-3 px-3`;
    } else {
      return "p-3";
    }
  } else {
    return "";
  }
});
const bottomPaddingStyle = computed(() => {
  if (props.context === "main-map") {
    if (datedGroupings.value.length > 0) {
      return `padding-bottom:${heightBufferArray.value.length * 0.5}rem`;
    } else {
      return "";
    }
  } else {
    return "";
  }
});
const spaceUsageBuilderContext = computed(() => {
  return props.context === "space-usage-builder-editor-container";
});
const spaceUsageBuilderAvailabilities = computed(() => {
  if (spaceUsageBuilderContext.value) {
    const spaceObject = _.head(props.sources);

    return spaceObject.availabilities || [];
  } else {
    return [];
  }
});
const datedSpaceBuilderAvailabilities = computed(() => {
  return _.filter(
    spaceUsageBuilderAvailabilities.value,
    function (availability) {
      return !!_.get(availability, "date");
    },
  );
});
const undatedSpaceBuilderAvailabilities = computed(() => {
  return _.reject(
    spaceUsageBuilderAvailabilities.value,
    function (availability) {
      return !!_.get(availability, "date");
    },
  );
});
const dealBuilderContext = computed(() => {
  return props.context === "deal-builder-editor-container";
});
const dealBuilderInvestments = computed(() => {
  if (dealBuilderContext.value) {
    const assetObject = _.head(props.sources);

    return assetObject.investments;
  } else {
    return [];
  }
});
const datedDealBuilderInvestments = computed(() => {
  return _.filter(dealBuilderInvestments.value, function (investment) {
    return !!_.get(investment, "date");
  });
});
const undatedDealBuilderInvestments = computed(() => {
  return _.reject(dealBuilderInvestments.value, function (investment) {
    return !!_.get(investment, "date");
  });
});
const combinedDated = computed(() => {
  let collection = null;
  const datedDataFields = dated.value
    .filter((event) => event.timelineDate && event.dataField)
    .map((event) => {
      if (event.dataField.fieldName === "loan_term_years") {
        return _.merge({}, event.dataField, {
          fieldImpliedDate: event.timelineDate,
        });
      } else {
        return event.dataField;
      }
    });

  if (dealBuilderContext.value) {
    collection = _.unionBy(
      datedDataFields,
      datedDealBuilderInvestments.value,
      identifyUnique,
    );
  } else if (spaceUsageBuilderContext.value) {
    collection = _.unionBy(
      datedDataFields,
      datedSpaceBuilderAvailabilities.value,
      identifyUnique,
    );
  } else {
    collection = _.uniqBy(datedDataFields, identifyUnique);
  }

  const filtered = collection.filter((df) => {
    return moment(df.fieldImpliedDate || df.date || df.fieldValue).isAfter(
      oldest.value,
    );
  });
  const analyzed = filtered.filter(analyzeIncluded);

  return analyzed;
});
const combinedUndated = computed(() => {
  const maskedDatedEvents = dated.value.filter(
    (event) => !event.timelineDate && event.dataField,
  );
  const maskedDatedDataFields = maskedDatedEvents.map(
    (event) => event.dataField,
  );
  const undatedDataFields = undated.value
    .filter((event) => event.dataField)
    .map((event) => event.dataField);

  if (dealBuilderContext.value) {
    const filtered = _.unionBy(
      maskedDatedDataFields,
      undatedDataFields,
      undatedDealBuilderInvestments.value,
      identifyUnique,
    );

    const analyzed = filtered.filter(analyzeIncluded);
    return analyzed;
  } else if (spaceUsageBuilderContext.value) {
    const filtered = _.unionBy(
      maskedDatedDataFields,
      undatedDataFields,
      undatedSpaceBuilderAvailabilities.value,
      identifyUnique,
    );

    const analyzed = filtered.filter(analyzeIncluded);
    return analyzed;
  } else {
    const filtered = _.unionBy(
      maskedDatedDataFields,
      undatedDataFields,
      identifyUnique,
    );

    const analyzed = filtered.filter(analyzeIncluded);
    return analyzed;
  }
});
function analyzeIncluded(dataField) {
  if (
    checkedCategories.value.length > 0 &&
    dataField.joiningContentType === "Property"
  ) {
    return _.includes(
      combinedFilteredPropertyIds.value,
      dataField.joiningContentId,
    );
  } else if (dataField.fieldContentType === "Investment") {
    const filteredInvestmentIncluded =
      investmentsChecked.value || loansChecked.value || companiesChecked.value
        ? analyzePanelStore.combinedFilteredInvestmentIncluded(
            dataField.fieldContentId,
          )
        : true;
    const valuationIncluded = investmentLikeFilterable.value
      ? analyzePanelStore.investmentLikeIncluded(
          dataField.fieldContentId,
          dataField.portfolioId,
        )
      : true;
    return filteredInvestmentIncluded && valuationIncluded;
  } else if (dataField.fieldContentType === "SpaceAvailability") {
    const filteredAvailabilityIncluded =
      spaceAvailabilitiesChecked.value ||
      spaceUsagesChecked.value ||
      companiesChecked.value
        ? analyzePanelStore.combinedFilteredSpaceAvailabilityIncluded(
            dataField.fieldContentId,
          )
        : true;
    const sizeIncluded = spaceUsageAvailabilityLikeFilterable.value
      ? analyzePanelStore.spaceUsageAvailabilityLikeIncluded(
          dataField.fieldContentId,
          dataField.portfolioId,
        )
      : true;
    return filteredAvailabilityIncluded && sizeIncluded;
  } else if (dataField.decoratingContentType === "Hunt") {
    const filteredHuntIncluded = huntsChecked.value
      ? analyzePanelStore.combinedFilteredHuntIncluded(
          dataField.decoratingContentId,
        )
      : true;

    return filteredHuntIncluded;
  } else {
    return true;
  }
}
const datedGroupings = computed(() => {
  const groups = _.groupBy(combinedDated.value, function (df) {
    const date = typeBasedDate(df);
    return moment(date).startOf("month").valueOf();
  });

  return _.map(groups, function (v, k) {
    return {
      date: moment(_.toNumber(k)).toDate(),
      events: _.sortBy(v, [
        function (df) {
          const date = typeBasedDate(df);
          return moment(date).valueOf();
        },
      ]),
    };
  });
});
function typeBasedDate(dataField) {
  switch (_.get(dataField, "decoratingContentType")) {
    case "Investment":
    case "SpaceAvailability":
      return _.get(dataField, "date", dataField.date);
    case "PropertyRight":
    case "LandCovering":
    case "FloorArea":
    case "PropertyEnhancement":
    case "CompanyInvolvement":
    case "Hunt":
      return dataField.fieldValue;
    case "SpaceUsage":
    case "Loan": {
      return dataField.fieldImpliedDate || dataField.fieldValue;
    }
    default:
      return dataField.date;
  }
}
const futureBound = computed(() => {
  if (customForwardDate.value) {
    return moment(customForwardDate.value)
      .endOf("month")
      .diff(moment(), "months");
  } else if (props.forwardMonths) {
    return props.forwardMonths;
  } else {
    return 60;
  }
});
const pastBound = computed(() => {
  if (customLookbackDate.value) {
    return moment().diff(
      moment(customLookbackDate.value).startOf("month"),
      "months",
    );
  } else if (props.lookbackYears) {
    return props.lookbackYears * 12;
  } else {
    return 10 * 12;
  }
});
const eventRollUpThreshold = 2;
const undatedRollUpThreshold = computed(() => {
  return expanded.value ? 6 : 3;
});
const mostDates = computed(() => {
  const maxDateObject = _.maxBy(datedGroupings.value, function (o) {
    return _.get(o, "events", []).length;
  });

  const rawMaxLength = _.get(maxDateObject, "events", []).length;

  return _.min([rawMaxLength, eventRollUpThreshold]);
});
const mostDatesBuffer = computed(() => {
  return mostDates.value === 0 ? 2 : 1;
});
const heightBufferArray = computed(() => {
  return _.range(mostDates.value + mostDatesBuffer.value);
});
const newest = computed(() => {
  return moment().add(futureBound.value, "months").endOf("month");
});
const newestDate = computed(() => {
  return newest.value.clone().toDate();
});
const oldest = computed(() => {
  return moment().subtract(pastBound.value, "months").startOf("month");
});
const oldestDate = computed(() => {
  return oldest.value.clone().toDate();
});
const totalMonths = computed(() => {
  return newest.value.clone().diff(oldest.value, "months");
});
const currentDate = computed(() => {
  return moment().toDate();
});
const sourceIds = computed(() => {
  return props.sources.map((source) => source.contentId);
});
const eventsAreaId = computed(() => {
  if (dealBuilderContext.value) {
    const assetKey = decoratingAndFieldKey(_.head(props.sources).dataField);

    return `${assetKey}-deal-builder-events-area`;
  } else if (spaceUsageBuilderContext.value) {
    const spaceKey = decoratingAndFieldKey(_.head(props.sources).dataField);

    return `${spaceKey}-space-usage-builder-events-area`;
  } else {
    return `${props.context}-events-area`;
  }
});

watch(refetchDealBuilderEditor, () => {
  if (!_.isNil(refetchDealBuilderEditor.value)) {
    fetchTimeline();
  }

  if (refetchDealBuilderEditor.value === true) {
    refetchDealBuilderEditor.value = null;
  }
});
watch(refetchSpaceUsageBuilderEditor, () => {
  if (!_.isNil(refetchSpaceUsageBuilderEditor.value)) {
    fetchTimeline();
  }

  if (refetchSpaceUsageBuilderEditor.value === true) {
    refetchSpaceUsageBuilderEditor.value = null;
  }
});
watch(sourceIds, (ary, oldAry) => {
  if (oldAry && !_.isEqual(ary, oldAry)) {
    fetchTimeline();
  }
});
watch(mainTimelineCustomForwardDate, () => {
  fetchTimeline();
});
watch(mainTimelineCustomLookbackDate, () => {
  fetchTimeline();
});
watch(mapPagysLoaded, () => {
  fetchTimeline();
});
watch(chosenDate, () => {
  const match =
    chosenDate.value?.date &&
    _.includes(
      ["customForwardDate", "customLookbackDate"],
      chosenDate.value?.dateType,
    );

  if (match) {
    if (chosenDate.value.dateType === "customForwardDate") {
      if (usingStore.value) {
        mainTimelineCustomForwardDate.value = chosenDate.value.date;
      } else {
        localForwardDate.value = chosenDate.value.date;
      }
    } else {
      if (usingStore.value) {
        mainTimelineCustomLookbackDate.value = chosenDate.value.date;
      } else {
        localLookbackDate.value = chosenDate.value.date;
      }
    }
    fetchTimeline();

    if (dealBuilderContext.value || spaceUsageBuilderContext.value) {
      setTimeout(() => {
        chosenDate.value = null;
      }, 1000);
    }
  }
});
watch(workspaceResized, () => {
  if (eventsAreaElement.value && workspaceResized.value) {
    pointerDate.value = { left: 10, date: moment().toDate() };

    setTimeout(function () {
      clearPointerDate();
    }, 100);
  }
});
watch(timeTravelTo, () => {
  establishTimelineBounds();
});
watch(propertyPatchableTimelineEvents, () => {
  if (
    props.context === "property-diagram" &&
    propertyPatchableTimelineEvents.value.length > 0
  ) {
    // console.log("patch timeline", propertyPatchableTimelineEvents.value);
    for (const timelineEvent of propertyPatchableTimelineEvents.value) {
      if (_.isNumber(timelineEvent)) {
        // id of destroyed event
        // console.log("reject deleted event id", timelineEvent);
        localDated.value = _.reject(localDated.value, function (e) {
          return e.id === timelineEvent;
        });
        localUndated.value = _.reject(localUndated.value, function (e) {
          return e.id === timelineEvent;
        });
      } else if (_.isPlainObject(timelineEvent)) {
        // console.log("merge new/updated event", timelineEvent);
        if (timelineEvent.timelineDate) {
          localDated.value = _.unionBy([timelineEvent], localDated.value, "id");
        } else {
          localUndated.value = _.unionBy(
            [timelineEvent],
            localUndated.value,
            "id",
          );
        }
      }
    }

    propertyPatchableTimelineEvents.value = [];
  }
});

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);

onMounted(async () => {
  await handleQueryView();
  establishTimelineBounds();
});

function xAxisLabels() {
  const timelineEl = document.getElementById("property-map-timeline");
  const timelineWidth = timelineEl?.offsetWidth || 9999;
  if (newest.value && oldest.value) {
    const range = newest.value.clone().year() - oldest.value.clone().year();
    if (range > 1 && timelineWidth >= 325) {
      return _.times(range, function (i) {
        const date = newest.value.clone().subtract(i, "years").startOf("year");
        return {
          label: date.year(),
          x: dateToX(date),
          draw: i !== 0,
        };
      });
    } else {
      const monthsDiff = newest.value
        .clone()
        .endOf("month")
        .diff(oldest.value.clone().endOf("month"), "months");
      const arr = _.times(monthsDiff, function (i) {
        const date = newest.value
          .clone()
          .subtract(i, "months")
          .startOf("month");
        const month = date.month();
        const showAllMonths = timelineWidth >= 600;
        const showQuarters = timelineWidth >= 280 && month % 3 === 0;
        if (showAllMonths || showQuarters) {
          return {
            label: date.format("MMM"),
            x: dateToX(date),
            draw: i !== 0,
          };
        } else {
          return null;
        }
      });

      return _.compact(arr);
    }
  } else {
    return [];
  }
}

function getHelp() {
  const documentationStore =
    props.documentationStore || useDocumentationStore();

  documentationStore.viewArticle(timeline);
}

async function setEventsAreaElement(isVisible) {
  await nextTick();
  if (isVisible && document.getElementById(eventsAreaId.value)) {
    eventsAreaElement.value = document.getElementById(eventsAreaId.value);
  }
}

async function handleQueryView() {
  return new Promise((resolve) => {
    const rawAsOf = _.get(query.value, "asOf", null);

    if (rawAsOf) {
      timeTravelTo.value = moment(_.toNumber(rawAsOf)).endOf("month").toDate();

      resolve();
    } else {
      resolve();
    }
  });
}

function establishTimelineBounds() {
  if (timeTravelTo.value && moment(timeTravelTo.value).isBefore(oldest.value)) {
    autoExtendLookBack();
  } else if (
    timeTravelTo.value &&
    moment(timeTravelTo.value).isAfter(newest.value)
  ) {
    autoExtendLookAhead();
  } else {
    fetchTimeline();
  }
}
function autoExtendLookBack() {
  if (timeTravelTo.value) {
    const date = moment(timeTravelTo.value).startOf("year").toDate();

    if (usingStore.value) {
      mainTimelineCustomLookbackDate.value = date;
    } else {
      localLookbackDate.value = date;
    }

    fetchTimeline();
  }
}
function autoExtendLookAhead() {
  if (timeTravelTo.value) {
    const date = moment(timeTravelTo.value).endOf("year").toDate();

    if (usingStore.value) {
      mainTimelineCustomForwardDate.value = date;
    } else {
      localForwardDate.value = date;
    }

    fetchTimeline();
  }
}
function identifyUnique(data) {
  switch (_.get(data, "decoratingContentType")) {
    case "Investment":
      return `${data.decoratingContentType}${data.fieldContentId}`;
    default:
      return `${data.decoratingContentType}${data.decoratingContentId}${data.fieldContentType}${data.fieldContentId}${data.fieldName}`;
  }
}
function dateToX(date) {
  const smoothedDate = moment(date).startOf("month");
  const monthsFromCurrent = newest.value.clone().diff(smoothedDate, "months");
  const percentageFromRight = monthsFromCurrent / totalMonths.value;

  return percentageFromRight * eventsAreaElement.value.offsetWidth;
}
function dateToString(date) {
  return moment(date).format("MMM YYYY");
}
function xToDate(xPosition) {
  const pxFromLeft = getTimelineX(xPosition);
  const percentageOfWidth = pxFromLeft / eventsAreaElement.value.offsetWidth;
  const percentageFromRight = 1 - percentageOfWidth;
  const monthsFromCurrent = _.round(totalMonths.value * percentageFromRight);

  return newest.value
    .clone()
    .subtract(monthsFromCurrent, "months")
    .endOf("month")
    .toDate();
}
function getTimelineX(xPosition) {
  return (
    relativeXPosition(xPosition) - (eventsAreaElement.value?.offsetLeft || 0)
  );
}
function relativeXPosition(clientX) {
  const leftEdgeElement = props.leftEdgeId
    ? document.getElementById(props.leftEdgeId)
    : null;

  if (leftEdgeElement) {
    const leftEdge = leftEdgeElement.getBoundingClientRect().left;

    return clientX - leftEdge;
  } else {
    return clientX;
  }
}
function setPointerDate(event) {
  const left = getTimelineX(event.clientX);

  if (left >= 0 && left < (eventsAreaElement.value?.offsetWidth || 0)) {
    pointerDate.value = {
      left,
      date: xToDate(event.clientX),
    };
  } else {
    clearPointerDate();
  }
}
function markerColor(dataField) {
  if (
    _.get(dataField, "investmentType") &&
    dataField.fieldContentType === "Investment"
  ) {
    switch (dataField.investmentType) {
      case "OwnershipInterest":
        return "bg-yellow-400 hover:bg-yellow-500";
      case "Loan":
        return "bg-blue-400 hover:bg-blue-500";
      default:
        return "bg-gray-500 hover:bg-gray-600";
    }
  } else {
    const removalMilestones = [
      "loan_term_years",
      "maturity_date",
      "terminated_date",
      "expired_date",
      "destroyed_date",
      "used_date",
      "demolition_proposed_date",
      "demolition_permitted_date",
      "demolished_date",
      "withdrawn_date",
      "ceased_date",
    ];
    if (_.includes(removalMilestones, _.get(dataField, "fieldName"))) {
      return "bg-gray-600 hover:bg-gray-700";
    } else if (_.get(dataField, "fieldContentType") === "Loan") {
      return "bg-gray-600 hover:bg-gray-700";
    } else if (_.get(dataField, "fieldContentType") === "SpaceAvailability") {
      return "bg-sky-400 hover:bg-sky-500";
    } else {
      switch (_.get(dataField, "decoratingContentType")) {
        case "PropertyRight":
          return "bg-amber-500 hover:bg-amber-600";
        case "LandCovering":
          return "bg-cyan-400 hover:bg-cyan-500";
        case "FloorArea":
          return "bg-emerald-400 hover:bg-emerald-500";
        case "PropertyEnhancement":
          return "bg-violet-400 hover:bg-violet-500";
        case "CompanyInvolvement":
          return "bg-teal-400 hover:bg-teal-500";
        case "Hunt":
          return "bg-rose-400 hover:bg-rose-500";
        default:
          return "bg-gray-500 hover:bg-gray-600";
      }
    }
  }
}
async function viewEvent(dataField, date) {
  const event = dataField;
  const actionableDate = dataField?.fieldImpliedDate
    ? dataField.fieldImpliedDate
    : date;
  console.log("view event", event, actionableDate, selectedTimelineEvent.value);

  if (props.context === "main-map") {
    viewDiagram(dataField);
  } else if (props.context === "company-detail") {
    companyDetailStore.findHoveringInvolvement(dataField, true);
  } else if (props.context === "contact-detail") {
    contactDetailStore.findHoveringInvolvement(dataField, true);
  } else {
    if (actionableDate && timeTravelTo.value !== actionableDate) {
      timeTravelTo.value = actionableDate;

      router.push({
        name: route.name,
        query: {
          ...route.query,
          asOf: moment(actionableDate).valueOf(),
        },
      });
      await nextTick();
    }

    switch (event.fieldContentType) {
      case "PropertyRight":
      case "Investment": {
        selectedTimelineEvent.value = {
          eventType: event.fieldContentType,
          event,
          dataField,
        };
        break;
      }
      default: {
        if (event.fieldName) {
          selectedTimelineEvent.value = {
            eventType: event.fieldName,
            dataField,
          };
        } else {
          // this.$store.dispatch("flash", "Coming soon!");
        }
      }
    }
  }
}
const debouncePulse = _.debounce(pulsePropertyMarker, 1000, { leading: true });
async function pulsePropertyMarker(dataField) {
  if (
    _.includes(
      ["main-map", "company-detail", "contact-detail"],
      props.context,
    ) &&
    dataField.localId
  ) {
    const topLevelField =
      await topLevelContentFieldsStore.fetchTopLevelContentDataField(
        dataField.outerDataFieldId,
        dataField.localId,
      );

    if (topLevelField) {
      let propertyId = null;
      if (topLevelField?.fieldContentType === "Property") {
        propertyId = topLevelField?.fieldContentId;
      } else if (
        topLevelField?.fieldContent?.propertyId ||
        topLevelField?.fieldContent?.topLevelPropertyId
      ) {
        propertyId =
          topLevelField?.fieldContent?.propertyId ||
          topLevelField?.fieldContent?.topLevelPropertyId;
      }

      if (propertyId) propertyMarkerPulseId.value = propertyId;
    }

    if (_.includes(["company-detail", "contact-detail"], props.context)) {
      hoveringMarkerField.value = dataField;
    }
  }
}
function clearMarkerHover() {
  propertyMarkerPulseId.value = null;
  hoveringMarkerField.value = null;
}
async function viewDiagram(dataField) {
  if (dataField.fieldContentType === "Investment") {
    api
      .get(`investment_deal_builder_assets/${dataField.fieldContentId}`)
      .then((json) => {
        const { assetDataField, propertyDataField } = json.data;

        dealStore.patchPropertyDataFields(propertyDataField);
        dealStore.upsertDealBuilder({
          assetDataField,
          timelineInvestmentId: dataField.fieldContentId,
          timeTravelDate: dataField?.date,
        });

        selectedTimelineEvent.value = {
          eventType: "Investment",
          event: dataField,
          dataField,
        };
      });
  } else if (dataField.fieldContentType === "SpaceAvailability") {
    const availabilityResponse = await api.get(
      `space_availability_builder_spaces/${dataField.fieldContentId}`,
    );
    if (availabilityResponse?.data) {
      const { availability, spaceDataField, propertyDataField, date } =
        availabilityResponse.data;

      propertyFieldsStore.patchPropertyDataFields(propertyDataField);
      await spaceUsageBuilderStore.upsertSpaceUsageBuilder({
        spaceDataField,
        timelineAvailabilityId: availability.id,
        timeTravelDate: date,
      });

      selectedTimelineEvent.value = {
        eventType: "SpaceAvailability",
        event: dataField,
        dataField,
      };
    }
  } else {
    const topLevelField =
      await topLevelContentFieldsStore.fetchTopLevelContentDataField(
        dataField.outerDataFieldId,
        dataField.localId,
      );
    if (topLevelField) {
      const propertyId = topLevelField.fieldContentId;
      props.propertyDiagramStore.navigateToDiagram(propertyId, true);
    }
  }
}
function viewTimeTravel() {
  if (pointerDate.value) {
    timeTravelTo.value = moment(pointerDate.value.date).endOf("month").toDate();

    crossInteraction.value = null;

    router.push({
      name: route.name,
      query: {
        ...route.query,
        asOf: moment(pointerDate.value.date).endOf("month").valueOf(),
      },
    });
  }
}
function clearTimeTravel() {
  timeTravelTo.value = null;
  crossInteraction.value = null;
  router.push({
    name: route.name,
    query: {
      ...route.query,
      asOf: undefined,
    },
  });
}
function clearPointerDate() {
  setTimeout(() => {
    pointerDate.value = null;
  }, 0);
}
function chooseCustomDate(dateType) {
  let existingDate;

  if (dateType === "customForwardDate") {
    existingDate = newestDate.value;
  } else if (dateType === "customLookbackDate") {
    existingDate = oldestDate.value;
  }

  uncachedCustomDate.value.existingDate = existingDate;
  uncachedCustomDate.value.dateType = dateType;
}
const fetchTimeline = _.debounce(function () {
  if (fetchingTimeline.value) return;
  if (usingStore.value && !mapPagysLoaded.value) {
    console.log("timeline map not set");
    return;
  }

  fetchingTimeline.value = true;
  localDatedPagyContainer.value.data = [];
  localDatedPagyContainer.value.pagy = null;
  localUndatedPagyContainer.value.data = [];
  localUndatedPagyContainer.value.pagy = null;
  fetchEvents({ fetchDated: true });
  fetchEvents({ fetchDated: false });
}, 1000);
function fetchParams(fetchDated, alreadyFetched = true) {
  const payload = {
    sources: props.sources.map(({ contentId, contentType }) => {
      return { contentId, contentType };
    }),
    oldest: fetchDated ? oldestDate.value : null,
    newest: fetchDated ? newestDate.value : null,
    holdingsOnly: props.context === "company-detail",
    alreadyFetchedEventIds:
      alreadyFetched && usingStore.value ? fetchedEventIds.value : [],
  };
  if (map.value) {
    const bounds = map.value.getBounds();
    const southwest = bounds.getSouthWest();
    const northeast = bounds.getNorthEast();
    const northeastLatLng = [northeast.lat, northeast.lng];
    const southwestLatLng = [southwest.lat, southwest.lng];
    payload.southwest = southwestLatLng;
    payload.northeast = northeastLatLng;
  }

  return payload;
}
function manageDealBuilderEvents(data) {
  const assetDataField = _.get(props.sources, "[0].dataField");
  if (dealBuilderContext.value && assetDataField) {
    const investmentEvents = data.filter((e) => {
      return (
        e.dataField?.decoratingContentType === "Investment" ||
        e.dataField?.fieldContentType === "Investment"
      );
    });
    if (investmentEvents.length > 0) {
      dealStore.updateDealBuilderAssetInvestments({
        assetKey: decoratingAndFieldKey(assetDataField),
        investments: investmentEvents.map((e) => e.dataField),
      });
    }
    dealStore.setDealBuilderAssetTimelineFetchState({
      assetKey: decoratingAndFieldKey(assetDataField),
      dated: localDated.value,
      state: true,
    });
  }
}
function manageSpaceBuilderEvents(data) {
  const assetDataField = _.get(props.sources, "[0].dataField");
  if (spaceUsageBuilderContext.value && assetDataField) {
    const spaceEvents = data.filter((e) => {
      return (
        e.dataField?.decoratingContentType === "SpaceAvailability" ||
        e.dataField?.fieldContentType === "SpaceAvailability"
      );
    });
    if (spaceEvents.length > 0) {
      console.log("space events", spaceEvents);
      spaceUsageBuilderStore.updateSpaceAvailabilities({
        spaceKey: decoratingAndFieldKey(assetDataField),
        availabilities: spaceEvents.map((e) => e.dataField),
      });
    }
    spaceUsageBuilderStore.setTimelineFetchState({
      spaceKey: decoratingAndFieldKey(assetDataField),
      dated: localDated.value,
      state: true,
    });
  }
}
async function fetchEvents({ fetchDated }) {
  const assetDataField = _.get(props.sources, "[0].dataField");

  if (dealBuilderContext.value && assetDataField) {
    dealStore.setDealBuilderAssetTimelineFetchState({
      assetKey: decoratingAndFieldKey(assetDataField),
      dated: localDated.value,
    });
  } else if (spaceUsageBuilderContext.value && assetDataField) {
    spaceUsageBuilderStore.setTimelineFetchState({
      spaceKey: decoratingAndFieldKey(assetDataField),
      dated: localDated.value,
    });
  }

  if (usingStore.value && fetchDated) refreshingMainTimeline.value = true;

  try {
    const payload = fetchParams(fetchDated);
    const keyPrefix = fetchDated ? "dated_" : "undated_";
    const json = await api.post(`timeline_events`, payload);

    const { data, pagy } = json.data;
    if (usingStore.value) {
      timelineFetchData.value[
        `${keyPrefix}${payload.southwest}_${payload.northeast}`
      ] = {
        loadingEvents: false,
        loaded: !pagy.next,
        fetchDated,
        count: 5,
        data,
        pagy,
      };
    }

    if (fetchDated) {
      localDatedPagyContainer.value.data = data;
      localDatedPagyContainer.value.pagy = pagy;
    } else {
      localUndatedPagyContainer.value.data = data;
      localUndatedPagyContainer.value.pagy = pagy;
    }

    manageDealBuilderEvents(data);
    manageSpaceBuilderEvents(data);
  } catch (error) {
    console.error(error);
  } finally {
    if (usingStore.value && fetchDated) refreshingMainTimeline.value = false;
    if (!fetchDated) fetchingTimeline.value = false;
  }
}
function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const combinedPagys = computed(() => {
  if (usingStore.value && mapPagysLoaded.value) {
    const combined = _.map(
      timelineFetchData.value,
      function ({ pagy, fetchDated, count }, key) {
        if (pagy?.next) {
          return { key, pagy, count, fetchDated };
        } else {
          return null;
        }
      },
    );

    return _.compact(combined);
  } else {
    return [];
  }
});
async function loadTimelineEvents({
  state,
  fetchDated,
  somePagy,
  key,
  count = 5,
}) {
  let contextedPagy = null;
  let existingData = null;
  let loadingEvents = false;
  if (somePagy && key) {
    existingData = timelineFetchData.value[key];
    loadingEvents = existingData.loadingEvents;
    contextedPagy = somePagy;
    console.log("timeline fetch", key);
  } else if (fetchDated) {
    contextedPagy = localDatedPagyContainer.value?.pagy;
  } else {
    contextedPagy = localUndatedPagyContainer.value?.pagy;
  }

  if (contextedPagy?.next && !loadingEvents) {
    if (existingData) existingData.loadingEvents = true;
    const endpoint = cleanUrl(contextedPagy.next_url);

    try {
      if (existingData && contextedPagy.next === 2) {
        notificationsStore.addNotification("paginatedDataLoading", 9999, {
          paginationKey: key,
          keyType: "timeline",
        });
      }
      api.post(endpoint, fetchParams(fetchDated, false)).then((json) => {
        const { data, pagy } = json.data;

        if (usingStore.value && existingData) {
          existingData.data = _.uniqBy(_.concat(existingData.data, data), "id");
          existingData.pagy = pagy;
        }

        if (fetchDated) {
          localDatedPagyContainer.value.data = _.concat(
            localDatedPagyContainer.value?.data,
            data,
          );
          localDatedPagyContainer.value.pagy = pagy;
        } else {
          localUndatedPagyContainer.value.data = _.concat(
            localUndatedPagyContainer.value?.data,
            data,
          );
          localUndatedPagyContainer.value.pagy = pagy;
        }

        manageDealBuilderEvents(data);
        manageSpaceBuilderEvents(data);
        if (data.length < count || !pagy.next) {
          state.complete();
          if (existingData) {
            existingData.loadingEvents = false;
            existingData.loaded = true;
            console.log("pagination fully complete", key);
          }
        } else {
          state.loaded();
          if (existingData) {
            existingData.loadingEvents = false;
          }
        }
      });
    } catch (error) {
      state.error();
      if (existingData) {
        existingData.loadingEvents = false;
      }
    }
  } else {
    state.complete();
    if (existingData) existingData.loaded = true;
  }
}
</script>

<style lang="css" scoped>
.leaflet-container a {
  @apply text-indigo-600 font-medium;
}
.leaflet-container a:hover {
  @apply text-indigo-500;
}
</style>
