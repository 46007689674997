/* global L */
import app from "@/entrypoints/application";
import { mount } from "mount-vue-component";
import UserMarker from "@/components/maps/UserMarker.vue";

export default function userMarker({
  geographyIntent,
  mapStore,
  documentationStore,
}) {
  let markerVueEl;

  const { el } = mount(UserMarker, {
    props: { geographyIntent, mapStore, documentationStore },
    app,
  });

  markerVueEl = el;

  if (markerVueEl) {
    return L.divIcon({
      className: "workspace-marker",
      html: markerVueEl,
    });
  } else {
    return null;
  }
}
