<template>
  <div v-if="displayable" class="h-full">
    <div
      v-if="
        analyze && unlockable && context === 'analyze-panel-filter-settings'
      "
      class="text-sm font-semibold"
    >
      Unlock datapoints
    </div>
    <div
      v-else-if="analyze && context === 'analyze-panel-filter-settings'"
      class="text-sm font-semibold"
    >
      Filtered Datapoints
    </div>
    <div v-if="refetchPending" class="mt-2 flex items-center space-x-2">
      <span v-if="refetchInProcess" class="text-gray-700 text-sm"
        >Updates fetching...</span
      >
      <span v-else class="text-gray-700 text-sm">Updates pending...</span>
      <SquareLoader :loading="true" size="12px" color="#0d9488" />
    </div>
    <template v-else>
      <VDropdown v-if="displayPricingContext" placement="left-start" class="">
        <button
          type="button"
          v-tooltip="
            rawFree
              ? 'Contributors earn credits from free content. Click for more detail.'
              : safezone
                ? 'Users pay to access safezone content. Click for more detail.'
                : 'Contributors earn revenue from paid content. Click for more detail.'
          "
          class="w-full group flex items-center p-2 space-x-2 hover:bg-gray-100 hover:text-gray-900"
        >
          <div
            :class="`${qualityColorFor('combined')}`"
            class="flex-shrink-0 rounded-full p-1"
          >
            <SparklesIcon class="h-3 w-3 text-white" aria-hidden="true" />
          </div>
          <span class="text-gray-700 text-xs font-semibold"
            >{{ combinedDataGrade }}x Base
            {{ rawFree ? "Credits of $0.001" : "Pricing of $0.005" }}</span
          >
        </button>
        <template #popper>
          <div class="w-64 flex flex-col">
            <div class="space-y-2">
              <div class="px-2 pt-2 flex items-center justify-between">
                <h1 class="text-xs font-semibold text-gray-700">
                  Pricing Details
                </h1>
                <a
                  @click.prevent="pricingHelp"
                  href=""
                  class="whitespace-nowrap text-xs font-medium text-indigo-700 hover:text-indigo-600"
                >
                  Learn more
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
              <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                <li
                  v-for="variable in applicablePricingVariables"
                  v-tooltip="variable.description"
                  :key="variable.id"
                >
                  <div
                    class="flex min-w-0 flex-1 items-center p-1 space-x-2 hover:bg-gray-50"
                  >
                    <component
                      :is="variable.icon"
                      :class="`h-4 w-4 ${qualityColorFor(variable)}`"
                      aria-hidden="true"
                    />
                    <div class="min-w-0 flex-1">
                      <p class="truncate text-xs font-medium text-gray-700">
                        {{ variable.name }}:
                        {{ gradeLabelFor(variable) }}&nbsp;&mdash;&nbsp;{{
                          variableGradeFor(variable.name)
                        }}x
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </VDropdown>
      <VDropdown
        v-if="attributionCount && !safezone"
        :disabled="attributions.length === 0"
        placement="left-start"
        class=""
      >
        <button
          type="button"
          v-tooltip="
            attributions.length > 0
              ? 'Click to see the types of users earning money and credits from this data.'
              : ''
          "
          :class="displayPricingContext || calculationName ? 'px-2' : ''"
          class="w-full text-gray-700 group flex items-center py-1 text-xs hover:bg-gray-100 hover:text-gray-900"
          data-test="add-task-button"
        >
          <UserGroupIcon
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
          />
          <div class="flex flex-grow items-center justify-between">
            <div class="font-semibold mr-2">{{ attributionCount }}</div>
            <div v-if="bundles.length > 0" class="">All fields</div>
          </div>
        </button>
        <template #popper>
          <ul class="max-h-64 overflow-y-auto divide-y divide-gray-200">
            <DataFieldAttribution
              v-for="attribution in groupedAttributions"
              :key="attribution.id"
              :attribution="attribution"
              :attributions="attributions"
              class="flex"
            />
          </ul>
        </template>
      </VDropdown>
      <div
        class="flex items-center space-x-2"
        :class="displayPricingContext || calculationName ? 'px-2 py-1' : ''"
      >
        <div class="flex flex-shrink-0 flex-col space-y-1">
          <CurrencyDollarIcon
            v-if="totalCount > 0 && fieldTypesLabel === 'cost'"
            v-tooltip="'Field pricing tier'"
            class="text-gray-400 w-4 h-4"
          />
          <EyeSlashIcon
            v-else-if="totalCount > 0 && fieldTypesLabel === 'visibility'"
            v-tooltip="'Field visibility'"
            class="text-gray-400 w-4 h-4"
          />
          <LockOpenIcon
            v-if="unlockable || bundles.length > 0"
            v-tooltip="unlockTooltip"
            class="text-gray-400 w-4 h-4"
          />
          <ExclamationTriangleIcon
            v-else-if="!safezone && unstaked"
            v-tooltip="'Stake data credits to proceed'"
            class="text-gray-400 w-4 h-4"
          />
        </div>
        <div class="flex flex-grow flex-col space-y-1">
          <FieldTypesBar
            v-if="totalCount > 0"
            :all-fields-data="actionableAllFieldsData"
            :field-types-label="fieldTypesLabel"
            class="w-full"
            style="height: 20px"
            @set-field-types-label="setFieldTypesLabel"
          />
          <ProgressBar
            v-if="!unlockable && bundles.length > 0"
            :all-fields-data="actionableAllFieldsData"
            class="w-full"
            style="height: 20px"
          />
          <CrowdsourcingUnlocker
            v-else-if="unlockable"
            :context="context"
            :user-store="userStore"
            :modal-store="modalStore"
            :unlocker-store="unlockerStore"
            :height-override="20"
            :icon-override="13"
            :data-field="dataField"
            :timing-fields="timingFields"
            @unlocked="unlocked"
            @reset-displayable="resetDisplayable"
          />
        </div>

        <UnlockBundles
          v-if="bundlesExist"
          :context="context"
          :calculation-name="calculationName"
          :data-field="dataField"
          :bundles="bundles"
          :all-fields-data="actionableAllFieldsData"
          :user-store="userStore"
          :modal-store="modalStore"
          :unlocker-store="unlockerStore"
          :analyze="analyze"
          @unlocked="unlocked"
          @reset-displayable="resetDisplayable"
        />
      </div>
      <ProveForValue
        v-if="challengeable"
        :class="displayPricingContext || calculationName ? 'px-2 py-1' : ''"
        :data-field="dataField"
        :parent-component-save-function="parentComponentSaveFunction"
        :user-store="userStore"
        :modal-store="modalStore"
        :unlocker-store="unlockerStore"
        @completed="completed"
        @unlocked="unlocked"
      />
    </template>
  </div>
</template>

<script setup>
import {
  BookOpenIcon,
  ClockIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  EyeSlashIcon,
  LockOpenIcon,
  SparklesIcon,
  StarIcon,
  UserGroupIcon,
} from "@heroicons/vue/20/solid";
import { bundlesFor } from "@/components/crowdsourcing/unlockableBundles";
import { useAvailableValidationsChannelStore } from "@/stores/availableValidationsChannel";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { useDataSharingsChannelStore } from "@/stores/dataSharingsChannel";
import { useCalculationFieldsStore } from "@/stores/calculationFields";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useMainMapStore } from "@/stores/mainMap";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useProveForValueStore } from "@/stores/proveForValue";
import { useNotificationsStore } from "@/stores/notifications";
import CrowdsourcingUnlocker from "@/components/crowdsourcing/CrowdsourcingUnlocker.vue";
import FieldTypesBar from "@/components/crowdsourcing/FieldTypesBar.vue";
import ProgressBar from "@/components/crowdsourcing/ProgressBar.vue";
import UnlockBundles from "@/components/crowdsourcing/UnlockBundles.vue";
import DataFieldAttribution from "@/components/crowdsourcing/DataFieldAttribution.vue";
import ProveForValue from "@/components/crowdsourcing/ProveForValue.vue";
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import api from "@/router/api";
import pluralize from "pluralize";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";
import { understandPayAsYouGoPricing } from "@/assets/documentation/articles";

const props = defineProps([
  "context",
  "dataField",
  "timingFields",
  "fetchedAllFieldsData",
  "calculationName",
  "bundleFieldIds",
  "calculationContentType",
  "calculationContentIds",
  "calculationIsSurvey",
  "userStore",
  "modalStore",
  "unlockerStore",
  "documentationStore",
  "displayPricingContext",
  "parentComponentSaveFunction",
  "analyze",
]);
const emit = defineEmits(["unlocked", "completed", "unlockable"]);

const calculationFieldsStore = useCalculationFieldsStore();
const notificationsStore = useNotificationsStore();
const { asOfMilliseconds, allPagysLoaded: timelinePagysLoaded } =
  storeToRefs(useTimeTravelStore());
const analyzePanelStore = useAnalyzePanelStore();
const { fetchingAnalyzePanelData } = storeToRefs(analyzePanelStore);
const mapStore = useMainMapStore();
const { allPagysLoaded: mapPagysLoaded } = storeToRefs(mapStore);
const companyDetailStore = useCompanyDetailStore();
const {
  companyIdParam,
  companySelectedRecordDataField,
  companyAllFetchedFields,
} = storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const {
  contactIdParam,
  contactSelectedRecordDataField,
  contactAllFetchedFields,
} = storeToRefs(contactDetailStore);
const proveForValueStore = useProveForValueStore();
const { proofProps } = storeToRefs(proveForValueStore);

const fieldTypesLabel = ref("cost");
const displayable = ref(true);
const refetchPending = ref(false);
const refetchInProcess = ref(false);
const attributions = ref([]);
const allFieldsData = ref(null);
const actionableAllFieldsData = computed(() => {
  return props.fetchedAllFieldsData || allFieldsData.value;
});
const calculationData = computed(() =>
  _.get(actionableAllFieldsData.value, "calculationFields"),
);
const pricingVariables = [
  {
    id: 1,
    name: "Recency",
    description: "Recent data indicate current market conditions.",
    icon: ClockIcon,
    grades: [
      { color: "text-gray-700", multiplier: 1.0, label: "> 6 mths" },
      { color: "text-orange-500", multiplier: 1.5, label: "1-6 mths" },
      { color: "text-gray-400", multiplier: 2.0, label: "< 1 mth" },
      { color: "text-yellow-400", multiplier: 2.5, label: "Live" },
    ],
  },
  {
    id: 2,
    name: "Difficulty",
    description:
      "Data requiring deep industry knowledge carries greater weight.",
    icon: EyeSlashIcon,
    grades: [
      { color: "text-gray-700", multiplier: 1.0, label: "None" },
      { color: "text-orange-500", multiplier: 2.0, label: "Low" },
      { color: "text-gray-400", multiplier: 3.0, label: "Medium" },
      { color: "text-yellow-400", multiplier: 4.0, label: "High" },
    ],
  },
  {
    id: 3,
    name: "Sourcing",
    description: "Data backed by multiple sources carries greater weight.",
    icon: BookOpenIcon,
    grades: [
      { color: "text-gray-700", multiplier: 1.0, label: "No citations" },
      { color: "text-orange-500", multiplier: 1.5, label: "1 citation" },
      { color: "text-gray-400", multiplier: 2.0, label: "2-3 citations" },
      { color: "text-yellow-400", multiplier: 2.5, label: "> 3 citations" },
    ],
  },
  {
    id: 4,
    name: "Credibility",
    description:
      "Data contributed by reputable members carries greater weight.",
    icon: StarIcon,
    grades: [
      { color: "text-gray-700", multiplier: 1.0, label: "Establishing rep" },
      { color: "text-orange-500", multiplier: 2.0, label: "Bronze" },
      { color: "text-gray-400", multiplier: 3.0, label: "Silver" },
      { color: "text-yellow-400", multiplier: 4.0, label: "Gold" },
    ],
  },
];
const groupedAttributions = computed(() => {
  const grouped = _.uniqBy(attributions.value, function (attribution) {
    return `${attribution.userId}_${attribution.repuationValue}`;
  });

  return _.orderBy(
    grouped,
    [
      function (attribution) {
        return _.toNumber(attribution.reputationValue);
      },
    ],
    ["desc"],
  );
});
const applicablePricingVariables = computed(() => {
  if (safezone.value) {
    return pricingVariables.filter((variable) => {
      return _.includes(["Recency", "Difficulty"], variable.name);
    });
  } else {
    return pricingVariables;
  }
});
const combinedDataGrade = computed(() => {
  if (props.dataField) {
    const { recencyGrade, difficultyGrade, sourcingGrade, credibilityGrade } =
      props.dataField;

    const combined =
      _.toNumber(recencyGrade) *
      _.toNumber(difficultyGrade) *
      _.toNumber(sourcingGrade) *
      _.toNumber(credibilityGrade);

    return _.round(combined, 1);
  } else {
    return null;
  }
});
const companyMaskedFields = computed(() =>
  companyAllFetchedFields.value.filter(
    (field) =>
      field.fieldContentType === "CompanyInvolvement" && !field.unmasked,
  ),
);
const contactMaskedFields = computed(() =>
  contactAllFetchedFields.value.filter(
    (field) =>
      field.fieldContentType === "CompanyInvolvement" && !field.unmasked,
  ),
);
const detailPageOverride = computed(() => {
  if (
    companyIdParam.value &&
    companySelectedRecordDataField.value?.fieldContentType === "Company" &&
    _.size(companyMaskedFields.value) > 0
  ) {
    return companyMaskedFields.value.map((field) => field.localId);
  } else if (
    contactIdParam.value &&
    contactSelectedRecordDataField.value?.fieldContentType === "Contact" &&
    _.size(contactMaskedFields.value) > 0
  ) {
    return companyMaskedFields.value.map((field) => field.localId);
  } else {
    return null;
  }
});

function setFieldTypesLabel(newLabel) {
  fieldTypesLabel.value = newLabel;
}

function variableGradeFor(variableName) {
  if (props.dataField) {
    switch (variableName) {
      case "Recency":
        return _.toNumber(props.dataField.recencyGrade);
      case "Difficulty":
        return _.toNumber(props.dataField.difficultyGrade);
      case "Sourcing":
        return _.toNumber(props.dataField.sourcingGrade);
      case "Credibility":
        return _.toNumber(props.dataField.credibilityGrade);
      default:
        return null;
    }
  } else {
    return null;
  }
}
function qualityColorFor(variable) {
  if (variable === "combined") {
    if (combinedDataGrade.value == 1.0) {
      return "bg-gray-700";
    } else if (combinedDataGrade.value <= 10.0) {
      return "bg-orange-500";
    } else if (combinedDataGrade.value <= 40.0) {
      return "bg-gray-400";
    } else {
      return "bg-yellow-400";
    }
  } else {
    return (
      _.find(variable.grades, {
        multiplier: variableGradeFor(variable.name),
      })?.color || "text-gray-700"
    );
  }
}
function gradeLabelFor(variable) {
  if (props.dataField?.dependentValidate) {
    return "Autogenerated field";
  } else {
    return (
      _.find(variable.grades, {
        multiplier: variableGradeFor(variable.name),
      })?.label || "Label"
    );
  }
}

const availableValidationsChannelStore = useAvailableValidationsChannelStore();
const { availableValidationsChannelDataQueue } = storeToRefs(
  availableValidationsChannelStore,
);
const dataLicensesChannelStore = useDataLicensesChannelStore();
const { dataLicensesChannelDataQueue } = storeToRefs(dataLicensesChannelStore);
const dataSharingsChannelStore = useDataSharingsChannelStore();
const { dataSharingsChannelDataQueue } = storeToRefs(dataSharingsChannelStore);

watch(
  () => props.bundleFieldIds,
  (arr, oldArr) => {
    if (!_.isEqual(arr, oldArr)) {
      refetchPending.value = true;
      debouncedFetchAllFields();
    }
  },
  { deep: true },
);

watch(availableValidationsChannelDataQueue, () => {
  if (!props.fetchedAllFieldsData) {
    allFieldsData.value = null;
    fetchAllFields();
  }
});

watch(dataLicensesChannelDataQueue, () => {
  const data = _.last(dataLicensesChannelDataQueue.value);

  if (data.dataFieldIds && !props.fetchedAllFieldsData) {
    fetchAllFields();
  }
});

watch(dataSharingsChannelDataQueue, () => {
  const data = _.last(dataSharingsChannelDataQueue.value);

  if (data.dataFieldIds && !props.fetchedAllFieldsData) {
    fetchAllFields();
  }
});

const debouncedFetchAllFields = _.debounce(function () {
  fetchAllFields();
}, 3000);

const unlockTooltip = computed(() => {
  if (!unlockable.value && bundles.value.length > 0) {
    return "Your unlockable collection";
  } else if (unlockable.value) {
    return "Unlock this field";
  } else {
    return "";
  }
});
const safezone = computed(() => _.get(props.dataField, "safezone"));
const rawFree = computed(() => _.get(props.dataField, "free"));
const priced = computed(() => {
  const rawPrice = _.get(props.dataField, "price");
  const price = rawPrice ? _.toNumber(rawPrice) : null;
  return price && price > 0;
});
const unlockable = computed(() => {
  if (!safezone.value)
    return (
      unstaked.value || priced.value || _.size(detailPageOverride.value) > 0
    );
  else return priced.value;
});
const challengeable = computed(() => {
  const groupAwardedAt =
    props.dataField?.fieldName === "awarded_at" &&
    props.dataField?.decoratingContentType === "CompanyInvolvement";
  const hasManyFieldTypes = [
    "ContentPropertyUse",
    "InvolvementRole",
    "CompanyInvolvement",
    "ContactCompanyInvolvement",
    "Hunt",
  ];
  const excludedStandaloneFields = ["minimum_subdivision_standardized_area"];
  const eligibleStandalone =
    !!props.dataField?.fieldName &&
    !_.includes(excludedStandaloneFields, props.dataField?.fieldName);
  const matching =
    eligibleStandalone ||
    _.includes(hasManyFieldTypes, props.dataField?.fieldContentType) ||
    groupAwardedAt;
  return matching && unlockable.value && !_.isEmpty(proofProps.value);
});
const platformState = computed(() => {
  return _.get(props.dataField, "state", null);
});
const unstaked = computed(() => platformState.value === "unstaked");
const asyncReady = computed(() => {
  if (props.analyze && props.context === "analyze-panel-filter-settings") {
    if (companyIdParam.value || contactIdParam.value) {
      return timelinePagysLoaded.value;
    } else {
      return mapPagysLoaded.value && timelinePagysLoaded.value;
    }
  } else {
    return true;
  }
});

const bundles = computed(() => {
  if (props.dataField) {
    return bundlesFor(props.dataField);
  } else if (props.calculationName) {
    return [
      {
        name: "Calculation",
        description: "Calculation inputs.",
        apiParam: "calculation",
      },
    ];
  } else if (props.analyze) {
    return [
      {
        name: "Filters",
        description: "Filtered datapoints.",
        apiParam: "filters",
      },
    ];
  } else {
    return [];
  }
});
const bundlesExist = computed(
  () =>
    (unlockable.value && !unstaked.value && bundles.value.length > 0) ||
    (bundles.value.length > 0 && unlicensedCount.value > 0),
);
const allFieldsIds = computed(() => {
  return _.concat(
    _.get(actionableAllFieldsData.value, "safezoneIds", []),
    _.get(actionableAllFieldsData.value, "openSourceIds", []),
  );
});
const unlicensedCount = computed(
  () => _.get(actionableAllFieldsData.value, "unlicensedIds", []).length,
);
const licenseableCount = computed(() =>
  _.get(actionableAllFieldsData.value, "licenseableCount", 0),
);
const licensedCount = computed(
  () =>
    licenseableCount.value -
    _.get(actionableAllFieldsData.value, "unlicensedIds", []).length,
);
const freeCount = computed(() =>
  _.get(actionableAllFieldsData.value, "freeCount", 0),
);
const safezoneCount = computed(() =>
  _.get(actionableAllFieldsData.value, "safezoneCount", 0),
);
const totalCount = computed(() =>
  _.sum([licenseableCount.value, freeCount.value, safezoneCount.value]),
);
const dataFieldLocalId = computed(() => _.get(props.dataField, "localId"));
const attributionCount = computed(() => {
  switch (platformState.value) {
    case "unstaked":
      return "Stake data credits to proceed";
    case "staked":
      return "Staked, publishable";
    case "proposed":
      return "Review pending";
    case "rejected":
      return "Rejected via peer review";
    case "approved":
    default:
      return groupedAttributions.value.length > 0
        ? pluralize("Contributor", groupedAttributions.value.length, true)
        : "No contributors yet";
  }
});

watch(unlockable, () => {
  if (unlockable.value) {
    emit("unlockable");
  }
});
watch(bundlesExist, () => {
  if (bundlesExist.value && licensedCount.value === 0) {
    emit("unlockable");
  }
});
watch(dataFieldLocalId, () => {
  if (props.dataField) {
    reset();
    refetch();
  }
});

onMounted(async () => {
  let modalStoreTemp = props.modalStore;
  let unlockerStoreTemp = props.unlockerStore;
  if (props.fetchedAllFieldsData) {
    fetchAttributions();
  } else if (
    dataFieldLocalId.value ||
    props.bundleFieldIds ||
    (props.calculationContentType && props.calculationContentIds)
  ) {
    await fetchAllFields();
  }

  if (unlockable.value) {
    emit("unlockable");
  }
});

async function fetchAllFields() {
  if (asyncReady.value) {
    try {
      let response = null;
      let bundleResponse = null;
      let requestKey = null;
      fetchingAnalyzePanelData.value = true;
      refetchInProcess.value = true;
      if (dataFieldLocalId.value) {
        response = await fetchFieldData(dataFieldLocalId.value);
      } else if (props.bundleFieldIds) {
        response = await fetchCalculationDataByField();
      } else if (props.calculationContentType && props.calculationContentIds) {
        requestKey = props.calculationIsSurvey
          ? "survey"
          : `${props.calculationContentType}_${props.calculationContentIds.join(
              "_",
            )}`;
        response = await fetchCalculationDataByContent();

        if (response?.data) {
          const payload = {
            bundleFieldIds: response.data.map(({ localId }) => localId),
            bundle: "calculation",
          };
          bundleResponse = await api.post(
            `crowdsourced_data_field_calculation_bundles`,
            payload,
          );
        }
      }

      if (response?.data) {
        if (bundleResponse?.data) {
          allFieldsData.value = bundleResponse?.data;
          allFieldsData.value.calculationFields = response.data;
        } else {
          allFieldsData.value = response.data;
        }
        fetchAttributions();

        if (_.size(calculationData.value) > 0) {
          analyzePanelStore.setCalculationDataByName(
            props.calculationName,
            calculationData.value,
            requestKey,
          );
        }
      }
    } catch (error) {
      console.error(error);
      notificationsStore.addNotification("anErrorOccurred");
    } finally {
      fetchingAnalyzePanelData.value = false;
      refetchPending.value = false;
      refetchInProcess.value = false;
    }
  }
}
async function fetchCalculationDataByField() {
  const payload = {
    bundleFieldIds: props.bundleFieldIds,
    bundle: props.analyze ? "filters" : "calculation",
  };
  return api.post(`crowdsourced_data_field_calculation_bundles`, payload);
}
const calculationContentFetchable = computed(() => {
  return (
    props.calculationContentType &&
    _.isArray(props.calculationContentIds) &&
    props.calculationContentIds.length > 0
  );
});
const calculationContentFetchKey = computed(() => {
  if (calculationContentFetchable.value) {
    return `${props.calculationName}-${props.calculationContentType}-${props.calculationContentIds}-${asOfMilliseconds.value}`;
  } else {
    return null;
  }
});

async function fetchCalculationDataByContent() {
  if (calculationContentFetchable.value) {
    const payload = {
      contentType: props.calculationContentType,
      contentIds: props.calculationContentIds,
      calculationName: props.calculationName,
      bundle: "calculation",
      asOf: asOfMilliseconds.value,
    };

    const response = await calculationFieldsStore.calculationDataInitialFetch({
      payload,
      key: calculationContentFetchKey.value,
      endpoint: `calculation_data_fields`,
      calculationName: payload.calculationName,
      analyzePanelSet: false,
      paginationCount: 10,
    });

    return response;
  }
}
async function fetchFieldData(id) {
  return api.get(`crowdsourced_data_field_bundles/${id}?bundle=all_fields`);
}
async function fetchAttributions() {
  if (props.bundleFieldIds || allFieldsIds.value.length > 0) {
    const payload = {
      bundleFieldIds: props.bundleFieldIds || allFieldsIds.value,
    };

    api.post(`data_field_calculation_attributions`, payload).then((json) => {
      attributions.value = json.data;
    });
  } else if (props.dataField && !safezone.value) {
    api
      .get(
        `data_field_attributions?crowdsourced_data_field_id=${props.dataField.localId}`,
      )
      .then((json) => {
        attributions.value = json.data;
      });
  }
}
function refetch() {
  // console.log("unlock selector refetch", dataFieldLocalId.value);
  if (props.fetchedAllFieldsData) {
    console.log("bundle data provided via props");
  } else {
    allFieldsData.value = null;
    fetchAllFields();
  }
}
async function reset() {
  displayable.value = false;

  await nextTick();
  displayable.value = true;
}
function unlocked(payload) {
  console.log("unlock selector unlocked", payload);
  emit("unlocked", payload);
  reset();
  refetch();
}
function completed(payload) {
  console.log("unlock selector completed", payload);
  emit("completed", payload);
}
function resetDisplayable() {
  reset();
}
function pricingHelp() {
  props.documentationStore.viewArticle(
    understandPayAsYouGoPricing,
    "How much does it cost",
  );
}
</script>
