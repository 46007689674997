<template>
  <section aria-labelledby="companies">
    <ContentHeader>
      <template v-slot:title>Operational Control</template>
      <template v-slot:description
        >Who controls this {{ contentType }}, and which advisors are
        engaged?</template
      >
    </ContentHeader>

    <ContentBody>
      <ControllingAsset
        v-for="asset in sortedAssets"
        :key="asset.localId"
        :data-field="asset"
      />
    </ContentBody>
  </section>
</template>

<script setup>
import ContentBody from "@/components/main-layout/secondary-panel/ContentBody.vue";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import ControllingAsset from "@/components/we/ControllingAsset.vue";
import { storeToRefs } from "pinia";
import { useTimeTravelStore } from "@/stores/timeTravel";
import api from "@/router/api";
import _ from "lodash";
import { ref, computed, watch, onMounted } from "vue";

const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);

const props = defineProps(["dataField", "context"]);
const controllingAssets = ref([]);
const sortedAssets = computed(() => {
  const uniqAssets = _.uniqBy(controllingAssets.value, "localId");

  return _.sortBy(uniqAssets, ["fieldContentSubType"]);
});
const contentType = computed(() => _.get(props.dataField, "fieldContentType"));
const contentId = computed(() => _.get(props.dataField, "fieldContentId"));
const combinedId = computed(() => `${contentType.value}${contentId.value}`);

watch(combinedId, () => {
  fetchControllingAssets();
});
watch(asOfDate, (date, oldDate) => {
  if (oldDate && date !== oldDate) {
    fetchControllingAssets();
  }
});
onMounted(() => {
  fetchControllingAssets();
});

function fetchControllingAssets() {
  if (props.dataField && contentType.value && contentId.value) {
    api
      .get(
        `operational_controlling_assets/?content_type=${contentType.value}&content_id=${contentId.value}&data_field_id=${props.dataField.localId}`,
      )
      .then((json) => {
        controllingAssets.value = json.data;
      });
  }
}
</script>
