<template>
  <header
    class="flex items-center justify-between p-2 border-b border-gray-300"
  >
    <div class="text-gray-700 font-semibold">{{ activeOption.name }}</div>
    <VDropdown class="mr-2 flex items-center">
      <button
        type="button"
        class="inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
      >
        <ChevronDownIcon class="size-6" aria-hidden="true" />
      </button>
      <template #popper>
        <div class="p-3">
          <ol class="flex flex-col gap-y-2">
            <li v-for="option in teamOptions" :key="option.name">
              <a
                v-close-popper
                class="flex items-center gap-x-2"
                href=""
                @click.prevent="option.handler"
              >
                <component
                  :is="option.icon"
                  class="size-5 text-indigo-600 hover:text-indigo-500"
                />
                <span
                  class="text-sm font-semibold text-indigo-600 hover:text-indigo-500"
                  >{{ option.name }}</span
                >
              </a>
            </li>
          </ol>
        </div>
      </template>
    </VDropdown>
  </header>
</template>

<script setup>
import TeamForm from "@/components/users/teams/TeamForm.vue";
import TeamDetails from "@/components/users/teams/TeamDetails.vue";
import { Cog8ToothIcon, ListBulletIcon, TrashIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useModalStore } from "@/stores/modal";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import api from "@/router/api";
import _ from "lodash";

const wePanelStore = useWePanelStore();
const { activeOption } = storeToRefs(wePanelStore);
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);

const team = computed(() => activeOption.value?.matchContent);
const isManager = computed(() =>
  _.includes(["Primary owner", "Billing member"], team.value?.role),
);
const teamOptions = computed(() => {
  const leaveTeamOption = {
    name: "Leave Team",
    handler: () => {
      const leaveCallback = () => {
        api
          .delete(
            `teams/${team.value.id}/team_memberships/${team.value.membershipId}`,
          )
          .then(() => {
            wePanelStore.dropTeam(team.value);
          });
      };
      confirmationPayload.value = {
        title: "Leave Team",
        message:
          "Are you sure you want to leave this team? This action cannot be undone.",
        affirmText: "Leave",
        affirmCallback: leaveCallback,
      };
    },
    icon: XMarkIcon,
  };
  const deleteTeamOption = {
    name: "Delete Team",
    handler: () => {
      const deleteCallback = () => {
        api.delete(`teams/${team.value.id}`).then(() => {
          wePanelStore.dropTeam(team.value);
        });
      };
      confirmationPayload.value = {
        title: "Delete Team",
        message:
          "Are you sure you want to delete this team? This action cannot be undone.",
        affirmText: "Delete",
        affirmCallback: deleteCallback,
      };
    },
    icon: TrashIcon,
  };
  const teamSettingsOption = {
    name: "Team Settings",
    handler: () => {
      wePanelStore.openSecondaryContentPanel({
        component: TeamForm,
        props: {
          teamId: activeOption.value.matchId,
        },
      });
    },
    icon: Cog8ToothIcon,
  };
  const rawOptions = [
    isManager.value ? teamSettingsOption : null,
    {
      name: "Team Details",
      handler: () => {
        wePanelStore.openSecondaryContentPanel({
          component: TeamDetails,
          props: {
            teamId: activeOption.value.matchId,
          },
        });
      },
      icon: ListBulletIcon,
    },
    team.value?.role === "Primary owner" ? deleteTeamOption : null,
    team.value?.canLeave ? leaveTeamOption : null,
  ];

  return _.compact(rawOptions);
});
</script>
