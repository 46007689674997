import propertyLandCoveringMapMarker from "@/components/property-diagram/propertyLandCoveringMapMarker";
import locationMarker from "@/components/maps/locationMarker";

export async function addLocationToMap({
  mode = "store",
  dataField,
  mapStore,
  featureGroup,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  layoutStore,
  secondaryPanelStore,
  documentationStore,
  analyzePanelStore,
}) {
  const location = dataField.fieldContent;

  let workspaceMarker = null;

  if (mode === "store") {
    workspaceMarker = propertyLandCoveringMapMarker({
      dataField,
      location,
      mapStore,
      propertyFieldsStore,
      modalStore,
      unlockerStore,
      userStore,
      tasksStore,
      taskListStore,
      reminderStore,
      guestProfileStore,
      propertyDiagramStore,
      changeGroupStore,
      layoutStore,
      secondaryPanelStore,
      documentationStore,
      analyzePanelStore,
    });
  } else {
    const markerIcon = locationMarker({
      classes: "h-3 w-3 bg-gray-600 hover:bg-gray-700 focus:ring-gray-500",
      interactive: true,
      mapStore,
      propertyFieldsStore,
      propertyId: dataField.decoratingContentId,
    });
    workspaceMarker = L.marker([location.lat, location.lng], {
      draggable: false,
      icon: markerIcon,
    });
  }

  if (workspaceMarker) {
    featureGroup.addLayer(workspaceMarker);
    const layerId = featureGroup.getLayerId(workspaceMarker);
    location.mapInternalId = layerId;
    if (mode === "store") {
      mapStore.setNearbyDataField({
        dataField,
        locationType: dataField.decoratingContentType === "Property" ? "Property" : "ContentLocation",
      });
    }
  }
}
