<template>
  <div class="relative">
    <span
      v-if="isSelf && (person?.linkedContactId || person?.linkedUserId)"
      v-tooltip="'Account linked to a Tower Hunt contact'"
      class="absolute -right-0.5 -top-0.5 block h-3 w-3 rounded-full bg-green-400 ring-2 ring-white"
    />
    <span
      v-else-if="isSelf && person?.linkRequestPending"
      v-tooltip="'Contact link request under review'"
      class="absolute -right-0.5 -top-0.5 block h-3 w-3 rounded-full bg-orange-400 ring-2 ring-white"
    />
    <span
      v-else-if="isSelf && !!person"
      v-tooltip="'Account not linked to a Tower Hunt contact'"
      class="absolute -right-0.5 -top-0.5 block h-3 w-3 rounded-full bg-gray-600 ring-2 ring-white"
    />
    <span
      v-if="person?.easyDataInputReferral"
      v-tooltip="'Data Input Job'"
      :class="`${shape} ${shapeSize} ${border} ${reputationColor}`"
      class="inline-flex items-center justify-center"
    >
      <span
        :class="`text-${textSize}`"
        class="font-medium uppercase leading-none text-white"
        data-test="avatar-easy-data-input-referral"
      >
        ?
      </span>
    </span>
    <div
      v-else-if="photoLink"
      :class="`${shape} ${shapeSize} ${border} ${reputationColor}`"
      class="bg-contain bg-center bg-no-repeat"
      :style="`background-image: url(${photoLink})`"
    />
    <span
      v-else-if="teamName || person?.name"
      :class="`${shape} ${shapeSize} ${border} ${reputationColor}`"
      class="inline-flex items-center justify-center"
    >
      <span
        :class="`text-${textSize}`"
        class="font-medium uppercase leading-none text-white"
        data-test="avatar-initials"
      >
        {{ $filters.initials(teamName || person.name) }}
      </span>
    </span>
    <span
      v-else
      :class="`${shapeSize} ${border} ${reputationColor}`"
      class="inline-flex items-center justify-center rounded-full"
    >
      <svg
        :class="`${iconSize}`"
        class="text-white"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fill-rule="evenodd"
          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
          clip-rule="evenodd"
        />
      </svg>
    </span>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps([
  "teamName",
  "person",
  "shapeSize",
  "iconSize",
  "textSize",
  "borderClass",
]);

const userStore = useUserStore();
const { currentUser, signedIn } = storeToRefs(userStore);

const border = computed(() => props.borderClass || "");
const shape = computed(() => (props.teamName ? "rounded-md" : "rounded-full"));
const reputationColor = computed(() => {
  switch (_.get(props.person, "reputationLevel")) {
    case "bronze":
      return "bg-orange-500";
    case "silver":
      return "bg-gray-400";
    case "gold":
      return "bg-yellow-400";
    default:
      return "bg-gray-400";
  }
});
const photoLink = computed(() => {
  return _.get(props.person, "photo.fieldContent.link");
});
const isSelf = computed(() => {
  if (props.person?.easyDataInputReferral) {
    return false;
  } else if (signedIn.value && currentUser.value && props.person) {
    const comparisonId =
      props.person.recordType === "Contact"
        ? props.person.linkedUserId
        : props.person.id;

    return currentUser.value.id === comparisonId;
  } else {
    return false;
  }
});
</script>
