import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import moment from "moment";
import _ from "lodash";
import { useMainMapStore } from "@/stores/mainMap";
import { usePropertyFieldsStore } from "@/stores/propertyFields";

export const useTimeTravelStore = defineStore("timeTravel", () => {
  const timeTravelTo = ref(null);
  const chosenDate = ref(null);
  const asOfDate = computed(() =>
    moment(timeTravelTo.value || undefined).toDate(),
  );
  const asOfMilliseconds = computed(() => moment(asOfDate.value).valueOf());
  const dayBeforeAsOf = computed(() =>
    moment(asOfDate.value).subtract(1, "day").valueOf(),
  );
  const hoveringMarkerField = ref(null);
  const refreshingMainTimeline = ref(false);
  const timelineFetchData = ref({});
  const fetchedEventIds = computed(() => {
    const fetches = _.filter(
      timelineFetchData.value,
      function (fetchData, fetchKey) {
        return fetchData.data;
      },
    );

    const rawEvents = fetches.flatMap(({ data }) => data);

    return _.uniqBy(rawEvents, "id").map(({ id }) => id);
  });
  const allPagysLoaded = computed(() =>
    _.every(timelineFetchData.value, function (fetchObject) {
      if (fetchObject.loaded) {
        return true;
      } else {
        return false;
      }
    }),
  );
  function actionableFetchObjectFor(key) {
    return timelineFetchData.value[key];
  }
  function fetchProgressFor(key) {
    const fetchObject = actionableFetchObjectFor(key);

    if (fetchObject?.pagy && !fetchObject.loaded) {
      const { pagy } = fetchObject;
      const { page, last } = pagy;
      const rawPercentage = page / last;

      return _.round(rawPercentage * 100);
    } else {
      return null;
    }
  }
  function fetchLabelFor(key) {
    if (fetchProgressFor(key)) {
      const { pagy } = actionableFetchObjectFor(key);
      const { count } = pagy;

      return `${fetchProgressFor(key)}% (${count} datapoints)`;
    } else {
      return null;
    }
  }
  const fetchedDatedEvents = computed(() => {
    const fetches = _.filter(
      timelineFetchData.value,
      function (fetchData, fetchKey) {
        const fetchKeyData = _.split(fetchKey, "_");
        const fetchKeyContentType = _.head(fetchKeyData);

        return fetchKeyContentType === "dated" && fetchData.data;
      },
    );

    const rawEvents = fetches.flatMap(({ data }) => data);

    return _.uniqBy(rawEvents, "id");
  });
  const fetchedUndatedEvents = computed(() => {
    const fetches = _.filter(
      timelineFetchData.value,
      function (fetchData, fetchKey) {
        const fetchKeyData = _.split(fetchKey, "_");
        const fetchKeyContentType = _.head(fetchKeyData);

        return fetchKeyContentType === "undated" && fetchData.data;
      },
    );

    const rawEvents = fetches.flatMap(({ data }) => data);

    return _.uniqBy(rawEvents, "id");
  });

  const mapStore = useMainMapStore();
  const { map, nearbyHuntDataFields } = storeToRefs(mapStore);
  const propertyFieldsStore = usePropertyFieldsStore();
  const { propertyFields } = storeToRefs(propertyFieldsStore);

  const mainTimelineDated = computed(() => {
    return fetchedDatedEvents.value.filter(nearbyEventFilter);
  });
  const mainTimelineUndated = computed(() => {
    return fetchedUndatedEvents.value.filter(nearbyEventFilter);
  });
  function nearbyEventFilter(timelineObj) {
    const containedProperties = () => {
      if (map.value) {
        const bounds = map.value.getBounds();
        return _.filter(propertyFields.value, function (dataField, id) {
          if (dataField.fieldContent?.locationDataField) {
            const { lat, lng } =
              dataField.fieldContent.locationDataField.fieldContent;
            const comparableLatLng = L.latLng(lat, lng);

            return bounds.contains(comparableLatLng);
          }
        });
      } else {
        return [];
      }
    };
    const containedPropertyIds = containedProperties().map(
      ({ fieldContentId }) => fieldContentId,
    );
    const huntIds = nearbyHuntDataFields.value.map(
      ({ fieldContentId }) => fieldContentId,
    );
    if (timelineObj?.dataField) {
      if (
        timelineObj.dataField.propertyId &&
        _.isNumber(timelineObj.dataField.propertyId)
      ) {
        return _.includes(
          containedPropertyIds,
          timelineObj.dataField.propertyId,
        );
      } else if (timelineObj.dataField.decoratingContentType === "Hunt") {
        return _.includes(huntIds, timelineObj.dataField.decoratingContentId);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  const mainTimelineCustomForwardDate = ref(null);
  const mainTimelineCustomLookbackDate = ref(null);

  const datedFields = computed(() =>
    _.compact(
      mainTimelineDated.value.map((timelineObj) => timelineObj?.dataField),
    ),
  );
  const undatedFields = computed(() =>
    _.compact(
      mainTimelineUndated.value.map((timelineObj) => timelineObj?.dataField),
    ),
  );
  const datedInvestmentFields = computed(() =>
    datedFields.value.filter(
      (dataField) => dataField.fieldContentType === "Investment",
    ),
  );
  const datedInvestmentIds = computed(() => {
    return datedInvestmentFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  const undatedInvestmentFields = computed(() =>
    undatedFields.value.filter(
      (dataField) => dataField.fieldContentType === "Investment",
    ),
  );
  const undatedInvestmentIds = computed(() => {
    return undatedInvestmentFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  const combinedTimelineInvestmentFields = computed(() =>
    _.unionBy(
      datedInvestmentFields.value,
      undatedInvestmentFields.value,
      function (dataField) {
        return `${dataField.fieldContentType}${dataField.fieldContentId}`;
      },
    ),
  );
  const combinedInvestmentIds = computed(() => {
    return combinedTimelineInvestmentFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  const datedSpaceAvailabilityFields = computed(() =>
    datedFields.value.filter(
      (dataField) => dataField.fieldContentType === "SpaceAvailability",
    ),
  );
  const datedSpaceAvailabilityIds = computed(() => {
    return datedSpaceAvailabilityFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  const undatedSpaceAvailabilityFields = computed(() =>
    undatedFields.value.filter(
      (dataField) => dataField.fieldContentType === "SpaceAvailability",
    ),
  );
  const undatedSpaceAvailabilityIds = computed(() => {
    return undatedSpaceAvailabilityFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  const combinedTimelineSpaceAvailabilityFields = computed(() =>
    _.unionBy(
      datedSpaceAvailabilityFields.value,
      undatedSpaceAvailabilityFields.value,
      function (dataField) {
        return `${dataField.fieldContentType}${dataField.fieldContentId}`;
      },
    ),
  );
  const combinedSpaceAvailabilityIds = computed(() => {
    return combinedTimelineSpaceAvailabilityFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  function triggerRefetch() {
    timeTravelTo.value = moment(asOfDate.value).add(1, "second").toDate();

    setTimeout(() => {
      if (moment().isSame(timeTravelTo.value, "day")) {
        timeTravelTo.value = null;
      }
    }, 100);
  }

  return {
    timeTravelTo,
    chosenDate,
    asOfDate,
    asOfMilliseconds,
    dayBeforeAsOf,
    hoveringMarkerField,
    refreshingMainTimeline,
    mainTimelineDated,
    mainTimelineUndated,
    mainTimelineCustomForwardDate,
    mainTimelineCustomLookbackDate,
    datedInvestmentFields,
    datedInvestmentIds,
    datedSpaceAvailabilityFields,
    datedSpaceAvailabilityIds,
    combinedTimelineInvestmentFields,
    combinedInvestmentIds,
    undatedInvestmentFields,
    undatedInvestmentIds,
    undatedSpaceAvailabilityFields,
    undatedSpaceAvailabilityIds,
    combinedTimelineSpaceAvailabilityFields,
    combinedSpaceAvailabilityIds,
    timelineFetchData,
    fetchedEventIds,
    allPagysLoaded,
    triggerRefetch,
    actionableFetchObjectFor,
    fetchProgressFor,
    fetchLabelFor,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTimeTravelStore, import.meta.hot));
}
