<template>
  <TransitionRoot
    as="div"
    class="absolute inset-0 flex z-[110]"
    :class="[expandedView ? '' : 'hidden']"
    :show="!!activeOption?.takeover?.component"
  >
    <TransitionChild
      as="template"
      enter="transition ease-in-out duration-300 transform"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition ease-in-out duration-300 transform"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <div
        class="h-full w-full bg-white overflow-y-auto"
      >
        <!-- Takeover component -->
        <component
          :is="activeOption.takeover.component"
          v-bind="activeOption.takeover.props"
        />
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>

<script setup>
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";

const wePanelStore = useWePanelStore();
const { expandedView, activeOption } = storeToRefs(wePanelStore);
</script>
