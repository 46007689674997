<template>
  <div v-if="highlightCard" class="mx-auto space-y-3">
    <CollectibleCardPaper
      :auto-card-size="false"
      :card="highlightCard"
      :timeline-override-field="timelineField"
      :flipped="flipped"
      :licensed="licensed"
      :displayable="displayable"
      :generic="!highlightCard?.collectibleCardId"
      :context="context"
    />
    <div class="flex items-center justify-center space-x-3">
      <UnlockBundles
        v-if="displayable && !flipped && (bundlesExist || unlockable)"
        context="content-highlight"
        :data-field="bundleDataField"
        :bundles="bundles"
        :all-fields-data="allFieldsData"
        :user-store="userStore"
        :modal-store="modalStore"
        :unlocker-store="unlockerStore"
        placement="top"
        class="flex-shrink-0"
        @unlocked="markCardUnlocked"
        @reset-displayable="resetDisplayable"
      >
        <template v-slot:button>
          <button
            type="button"
            v-tooltip="'Instant Unlock'"
            class="inline-flex items-center justify-center h-12 w-12 rounded-full bg-blue-400 hover:bg-blue-600"
            data-test="unlock-collectible-card-button"
          >
            <LockOpenIcon class="h-6 w-6 text-white" />
          </button>
        </template>
      </UnlockBundles>
      <button
        v-if="saveable"
        type="button"
        v-tooltip="'Save to Tasks'"
        @click="saveCardToTasks"
        class="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-full bg-green-300 hover:bg-green-500"
        data-test="accept-collectible-card-button"
      >
        <StarIcon class="h-6 w-6 text-white" />
      </button>
    </div>
  </div>
</template>

<script setup>
import UnlockBundles from "@/components/crowdsourcing/UnlockBundles.vue";
import CollectibleCardPaper from "@/components/collectible-cards/CollectibleCardPaper.vue";
import { bundlesFor } from "@/components/crowdsourcing/unlockableBundles";
import { LockOpenIcon, StarIcon } from "@heroicons/vue/20/solid";
import { useModalStore } from "@/stores/modal";
import { useUserStore } from "@/stores/user";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useUnlockerStore } from "@/stores/unlocker";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps(["dataField", "context"]);
const emit = defineEmits(["loading-complete"]);

const modalStore = useModalStore();
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const companyDetailStore = useCompanyDetailStore();
const { companyDetailSelected, companyAllFetchedFields } =
  storeToRefs(companyDetailStore);

const unlockerStore = useUnlockerStore();
const { resetRequired, upgradeSuccessful } = storeToRefs(unlockerStore);
const upgradeInProgress = ref(false);
const readyToUnlock = computed(() => {
  if (upgradeInProgress.value) {
    return upgradeSuccessful.value;
  } else {
    return true;
  }
});

const displayable = ref(true);
const highlightCard = ref(null);
const timelineField = ref(null);
const flipped = ref(false);
const allFieldsData = ref(null);

const fieldContentType = computed(() =>
  _.get(props.dataField, "fieldContentType"),
);
const fieldName = computed(() => _.get(props.dataField, "fieldName"));
const bundleDataFieldId = computed(() =>
  _.get(highlightCard.value, "bundleDataFieldId", false),
);
const bundleDataField = ref(null);

const bundles = computed(() => bundlesFor(bundleDataField.value));
const safezone = computed(() => _.get(bundleDataField.value, "safezone"));
const priced = computed(() => {
  const rawPrice = _.get(bundleDataField.value, "price");
  const price = rawPrice ? _.toNumber(rawPrice) : null;
  return price && price > 0;
});
const licensed = computed(() => _.get(bundleDataField.value, "licensed"));
const unlockable = computed(() => {
  if (!safezone.value) return unstaked.value || priced.value;
  else return priced.value;
});
const unstaked = computed(
  () => _.get(bundleDataField.value, "state") === "unstaked",
);
const unlicensedCount = computed(
  () => _.get(allFieldsData.value, "unlicensedIds", []).length,
);
const bundlesExist = computed(
  () =>
    (unlockable.value && !unstaked.value) ||
    (bundles.value.length > 0 && unlicensedCount.value > 0),
);
const shouldFlip = computed(() => {
  return highlightCard.value?.unlocked;
});

const saveable = computed(() => {
  const dataCoverageCard = highlightCard.value?.category === "data_coverage";
  return (
    !dataCoverageCard &&
    (!highlightCard.value?.collectibleCardId || !highlightCard.value?.reaction)
  );
});

watch(resetRequired, () => {
  if (upgradeInProgress.value) {
    resetRequired.value = false;
    upgradeInProgress.value = false;
  }
});
watch(shouldFlip, () => {
  if (shouldFlip.value) {
    flipCard();
  }
});

onMounted(() => {
  switch (fieldContentType.value) {
    case "SpaceAvailability":
    case "Investment":
    case "Hunt":
    case "CompanyInvolvement": {
      seekGenericCard();
      break;
    }
    case "Company":
    case "Contact":
    case "Property": {
      bundleDataField.value = props.dataField;
      highlightCard.value = {
        id: null,
        category: "data_coverage",
        staticContentId: null,
        taskName: `${fieldContentType.value} Data Coverage`,
        bundleDataFieldId: props.dataField.localId,
        combinedKey: `decoratingContentFieldContent---${fieldContentType.value}`,
        sortDate: props.dataField.updatedAt,
        unlocked: false,
      };
      fetchBundles();
      break;
    }
    default: {
      const eligibleDateTypes = [
        "construction_proposed_date",
        "demolition_proposed_date",
        "construction_approved_date",
        "construction_permitted_date",
        "construction_commenced_date",
        "demolition_permitted_date",
        "construction_completed_date",
        "remeasurement_created_date",
        "remeasurement_retired_date",
        "demolished_date",
      ];
      if (fieldName.value && _.includes(eligibleDateTypes, fieldName.value)) {
        timelineField.value = props.dataField;
        seekGenericCard();
      } else {
        console.log("unhandled highlight type", fieldContentType.value);
        emit("loading-complete", { displayableBool: false });
      }
    }
  }
});

async function fetchDataField() {
  return new Promise((resolve) => {
    api.get(`data_field_by_id/${bundleDataFieldId.value}`).then((json) => {
      bundleDataField.value = json.data;
      resolve();
    });
  });
}

function seekGenericCard() {
  const timelineAvailabilityId =
    props.dataField?.fieldContentType === "SpaceAvailability"
      ? props.dataField.fieldContentId
      : null;
  const availabilityId =
    props.dataField?.fieldContent?.availability?.id ||
    props.dataField?.fieldContent?.availabilityId ||
    timelineAvailabilityId;
  const timelineInvestmentId =
    props.dataField?.fieldContentType === "Investment"
      ? props.dataField.fieldContentId
      : null;
  const investmentId =
    props.dataField?.fieldContent?.investment?.id ||
    props.dataField?.fieldContent?.investmentId ||
    timelineInvestmentId;
  const timelineHuntId =
    props.dataField?.fieldContentType === "Hunt"
      ? props.dataField.fieldContentId
      : null;
  const huntId = props.dataField?.fieldContent?.id || timelineHuntId;

  if (investmentId) {
    api
      .get(`content_collectible_cards/Investment/${investmentId}`)
      .then((json) => {
        highlightCard.value = json.data;

        if (highlightCard.value) {
          fetchDataField().then(() => fetchBundles());
        }
      });
  } else if (availabilityId) {
    api
      .get(`content_collectible_cards/SpaceAvailability/${availabilityId}`)
      .then((json) => {
        highlightCard.value = json.data;

        if (highlightCard.value) {
          fetchDataField().then(() => fetchBundles());
        }
      });
  } else if (huntId) {
    api
      .get(`content_collectible_cards/Hunt/${huntId}`)
      .then((json) => {
        highlightCard.value = json.data;

        if (highlightCard.value) {
          fetchDataField().then(() => fetchBundles());
        }
      });
  } else if (props.dataField?.decoratingContentType && fieldName.value) {
    api
      .get(
        `content_collectible_cards/${props.dataField.decoratingContentType}/${props.dataField.decoratingContentId}`,
      )
      .then((json) => {
        highlightCard.value = json.data;

        if (highlightCard.value) {
          fetchDataField().then(() => fetchBundles());
        }
      });
  }
}

async function fetchBundles() {
  api
    .get(
      `crowdsourced_data_field_bundles/${bundleDataFieldId.value}?bundle=all_fields`,
    )
    .then((json) => {
      allFieldsData.value = json.data;
      emit("loading-complete", { displayableBool: true });
    });
}

async function flipCard() {
  if (readyToUnlock.value) {
    upgradeInProgress.value = false;
    upgradeSuccessful.value = false;
    flipped.value = true;
  }
}

function markCardUnlocked() {
  api
    .patch(
      `unlock_collectible_card/${
        highlightCard.value.id
      }?generic=${!highlightCard.value?.collectibleCardId}`,
    )
    .then(async () => {
      const existingFieldId = companyDetailSelected.value.localId;
      companyDetailSelected.value = null;

      await nextTick();
      await companyDetailStore.fetchCompanyData();
      const updatedField = _.find(companyAllFetchedFields.value, {
        localId: existingFieldId,
      });
      companyDetailSelected.value = updatedField;
    });
}

async function resetDisplayable() {
  console.log("reset displayable");
  displayable.value = false;

  await nextTick();
  displayable.value = true;
}

function saveCardToTasks() {
  if (signedIn.value) {
    api
      .patch(`accept_content_collectible_card/${highlightCard.value.id}`)
      .then(async (json) => {
        displayable.value = false;
        highlightCard.value = json.data;

        await nextTick();

        displayable.value = true;
      });
  }
}
</script>
