<template>
  <div class="relative h-full">
    <div class="h-full w-full flex flex-row">
      <div class="h-full w-[400px] flex flex-col bg-white overflow-x-hidden">
        <component :is="selectionComponent" />
      </div>
      <div class="w-full h-full flex flex-col bg-gray-200">
        <component
          :is="rightEdgeComponent"
          v-if="!!rightEdgeComponent"
        />
        <ComingSoon v-else class="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import ComingSoon from "@/components/ComingSoon.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";

const wePanelStore = useWePanelStore();
const { rightEdgeComponent } = storeToRefs(wePanelStore);

defineProps(["selectionComponent"]);
</script>
