<template>
  <div class="flex items-center p-2 border-b border-gray-400 gap-2">
    <button v-if="!expandedView" type="button" @click="wePanelStore.closeRightEdgePanel()">
      <ChevronLeftIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
    </button>
    <slot />
  </div>
</template>

<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";

const wePanelStore = useWePanelStore();
const { expandedView } = storeToRefs(wePanelStore);
</script>
