<template>
  <div
    v-if="mounted"
    class="bg-white divide-y divide-gray-200"
    :class="[
      searchable ? '' : 'shadow rounded-lg',
      context === 'wePanelUserRegions' ? 'overflow-y-auto' : '',
    ]"
  >
    <div :class="searchable && context !== 'wePanelUserRegions' ? '' : 'p-3'">
      <div
        v-if="searchable"
        :class="
          workspaceLayout === 'topAndBottom' ? 'grid-cols-6' : 'grid-cols-1'
        "
        class="grid gap-3 mb-3 items-center"
      >
        <h3 class="leading-5 font-medium text-gray-900">Search</h3>
        <div
          :class="workspaceLayout === 'topAndBottom' ? 'col-span-3' : ''"
          class="divide-y divide-gray-100 overflow-hidden rounded-xl bg-white border border-gray-300 ring-1 ring-black ring-opacity-5"
        >
          <Combobox v-model="selectedRecord" nullable>
            <div
              class="relative"
              v-observe-visibility="{ callback: attachPlaceService }"
            >
              <MagnifyingGlassIcon
                class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <ComboboxInput
                :id="searchInputId"
                class="h-12 w-full border-0 bg-transparent pl-11 pr-11 text-gray-800 placeholder-gray-400 focus:ring-0 text-sm"
                :placeholder="searchable ? 'Location' : 'Address lookup'"
                @keydown.escape="clearSearch"
                @change="rawQuery = $event.target.value"
                :displayValue="(record) => record?.name || record?.description"
              />
              <button
                v-show="rawQuery !== ''"
                @click="clearSearch"
                type="button"
                class="absolute top-3.5 right-4"
              >
                <XMarkIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
              </button>
              <div
                id="polygon-location-places-service-container"
                class="hidden"
              />
            </div>

            <ComboboxOptions
              class="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2"
            >
              <li v-if="regionResults.length > 0">
                <h2 class="text-xs font-semibold text-gray-900">
                  Your Regions<template v-if="huntPolygon"
                    >&nbsp;(select to add to this Hunt)</template
                  >
                </h2>
                <ul class="-mx-4 mt-2 text-sm text-gray-700">
                  <ComboboxOption
                    v-for="(searchResult, index) in regionResults"
                    :key="searchResult.id"
                    :value="searchResult"
                    as="template"
                    :data-test="`region-result-${index}`"
                    v-slot="{ active }"
                  >
                    <li
                      :class="[
                        'flex cursor-default select-none items-center',
                        active && 'bg-indigo-600 text-white',
                      ]"
                    >
                      <ParcelMap
                        :map-id="`region-result-${searchResult.id}-${index}`"
                        :polygon="searchResult"
                        :search-result="true"
                        :nearby="false"
                        class="h-24 w-32"
                      />
                      <span class="ml-3 flex-auto truncate">{{
                        searchResult.name
                      }}</span>
                    </li>
                  </ComboboxOption>
                </ul>
              </li>
              <li v-if="processedPlaces.length > 0">
                <h2 class="text-xs font-semibold text-gray-900">Places</h2>
                <ul class="-mx-4 mt-2 text-sm text-gray-700">
                  <ComboboxOption
                    v-for="(place, index) in processedPlaces"
                    :key="place.place_id"
                    :value="place"
                    as="template"
                    :data-test="`place-result-${index}`"
                    v-slot="{ active }"
                  >
                    <li
                      :class="[
                        'flex cursor-default select-none items-center px-4 py-2',
                        active && 'bg-indigo-600 text-white',
                      ]"
                    >
                      <MapPinIcon
                        :class="[
                          'h-6 w-6 flex-none',
                          active ? 'text-white' : 'text-gray-400',
                        ]"
                        aria-hidden="true"
                      />
                      <span class="ml-3 flex-auto truncate">{{
                        place.description
                      }}</span>
                    </li>
                  </ComboboxOption>
                </ul>
              </li>
            </ComboboxOptions>

            <div
              v-if="noResults"
              class="py-14 px-6 text-center text-sm sm:px-14"
            >
              <ExclamationTriangleIcon
                class="mx-auto h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
              <p class="mt-4 font-semibold text-gray-900">No results found</p>
              <p class="mt-2 text-gray-500">
                We couldn’t find anything with that term. Please try again.
              </p>
            </div>
          </Combobox>
        </div>
        <button
          @click="resetLocation()"
          type="button"
          class="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="flex-shrink-0 -ml-2 mr-1 h-4 w-4 text-gray-400" />
          <span>Reset</span>
        </button>
      </div>

      <div
        :class="
          workspaceLayout === 'topAndBottom' ? 'flex-nowrap' : 'flex-wrap'
        "
        class="flex justify-between items-center"
      >
        <div class="">
          <h3 v-if="searchable" class="leading-5 font-medium text-gray-900">
            Draw region boundaries
          </h3>
          <h3 v-else class="leading-5 font-medium text-gray-900">
            Draw parcel boundaries
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Click on the map to place. Draw/edit controls on map's left edge.
          </p>
        </div>
        <button
          @click="clearMapLayers('focal')"
          type="button"
          :class="workspaceLayout === 'topAndBottom' ? '' : 'mt-2'"
          class="flex-shrink-0 bg-white inline-flex self-end items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="flex-shrink-0 -ml-2 mr-1 h-4 w-4 text-gray-400" />
          <span>Reset</span>
        </button>
      </div>

      <div :id="mapId" class="mt-2 h-64 sm:h-96 w-full" />
    </div>
    <div class="p-3 bg-gray-50 flex flex-col items-end space-y-2">
      <div
        v-if="huntPolygon || context === 'wePanelUserRegions'"
        class="w-full space-y-1"
      >
        <div class="text-sm font-medium text-gray-500">Region Name</div>
        <input
          v-focus
          v-model="regionName"
          type="text"
          name="polygon-name"
          id="polygon-name"
          class="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block text-sm border-gray-300 rounded-md"
          data-test="polygon-name"
        />
      </div>
      <div class="flex items-center space-x-2">
        <button
          @click="cancel"
          type="button"
          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <DataVisibilityButton
          :visibility="searchable ? 'safezone' : 'public'"
          :tooltip="complete ? null : saveTooltip"
          class="ml-3 inline-flex"
        >
          <template v-slot:button>
            <button
              @click="save"
              :disabled="originatingData || !complete"
              type="button"
              :class="[saveButtonStyle]"
              class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <span v-else>Save</span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import ParcelMap from "@/components/maps/ParcelMap.vue";
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import { ExclamationTriangleIcon, MapPinIcon } from "@heroicons/vue/24/outline";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { useReminderStore } from "@/stores/reminder";
import { useUserStore } from "@/stores/user";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useMainMapStore } from "@/stores/mainMap";
import { useDocumentationStore } from "@/stores/documentation";
import { useAvailableValidationsChannelStore } from "@/stores/availableValidationsChannel";
import { fetchNearbyValidationParcels } from "@/components/maps/fetchBoundingBoxProperties";
import { storeToRefs } from "pinia";
import { onMounted, ref, computed, watch, nextTick } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import "leaflet-draw/dist/leaflet.draw";
import api from "@/router/api";
import mapStyles from "@/components/maps/mapStyles";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import moment from "moment";
/* global L */
/* global google */

const props = defineProps([
  "decoratingDataField",
  "polygonDataField",
  "joiningPropertyDataField",
  "context",
]);
const emit = defineEmits(["completed", "cancel"]);

// OPTIONAL SEARCH
const mounted = ref(false);
const dataFetchTime = ref(null);
const regionName = ref(null);
const places = ref([]);
const regionResults = ref([]);
const processedPlaces = computed(() =>
  query.value === "" ? [] : places.value,
);
const placeDetails = ref(null);
const placePredictions = ref(null);
const rawQuery = ref("");
const selectedRecord = ref(null);
const query = computed(() => rawQuery.value.toLowerCase().replace(/^[#>]/, ""));
const hasResults = computed(() => processedPlaces.value.length > 0);
const noResults = computed(
  () => query.value !== "" && rawQuery.value !== "?" && !hasResults.value,
);
const huntPolygon = computed(
  () => props.decoratingDataField?.fieldContentType === "HuntGeographyIntent",
);
const nameComplete = computed(() => {
  const filled = _.trim(regionName.value) !== "";
  return (
    filled || (!huntPolygon.value && props.context !== "wePanelUserRegions")
  );
});
const polygonComplete = computed(() => {
  return !!_.head(featureGroup.value.pm.getLayers());
});
const complete = computed(() => nameComplete.value && polygonComplete.value);
const saveButtonStyle = computed(() => {
  if (!complete.value) {
    return "bg-gray-400 hover:bg-gray-500 focus:ring-gray-500 cursor-not-allowed";
  } else if (searchable.value) {
    return "bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600";
  } else {
    return "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500";
  }
});
const saveTooltip = computed(() => {
  if (huntPolygon.value) {
    return "Name and polygon required.";
  } else {
    return "Polygon required.";
  }
});

function clearSearch() {
  rawQuery.value = "";
  selectedRecord.value = null;
  places.value = [];
}
const searchable = computed(
  () =>
    props.context === "wePanelUserRegions" ||
    props.decoratingDataField?.fieldContentType === "HuntGeographyIntent",
);

// END SEARCH
const refreshing = ref(null);
const map = ref(null);
const zoom = searchable.value ? 10 : 18;
const featureGroup = ref(L.featureGroup());
const parcelFeatureGroup = ref(L.featureGroup());
const baseLayers = ref({
  Base: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: mapStyles.styles,
  }),
  Satellite: L.gridLayer.googleMutant({
    type: "satellite",
    styles: [],
  }),
  Hybrid: L.gridLayer.googleMutant({
    type: "hybrid",
    styles: [],
  }),
  Transit: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: [],
  }),
});
const wePanelStore = useWePanelStore();
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop, workspaceLayout, workspaceResized } =
  storeToRefs(layoutStore);
const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(
  crowdsourcedChangeGroupStore,
);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);
const analyzePanelStore = useAnalyzePanelStore();
const secondaryPanelStore = useSecondaryPanelStore();
const modalStore = useModalStore();
const unlockerStore = useUnlockerStore();
const userStore = useUserStore();
const documentationStore = useDocumentationStore();
const tasksStore = useTasksStore();
const taskListStore = useTaskListStore();
const reminderStore = useReminderStore();
const guestProfileStore = useGuestProfileStore();
const propertyDiagramStore = usePropertyDiagramStore();
const propertyFieldsStore = usePropertyFieldsStore();
const mapStore = useMainMapStore();
const availableValidationsChannelStore = useAvailableValidationsChannelStore();
const { activeValidationChangeGroupId } = storeToRefs(
  availableValidationsChannelStore,
);

const existingPolygon = computed(() => {
  return _.get(props.polygonDataField, "fieldContent", null);
});
const existingLocation = computed(() => {
  return _.get(props.joiningPropertyDataField, "fieldContent", null);
});
const center = computed(() => {
  if (existingLocation.value) {
    const { lat, lng } = existingLocation.value;

    return [_.toNumber(lat), _.toNumber(lng)];
  } else {
    return [42.350853, -71.257712];
  }
});
const searchInputId = computed(() => {
  if (searchable.value) {
    if (props.context === "wePanelUserRegions") {
      return `user-region-search-input`;
    } else {
      return `hunt-geography-intent-${props.decoratingDataField?.fieldContentId}-search-input`;
    }
  } else {
    return null;
  }
});

watch(selectedRecord, async () => {
  onSelect();
});

watch(query, async () => {
  if (query.value !== "") {
    debouncedFilterAll();
  }
});

watch(workspaceLayout, () => {
  if (map.value) {
    setTimeout(function () {
      map.value.invalidateSize();
    }, 100);
  }
});

watch(workspaceResized, () => {
  if (map.value && workspaceResized.value) {
    setTimeout(function () {
      map.value.invalidateSize();
    }, 100);
  }
});
onMounted(async () => {
  if (searchable.value) {
    await mount();
  } else {
    mounted.value = true;
  }

  await nextTick();

  setupMap();

  if (existingPolygon.value) {
    mountExisting();
  } else {
    setupDrawing();
  }
});

async function filterPlaces() {
  if (_.trim(query.value) !== "") {
    const { lat, lng } = map.value.getCenter();
    const location = new google.maps.LatLng(lat, lng);
    placePredictions.value.getQueryPredictions(
      {
        input: query.value,
        location,
        radius: 5000,
      },
      displaySuggestions,
    );
  }
}
const debouncedFilterAll = _.debounce(function () {
  searchAll();
}, 250);

async function searchAll() {
  await Promise.all([filterTowerHuntData(), filterPlaces()]);
}

async function filterTowerHuntData() {
  if (
    (huntPolygon.value || props.context === "wePanelUserRegions") &&
    _.trim(query.value).length > 0
  ) {
    const fetchTime = moment().valueOf();
    dataFetchTime.value = fetchTime;
    const request =
      query.value === "" || query.value === null
        ? null
        : _.toLower(query.value);

    api
      .post(`geography_region_searches`, {
        query: request,
      })
      .then((json) => {
        if (fetchTime === dataFetchTime.value) {
          regionResults.value = json.data;
          dataFetchTime.value = null;
        }
      });
  }
}

const displaySuggestions = function (predictions, status) {
  if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
    console.log(status);
    return;
  }

  places.value = predictions;
};
function resetLocation() {
  if (searchInputId.value) {
    clearSearch();
    document.getElementById(searchInputId.value).focus();
  }
}

async function onSelect() {
  if (!selectedRecord.value) return;

  if (selectedRecord.value.place_id) {
    var request = {
      placeId: selectedRecord.value.place_id,
      fields: ["name", "formatted_address", "address_components", "geometry"],
    };

    placeDetails.value.getDetails(request, handleDetails);
  } else if (selectedRecord.value.coordinates) {
    save();
  }
}
function handleDetails(place, status) {
  if (status == google.maps.places.PlacesServiceStatus.OK) {
    const newLat = place.geometry.location.lat();
    const newLng = place.geometry.location.lng();

    regionName.value = huntPolygon.value ? place.formatted_address : null;

    resetLocation();

    setTimeout(() => {
      map.value.setView([newLat, newLng], 13);
    }, 50);
  }
}

function attachPlaceService(isVisible) {
  const mapDiv = document.getElementById(
    "polygon-location-places-service-container",
  );
  if (!placeDetails.value && isVisible && mapDiv) {
    setTimeout(async () => {
      const { PlacesService, AutocompleteService } =
        await google.maps.importLibrary("places");
      placeDetails.value = new PlacesService(mapDiv);
      placePredictions.value = new AutocompleteService();
    }, 250);
  }
}

async function mount() {
  mounted.value = true;

  await nextTick();

  if (searchInputId.value) {
    document.getElementById(searchInputId.value).focus();
  }
}

const mapId = computed(() => {
  let id = `parcel-map-editing`;

  if (searchable.value) {
    if (props.context === "wePanelUserRegions") {
      id = `user-region-map-editing`;
    } else {
      id = `hunt-geography-intent-${props.decoratingDataField.fieldContentId}-region-map-editing`;
    }
  }

  return id;
});

function setupMap() {
  map.value = L.map(mapId.value, {
    fullscreenControl: true,
    zoomAnimation: false,
    fadeAnimation: true,
    zoomSnap: 0.25,
    zoomDelta: 0.25,
    keyboardPanDelta: 40,
    markerZoomAnimation: true,
  }).setView(center.value, zoom);

  baseLayers.value["Transit"].addGoogleLayer("TransitLayer");

  if (searchable.value) {
    baseLayers.value["Base"].addTo(map.value);
  } else {
    baseLayers.value["Satellite"].addTo(map.value);
  }

  map.value.on("fullscreenchange", async function () {
    if (map.value.isFullscreen()) {
      map.value.scrollWheelZoom.enable();
    } else {
      map.value.scrollWheelZoom.disable();
      await nextTick();
      map.value.fitBounds(featureGroup.value.getBounds(), { maxZoom: zoom });
    }
  });

  map.value.on("moveend", function () {
    transitionMapLayer();
    if (!refreshing.value) {
      debouncedRefreshAllLayers();
    }
  });

  map.value.on("pm:create", ({ layer }) => {
    featureGroup.value.addLayer(layer);
    setEditingControls();
  });

  map.value.on("pm:remove", () => {
    clearMapLayers("focal");
  });

  parcelFeatureGroup.value.addTo(map.value);

  L.control
    .layers(
      baseLayers.value,
      {},
      {
        collapsed: true,
      },
    )
    .addTo(map.value);

  if (!refreshing.value) {
    debouncedRefreshAllLayers();
  }
}

function transitionMapLayer() {
  const currentZoom = map.value.getZoom();

  if (currentZoom > 14.25) {
    toggleMapLayer("Hybrid");
  } else {
    toggleMapLayer("Base");
  }
}

function toggleMapLayer(layerName) {
  const layerToCheck = _.get(baseLayers.value, layerName);

  if (!layerToCheck) {
    return;
  } else if (map.value.hasLayer(layerToCheck)) {
    return;
  } else {
    let addedLayer;

    _.forEach(baseLayers.value, function (layer, key) {
      if (key == layerName) {
        addedLayer = layer;
      } else {
        layer.remove();
      }
    });

    addedLayer.addTo(map.value);
  }
}

const debouncedRefreshAllLayers = _.debounce(function () {
  refreshAllLayers();
}, 2000);

async function refreshAllLayers() {
  refreshing.value = true;
  clearMapLayers("nearby");
  await fetchNearby();
  refreshing.value = false;
}

async function fetchNearby() {
  return new Promise((resolve) => {
    if (isDesktop.value) {
      fetchNearbyValidationParcels({
        map: map.value,
        mapStore,
        propertyFieldsStore,
        modalStore,
        unlockerStore,
        userStore,
        tasksStore,
        taskListStore,
        reminderStore,
        guestProfileStore,
        propertyDiagramStore,
        changeGroupStore: crowdsourcedChangeGroupStore,
        secondaryPanelStore,
        documentationStore,
        analyzePanelStore,
        layoutStore,
        parcelFeatureGroup: parcelFeatureGroup.value,
        asOfMilliseconds: asOfMilliseconds.value,
        activeChangeGroupId: activeValidationChangeGroupId.value,
      }).then(() => {
        resolve();
      });
    } else {
      resolve();
    }
  });
}

function setupDrawing() {
  map.value.pm.enableDraw("Polygon", {
    allowSelfIntersection: false,
  });
}

function mountExisting() {
  displayParcel();
}

function displayParcel() {
  if (existingPolygon.value) {
    const mapPolygon = L.polygon(existingPolygon.value.coordinates, {
      interactive: false,
      color: "#FDBA74", // orange-300
      stroke: true,
      fill: true,
    });
    mapPolygon.addTo(map.value);
    featureGroup.value.addLayer(mapPolygon);
    map.value.fitBounds(featureGroup.value.getBounds(), { maxZoom: zoom });
    setEditingControls();
  }
}

function getCoordinates() {
  let polygonLayer = _.head(featureGroup.value.pm.getLayers());
  if (polygonLayer) {
    return polygonLayer.getLatLngs()[0].map((c) => {
      return { lat: c.lat, lng: c.lng };
    });
  }
}
async function clearMapLayers(scope) {
  let group = null;

  switch (scope) {
    case "nearby":
      group = parcelFeatureGroup.value;
      break;
    case "focal":
      group = featureGroup.value;
      break;
    default:
      break;
  }

  group.eachLayer((layer) => {
    map.value.removeLayer(layer);
    group.removeLayer(layer);
  });

  await nextTick();

  if (scope === "focal") {
    regionName.value = null;
    map.value.pm.enableDraw("Polygon", {
      allowSelfIntersection: false,
    });
  }
}
function setEditingControls() {
  map.value.pm.addControls({
    position: "topleft",
    drawMarker: false,
    drawCircleMarker: false,
    drawPolyline: false,
    drawRectangle: false,
    drawPolygon: false,
    drawCircle: false,
    drawText: false,
    editMode: false,
    dragMode: false,
    rotateMode: false,
    cutPolygon: false,
    removalMode: false,
  });
  featureGroup.value.pm.enable({
    allowSelfIntersection: false,
  });
}
function cancel() {
  emit("cancel");
  if (props.context === "wePanelUserRegions") {
    wePanelStore.closeSecondaryContentPanel();
  }
}
async function save() {
  featureGroup.value.pm.disable();
  const coords = getCoordinates();
  const eligibleCoords = coords && coords.length >= 3;
  const eligibleSearchResult = selectedRecord.value?.coordinates;

  if (eligibleCoords || eligibleSearchResult) {
    if (props.context === "wePanelUserRegions") {
      await saveStandaloneUserRegion();
    } else {
      await dismiss();

      const apiRequestFunc = () => persist();
      const successCallback = () => emit("completed");
      const failureCallback = () => cancel();

      return crowdsourcedChangeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    }
  }
}
async function dismiss() {
  if (!props.polygonDataField) return;

  await crowdsourcedChangeGroupStore.dismissData({
    dataFieldId: props.polygonDataField.localId,
    successCallback: () => {},
  });
}

async function saveStandaloneUserRegion() {
  let payload = {
    context: "wePanelUserRegions",
    geographyRegionId: selectedRecord.value?.coordinates
      ? selectedRecord.value?.id
      : null,
    name: regionName.value,
  };
  let polygonLayer = _.head(featureGroup.value.pm.getLayers());

  if (polygonLayer) {
    _.merge(payload, {
      coordinates: getCoordinates(),
    });
  }

  const regionResponse = await api.post(`geography_regions`, payload);

  if (regionResponse?.data) {
    wePanelStore.patchRegion(regionResponse.data);
  }
  wePanelStore.closeSecondaryContentPanel();
}

function persist() {
  let payload = {};
  if (huntPolygon.value) {
    if (selectedRecord.value?.coordinates) {
      payload = {
        geographyRegionId: selectedRecord.value?.id,
        contentType: props.decoratingDataField.fieldContentType,
        contentToken: props.decoratingDataField.fieldContentId,
        changeGroupId: changeGroupId.value,
      };

      return api.post(`geography_regions`, payload);
    } else {
      payload = {
        name: regionName.value,
        contentType: props.decoratingDataField.fieldContentType,
        contentToken: props.decoratingDataField.fieldContentId,
        changeGroupId: changeGroupId.value,
      };

      let polygonLayer = _.head(featureGroup.value.pm.getLayers());

      if (polygonLayer) {
        _.merge(payload, {
          coordinates: getCoordinates(),
        });

        return api.post(`geography_regions`, payload);
      } else {
        return {};
      }
    }
  } else {
    payload = {
      contentType: props.decoratingDataField.fieldContentType,
      contentToken: props.decoratingDataField.fieldContentId,
      changeGroupId: changeGroupId.value,
    };

    let polygonLayer = _.head(featureGroup.value.pm.getLayers());

    if (polygonLayer) {
      const latLngs = polygonLayer.getLatLngs()[0];
      const a = L.GeometryUtil.geodesicArea(latLngs);
      const acres = a / 4047;

      _.merge(payload, {
        coordinates: getCoordinates(),
        area: acres,
      });

      return api.post(`content_polygons`, payload);
    } else {
      return {};
    }
  }
}
</script>
