<template>
  <li
    class="relative flex px-2 space-x-2 hover:bg-gray-300"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    @touchstart="touchStartHandler"
    @touchend="touchEndHandler"
  >
    <span
      class="flex-shrink-0 inline-block size-8 overflow-hidden rounded-full bg-gray-100"
    >
      <svg
        class="size-full text-gray-300"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
    </span>
    <div class="flex-grow flex flex-col">
      <div class="flex items-baseline space-x-2">
        <div class="text-gray-800 text-sm font-semibold">Author info</div>
        <div class="text-gray-500 text-xs">Timestamp</div>
      </div>
      <div class="flex-grow space-y-3 text-gray-700 text-sm">
        <p v-for="(paragraph, index) in paragraphs" :key="index">
          {{ words.join(" ") }}
        </p>
      </div>
    </div>
    <WeMessageInlineActions
      v-if="isDesktop && (isHovered || actionsMenuOpen)"
      class="absolute -top-4 right-4"
      :message="message"
      @more-open="actionsMenuOpen = true"
      @more-closed="actionsMenuOpen = false"
    />
  </li>
</template>

<script setup>
import WeMessageBottomEdgeMoreActions from "@/components/we/message-output/WeMessageBottomEdgeMoreActions.vue";
import WeMessageInlineActions from "@/components/we/message-output/WeMessageInlineActions.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ref, markRaw } from "vue";
import _ from "lodash";

const props = defineProps(["message"]);

const wePanelStore = useWePanelStore();
const { bottomEdgeComponent, bottomEdgeProps } = storeToRefs(wePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const isHovered = ref(false);
const actionsMenuOpen = ref(false);
const longPressTimeout = ref(null);

const paragraphs = _.times(_.random(1, 6));
const words = _.times(_.random(5, 50), function () {
  return "text";
});

function touchStartHandler(event) {
  longPressTimeout.value = setTimeout(() => {
    event.preventDefault();
    viewBottomEdgeActions();
  }, 500); // Adjust 500ms (long press duration) as needed
}

function touchEndHandler() {
  clearTimeout(longPressTimeout.value);
}

function viewBottomEdgeActions() {
  if (!isDesktop.value) {
    bottomEdgeComponent.value = markRaw(WeMessageBottomEdgeMoreActions);
    bottomEdgeProps.value = {
      message: props.message,
    };
  }
}
</script>
