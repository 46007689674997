import {
  DocumentArrowUpIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/vue/20/solid";
import { useUserStore } from "@/stores/user";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useWePanelStore } from "@/stores/wePanel";
import { defineStore, storeToRefs, acceptHMRUpdate } from "pinia";
import { computed, ref } from "vue";
import _ from "lodash";

export const useWeMessageInputStore = defineStore("weMessageInput", () => {
  const secondaryPanelStore = useSecondaryPanelStore();
  const userStore = useUserStore();
  const { signedIn } = storeToRefs(userStore);
  const wePanelStore = useWePanelStore();
  const { mainContent } = storeToRefs(wePanelStore);
  const creationOptions = ref([
    {
      name: "Upload a File",
      handler: () => {
        const callback = (fileInputRef) => {
          fileInputRef.click();
        };
        registrationGuard(callback);
      },
      icon: DocumentArrowUpIcon,
    },
    {
      name: "Create Thread",
      handler: () => {
        const callback = () => {};
        registrationGuard(callback);
      },
      icon: ChatBubbleLeftRightIcon,
    },
  ]);
  const actionableCreationOptions = computed(() => {
    if (threadable.value) {
      return creationOptions.value;
    } else {
      return creationOptions.value.filter(
        ({ name }) => name !== "Create Thread",
      );
    }
  });

  const threadable = computed(
    () => !_.includes(["directMessage", "thread"], mainContent.value?.contentType),
  );

  function resizeTextArea(event) {
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  }

  async function sendMessage() {
    const callback = () => {
      console.log("send message");
    };
    registrationGuard(callback);
  }

  function registrationGuard(callback) {
    if (signedIn.value) {
      callback();
    } else {
      secondaryPanelStore.promptToRegister();
    }
  }

  return {
    creationOptions,
    actionableCreationOptions,
    threadable,
    resizeTextArea,
    sendMessage,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useWeMessageInputStore, import.meta.hot),
  );
}
