<template>
  <li
    v-if="thread.isVisible"
    class="relative w-full flex pl-6"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <span
      class="absolute -top-6 left-3 -ml-px -mt-px w-[50%] h-[120%] rounded border-l-2 border-b-2 border-gray-300"
      aria-hidden="true"
    ></span>
    <a
      href=""
      class="w-full px-1 py-2 rounded-md flex items-center justify-between hover:bg-gray-300 gap-x-2 z-10"
      :class="[wePanelStore.activeSelectionOptionClass(thread)]"
      @click.prevent="view"
    >
      <div
        class="max-w-[90%] flex-shrink-0 flex items-center space-x-2 text-sm text-gray-700 font-medium hover:text-gray-600"
      >
        <div class="truncate">{{ thread.name }}</div>
      </div>
    </a>
  </li>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { markRaw, ref } from "vue";
import ChannelMessagesContainer from "@/components/we/main-content-views/ChannelMessagesContainer.vue";

const props = defineProps(["thread"]);
const wePanelStore = useWePanelStore();
const { rightEdgeComponent, mainContent } = storeToRefs(wePanelStore);

const isHovered = ref(false);

function view() {
  rightEdgeComponent.value = markRaw(ChannelMessagesContainer);
  mainContent.value = {
    contentType: "thread",
    content: props.thread,
  };
}
</script>
