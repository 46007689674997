<template>
  <main v-if="tabs && selectedTab" class="h-full flex flex-1">
    <!-- Narrow sidebar-->
    <nav
      aria-label="Sidebar"
      class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
    >
      <div class="flex flex-col space-y-2">
        <a
          v-for="tab in visibleTabs"
          :key="tab.name"
          @click.prevent="selectTab(tab)"
          :href="tab.href"
          v-tooltip="tab.name"
          :class="[
            selectedTab.name === tab.name
              ? 'text-gray-700 bg-gray-300'
              : 'text-gray-500 hover:bg-gray-200',
            'relative flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
          ]"
          :data-test="`${_.kebabCase(tab.tabQuery)}-nav`"
        >
          <span class="sr-only">{{ tab.name }}</span>
          <span
            v-if="
              signedIn &&
              tab.name === 'News' &&
              availableCollectibleCardCount > 0
            "
            class="absolute -top-2 -right-1 inline-flex items-center justify-center h-4 min-w-4 rounded-full bg-orange-600 px-1 py-0.5 text-xs font-medium text-white"
            data-test="untouched-news-card-count"
            >{{ availableCollectibleCardCount }}</span
          >
          <span
            v-else-if="signedIn && tab.name === 'Tasks' && todayCount > 0"
            class="absolute -top-2 -right-1 inline-flex items-center justify-center h-4 min-w-4 rounded-full bg-orange-600 px-1 py-0.5 text-xs font-medium text-white"
            data-test="today-tasks-count"
            >{{ todayCount }}</span
          >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            :class="selectedTab.name === tab.name ? '' : ''"
            class="h-5 w-5"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            v-html="pathsForIcon(tab.svgPaths)"
          ></svg>
        </a>
      </div>
    </nav>

    <div
      v-if="selectedTab.tabQuery === 'News'"
      class="flex flex-col flex-grow overflow-x-hidden"
    >
      <ContentHeader :no-margin="true">
        <template v-slot:title>News</template>
        <template v-slot:description
          >Personalized market and platform alerts.</template
        >
      </ContentHeader>
      <div class="flex flex-grow overflow-x-hidden">
        <component :is="selectedTab.component" v-bind="selectedTabProperties" />
      </div>
    </div>

    <div v-else class="flex-grow overflow-x-hidden">
      <component :is="selectedTab.component" v-bind="selectedTabProperties" />
    </div>
  </main>
</template>

<script setup>
import {
  initialSelectedTab,
  tabs,
} from "@/components/collectible-cards/meTabs";
import { useUserStore } from "@/stores/user";
import { useTasksStore } from "@/stores/tasks";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useRoute, useRouter } from "vue-router";
import { computed, ref, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import pathsForIcon from "@/assets/pathsForIcon";
import _ from "lodash";

const userStore = useUserStore();
const { signedIn, availableCollectibleCardCount } = storeToRefs(userStore);
const tasksStore = useTasksStore();
const { todayCount } = storeToRefs(tasksStore);
const secondaryPanelStore = useSecondaryPanelStore();
const selectedTab = ref(null);
const visibleTabs = computed(() => {
  if (signedIn.value) {
    return tabs;
  } else {
    return [_.get(tabs, "news"), _.get(tabs, "tasks")];
  }
});
const selectedTabProperties = {
  context: "panel",
};
const horizontalIsMe = computed(
  () => _.get(query.value, "horizontalTab") === "Me",
);

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);

onMounted(() => {
  refreshTabs();
});

watch(horizontalIsMe, () => {
  if (horizontalIsMe.value) refreshTabs();
});

watch(query, () => {
  selectTab();
});

async function refreshTabs() {
  selectTab();
}

async function selectTab(tab) {
  const oldTabQuery = _.get(selectedTab.value, "tabQuery");
  const hasVerticalTab = !!_.get(query.value, "verticalTab");

  return new Promise((resolve) => {
    if (tab) selectedTab.value = tab;
    else if (horizontalIsMe.value) {
      if (hasVerticalTab) {
        selectedTab.value = _.find(visibleTabs.value, {
          tabQuery: query.value.verticalTab,
        });
      } else {
        selectedTab.value = initialSelectedTab();
      }
    }

    const newTabQuery = _.get(selectedTab.value, "tabQuery");

    if (selectedTab.value && newTabQuery !== oldTabQuery) {
      if (newTabQuery === "News") {
        router.push({
          name: route.name,
          query: {
            ...route.query,
            verticalTab: newTabQuery,
            taskList: undefined,
            taskIds: undefined,
            relatedTaskIds: undefined,
          },
        });
        secondaryPanelStore.setSecondaryPanelWidth();
      } else {
        router.push({
          name: route.name,
          query: {
            ...route.query,
            verticalTab: newTabQuery,
          },
        });

        if (_.includes(["Safezone", "Contributions"], newTabQuery)) {
          secondaryPanelStore.setSecondaryPanelWidth("auto 10px 800px");
        } else {
          secondaryPanelStore.setSecondaryPanelWidth();
        }
      }
    }

    resolve();
  });
}
</script>
