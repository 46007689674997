import { defineStore, acceptHMRUpdate } from "pinia";
import { ref, computed } from "vue";
import api from "@/router/api";
import _ from "lodash";
/* global L */

export const usePropertyFieldsStore = defineStore("propertyFields", () => {
  const propertyFields = ref({});
  const fieldKeys = computed(() => _.keys(propertyFields.value));

  async function fetchPropertyDataField(propertyId, scopeParam = "") {
    const existingField = getPropertyField(propertyId);

    if (existingField === "await") {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const result = await fetchPropertyDataField(propertyId);

      return result;
    } else if (_.isObject(existingField)) {
      return existingField;
    } else if (_.includes(fieldKeys.value, propertyId)) {
      return null;
    } else {
      propertyFields.value[propertyId] = "await";
      const response = await api.get(`properties/${propertyId}${scopeParam}`);

      patchPropertyDataFields(response.data);

      return response.data;
    }
  }

  function patchPropertyDataFields(newField) {
    const id = newField.fieldContentId;
    propertyFields.value[id] = newField;
  }

  function getPropertyField(id) {
    return propertyFields.value[id];
  }

  function setPropertyFieldLayerId(id, layerId) {
    const existingField = getPropertyField(id);

    if (existingField) {
      existingField.fieldContent.mapInternalId = layerId;
    } else {
      console.log(id, "not in store for layer", layerId);
    }
  }

  const storeLandCoveringLocationDataFields = ref({});
  async function fetchLandCoveringDataField(landCoveringId, scopeParam = "") {
    const existingField =
      storeLandCoveringLocationDataFields.value[landCoveringId]
        ?.landCoveringField;

    if (existingField === "await") {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const result = await fetchLandCoveringDataField(landCoveringId);

      return result;
    } else if (_.isObject(existingField)) {
      return existingField;
    } else if (
      _.includes(
        _.keys(storeLandCoveringLocationDataFields.value),
        landCoveringId,
      )
    ) {
      return null;
    } else {
      storeLandCoveringLocationDataFields.value[landCoveringId].landCoveringField = "await";
      const response = await api.get(
        `land_coverings/${landCoveringId}${scopeParam}`,
      );

      if (
        _.isObject(storeLandCoveringLocationDataFields.value[landCoveringId])
      ) {
        storeLandCoveringLocationDataFields.value[
          landCoveringId
        ].landCoveringField = response.data;
      } else {
        storeLandCoveringLocationDataFields.value[landCoveringId] = {
          locationField: null,
          landCoveringField: response.data,
        };
      }

      return response.data;
    }
  }
  function setLandCoveringFieldLayerId(id, layerId) {
    const existingField =
      storeLandCoveringLocationDataFields.value[id]?.locationField;

    if (existingField) {
      // console.log("set land covering layer id", layerId, existingField);
      existingField.fieldContent.mapInternalId = layerId;
    } else {
      // console.log(id, "not in store", layerId);
    }
  }
  function dropLandCoveringFieldLayerId(id) {
    const existingField =
      storeLandCoveringLocationDataFields.value[id]?.locationField;

    if (existingField) {
      // console.log("DROP land covering layer id", existingField);
      existingField.fieldContent.mapInternalId = null;
    } else {
      // console.log(id, "not in store");
    }
  }
  const storeParcelPolygonDataFields = ref({});
  async function fetchPropertyRightDataField(propertyRightId, scopeParam = "") {
    const existingField =
      storeParcelPolygonDataFields.value[propertyRightId]
        ?.propertyRightField;

    if (existingField === "await") {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const result = await fetchPropertyRightDataField(propertyRightId);

      return result;
    } else if (_.isObject(existingField)) {
      return existingField;
    } else if (
      _.includes(
        _.keys(storeParcelPolygonDataFields.value),
        propertyRightId,
      )
    ) {
      return null;
    } else {
      storeParcelPolygonDataFields.value[propertyRightId].propertyRightField = "await";
      const response = await api.get(
        `property_rights/${propertyRightId}${scopeParam}`,
      );

      if (
        _.isObject(storeParcelPolygonDataFields.value[propertyRightId])
      ) {
        storeParcelPolygonDataFields.value[
          propertyRightId
        ].propertyRightField = response.data;
      } else {
        storeParcelPolygonDataFields.value[propertyRightId] = {
          parcelField: null,
          propertyRightField: response.data,
        };
      }

      return response.data;
    }
  }
  function setParcelFieldLayerId(id, layerId) {
    const existingField =
      storeParcelPolygonDataFields.value[id]?.parcelField;

    if (existingField) {
      // console.log("set parcel layer id", layerId, existingField);
      existingField.fieldContent.mapInternalId = layerId;
    } else {
      // console.log(id, "not in store for layer", layerId);
    }
  }
  function dropParcelFieldLayerId(id) {
    const existingField =
      storeParcelPolygonDataFields.value[id]?.parcelField;

    if (existingField) {
      // console.log("DROP parcel layer id", existingField);
      existingField.fieldContent.mapInternalId = null;
    } else {
      // console.log(id, "not in store");
    }
  }

  return {
    propertyFields,
    fieldKeys,
    storeLandCoveringLocationDataFields,
    storeParcelPolygonDataFields,
    fetchPropertyDataField,
    getPropertyField,
    patchPropertyDataFields,
    setPropertyFieldLayerId,
    fetchLandCoveringDataField,
    setLandCoveringFieldLayerId,
    dropLandCoveringFieldLayerId,
    fetchPropertyRightDataField,
    setParcelFieldLayerId,
    dropParcelFieldLayerId,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePropertyFieldsStore, import.meta.hot),
  );
}
