<template>
  <main v-if="teamLoaded" class="max-w-lg mx-auto p-2">
    <div class="space-y-6">
      <div class="min-w-0 flex-1 space-y-1">
        <h1 class="text-lg font-medium leading-6 text-gray-900 truncate">
          {{ name }}
        </h1>
        <div v-if="membersLoaded" class="flex items-center -space-x-0.5">
          <AvatarPhoto
            v-for="member in members"
            :key="member.id"
            :person="member"
            border-class="ring-2 ring-white"
            shape-size="h-6 w-6"
            text-size="xs"
            v-tooltip="member.name"
          />
        </div>
      </div>
      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="text-base font-medium leading-7 text-gray-900">
          Safezone Sharing Rules
        </h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">
          Automatically share certain types of info with this team. Rules do not
          retroactively share existing data.
        </p>

        <div class="mt-4">
          <fieldset v-if="sharingRulesLoaded">
            <legend class="text-sm font-semibold leading-6 text-gray-900">
              Content Being Shared
            </legend>
            <div class="mt-4 space-y-4">
              <div class="relative flex gap-x-3">
                <div class="flex h-6 items-center">
                  <input
                    v-model="sharingAll"
                    id="everything"
                    name="everything"
                    type="checkbox"
                    data-test="share-everything-checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div class="text-sm leading-6">
                  <label for="everything" class="font-medium text-gray-900"
                    >Everything</label
                  >
                  <p class="text-gray-500">
                    All safezone data that you create.
                  </p>
                  <p v-if="sharingAll" class="text-red-600">
                    Warning! This will automatically share every safezone field
                    you create. Please be sure this is appropriate for this
                    team.
                  </p>
                </div>
              </div>
              <template v-if="!sharingAll">
                <div class="relative flex gap-x-3">
                  <div class="flex h-6 items-center">
                    <input
                      v-model="sharingNotes"
                      id="notes"
                      name="notes"
                      type="checkbox"
                      :disabled="sharingAll"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div class="text-sm leading-6">
                    <label for="notes" class="font-medium text-gray-900"
                      >Notes</label
                    >
                    <p class="text-gray-500">
                      Any note that you attach to a datapoint.
                    </p>
                  </div>
                </div>
                <div v-if="false" class="relative flex gap-x-3">
                  <div class="flex h-6 items-center">
                    <input
                      v-model="sharingCompanies"
                      id="companies"
                      name="companies"
                      type="checkbox"
                      :disabled="sharingAll"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div class="text-sm leading-6">
                    <label for="companies" class="font-medium text-gray-900"
                      >Companies</label
                    >
                    <p class="text-gray-500">
                      Companies you link (e.g., to deals, as advisors)
                    </p>
                  </div>
                </div>
                <div v-if="false" class="relative flex gap-x-3">
                  <div class="flex h-6 items-center">
                    <input
                      v-model="sharingContacts"
                      id="contacts"
                      name="contacts"
                      type="checkbox"
                      :disabled="sharingAll"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div class="text-sm leading-6">
                    <label for="contacts" class="font-medium text-gray-900"
                      >Contacts</label
                    >
                    <p class="text-gray-500">
                      People you link (e.g., to companies)
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="flex justify-end">
        <button
          @click="cancel"
          type="button"
          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          data-test="cancel-team-button"
        >
          Go Back
        </button>

        <button
          @click="saveRules"
          type="button"
          :disabled="!changesMade"
          class="ml-3 inline-flex justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2"
          :class="buttonStyling"
          data-test="update-team-button"
        >
          Save Rules
        </button>
      </div>
    </div>
  </main>
</template>

<script setup>
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import api from "@/router/api";
import { computed, watch, onMounted, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";

const props = defineProps(["teamId"]);
const emit = defineEmits(["cancel", "saved"]);
const teamLoaded = ref(false);
const membersLoaded = ref(false);
const sharingRulesLoaded = ref(false);
const name = ref(null);
const cachedSharingAll = ref(false);
const cachedSharingNotes = ref(false);
const cachedSharingCompanies = ref(false);
const cachedSharingContacts = ref(false);
const sharingAll = ref(false);
const sharingNotes = ref(false);
const sharingCompanies = ref(false);
const sharingContacts = ref(false);
const members = ref([]);

const userStore = useUserStore();
const { emailUnverified } = storeToRefs(userStore);
const wePanelStore = useWePanelStore();
const { activeOption } = storeToRefs(wePanelStore);

const rulesPayload = computed(() => {
  return {
    sharingAll: sharingAll.value,
    sharingNotes: sharingNotes.value,
    sharingCompanies: sharingCompanies.value,
    sharingContacts: sharingContacts.value,
  };
});
const changesMade = computed(() => {
  return (
    cachedSharingAll.value !== sharingAll.value ||
    cachedSharingNotes.value !== sharingNotes.value ||
    cachedSharingCompanies.value !== sharingCompanies.value ||
    cachedSharingContacts.value !== sharingContacts.value
  );
});
const buttonStyling = computed(() => {
  if (changesMade.value) {
    return "inline-flex justify-center border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
  } else {
    return "border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-indigo-500 cursor-not-allowed";
  }
});

watch(sharingAll, () => {
  if (sharingAll.value) {
    sharingNotes.value = sharingCompanies.value = sharingContacts.value = false;
  }
});

onMounted(() => {
  if (props.teamId) {
    fetchTeam();
    fetchMembers();
    fetchSharingRules();
  } else {
    cancel();
  }
});

function cancel() {
  emit("cancel");
  if (props.teamId && activeOption.value?.container) {
    wePanelStore.closeSecondaryContentPanel();
  }
}
function fetchTeam() {
  api.get(`teams/${props.teamId}`).then((json) => {
    const team = json.data;

    name.value = team.name;
    teamLoaded.value = true;
  });
}
function fetchMembers() {
  members.value = [];
  membersLoaded.value = false;
  api.get(`teams/${props.teamId}/team_memberships`).then((json) => {
    members.value = json.data;
    membersLoaded.value = true;
  });
}
function fetchSharingRules() {
  sharingRulesLoaded.value = false;
  api.get(`teams/${props.teamId}/safezone_team_sharing_rules`).then((json) => {
    mountSharingRules(json.data);
  });
}
function mountSharingRules(rulesData) {
  cachedSharingAll.value = sharingAll.value = rulesData.sharingAll;
  cachedSharingNotes.value = sharingNotes.value = rulesData.sharingNotes;
  cachedSharingCompanies.value = sharingCompanies.value =
    rulesData.sharingCompanies;
  cachedSharingContacts.value = sharingContacts.value =
    rulesData.sharingContacts;

  sharingRulesLoaded.value = true;
}
async function saveRules() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else if (changesMade.value) {
    await api.post(
      `teams/${props.teamId}/safezone_team_sharing_rules`,
      rulesPayload.value,
    );

    emit("saved");
    cancel();
  }
}
</script>
