import api from "@/router/api";
import moment from "moment";
import propertyLandCoveringMapMarker from "@/components/property-diagram/propertyLandCoveringMapMarker";
import locationMarker from "@/components/maps/locationMarker";
import { storeToRefs } from "pinia";
import { addLocationToMap } from "@/components/maps/addLocationToMap";
import { addPolygonToMap } from "@/components/maps/addPolygonToMap";
import { defaultBoundaryMeta } from "@/components/maps/defaultBoundaryMeta";
import _ from "lodash";
/* global L */

export async function fetchNearbyValidationParcels({
  map,
  mapStore,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  secondaryPanelStore,
  documentationStore,
  analyzePanelStore,
  layoutStore,
  parcelFeatureGroup,
  asOfMilliseconds,
  activeChangeGroupId,
}) {
  const zoom = map.getZoom();
  const bounds = map.getBounds();
  const southwest = bounds.getSouthWest();
  const northeast = bounds.getNorthEast();
  const northeastLatLng = [northeast.lat, northeast.lng];
  const southwestLatLng = [southwest.lat, southwest.lng];

  await Promise.all(
    _.compact([
      fetchParcels({
        mode: "validation",
        propertyFieldsStore,
        mapStore,
        modalStore,
        unlockerStore,
        userStore,
        tasksStore,
        taskListStore,
        reminderStore,
        guestProfileStore,
        propertyDiagramStore,
        changeGroupStore,
        layoutStore,
        secondaryPanelStore,
        documentationStore,
        analyzePanelStore,
        featureGroup: parcelFeatureGroup,
        southwestLatLng,
        northeastLatLng,
        asOfMilliseconds,
        activeChangeGroupId,
        horizontalIsAnalyze: false,
        zoom,
      }),
    ]),
  );
}

export async function fetchNearbyValidationProperties({
  nearbyType,
  map,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  documentationStore,
  layoutStore,
  propertyFeatureGroup,
  landCoveringFeatureGroup,
  asOfMilliseconds,
  activeChangeGroupId,
}) {
  const zoom = map.getZoom();
  const bounds = map.getBounds();
  const southwest = bounds.getSouthWest();
  const northeast = bounds.getNorthEast();
  const northeastLatLng = [northeast.lat, northeast.lng];
  const southwestLatLng = [southwest.lat, southwest.lng];

  await Promise.all(
    _.compact([
      nearbyType === "Property"
        ? fetchProperties({
            mode: "validation",
            mapStore: null,
            propertyFieldsStore,
            modalStore,
            unlockerStore,
            userStore,
            tasksStore,
            taskListStore,
            reminderStore,
            guestProfileStore,
            propertyDiagramStore,
            changeGroupStore,
            documentationStore,
            layoutStore,
            featureGroup: propertyFeatureGroup,
            southwestLatLng,
            northeastLatLng,
            asOfMilliseconds,
            activeChangeGroupId,
            zoom,
            propertyZoomTrigger: 16,
            horizontalIsAnalyze: false,
            companyIdParam: null,
            companyDetailStore: null,
            contactIdParam: null,
            contactDetailStore: null,
          })
        : null,
      nearbyType === "LandCovering"
        ? fetchLandCoverings({
            mode: "validation",
            mapStore: null,
            propertyFieldsStore,
            modalStore,
            unlockerStore,
            userStore,
            tasksStore,
            taskListStore,
            reminderStore,
            guestProfileStore,
            propertyDiagramStore,
            changeGroupStore,
            documentationStore,
            featureGroup: landCoveringFeatureGroup,
            southwestLatLng,
            northeastLatLng,
            asOfMilliseconds,
            activeChangeGroupId,
            horizontalIsAnalyze: false,
            zoom,
          })
        : null,
    ]),
  );
}

export async function pingUsers({
  mapStore,
  userStore,
  userGeographiesStore,
  documentationStore,
  userFeatureGroup,
}) {
  const { map } = storeToRefs(mapStore);
  const { currentUser } = storeToRefs(userStore);
  const sessionFetchedAt = _.get(currentUser.value, "sessionFetchedAt");
  const bounds = map.value.getBounds();
  const southwest = bounds.getSouthWest();
  const northeast = bounds.getNorthEast();
  const northeastLatLng = [northeast.lat, northeast.lng];
  const southwestLatLng = [southwest.lat, southwest.lng];

  const zoom = map.value.getZoom();

  if (
    sessionFetchedAt &&
    moment(sessionFetchedAt).isAfter(moment().subtract(5, "minutes"))
  ) {
    await fetchUsers({
      zoom,
      mapStore,
      featureGroup: userFeatureGroup,
      southwestLatLng,
      northeastLatLng,
      userGeographiesStore,
      documentationStore,
    });
  }
}

export async function exploreMap({
  mapStore,
  userStore,
  guestProfileStore,
  polygonDrawer,
  fullSize,
  navigationType,
}) {
  const { map, minimapSize, newOriginOverride } = storeToRefs(mapStore);
  const { signedIn } = storeToRefs(userStore);
  const bounds = map.value.getBounds();
  const southwest = bounds.getSouthWest();
  const northeast = bounds.getNorthEast();
  const northeastLatLng = [northeast.lat, northeast.lng];
  const southwestLatLng = [southwest.lat, southwest.lng];

  const zoom = map.value.getZoom();
  const eligibleMode = fullSize || minimapSize.value === "compact";

  if (zoom >= 16.5 && eligibleMode) {
    const { lat, lng } = map.value.getCenter();
    const payload = {
      lat,
      lng,
      navigationType: newOriginOverride.value ? "origin" : navigationType.value,
      createdAt: new Date(),
    };

    if (signedIn.value) {
      const { sessionGeographyIntents } = storeToRefs(userStore);

      if (sessionGeographyIntents.value === 0) {
        payload.navigationType = "origin";
      }

      await api.post(`user_geography_intents`, payload).then(() => {
        navigationType.value = null;
        newOriginOverride.value = false;
        sessionGeographyIntents.value = sessionGeographyIntents.value + 1;
      });
    } else {
      const { geographyIntents } = storeToRefs(guestProfileStore);

      if (geographyIntents.value.length === 0) {
        payload.navigationType = "origin";
      }

      await geographyIntents.value.push(payload);
      navigationType.value = null;
      newOriginOverride.value = false;
    }
  } else if (zoom < 16.5 || newOriginOverride.value) {
    navigationType.value = "origin";
  }

  await fetchFog({
    zoom,
    polygonDrawer,
    southwestLatLng,
    northeastLatLng,
    signedIn: signedIn.value,
    guestProfileStore,
    userStore,
  });
}

export async function fetchBoundingBoxProperties({
  mapStore,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  userGeographiesStore,
  propertyDiagramStore,
  companyDetailStore,
  contactDetailStore,
  changeGroupStore,
  documentationStore,
  analyzePanelStore,
  layoutStore,
  secondaryPanelStore,
  userFeatureGroup,
  propertyFeatureGroup,
  landCoveringFeatureGroup,
  parcelFeatureGroup,
  asOfMilliseconds,
  activeChangeGroupId,
  routeName,
}) {
  const {
    map,
    propertyZoomTrigger,
    horizontalIsAnalyze,
    minimapSize,
    fetchingMapData,
  } = storeToRefs(mapStore);
  const { signedIn } = storeToRefs(userStore);
  const { companyIdParam } = storeToRefs(companyDetailStore);
  const { contactIdParam } = storeToRefs(contactDetailStore);
  const bounds = map.value.getBounds();
  const southwest = bounds.getSouthWest();
  const northeast = bounds.getNorthEast();
  const northeastLatLng = [northeast.lat, northeast.lng];
  const southwestLatLng = [southwest.lat, southwest.lng];

  const zoom = map.value.getZoom();

  fetchingMapData.value = true;

  await Promise.all([
    fetchUsers({
      zoom,
      mapStore,
      featureGroup: userFeatureGroup,
      southwestLatLng,
      northeastLatLng,
      userGeographiesStore,
      documentationStore,
    }),
    fetchProperties({
      mode: "store",
      mapStore,
      propertyFieldsStore,
      modalStore,
      unlockerStore,
      userStore,
      tasksStore,
      taskListStore,
      reminderStore,
      guestProfileStore,
      propertyDiagramStore,
      changeGroupStore,
      documentationStore,
      analyzePanelStore,
      layoutStore,
      secondaryPanelStore,
      featureGroup: propertyFeatureGroup,
      southwestLatLng,
      northeastLatLng,
      asOfMilliseconds,
      activeChangeGroupId,
      zoom,
      minimapSize: minimapSize.value,
      propertyZoomTrigger: propertyZoomTrigger.value,
      horizontalIsAnalyze: horizontalIsAnalyze.value,
      companyIdParam: companyIdParam.value,
      companyDetailStore,
      contactIdParam: contactIdParam.value,
      contactDetailStore,
    }),
    fetchTaskPriorities({
      signedIn: signedIn.value,
      tasksStore,
      southwestLatLng,
      northeastLatLng,
      zoom,
      propertyZoomTrigger: propertyZoomTrigger.value,
    }),
    fetchLandCoverings({
      mode: "store",
      mapStore,
      propertyFieldsStore,
      modalStore,
      unlockerStore,
      userStore,
      tasksStore,
      taskListStore,
      reminderStore,
      guestProfileStore,
      propertyDiagramStore,
      changeGroupStore,
      secondaryPanelStore,
      documentationStore,
      analyzePanelStore,
      featureGroup: landCoveringFeatureGroup,
      southwestLatLng,
      northeastLatLng,
      asOfMilliseconds,
      activeChangeGroupId,
      horizontalIsAnalyze: horizontalIsAnalyze.value,
      zoom,
    }),
    fetchParcels({
      mode: "store",
      propertyFieldsStore,
      mapStore,
      modalStore,
      unlockerStore,
      userStore,
      tasksStore,
      taskListStore,
      reminderStore,
      guestProfileStore,
      propertyDiagramStore,
      changeGroupStore,
      documentationStore,
      analyzePanelStore,
      layoutStore,
      secondaryPanelStore,
      featureGroup: parcelFeatureGroup,
      southwestLatLng,
      northeastLatLng,
      asOfMilliseconds,
      activeChangeGroupId,
      horizontalIsAnalyze: horizontalIsAnalyze.value,
      zoom,
    }),
    fetchDetailPageHuntBoundaries({
      mapStore,
      companyDetailStore,
      contactDetailStore,
      featureGroup: parcelFeatureGroup,
      asOfMilliseconds,
    }),
    fetchNearbyHunts({
      mapStore,
      southwestLatLng,
      northeastLatLng,
      asOfMilliseconds,
      zoom,
      routeName,
    }),
  ]);

  fetchingMapData.value = false;
}

function fetchFog({
  zoom,
  polygonDrawer,
  southwestLatLng,
  northeastLatLng,
  signedIn,
  userStore,
  guestProfileStore,
}) {
  return new Promise((resolve) => {
    if (signedIn && zoom >= defaultBoundaryMeta.zoom) {
      const { userGeographyIntents } = storeToRefs(userStore);
      api
        .get(`my_map_fog/${southwestLatLng}/${northeastLatLng}`)
        .then((response) => {
          const intents = response.data;
          userGeographyIntents.value = intents;
          if (intents.length > 0) polygonDrawer.consolidateIntents(intents);
          resolve();
        });
    } else {
      const { geographyIntents } = storeToRefs(guestProfileStore);
      const geocodedIntents = geographyIntents.value.filter((intent) => {
        return !!intent.lat && !!intent.lng;
      });

      if (geocodedIntents.length > 0)
        polygonDrawer.consolidateIntents(_.reverse(geocodedIntents));

      resolve();
    }
  });
}

function fetchUsers({
  zoom,
  mapStore,
  featureGroup,
  southwestLatLng,
  northeastLatLng,
  userGeographiesStore,
  documentationStore,
}) {
  return new Promise((resolve) => {
    if (zoom >= defaultBoundaryMeta.zoom) {
      api
        .get(`user_geography_intents/${southwestLatLng}/${northeastLatLng}`)
        .then((response) => {
          const intents = response.data;
          userGeographiesStore.updateUsers(
            intents,
            mapStore,
            featureGroup,
            documentationStore,
          );
          resolve();
        });
    } else {
      resolve();
    }
  });
}

function fetchProperties({
  mode,
  mapStore,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  documentationStore,
  analyzePanelStore,
  layoutStore,
  secondaryPanelStore,
  featureGroup,
  southwestLatLng,
  northeastLatLng,
  asOfMilliseconds,
  activeChangeGroupId,
  zoom,
  minimapSize,
  propertyZoomTrigger,
  horizontalIsAnalyze,
  companyIdParam,
  companyDetailStore,
  contactIdParam,
  contactDetailStore,
}) {
  return new Promise((resolve) => {
    if (companyIdParam || contactIdParam) {
      const { companyFetchedLocationDataFields } =
        storeToRefs(companyDetailStore);
      const { contactFetchedLocationDataFields } =
        storeToRefs(contactDetailStore);
      const requestKey = companyIdParam
        ? `Company${companyIdParam}Locations`
        : `Contact${contactIdParam}Locations`;
      const needsFetch = companyIdParam
        ? _.isEmpty(companyFetchedLocationDataFields.value)
        : _.isEmpty(contactFetchedLocationDataFields.value);
      const endpoint = companyIdParam
        ? `company_properties/${companyIdParam}`
        : `contact_properties/${contactIdParam}`;
      const contextStore = companyIdParam
        ? companyDetailStore
        : contactDetailStore;
      const contextLocations = companyIdParam
        ? companyFetchedLocationDataFields
        : contactFetchedLocationDataFields;
      const displayLocation = (dataField) => {
        const location = dataField.fieldContent;
        const workspaceMarker = propertyLandCoveringMapMarker({
          dataField,
          location,
          mapStore,
          propertyFieldsStore,
          modalStore,
          unlockerStore,
          userStore,
          tasksStore,
          taskListStore,
          reminderStore,
          guestProfileStore,
          propertyDiagramStore,
          changeGroupStore,
          layoutStore,
          secondaryPanelStore,
          documentationStore,
          analyzePanelStore,
          playerMinimap: minimapSize === "micro",
        });

        if (workspaceMarker) {
          featureGroup.addLayer(workspaceMarker);
          location.mapInternalId = featureGroup.getLayerId(workspaceMarker);
        }
        mapStore.setNearbyDataField({
          dataField,
          locationType: "Property",
        });
      };

      console.log(
        "company/contact property fetch",
        contextLocations.value,
        needsFetch && !contextStore.alreadyFetched(requestKey),
      );

      if (needsFetch && !contextStore.alreadyFetched(requestKey)) {
        api.get(`${endpoint}?as_of=${asOfMilliseconds}`).then((json) => {
          const locationFields = json.data;
          contextStore.patchLocationDataFields(locationFields);
          contextStore.registerFetchRequest(
            _.compact(locationFields),
            requestKey,
          );
          contextLocations.value.forEach(displayLocation);

          resolve();
        });
      } else {
        contextLocations.value.forEach(displayLocation);

        resolve();
      }
    } else if (zoom >= propertyZoomTrigger) {
      const analyzeParam =
        mode === "validation" || horizontalIsAnalyze ? "all" : "explored";
      const { fieldKeys } = storeToRefs(propertyFieldsStore);
      const { locationFetchData } = storeToRefs(mapStore);
      const payload = {
        alreadyFetchedPropertyIds: fieldKeys.value,
      };
      let fetchObject = null;

      if (locationFetchData.value) {
        const key = `Property_${southwestLatLng}_${northeastLatLng}_${zoom}`;
        fetchObject = locationFetchData.value[key];

        if (!fetchObject) {
          locationFetchData.value[key] = {
            mapStore,
            featureGroup,
            loadingEvents: false,
            loaded: false,
            count: 10,
          };
          fetchObject = locationFetchData.value[key];
        }
      }

      api
        .post(
          `content_locations/${southwestLatLng}/${northeastLatLng}?zoom=${zoom}&content_type=Property&as_of=${asOfMilliseconds}&active_change_group_id=${activeChangeGroupId}&analyze=${analyzeParam}`,
          payload,
        )
        .then((response) => {
          const { data, pagy } = response.data;
          if (fetchObject) {
            fetchObject.loadingEvents = false;
            fetchObject.loaded = !pagy.next;
            fetchObject.data = data;
            fetchObject.pagy = pagy;
          }
          const contentLocationDataFields = response.data.data;

          contentLocationDataFields.forEach((dataField) => {
            addLocationToMap({
              mode,
              dataField,
              mapStore,
              featureGroup,
              propertyFieldsStore,
              modalStore,
              unlockerStore,
              userStore,
              tasksStore,
              taskListStore,
              reminderStore,
              guestProfileStore,
              propertyDiagramStore,
              changeGroupStore,
              layoutStore,
              secondaryPanelStore,
              documentationStore,
              analyzePanelStore,
            });
          });

          resolve();
        });
    } else {
      resolve();
    }
  });
}

function fetchTaskPriorities({
  signedIn,
  tasksStore,
  southwestLatLng,
  northeastLatLng,
  zoom,
  propertyZoomTrigger,
}) {
  return new Promise((resolve) => {
    if (signedIn && zoom >= propertyZoomTrigger) {
      api
        .post(`property_task_priorities/${southwestLatLng}/${northeastLatLng}`)
        .then((response) => {
          const taskPriorities = response.data;
          const { propertyTaskPriorities } = storeToRefs(tasksStore);

          propertyTaskPriorities.value = taskPriorities;

          resolve();
        });
    } else {
      resolve();
    }
  });
}

function fetchLandCoverings({
  mode,
  mapStore,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  secondaryPanelStore,
  documentationStore,
  analyzePanelStore,
  featureGroup,
  southwestLatLng,
  northeastLatLng,
  asOfMilliseconds,
  activeChangeGroupId,
  horizontalIsAnalyze,
  zoom,
}) {
  return new Promise((resolve) => {
    if (zoom >= 16) {
      const analyzeParam =
        mode === "validation" || horizontalIsAnalyze ? "all" : "explored";
      const { storeLandCoveringLocationDataFields } =
        storeToRefs(propertyFieldsStore);
      const { locationFetchData } = storeToRefs(mapStore);
      const payload = {
        alreadyFetchedLandCoveringIds: _.keys(
          storeLandCoveringLocationDataFields.value,
        ),
      };
      let fetchObject = null;

      if (locationFetchData.value) {
        const key = `LandCovering_${southwestLatLng}_${northeastLatLng}_${zoom}`;
        fetchObject = locationFetchData.value[key];

        if (!fetchObject) {
          locationFetchData.value[key] = {
            mapStore,
            featureGroup,
            loadingEvents: false,
            loaded: false,
            count: 10,
          };
          fetchObject = locationFetchData.value[key];
        }
      }

      api
        .post(
          `content_locations/${southwestLatLng}/${northeastLatLng}?zoom=${zoom}&content_type=LandCovering&as_of=${asOfMilliseconds}&active_change_group_id=${activeChangeGroupId}&analyze=${analyzeParam}`,
          payload,
        )
        .then((response) => {
          const { data, pagy } = response.data;
          if (fetchObject) {
            fetchObject.loadingEvents = false;
            fetchObject.loaded = !pagy.next;
            fetchObject.data = data;
            fetchObject.pagy = pagy;
          }
          const contentLocationDataFields = response.data.data;

          contentLocationDataFields.forEach((dataField) => {
            const location = dataField.fieldContent;
            let workspaceMarker = null;
            if (mode === "store") {
              workspaceMarker = propertyLandCoveringMapMarker({
                dataField,
                location,
                mapStore,
                propertyFieldsStore,
                modalStore,
                unlockerStore,
                userStore,
                tasksStore,
                taskListStore,
                reminderStore,
                guestProfileStore,
                propertyDiagramStore,
                changeGroupStore,
                secondaryPanelStore,
                documentationStore,
                analyzePanelStore,
              });
            } else {
              const markerIcon = locationMarker({
                classes:
                  "h-3 w-3 bg-gray-600 hover:bg-gray-700 focus:ring-gray-500",
                interactive: true,
                mapStore,
                propertyFieldsStore,
                landCoveringId: dataField.decoratingContentId,
              });
              workspaceMarker = L.marker([location.lat, location.lng], {
                draggable: false,
                icon: markerIcon,
              });
            }

            if (workspaceMarker) {
              featureGroup.addLayer(workspaceMarker);
              location.mapInternalId = featureGroup.getLayerId(workspaceMarker);
              if (mode === "store") {
                mapStore.setNearbyDataField({
                  dataField,
                  locationType: "ContentLocation",
                });
              }
            }
          });

          resolve();
        });
    } else {
      resolve();
    }
  });
}

function fetchParcels({
  mode,
  propertyFieldsStore,
  mapStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  layoutStore,
  secondaryPanelStore,
  documentationStore,
  analyzePanelStore,
  featureGroup,
  southwestLatLng,
  northeastLatLng,
  asOfMilliseconds,
  activeChangeGroupId,
  horizontalIsAnalyze,
  zoom,
}) {
  return new Promise((resolve) => {
    if (zoom >= 16) {
      const analyzeParam =
        mode === "validation" || horizontalIsAnalyze ? "all" : "explored";
      const { storeParcelPolygonDataFields } = storeToRefs(propertyFieldsStore);
      const { locationFetchData } = storeToRefs(mapStore);
      const payload = {
        alreadyFetchedPropertyRightIds: _.keys(
          storeParcelPolygonDataFields.value,
        ),
      };
      let fetchObject = null;

      if (locationFetchData.value) {
        const key = `PropertyRight_${southwestLatLng}_${northeastLatLng}_${zoom}`;
        fetchObject = locationFetchData.value[key];

        if (!fetchObject) {
          locationFetchData.value[key] = {
            mapStore,
            featureGroup,
            loadingEvents: false,
            loaded: false,
            count: 10,
          };
          fetchObject = locationFetchData.value[key];
        }
      }

      api
        .post(
          `content_polygons/${southwestLatLng}/${northeastLatLng}?zoom=${zoom}&as_of=${asOfMilliseconds}&active_change_group_id=${activeChangeGroupId}&analyze=${analyzeParam}`,
          payload,
        )
        .then((response) => {
          const { data, pagy } = response.data;
          if (fetchObject) {
            fetchObject.loadingEvents = false;
            fetchObject.loaded = !pagy.next;
            fetchObject.data = data;
            fetchObject.pagy = pagy;
          }

          const contentPolygonDataFields = response.data.data;

          for (const dataField of contentPolygonDataFields) {
            addPolygonToMap({
              mode,
              dataField,
              mapStore,
              featureGroup,
              propertyFieldsStore,
              modalStore,
              unlockerStore,
              userStore,
              tasksStore,
              taskListStore,
              reminderStore,
              guestProfileStore,
              propertyDiagramStore,
              changeGroupStore,
              layoutStore,
              secondaryPanelStore,
              documentationStore,
              analyzePanelStore,
              horizontalIsAnalyze,
            });
          }

          resolve();
        });
    } else {
      resolve();
    }
  });
}

async function fetchDetailPageHuntBoundaries({
  mapStore,
  companyDetailStore,
  contactDetailStore,
  featureGroup,
  asOfMilliseconds,
}) {
  const { companyIdParam, companyFetchedPolygonDataFields } =
    storeToRefs(companyDetailStore);
  const { contactIdParam, contactFetchedPolygonDataFields } =
    storeToRefs(contactDetailStore);

  if (!companyIdParam.value && !contactIdParam.value) return;

  const requestKey = companyIdParam.value
    ? `Company${companyIdParam.value}Polygons`
    : `Contact${contactIdParam.value}Polygons`;

  const needsFetch = companyIdParam.value
    ? _.isEmpty(companyFetchedPolygonDataFields.value)
    : _.isEmpty(contactFetchedPolygonDataFields.value);
  const endpoint = companyIdParam.value
    ? `company_hunt_polygons/${companyIdParam.value}`
    : `contact_hunt_polygons/${contactIdParam.value}`;
  const contextStore = companyIdParam.value
    ? companyDetailStore
    : contactDetailStore;
  const contextPolygons = companyIdParam.value
    ? companyFetchedPolygonDataFields
    : contactFetchedPolygonDataFields;
  const displayPolygon = (dataField) => {
    const huntPolygon = dataField.fieldContent;

    if (huntPolygon.coordinates) {
      const polygon = L.polygon(huntPolygon.coordinates, {
        interactive: false,
        color: "#fb7185", // rose-400
        stroke: true,
        weight: 1.5,
        fill: true,
      });

      featureGroup.addLayer(polygon);
      huntPolygon.mapInternalId = featureGroup.getLayerId(polygon);
    }
  };

  console.log(
    "company/contact polygon fetch",
    contextPolygons.value,
    needsFetch && !contextStore.alreadyFetched(requestKey),
  );

  if (needsFetch && !contextStore.alreadyFetched(requestKey)) {
    const response = await api.get(`${endpoint}?as_of=${asOfMilliseconds}`);

    if (response?.data) {
      const contentPolygonDataFields = response.data;
      contextStore.patchPolygonDataFields(contentPolygonDataFields);
      contextStore.registerFetchRequest(
        _.compact(contentPolygonDataFields),
        requestKey,
      );

      for (const dataField of contextPolygons.value) {
        displayPolygon(dataField);
      }
    }
  } else {
    for (const dataField of contextPolygons.value) {
      displayPolygon(dataField);
    }
  }
}

async function fetchNearbyHunts({
  mapStore,
  southwestLatLng,
  northeastLatLng,
  zoom,
  asOfMilliseconds,
  routeName,
}) {
  if (routeName !== "MainMap") return;

  const { locationFetchData, nearbyRegionHuntIds } = storeToRefs(mapStore);
  const payload = {
    alreadyFetchedHuntIds: nearbyRegionHuntIds.value,
  };
  let huntRegionFetchObject = null;
  let huntInvolvementFetchObject = null;

  if (locationFetchData.value) {
    const huntRegionKey = `HuntRegion_${southwestLatLng}_${northeastLatLng}_${zoom}`;
    huntRegionFetchObject = locationFetchData.value[huntRegionKey];

    if (!huntRegionFetchObject) {
      locationFetchData.value[huntRegionKey] = {
        mapStore,
        featureGroup: null,
        loadingEvents: false,
        loaded: false,
        count: 5,
      };
      huntRegionFetchObject = locationFetchData.value[huntRegionKey];
    }

    const huntInvolvementKey = `HuntInvolvement_${southwestLatLng}_${northeastLatLng}_${zoom}`;
    huntInvolvementFetchObject = locationFetchData.value[huntInvolvementKey];

    if (!huntInvolvementFetchObject) {
      locationFetchData.value[huntInvolvementKey] = {
        mapStore,
        featureGroup: null,
        loadingEvents: false,
        loaded: false,
        count: 5,
      };
      huntInvolvementFetchObject = locationFetchData.value[huntInvolvementKey];
    }
  }

  const regionResponse = await api.post(
    `content_polygons/${southwestLatLng}/${northeastLatLng}?zoom=${zoom}&as_of=${asOfMilliseconds}&content_type=Hunt&output_type=region`,
    payload,
  );

  if (regionResponse?.data) {
    const { data, pagy } = regionResponse.data;
    if (huntRegionFetchObject) {
      huntRegionFetchObject.loadingEvents = false;
      huntRegionFetchObject.loaded = !pagy.next;
      huntRegionFetchObject.data = data;
      huntRegionFetchObject.pagy = pagy;
    }
  }

  const involvementResponse = await api.post(
    `content_polygons/${southwestLatLng}/${northeastLatLng}?zoom=${zoom}&as_of=${asOfMilliseconds}&content_type=Hunt&output_type=involvement`,
    payload,
  );

  if (involvementResponse?.data) {
    const { data, pagy } = involvementResponse.data;
    if (huntInvolvementFetchObject) {
      huntInvolvementFetchObject.loadingEvents = false;
      huntInvolvementFetchObject.loaded = !pagy.next;
      huntInvolvementFetchObject.data = data;
      huntInvolvementFetchObject.pagy = pagy;
    }
  }
}
