import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useNotificationsStore } from "@/stores/notifications";
import { defineStore, acceptHMRUpdate } from "pinia";
import { ref, computed } from "vue";
import api from "@/router/api";
import _ from "lodash";

export const useCalculationFieldsStore = defineStore(
  "calculationFields",
  () => {
    const notificationsStore = useNotificationsStore();
    const calculationFetchData = ref({});
    const allPagysLoaded = computed(() =>
      _.every(calculationFetchData.value, function (fetchObject) {
        if (fetchObject.loaded) {
          return true;
        } else {
          return false;
        }
      }),
    );
    const fetchedFields = computed(() => {
      const rawFetches = _.filter(
        calculationFetchData.value,
        function (fetchData) {
          return fetchData.data;
        },
      );

      const rawFields = rawFetches.flatMap(({ data }) => data);
      const filteredFields = rawFields.filter(
        (maybeField) => _.isObject(maybeField) && maybeField.fieldContentId,
      );

      return _.uniqBy(filteredFields, "fieldContentId");
    });

    const analyzePanelStore = useAnalyzePanelStore();

    async function calculationDataInitialFetch({
      payload,
      key,
      endpoint,
      calculationName,
      analyzePanelRequestKey = null,
      analyzePanelSet = true,
      paginationCount,
    }) {
      if (calculationFetchData.value) {
        let fetchObject = null;
        fetchObject = calculationFetchData.value[key];

        console.log("calculation initial fetch", key);

        if (fetchObject?.loadingEvents) return;
        else if (!fetchObject) {
          fetchObject = setFetchObject(key, paginationCount);
        }

        if (fetchObject) {
          if (!fetchObject?.loaded) {
            fetchObject.loadingEvents = true;
            const response = await api.post(endpoint, payload);

            if (response?.data) {
              const { data, pagy } = response.data;
              fetchObject.loadingEvents = false;
              fetchObject.loaded = !pagy.next;
              fetchObject.data = data;
              fetchObject.pagy = pagy;

              do {
                await loadCalculationData(key, fetchObject, payload);
              } while (!fetchObject.loaded);
            }
          } else {
            console.log("already loaded!", key);
          }

          if (analyzePanelSet) {
            analyzePanelStore.setCalculationDataByName(
              calculationName,
              fetchObject.data,
              analyzePanelRequestKey,
            );
          }

          return fetchObject;
        }
      }
    }

    function setFetchObject(key, count) {
      calculationFetchData.value[key] = {
        loadingEvents: false,
        loaded: false,
        count,
        data: [],
        pagy: null,
        fetchedAt: null,
      };
      return calculationFetchData.value[key];
    }

    function cleanUrl(url) {
      return url.replace("/api/v1/", "");
    }

    async function loadCalculationData(key, fetchObject, existingPayload) {
      if (!fetchObject.pagy?.next) return;

      fetchObject.loadingEvents = true;
      const endpoint = cleanUrl(fetchObject.pagy.next_url);
      const payload = _.merge({}, existingPayload, {
        preLoadedFieldContent: fetchedFields.value,
      });

      try {
        if (fetchObject.pagy.next === 2) {
          notificationsStore.addNotification("paginatedDataLoading", 9999, {
            paginationKey: key,
            keyType: "calculation",
          });
        }

        const response = await api.post(endpoint, payload);

        if (response?.data) {
          const { data, pagy } = response.data;
          console.log("paginated", key, data);

          fetchObject.data = _.uniqBy(
            _.concat(fetchObject.data, data),
            function (maybeField) {
              if (_.isObject(maybeField)) {
                return maybeField.localId;
              } else {
                return maybeField;
              }
            },
          );
          fetchObject.pagy = pagy;

          if (data.length < fetchObject.count || !pagy.next) {
            fetchObject.loadingEvents = false;
            fetchObject.loaded = true;
            console.log("loaded!", key);
          } else {
            fetchObject.loadingEvents = false;
          }
        }
      } catch (error) {
        fetchObject.loadingEvents = false;
      }
    }

    function actionableFetchObjectFor(key) {
      return calculationFetchData.value[key];
    }
    function fetchProgressFor(key) {
      const fetchObject = actionableFetchObjectFor(key);

      if (fetchObject?.pagy && !fetchObject.loaded) {
        const { pagy } = fetchObject;
        const { page, last } = pagy;
        const rawPercentage = page / last;

        return _.round(rawPercentage * 100);
      } else {
        return null;
      }
    }
    function fetchLabelFor(key) {
      if (fetchProgressFor(key)) {
        const { pagy } = actionableFetchObjectFor(key);
        const { count } = pagy;

        return `${fetchProgressFor(key)}% (${count} datapoints)`;
      } else {
        return null;
      }
    }

    return {
      calculationFetchData,
      fetchedFields,
      allPagysLoaded,
      setFetchObject,
      loadCalculationData,
      calculationDataInitialFetch,
      actionableFetchObjectFor,
      fetchProgressFor,
      fetchLabelFor,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCalculationFieldsStore, import.meta.hot),
  );
}
