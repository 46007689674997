<template>
  <VMenu :disabled="!canDisplayMenu">
    <button
      @click.exact="markerSingleClick"
      @click.ctrl.exact="markerCtrlClick"
      v-observe-visibility="{
        callback: fetchLandCoveringDataField,
        once: true,
      }"
      v-tooltip="canDisplayMenu ? '' : `${landCoveringName}`"
      type="button"
      :class="[
        `inline-flex flex-shrink-0 items-center justify-center ${markerSize} border-2 border-gray-300 rounded-md shadow-sm text-white ${markerColor} focus:ring-2 focus:outline-none focus:ring-offset-2`,
        discoveryPulse ? 'discovery-pulse' : '',
      ]"
    >
      <div
        v-if="diagramSelected"
        :class="`${pulseMarkerSize} bg-transparent flex-shrink-0`"
        :style="`box-shadow: 0 0 10px 10px ${markerPulseColor}; animation: pulsate 1.5s ease-out; animation-iteration-count: infinite; animation-delay: 2s;`"
      />
    </button>

    <template #popper>
      <div class="flex flex-col p-1">
        <DataFieldInfoPopup
          v-if="landCoveringDataField?.localId"
          :data-field="landCoveringDataField"
          :map-store="mapStore"
        />
        <div v-if="staked" class="mt-1 flex items-center space-x-2">
          <button
            v-if="alreadyInDiagram"
            @click.prevent="switchToDiagramProperty"
            v-tooltip.bottom="'Focus in diagram'"
            type="button"
            class="h-6 w-6 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <template v-else>
            <button
              v-if="propertyIdParam"
              @click.prevent="addPropertyToDiagram"
              v-tooltip.bottom="'Add to diagram'"
              type="button"
              class="h-6 w-6 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-gray-500 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
                />
              </svg>
            </button>
            <button
              @click.prevent="propertyPage"
              v-tooltip.bottom="
                propertyIdParam ? 'View by itself' : 'View diagram'
              "
              type="button"
              class="h-6 w-6 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-gray-500 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
                />
              </svg>
            </button>
          </template>
        </div>
      </div>
    </template>
  </VMenu>
</template>

<script setup>
import { storeToRefs } from "pinia";
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import api from "@/router/api";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import stateAbbreviationsUS from "@/assets/stateAbbreviationsUS";
import moment from "moment";
import _ from "lodash";

const props = defineProps([
  "mapStore",
  "propertyFieldsStore",
  "modalStore",
  "unlockerStore",
  "userStore",
  "tasksStore",
  "taskListStore",
  "reminderStore",
  "guestProfileStore",
  "propertyDiagramStore",
  "changeGroupStore",
  "secondaryPanelStore",
  "documentationStore",
  "analyzePanelStore",
  "location",
  "locationDataField",
]);
const { map, minimapSize, horizontalIsAnalyze } = storeToRefs(props.mapStore);
const { signedIn } = storeToRefs(props.userStore);
const { discoveredContent } = storeToRefs(props.guestProfileStore);
const {
  propertyDiagramSelectedLandCoveringId,
  propertyDiagramSelectedPropertyId,
  propertyDiagramSelected,
  propertyDiagramPropertyIds,
  propertyIdParam,
} = storeToRefs(props.propertyDiagramStore);
const {
  investmentsChecked,
  loansChecked,
  spaceAvailabilitiesChecked,
  spaceUsagesChecked,
  checkedCategories,
  datedInvestmentPropertyIds,
  datedAvailabilityPropertyIds,
  combinedFilteredLandCoveringIds,
  combinedFilteredPropertyIds,
} = storeToRefs(props.analyzePanelStore);
const { storeLandCoveringLocationDataFields } = storeToRefs(
  props.propertyFieldsStore,
);

const route = useRoute();
const queryZoom = computed(() => _.toNumber(route?.query?.zoom));

const landCoveringDataField = computed(() => {
  return storeLandCoveringLocationDataFields.value[
    props.locationDataField.decoratingContentId
  ]?.landCoveringField;
});
const discoveryPulse = ref(false);
const fieldState = computed(() => _.get(landCoveringDataField.value, "state"));
const propertyId = computed(() => {
  if (landCoveringDataField.value)
    return landCoveringDataField.value.joiningContentId;
  else return propertyIdParam.value;
});
const staked = computed(
  () => landCoveringDataField.value && fieldState.value !== "unstaked",
);
const approved = computed(
  () => landCoveringDataField.value && fieldState.value === "approved",
);
const rejected = computed(
  () => landCoveringDataField.value && fieldState.value === "rejected",
);
const proposed = computed(
  () => landCoveringDataField.value && fieldState.value === "proposed",
);
const landCoveringName = computed(() => {
  const state = props.location.state;
  const abbreviatedState = stateAbbreviationsUS[state];

  return _.get(
    landCoveringDataField.value,
    "fieldContent.name",
    `${props.location.city}, ${abbreviatedState || state}`,
  );
});
const diagramSelected = computed(() => {
  return (
    propertyDiagramSelectedLandCoveringId.value ==
    props.locationDataField.decoratingContentId
  );
});
const alreadyInDiagram = computed(() => {
  return _.includes(
    propertyDiagramPropertyIds.value,
    props.locationDataField.joiningContentId,
  );
});
const excludedInvestment = computed(() => {
  return (
    (investmentsChecked.value || loansChecked.value) &&
    !_.includes(
      datedInvestmentPropertyIds.value,
      props.locationDataField.joiningContentId,
    )
  );
});
const excludedAvailability = computed(() => {
  return (
    (spaceAvailabilitiesChecked.value || spaceUsagesChecked.value) &&
    !_.includes(
      datedAvailabilityPropertyIds.value,
      props.locationDataField.joiningContentId,
    )
  );
});
const excludedLandCovering = computed(() => {
  return (
    checkedCategories.value.length > 0 &&
    !_.includes(
      combinedFilteredLandCoveringIds.value,
      props.locationDataField.decoratingContentId,
    )
  );
});
const excludedProperty = computed(() => {
  return (
    checkedCategories.value.length > 0 &&
    !_.includes(
      combinedFilteredPropertyIds.value,
      props.locationDataField.joiningContentId,
    )
  );
});
const markerPulseColor = computed(() => {
  if (diagramSelected.value) {
    return `#FDBA74`;
  } else {
    return `#4F46E5`;
  }
});
const markerColor = computed(() => {
  if (diagramSelected.value) {
    return `bg-orange-400 hover:bg-orange-500 focus:ring-orange-300`;
  } else if (alreadyInDiagram.value) {
    return `bg-yellow-300 hover:bg-yellow-400 focus:ring-yellow-200`;
  } else if (
    horizontalIsAnalyze.value &&
    ((excludedInvestment.value && excludedAvailability.value) ||
      excludedLandCovering.value ||
      excludedProperty.value)
  ) {
    return `bg-gray-400 hover:bg-gray-500 focus:ring-gray-300`;
  } else if (staked.value && approved.value) {
    return `bg-teal-500 hover:bg-teal-600 focus:ring-teal-400`;
  } else if (staked.value && rejected.value) {
    return `bg-pink-600 hover:bg-pink-700 focus:ring-pink-500`;
  } else if (staked.value) {
    return `bg-orange-600 hover:bg-orange-700 focus:ring-orange-500`;
  } else if (landCoveringDataField.value) {
    return `bg-pink-600 hover:bg-pink-700 focus:ring-pink-500`;
  } else {
    return `bg-gray-800 hover:bg-gray-900 focus:ring-gray-700`;
  }
});
const canDisplayMenu = computed(() => {
  return !propertyIdParam.value || minimapSize.value === "compact";
});
const markerSize = computed(() => {
  if (queryZoom.value > 15) {
    return "h-3 w-3";
  } else {
    return "h-2 w-2";
  }
});
const pulseMarkerSize = computed(() => {
  if (queryZoom.value > 15) {
    return "h-2 w-2";
  } else {
    return "h-1 w-1";
  }
});

onMounted(() => {
  let modalStoreTemp = props.modalStore;
  let unlockerStoreTemp = props.unlockerStore;
  let userStoreTemp = props.userStore;
  let tasksStoreTemp = props.tasksStore;
  let taskListStoreTemp = props.taskListStore;
  let reminderStoreTemp = props.reminderStore;
  let guestProfileStoreTemp = props.guestProfileStore;
  let propertyDiagramStoreTemp = props.propertyDiagramStore;
  let changeGroupStoreTemp = props.changeGroupStore;

  pulseDiscovery();
});

const { modalPayload } = storeToRefs(props.modalStore);
const { upgradeSuccessful } = storeToRefs(props.unlockerStore);

function promptToSubscribe() {
  subscribeInterceptor({
    modalPayloadRef: modalPayload,
    upgradeSuccessfulRef: upgradeSuccessful,
    context: "originating",
  });
}
function markerSingleClick() {
  if (staked.value) {
    if (propertyIdParam.value) {
      if (alreadyInDiagram.value) {
        switchToDiagramProperty();
      } else {
        propertyPage();
      }
    } else {
      propertyPage();
    }
  } else if (landCoveringDataField.value) {
    promptToSubscribe();
  }
}
function markerCtrlClick() {
  if (staked.value && !canDisplayMenu.value) {
    addPropertyToDiagram();
  }
}

function propertyPage() {
  props.secondaryPanelStore.closeValidations();
  setTimeout(() => {
    props.propertyDiagramStore.navigateToDiagram(propertyId.value, true);
  }, 100);
}

function switchToDiagramProperty() {
  if (propertyId.value) {
    propertyDiagramSelectedPropertyId.value = propertyId.value;
    propertyDiagramSelected.value = {
      dataField: landCoveringDataField.value,
      recordType: "LandCovering",
    };
    propertyDiagramSelectedLandCoveringId.value =
      props.locationDataField.decoratingContentId;
  }
}
function addPropertyToDiagram() {
  if (propertyId.value) {
    props.propertyDiagramStore.addPropertyToDiagram(propertyId.value);
    props.propertyDiagramStore.clearSelect(propertyId.value);
  }
}

async function fetchLandCoveringDataField() {
  await props.propertyFieldsStore.fetchLandCoveringDataField(
    props.locationDataField.decoratingContentId,
    "?scope=draft_visible&template=draft_visible",
  );
  pulseDiscovery();
}

function pulseDiscovery() {
  const pulse = () => {
    discoveryPulse.value = true;

    setTimeout(() => {
      discoveryPulse.value = null;
    }, 3100);
  };
  const discoveredAt = _.get(
    props.locationDataField,
    "fieldContent.discovered",
    null,
  );

  if (!signedIn.value) {
    const discoveryId = `${props.locationDataField.decoratingContentType}${props.locationDataField.decoratingContentId}`;

    if (_.includes(discoveredContent.value, discoveryId)) {
      return;
    } else {
      pulse();
      discoveredContent.value = _.union([discoveryId], discoveredContent.value);
    }
  } else if (discoveredAt) {
    if (moment(discoveredAt).isAfter(moment().subtract(7, "seconds"))) {
      pulse();
    } else {
      return;
    }
  } else {
    pulse();
  }
}
</script>

<style scoped>
.discovery-pulse {
  animation: discovery-pulse 3s;
  box-shadow: 0 0 0 8em transparent;
}
@keyframes discovery-pulse {
  0% {
    box-shadow: 0 0 0 0 #ec4899;
  }
}
</style>
