<template>
  <li v-if="isVisible" class="w-full flex flex-col px-2">
    <div class="flex">
      <a
        href=""
        class="w-full px-1 py-2 rounded-md flex items-center justify-between hover:bg-gray-300 gap-x-2 z-10"
        :class="[wePanelStore.activeSelectionOptionClass(channel)]"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        @click.prevent="view"
      >
        <div
          class="max-w-[90%] flex-shrink-0 flex items-center space-x-2 text-sm text-gray-700 font-medium hover:text-gray-600"
        >
          <ChatBubbleLeftRightIcon class="size-5" />
          <div class="truncate">{{ channel.name }}</div>
        </div>
        <div
          v-if="isHovered || wePanelStore.selectionIsActive(channel)"
          class="flex items-center gap-x-1"
        >
          <button
            v-tooltip.top-end="'Create Invite'"
            type="button"
            class="flex-shrink-0 inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            @click.stop.prevent
          >
            <UserPlusIcon class="size-5" />
          </button>
          <button
            v-if="canManage"
            v-tooltip.top-end="'Edit Channel'"
            type="button"
            class="flex-shrink-0 inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            @click.stop.prevent
          >
            <Cog8ToothIcon class="size-5" />
          </button>
        </div>
      </a>
    </div>
    <ol class="relative">
      <span
        class="hidden absolute top-0 left-3 -ml-px h-full w-0.5 bg-gray-300"
        aria-hidden="true"
      ></span>
      <ThreadListElement
        v-for="thread in threadsFor(channel)"
        :key="thread.id"
        :thread="thread"
      />
    </ol>
  </li>
</template>

<script setup>
import {
  ChatBubbleLeftRightIcon,
  Cog8ToothIcon,
  UserPlusIcon,
} from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { computed, markRaw, ref } from "vue";
import ChannelMessagesContainer from "@/components/we/main-content-views/ChannelMessagesContainer.vue";
import _ from "lodash";
import ThreadListElement from "./ThreadListElement.vue";

const props = defineProps(["channel"]);
const wePanelStore = useWePanelStore();
const { rightEdgeComponent, mainContent, categories, threads } =
  storeToRefs(wePanelStore);

const isHovered = ref(false);
const storeCategory = computed(() =>
  _.find(categories.value, { id: props.channel.categoryId }),
);
const isVisible = computed(
  () =>
    storeCategory.value?.expanded ||
    wePanelStore.selectionIsActive(props.channel),
);
function threadsFor(channel) {
  return threads.value.filter((thread) => thread.channelId === channel.id);
}

const canManage = computed(() => true);

function view() {
  rightEdgeComponent.value = markRaw(ChannelMessagesContainer);
  mainContent.value = {
    contentType: "channel",
    content: props.channel,
  };
}
</script>
