<template>
  <div class="h-full w-full relative">
    <template v-if="newestPhotoDataField">
      <button
        v-if="photoLink"
        @click="viewEmbed"
        type="button"
        class="h-full w-full"
        data-test="hero-image-button"
      >
        <div
          class="h-full w-full bg-contain bg-center bg-no-repeat"
          :style="`background-image: url(${photoLink})`"
        />
      </button>
      <button
        v-else
        @click="viewPhotosTab"
        class="h-full w-full text-center text-gray-400 bg-gray-100 hover:bg-gray-200"
      >
        <PhotoIcon class="mx-auto my-auto h-16 w-16" />
      </button>
    </template>
    <div
      v-else
      class="h-full w-full bg-contain bg-center bg-no-repeat"
      style="
        background-image: url(https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png);
      "
    />
    <DataVisibilityButton
      v-if="canAddPhotos"
      visibility="safezone"
      tooltip="Add photos"
      class="absolute bottom-1 left-1"
    >
      <template v-slot:button>
        <button
          @click="uploadPhotos"
          type="button"
          class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-600"
          data-test="upload-photos-button"
        >
          <PlusIcon class="h-3 w-3" />
        </button>
      </template>
    </DataVisibilityButton>
    <button
      v-if="newestPhotoDataField && routeName !== 'MainMap' && !noAdd"
      @click="viewPhotosTab"
      type="button"
      v-tooltip="'View all photos'"
      class="absolute bottom-1 right-1 inline-flex items-center p-1 border border-white rounded-full shadow-sm bg-indigo-700 text-white hover:text-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
      data-test="view-photos-list-button"
    >
      <SquaresPlusIcon class="h-3 w-3" />
    </button>
  </div>
</template>

<script setup>
import { PhotoIcon } from "@heroicons/vue/24/outline";
import { PlusIcon, SquaresPlusIcon } from "@heroicons/vue/20/solid";
import api from "@/router/api";
import { useUserAvailableBalancesChannelStore } from "@/stores/userAvailableBalancesChannel";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { useDataSharingsChannelStore } from "@/stores/dataSharingsChannel";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import { storeToRefs } from "pinia";
import { computed, markRaw, nextTick, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUploadedFileStore } from "@/stores/uploadedFile";
import FileDragDrop from "@/components/crowdsourcing/FileDragDrop.vue";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const props = defineProps(["overrideDataField", "noAdd", "noView"]);
const newestPhotoDataField = ref(null);
const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  propertyIdParam,
  propertyDiagramSelectedPropertyId,
  propertySelectedRecordDataField,
} = storeToRefs(propertyDiagramStore);
const companyDetailStore = useCompanyDetailStore();
const { companyIdParam, companySelectedRecordDataField } =
  storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const { contactIdParam, contactSelectedRecordDataField } =
  storeToRefs(contactDetailStore);
const uploadedFileStore = useUploadedFileStore();
const { completedUploadPayload } = storeToRefs(uploadedFileStore);
const userAvailableBalancesChannelStore =
  useUserAvailableBalancesChannelStore();
const { userAvailableBalancesChannelDataQueue } = storeToRefs(
  userAvailableBalancesChannelStore,
);
const dataLicensesChannelStore = useDataLicensesChannelStore();
const { dataLicensesChannelDataQueue } = storeToRefs(dataLicensesChannelStore);
const dataSharingsChannelStore = useDataSharingsChannelStore();
const { dataSharingsChannelDataQueue } = storeToRefs(dataSharingsChannelStore);

const photoLink = computed(() => {
  return _.get(photo.value, "link");
});
const photo = computed(() => {
  return _.get(newestPhotoDataField.value, "fieldContent");
});
const selectedRecordDataField = computed(() => {
  if (props.overrideDataField) return props.overrideDataField;
  else if (contactIdParam.value) return contactSelectedRecordDataField.value;
  else if (companyIdParam.value) return companySelectedRecordDataField.value;
  else if (propertyIdParam.value) return propertySelectedRecordDataField.value;
  else return null;
});
const joiningId = computed(() => {
  if (props.overrideDataField) {
    return (
      props.overrideDataField.joiningContentId ||
      props.overrideDataField.fieldContentId
    );
  } else {
    return (
      contactIdParam.value ||
      companyIdParam.value ||
      propertyDiagramSelectedPropertyId.value
    );
  }
});
const joiningType = computed(() => {
  if (props.overrideDataField) {
    return (
      props.overrideDataField.joiningContentType ||
      props.overrideDataField.fieldContentType
    );
  } else {
    if (contactIdParam.value) return "Contact";
    else if (companyIdParam.value) return "Company";
    else if (propertyIdParam.value) return "Property";
    else return null;
  }
});
const selectedContentType = computed(() => {
  if (selectedRecordDataField.value?.fieldContent?.recordType === "Contact") {
    return "Contact";
  } else if (selectedRecordDataField.value?.fieldContent?.companyId) {
    return "Company";
  } else {
    return _.get(selectedRecordDataField.value, "fieldContentType");
  }
});
const selectedContentId = computed(() => {
  if (selectedRecordDataField.value?.fieldContent?.recordType === "Contact") {
    return selectedRecordDataField.value.fieldContent.id;
  } else if (selectedRecordDataField.value?.fieldContent?.companyId) {
    return selectedRecordDataField.value?.fieldContent?.companyId;
  } else {
    return _.get(selectedRecordDataField.value, "fieldContentId");
  }
});
const assetDataField = computed(() => {
  if (selectedContentType.value === "CompanyInvolvement") {
    return _.get(
      selectedRecordDataField.value,
      "fieldContent.investment.asset",
    );
  } else {
    return null;
  }
});
const assetMeta = computed(() => {
  if (selectedContentType.value === "CompanyInvolvement") {
    return _.get(selectedRecordDataField.value, "fieldContent.asset");
  } else {
    return null;
  }
});
const assetType = computed(
  () =>
    _.get(assetDataField.value, "fieldContentType") ||
    _.get(assetMeta.value, "recordType"),
);
const assetId = computed(
  () =>
    _.get(assetDataField.value, "fieldContentId") ||
    _.get(assetMeta.value, "id"),
);
const canAddPhotos = computed(() => {
  switch (selectedContentType.value) {
    case "Property":
    case "PropertyRight":
    case "LandCovering":
    case "FloorArea":
    case "FloorAreaLayout":
    case "PropertyEnhancement":
    case "Company":
    case "Contact":
      return !props.noAdd;
    default:
      return false;
  }
});

const unlockerStore = useUnlockerStore();
const { upgradeSuccessful } = storeToRefs(unlockerStore);

watch(completedUploadPayload, () => {
  if (completedUploadPayload.value) {
    console.log(completedUploadPayload.value);
    if (completedUploadPayload.value.stakeStatus === "prompt_to_subscribe") {
      subscribeInterceptor({
        successCallback: () => (completedUploadPayload.value = null),
        modalPayloadRef: modalPayload,
        upgradeSuccessfulRef: upgradeSuccessful,
        context: "originating",
      });
    } else {
      fetchNewestPhoto();
    }
  }
});

watch(userAvailableBalancesChannelDataQueue, () => {
  const data = _.last(userAvailableBalancesChannelDataQueue.value);
  if (data.triggeredBy === "staked_changes") {
    fetchNewestPhoto();
  }
});

watch(dataLicensesChannelDataQueue, () => {
  const data = _.last(dataLicensesChannelDataQueue.value);

  if (data.dataFieldIds) {
    fetchNewestPhoto();
  }
});

watch(dataSharingsChannelDataQueue, () => {
  const data = _.last(dataSharingsChannelDataQueue.value);

  if (data.dataFieldIds) {
    fetchNewestPhoto();
  }
});

watch(selectedRecordDataField, () => {
  if (selectedRecordDataField.value) {
    fetchNewestPhoto();
  }
});

onMounted(() => {
  fetchNewestPhoto();
});

async function fetchNewestPhoto(width = null, height = null) {
  if (selectedRecordDataField.value) {
    newestPhotoDataField.value = null;

    const assetParam = assetType.value ? "?asset_hero=true" : "";
    let resizeParam = "";
    if (width && height) {
      if (assetParam !== "") {
        resizeParam = `&width=${width}&height=${height}`;
      } else {
        resizeParam = `?width=${width}&height=${height}`;
      }
    }

    const photoResponse = await api.get(
      `newest_content_photo/${assetType.value || selectedContentType.value}/${
        assetId.value || selectedContentId.value
      }${assetParam}${resizeParam}`,
    );
    if (photoResponse?.data) {
      newestPhotoDataField.value = photoResponse.data;
      completedUploadPayload.value = null;
    }
  }
}

const router = useRouter();
const route = useRoute();
const routeName = computed(() => route.name);

function viewPhotosTab() {
  if (!props.noView) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Details",
        verticalTab: "Photos",
      },
    });
  }
}

async function viewEmbed() {
  if (!props.noView) {
    await fetchNewestPhoto(1920, 1080);

    setTimeout(() => {
      console.log(photo.value);

      uploadedFileStore.viewEmbed(photo.value);
    }, 250);
  }
}

function uploadPhotos() {
  if (joiningType.value && joiningId.value) {
    modalPayload.value = {
      size: "base",
      theme: "light",
      component: markRaw(FileDragDrop),
      props: {
        endpoint: `content_photos/${selectedContentType.value}/${selectedRecordDataField.value.fieldContentId}?joining_type=${joiningType.value}&joining_id=${joiningId.value}`,
        fileTypes: "image/*",
        fileTypeLabels: "PNG, JPG, GIF",
        sizeLimit: 50,
        multiple: true,
        heading: "Add photos",
        marginBottom: true,
        flashMessage: "Photos saved successfully",
        afterAction: {
          type: "commit",
          actionName: "setUploadedFile",
        },
      },
    };
  }
}
</script>
