<template>
  <div class="shrink-0 flex items-center">
    <caption class="sr-only">
      Tower Hunt
    </caption>
    <div class="flex items-center space-x-2">
      <a
        href=""
        @click.prevent="logoClickHandler"
        class=""
        data-test="home-link"
      >
        <img
          class="h-10 w-auto"
          :class="[
            availableCollectibleCardCount > 0
              ? 'border-2 border-amber-500'
              : '',
            pulseAvailableCards ? 'pulse-cards' : '',
          ]"
          :src="
            brandTextColor
              ? 'https://assets.towerhunt.com/site/Transparent_White_T0.png'
              : 'https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png'
          "
          alt="Tower Hunt"
          data-test="th-logo"
        />
      </a>
      <div v-if="isDesktop" class="flex flex-col">
        <a
          href=""
          @click.prevent="mainMap"
          data-test="th-name"
          :class="brandTextColor || 'text-indigo-600 hover:text-indigo-500'"
          class="pr-8 whitespace-nowrap text-xl font-bold"
        >
          Tower Hunt
        </a>
        <div v-if="availableCollectibleCardCount > 0 && !easyDataForm">
          <a
            v-if="testEnvironment"
            href=""
            @click.prevent="goToCollectibleCards"
            :class="brandTextColor || 'text-indigo-600 hover:text-indigo-500'"
            class="font-medium"
            data-test="collectible-cards-button"
            >Collectible Cards</a
          >
          <SlideUnlock
            v-else-if="sliderDisplayable"
            :height="20"
            :circle="true"
            :text="`Swipe for news`"
            name="shell-news-unlock"
            success-text="Let's go!"
            @completed="goToCollectibleCards"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SlideUnlock from "vue-slide-unlock";
import { useUserStore } from "@/stores/user";
import { useTasksStore } from "@/stores/tasks";
import { useMobileNavigationStore } from "@/stores/mobileNavigation";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { useCollectibleCardsChannelStore } from "@/stores/collectibleCardsChannel";
import { storeToRefs } from "pinia";
import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import _ from "lodash";

defineProps(["brandTextColor"]);
const userStore = useUserStore();
const { currentUser, signedIn, availableCollectibleCardCount } =
  storeToRefs(userStore);
const tasksStore = useTasksStore();
const collectibleCardsChannelStore = useCollectibleCardsChannelStore();
const { collectibleCardsChannelDataQueue, pulseAvailableCards } = storeToRefs(
  collectibleCardsChannelStore,
);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop, workspaceLayout } = storeToRefs(layoutStore);
const mobileNavigationStore = useMobileNavigationStore();
const { selectedMobileTab } = storeToRefs(mobileNavigationStore);
const guestProfileStore = useGuestProfileStore();
const { geographyIntents, discoverySearches } = storeToRefs(guestProfileStore);

const testEnvironment = computed(() => {
  return _.get(currentUser.value, "cypress") === "yes";
});
const sliderDisplayable = ref(true);
const guestGeographySize = computed(() => {
  if (signedIn.value) return 0;
  else return geographyIntents.value.length;
});
const guestDiscoverySize = computed(() => {
  if (signedIn.value) return 0;
  else return discoverySearches.value.length;
});

watch(guestGeographySize, () => {
  if (!signedIn.value) {
    userStore.fetchAvailableCollectibleCardCount();
  }
});

watch(guestDiscoverySize, () => {
  if (!signedIn.value) {
    userStore.fetchAvailableCollectibleCardCount();
  }
});

watch(signedIn, (val, oldVal) => {
  if (val && val !== oldVal) {
    userStore.fetchAvailableCollectibleCardCount();
    tasksStore.fetchUserTaskViewsMeta();
  }
});

watch(availableCollectibleCardCount, async (val, oldVal) => {
  var faviconApple = document.getElementById("favicon-apple");
  var favicon32 = document.getElementById("favicon-32");
  var favicon16 = document.getElementById("favicon-16");
  if (oldVal === 0 && val !== oldVal && val > 0) {
    collectibleCardsChannelStore.resetPulse();
    faviconApple.href = "/apple-touch-icon-alert.png";
    favicon32.href = "/favicon-alert-32x32.png";
    favicon16.href = "/favicon-alert-16x16.png";
  } else if (val === 0) {
    collectibleCardsChannelStore.clearPulse();
    faviconApple.href = "/apple-touch-icon.png";
    favicon32.href = "/favicon-32x32.png";
    favicon16.href = "/favicon-16x16.png";
  }
});

watch(collectibleCardsChannelDataQueue, () => {
  const data = _.last(collectibleCardsChannelDataQueue.value);
  availableCollectibleCardCount.value = data.count;
});

onMounted(async () => {
  collectibleCardsChannelStore.clearPulse();
  userStore.fetchAvailableCollectibleCardCount();
  tasksStore.fetchUserTaskViewsMeta();

  await nextTick();

  if (availableCollectibleCardCount.value > 0)
    collectibleCardsChannelStore.beginPulse();
});

onBeforeUnmount(() => {
  collectibleCardsChannelStore.clearPulse();
});

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);
const routeName = computed(() => route.name);

const easyDataForm = computed(() => routeName.value === "DataEasyButton");

async function goToCollectibleCards() {
  if (workspaceLayout.value !== "sideBySide")
    workspaceLayout.value = "sideBySide";
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "News",
    },
  });

  sliderDisplayable.value = false;

  setTimeout(() => {
    sliderDisplayable.value = true;
  }, 2000);
}

const horizontalIsDetails = computed(
  () => _.get(query.value, "horizontalTab") === "Details",
);
const mainTabs = computed(() =>
  horizontalIsDetails.value ? { horizontalTab: "Me", verticalTab: "News" } : {},
);

function logoClickHandler() {
  if (isDesktop.value) {
    if (availableCollectibleCardCount.value > 0) {
      goToCollectibleCards();
    } else {
      mainMap();
    }
  } else {
    router.push({ path: "/" });
    selectedMobileTab.value = "News";
  }
}

function mainMap() {
  router.push({
    name: "MainMap",
    query: _.merge(
      {},
      { ...route.query, propertyIds: undefined },
      mainTabs.value,
    ),
  });
}
</script>

<style>
:root {
  --su-size-text: 12px !important;
  --su-size-padding: 3px !important;
}
</style>

<style scoped>
:deep(.slideunlock) {
  --su-size-padding: 3px !important;
}
:deep(.slideunlock .slideunlock-text) {
  --su-size-text: 12px !important;
}
.pulse-cards {
  animation: pulse-cards 1.5s;
  box-shadow: 0 0 0 2em transparent;
}

@keyframes pulse-cards {
  0% {
    box-shadow: 0 0 0 0 #f59e0b;
  }
}
</style>
