/* global L */
import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { addLocationToMap } from "@/components/maps/addLocationToMap";
import { addPolygonToMap } from "@/components/maps/addPolygonToMap";
import { useUserStore } from "@/stores/user";
import { useUserGeographiesStore } from "@/stores/userGeographies";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDocumentationStore } from "@/stores/documentation";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { useReminderStore } from "@/stores/reminder";
import { useGuestProfileStore } from "@/stores/guestProfile";
import locationMarker from "@/components/maps/locationMarker";
import api from "@/router/api";
import _ from "lodash";
import { useRoute } from "vue-router";

export const useMainMapStore = defineStore("mainMap", () => {
  const map = ref(null);
  const refreshing = ref(false);
  const fitting = ref(false);
  const placeDetails = ref(null);
  const placePredictions = ref(null);
  const minimapSize = ref("micro");
  const expandedMap = computed(() => minimapSize.value === "compact");
  const zoomBasedLayerSwitchOverride = ref(false);
  const mapAddingProperty = ref(false);
  const fetchingMapData = ref(false);
  const fetchingAddedPropertyAddress = ref(false);
  const mapAddedProperty = ref({
    marker: null,
    name: null,
    address: null,
    cityState: null,
    customAddress: false,
  });
  const mapSearch = ref(null);
  const hoveringSearchResults = ref(false);
  const searchControl = ref(null);
  const loaderControl = ref(null);
  const searchMarker = ref(null);
  const newOriginOverride = ref(false);
  const temporaryMapCenter = ref({
    lat: null,
    lng: null,
    zoom: null,
  });
  const mapBoundaryMeta = ref({
    centerLatLng: null,
    southwestLatLng: null,
    northeastLatLng: null,
    zoom: null,
  });
  const zoom = computed(() => mapBoundaryMeta.value.zoom);
  const centerLatLng = computed(() => mapBoundaryMeta.value.centerLatLng);
  const compositeMapMeta = computed(
    () => `${centerLatLng.value}_${zoom.value}`,
  );
  const propertyMarkerPulseId = ref(null);
  const nearbyPropertyDataFields = ref({});
  const nearbyLandCoveringLocationDataFields = ref({});
  const nearbyParcelPolygonDataFields = ref({});
  const nearbyHunts = computed(() => {
    if (map.value) {
      const bounds = map.value.getBounds();
      return nearbyHuntRegionFields.value.filter(({ fieldContent }) => {
        if (fieldContent) {
          const { coordinates } = fieldContent;

          if (coordinates) {
            return bounds.overlaps(L.polygon(coordinates).getBounds());
          }
        }
      });
    } else {
      return [];
    }
  });
  const nearbyHuntDataFields = computed(() => {
    const huntFetches = _.filter(
      locationFetchData.value,
      function (fetchData, fetchKey) {
        const fetchKeyData = _.split(fetchKey, "_");
        const fetchKeyContentType = _.head(fetchKeyData);

        return fetchKeyContentType === "HuntInvolvement" && fetchData.data;
      },
    );

    const rawHunts = huntFetches.flatMap(({ data }) => data);
    const filteredHunts = rawHunts.filter(({ fieldContentId }) =>
      _.includes(
        nearbyHunts.value.map(({ joiningContentId }) => joiningContentId),
        fieldContentId,
      ),
    );

    return _.uniqBy(filteredHunts, "fieldContentId");
  });
  const nearbyHuntRegionFields = computed(() => {
    const huntFetches = _.filter(
      locationFetchData.value,
      function (fetchData, fetchKey) {
        const fetchKeyData = _.split(fetchKey, "_");
        const fetchKeyContentType = _.head(fetchKeyData);

        return fetchKeyContentType === "HuntRegion" && fetchData.data;
      },
    );

    const rawHunts = huntFetches.flatMap(({ data }) => data);

    return _.uniqBy(rawHunts, "fieldContentId");
  });
  const nearbyRegionHuntIds = computed(() =>
    _.uniq(
      nearbyHuntRegionFields.value.map(
        ({ joiningContentId }) => joiningContentId,
      ),
    ),
  );
  const propertyFieldsStore = usePropertyFieldsStore();
  const { storeLandCoveringLocationDataFields, storeParcelPolygonDataFields } =
    storeToRefs(propertyFieldsStore);

  function cleanUrl(url) {
    return url.replace("/api/v1/", "");
  }
  const locationFetchData = ref({});
  const allPagysLoaded = computed(() =>
    _.every(locationFetchData.value, function (fetchObject) {
      if (fetchObject.loaded) {
        return true;
      } else {
        return false;
      }
    }),
  );
  async function loadPaginatedLocationRecords({ state, somePagy, key, count }) {
    let existingData = locationFetchData.value[key];
    const payload = {
      alreadyFetchedPropertyIds: [], // fieldKeys only on initial fetch, not pagination
    };

    if (somePagy.next && !existingData.loadingEvents) {
      existingData.loadingEvents = true;
      const endpoint = cleanUrl(somePagy.next_url);
      try {
        api.post(endpoint, payload).then((json) => {
          const { data, pagy } = json.data;

          existingData.data = _.uniqBy(
            _.concat(existingData.data, data),
            "localId",
          );
          existingData.pagy = pagy;

          data.forEach((dataField) => {
            if (dataField.fieldContentType === "ContentLocation") {
              addLocationToMap({
                mode: "store",
                dataField,
                mapStore: existingData.mapStore,
                featureGroup: existingData.featureGroup,
                propertyFieldsStore,
                modalStore: useModalStore(),
                unlockerStore: useUnlockerStore(),
                userStore,
                tasksStore: useTasksStore(),
                taskListStore: useTaskListStore(),
                reminderStore: useReminderStore(),
                guestProfileStore: useGuestProfileStore(),
                propertyDiagramStore: usePropertyDiagramStore(),
                changeGroupStore: useCrowdsourcedChangeGroupStore(),
                layoutStore: useWorkspaceLayoutStore(),
                secondaryPanelStore: useSecondaryPanelStore(),
                documentationStore: useDocumentationStore(),
                analyzePanelStore: useAnalyzePanelStore(),
              });
            } else if (dataField.fieldContentType === "ContentPolygon") {
              addPolygonToMap({
                mode: "store",
                dataField,
                mapStore: existingData.mapStore,
                featureGroup: existingData.featureGroup,
                propertyFieldsStore,
                modalStore: useModalStore(),
                unlockerStore: useUnlockerStore(),
                userStore,
                tasksStore: useTasksStore(),
                taskListStore: useTaskListStore(),
                reminderStore: useReminderStore(),
                guestProfileStore: useGuestProfileStore(),
                propertyDiagramStore: usePropertyDiagramStore(),
                changeGroupStore: useCrowdsourcedChangeGroupStore(),
                layoutStore: useWorkspaceLayoutStore(),
                secondaryPanelStore: useSecondaryPanelStore(),
                documentationStore: useDocumentationStore(),
                analyzePanelStore: useAnalyzePanelStore(),
                horizontalIsAnalyze: horizontalIsAnalyze.value,
              });
            }
          });

          if (data.length < count || !pagy.next) {
            state.complete();
            existingData.loadingEvents = false;
            existingData.loaded = true;
            // console.log("pagination fully complete", key);
          } else {
            state.loaded();
            existingData.loadingEvents = false;
          }
        });
      } catch (error) {
        state.error();
        existingData.loadingEvents = false;
      }
    } else {
      // console.log("pagination complete without ever fetching", key);
      state.complete();
      if (existingData) existingData.loaded = true;
    }
  }

  const route = useRoute();
  const query = computed(() => route.query);
  const horizontalIsAnalyze = computed(
    () => _.get(query.value, "horizontalTab") === "Analyze",
  );
  const propertyZoomTrigger = computed(() => {
    return horizontalIsAnalyze.value ? 11 : 13;
  });

  function clearSearchMarker() {
    if (searchMarker.value) {
      map.value.removeLayer(searchMarker.value);
      searchMarker.value = null;
    }
  }

  function clearSearchControl() {
    if (searchControl.value) {
      map.value.removeControl(searchControl.value);
      searchControl.value = null;
    }
  }

  function clearLoaderControl() {
    if (loaderControl.value) {
      map.value.removeControl(loaderControl.value);
      loaderControl.value = null;
    }
  }

  function tapView() {
    let { lat, lng } = map.value.getCenter();
    lat += 0.0000000000005;
    lng += 0.0000000000005;
    const zoom = map.value.getZoom();
    map.value.setView([lat, lng], zoom);
  }

  const userGeographiesStore = useUserGeographiesStore();
  const { userGeographies } = storeToRefs(userGeographiesStore);
  const userStore = useUserStore();
  const { currentUser } = storeToRefs(userStore);

  function renameRemotePendingMarker() {
    const existingUserGeographiesChannel =
      userGeographies.value[currentUser.value.token];

    if (existingUserGeographiesChannel) {
      existingUserGeographiesChannel.channel.perform("rename_pending_marker", {
        token: currentUser.value.token,
        name: mapAddedProperty.value.name,
      });
    }
  }

  async function lookupMapAddedPropertyAddress(mapStoreInstance) {
    return new Promise((resolve) => {
      let payload = {};

      if (mapAddedProperty.value.marker) {
        const currentCoordinates = mapAddedProperty.value.marker.getLatLng();
        payload.lat = currentCoordinates.lat;
        payload.lng = currentCoordinates.lng;
      } else if (mapSearch.value) {
        const { lat, lng } = mapSearch.value;
        payload.lat = lat;
        payload.lng = lng;

        clearSearchMarker();

        const markerIcon = locationMarker({
          classes: "h-5 w-5 bg-red-600 hover:bg-red-700 focus:ring-red-500",
          interactive: true,
          mapStore: mapStoreInstance,
        });
        mapAddedProperty.value.marker = L.marker([lat, lng], {
          icon: markerIcon,
        });
        mapAddedProperty.value.marker.addTo(map.value);
        mapAddedProperty.value.marker.pm.enable();
      }

      fetchingAddedPropertyAddress.value = true;

      api.post(`property_address_lookups`, payload).then(
        (json) => {
          if (json.data) {
            const { street_number, street, city, state } = json.data;
            const number = street_number ? `${street_number} ` : "";
            const cityState = `${city}, ${state}`;
            const rawAddress = `${number}${street}`;
            let simpleAddress;

            simpleAddress = rawAddress;

            if (!mapAddedProperty.value.customAddress) {
              setMapAddedPropertyName({
                name: simpleAddress,
                userCustomized: false,
              });
            }
            mapAddedProperty.value.address = rawAddress;
            mapAddedProperty.value.cityState = cityState;
          } else {
            if (!mapAddedProperty.value.customAddress) {
              setMapAddedPropertyName({
                name: "New Property",
                userCustomized: false,
              });
            }
            mapAddedProperty.value.address = "Unknown address";
            mapAddedProperty.value.cityState = "Unknown area";
          }

          if (!mapSearch.value) mapAddedProperty.value.marker.openPopup();
          fetchingAddedPropertyAddress.value = false;
          resolve();
        },
        () => {
          if (!mapAddedProperty.value.customAddress) {
            setMapAddedPropertyName({
              name: "New Property",
              userCustomized: false,
            });
          }
          if (!mapSearch.value) mapAddedProperty.value.marker.openPopup();
          mapAddedProperty.value.address = "Unknown address";
          mapAddedProperty.value.cityState = "Unknown area";
          fetchingAddedPropertyAddress.value = false;
          resolve();
        },
      );
    });
  }

  function clearMapAddedProperty() {
    mapAddedProperty.value = {
      marker: null,
      address: null,
      cityState: null,
      customAddress: false,
    };
  }

  function setMapAddedPropertyName({ name, userCustomized }) {
    mapAddedProperty.value.name = name;
    mapAddedProperty.value.customAddress = userCustomized;
    renameRemotePendingMarker();
  }

  async function setNearbyDataField({ dataField, locationType }) {
    if (dataField) {
      let layerId = null;
      switch (locationType) {
        case "Property": {
          const propertyId =
            dataField.joiningContentType === "Property"
              ? dataField.joiningContentId
              : null;

          if (propertyId) {
            await propertyFieldsStore.fetchPropertyDataField(
              propertyId,
              "?scope=draft_visible&template=draft_visible",
            );
            layerId = dataField.fieldContent.mapInternalId;
            propertyFieldsStore.setPropertyFieldLayerId(propertyId, layerId);
          }
          nearbyPropertyDataFields.value[dataField.fieldContentId] = dataField;
          break;
        }
        case "ContentLocation":
          nearbyLandCoveringLocationDataFields.value[dataField.fieldContentId] =
            dataField;
          if (
            _.isObject(
              storeLandCoveringLocationDataFields.value[
                dataField.decoratingContentId
              ],
            )
          ) {
            // console.log("land covering exists in store", dataField.decoratingContentId);
            storeLandCoveringLocationDataFields.value[
              dataField.decoratingContentId
            ].locationField = dataField;
          } else {
            // console.log("land covering absent from store", dataField.decoratingContentId);
            storeLandCoveringLocationDataFields.value[
              dataField.decoratingContentId
            ] = {
              locationField: dataField,
              landCoveringField: null,
            };
          }

          layerId = dataField.fieldContent.mapInternalId;
          propertyFieldsStore.setLandCoveringFieldLayerId(
            dataField.decoratingContentId,
            layerId,
          );
          break;
        case "ContentPolygon":
          nearbyParcelPolygonDataFields.value[dataField.fieldContentId] =
            dataField;
          if (
            _.isObject(
              storeParcelPolygonDataFields.value[dataField.decoratingContentId],
            )
          ) {
            // console.log("parcel exists in store", dataField.decoratingContentId);
            storeParcelPolygonDataFields.value[
              dataField.decoratingContentId
            ].parcelField = dataField;
          } else {
            // console.log("parcel absent from store", dataField.decoratingContentId);
            storeParcelPolygonDataFields.value[dataField.decoratingContentId] =
              {
                parcelField: dataField,
                propertyRightField: null,
              };
          }

          layerId = dataField.fieldContent.mapInternalId;
          propertyFieldsStore.setParcelFieldLayerId(
            dataField.decoratingContentId,
            layerId,
          );
          break;
        default:
          console.log("setNearbyDataField: unknown locationType", locationType);
      }
    }
  }

  return {
    map,
    refreshing,
    fitting,
    placeDetails,
    placePredictions,
    mapAddedProperty,
    mapSearch,
    hoveringSearchResults,
    temporaryMapCenter,
    mapBoundaryMeta,
    zoom,
    propertyZoomTrigger,
    centerLatLng,
    minimapSize,
    mapAddingProperty,
    fetchingMapData,
    fetchingAddedPropertyAddress,
    propertyMarkerPulseId,
    nearbyPropertyDataFields,
    nearbyLandCoveringLocationDataFields,
    nearbyParcelPolygonDataFields,
    nearbyHunts,
    nearbyHuntDataFields,
    nearbyHuntRegionFields,
    nearbyRegionHuntIds,
    searchMarker,
    searchControl,
    loaderControl,
    newOriginOverride,
    zoomBasedLayerSwitchOverride,
    expandedMap,
    horizontalIsAnalyze,
    locationFetchData,
    allPagysLoaded,
    compositeMapMeta,
    loadPaginatedLocationRecords,
    clearMapAddedProperty,
    clearSearchMarker,
    clearSearchControl,
    clearLoaderControl,
    setMapAddedPropertyName,
    setNearbyDataField,
    lookupMapAddedPropertyAddress,
    tapView,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMainMapStore, import.meta.hot));
}
