<template>
  <div class="h-full p-8 space-y-4 overflow-y-auto">
    <ul
      v-for="(menuActions, key) in groupedMoreActions"
      :key="key"
      class="divide-y divide-gray-300"
    >
      <li
        v-for="(action, index) in menuActions"
        :key="action.name"
        class="w-full flex p-3"
        :class="[
          menuActions.length === 1 ? 'rounded-md' : '',
          menuActions.length > 1 && index === 0 ? 'rounded-t-md' : '',
          menuActions.length > 1 && index === menuActions.length - 1
            ? 'rounded-b-md'
            : '',
          action.moreActionsSection === '02_confirmationActions'
            ? 'bg-red-100'
            : 'bg-gray-100',
        ]"
      >
        <button
          type="button"
          class="w-full flex items-center font-medium gap-x-3"
          :class="[
            action.moreActionsSection === '02_confirmationActions'
              ? 'text-red-400'
              : 'text-gray-500',
          ]"
          @click.prevent="action.handler"
        >
          <component :is="action.icon" class="size-6" aria-hidden="true" />
          <div class="">{{ action.name }}</div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useWeMessageOutputStore } from "@/stores/weMessageOutput";
import { storeToRefs } from "pinia";

const props = defineProps(["message"]);
const weMessageOutputStore = useWeMessageOutputStore();
const { groupedMoreActions } = storeToRefs(weMessageOutputStore);
</script>
