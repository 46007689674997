<template>
  <GridLoader
    v-if="fetchingAddedPropertyAddress"
    :loading="true"
    size="4px"
    color="#dc2626"
  />
  <div
    v-else
    v-tooltip="tooltip ? tooltip : ''"
    :class="`${classes} ${
      interactive ? '' : 'cursor-not-allowed'
    } inline-flex flex-shrink-0 items-center justify-center ring-4 ring-white rounded-md shadow-sm text-white focus:ring-2 focus:outline-none focus:ring-offset-2`"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import api from "@/router/api";

const props = defineProps([
  "classes",
  "tooltip",
  "interactive",
  "mapStore",
  "propertyFieldsStore",
  "propertyId",
  "landCoveringId",
]);
const { fetchingAddedPropertyAddress } = storeToRefs(props.mapStore);
const propertyDataField = computed(() => {
  return props.propertyFieldsStore?.getPropertyField(props.propertyId);
});
const landCoveringDataField = ref(null);
const tooltip = computed(() => {
  if (props.tooltip) {
    return props.tooltip;
  } else if (propertyDataField.value) {
    return `Existing property: ${propertyDataField.value.fieldContent?.name}`;
  } else if (landCoveringDataField.value) {
    return `Existing land covering: ${landCoveringDataField.value.fieldContent?.name}`;
  } else {
    return "";
  }
});

onMounted(() => {
  if (props.propertyId) {
    fetchPropertyDataField();
  } else if (props.landCoveringId) {
    fetchLandCoveringDataField();
  }
});

async function fetchPropertyDataField() {
  props.propertyFieldsStore.fetchPropertyDataField(
    props.propertyId,
    "?scope=draft_visible&template=draft_visible",
  );
}
async function fetchLandCoveringDataField() {
  api.get(`land_coverings/${props.landCoveringId}`).then((json) => {
    landCoveringDataField.value = json.data;
  });
}
</script>
