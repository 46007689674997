<template>
  <div class="h-full flex flex-col px-2">
    <h2
      class="w-full p-2 flex items-center justify-between border-b border-gray-400"
    >
      <div class="text-gray-700 font-semibold text-sm">
        {{ networkTab }} &mdash; ##
      </div>
    </h2>
    <div
      v-if="friends.length === 0"
      class="mt-8 p-8 flex flex-col items-center gap-y-4"
    >
      <UserIcon class="size-10 text-gray-500" />
      <h3 class="text-center text-sm text-gray-600">
        People make real estate come alive.
      </h3>
      <button
        type="button"
        class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        Add Friend
      </button>
    </div>
    <ol class="flex-grow overflow-y-auto">
      <li v-for="friend in friends" :key="friend">Friend {{ friend }}</li>
    </ol>
  </div>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";
import { UserIcon } from "@heroicons/vue/24/outline";

const wePanelStore = useWePanelStore();
const { networkTab } = storeToRefs(wePanelStore);

const friends = computed(() => _.times(50));
</script>
