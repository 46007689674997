<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" style="z-index: 9999" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-rose-500 to-red-600 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative flex flex-col">
      <div
        v-if="huntId && locationCenter"
        :id="`hunt-location-map-${huntId}-${context}`"
        class="w-full bg-gray-100"
        style="height: 237.5px"
      />
      <div v-else class="w-full bg-gray-100" style="height: 237.5px" />
      <div class="mt-5 flex flex-col items-center">
        <h1
          v-if="cardData?.company"
          class="flex max-w-[18rem] justify-center text-center text-2xl font-bold tracking-tight space-x-2"
        >
          <span class="text-white">{{
            cardData.company.fieldContent.companyName
          }}</span>
        </h1>
        <p
          v-if="cardData?.hunt"
          class="max-w-[16rem] text-center font-medium text text-red-200"
        >
          {{ cardData.hunt.fieldContent.name }}
        </p>
        <p
          v-if="cardData?.huntType"
          class="max-w-[16rem] text-center font-medium text text-red-200"
        >
          {{ cardData.huntType }}
        </p>
        <div
          v-if="cardData?.hunt"
          class="flex items-center justify-center space-x-2"
        >
          <span
            v-if="inPlay"
            class="inline-flex items-center justify-center h-3 w-3 rounded-full bg-green-400"
          />
          <span class="font-medium text text-red-200">{{ dateLabel }}</span>
        </div>
        <div
          v-if="cardData?.company && isDesktop"
          class="pb-3 flex flex-col items-center"
        >
          <a
            href=""
            @click.prevent="viewCompany"
            class="mt-6 flex items-center justify-center rounded-md border border-transparent bg-white px-3 py-2 text-base font-medium text-red-700 shadow-sm hover:bg-red-50"
            >View Company</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* global L */
import mapStyles from "@/components/maps/mapStyles";
import locationMarker from "@/components/maps/locationMarker";
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useMainMapStore } from "@/stores/mainMap";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import milestones from "@/assets/contentMilestones";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import _ from "lodash";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
  "displayBack",
  "generic",
  "context",
  "timelineOverrideField",
]);

const mapStore = useMainMapStore();
const { temporaryMapCenter, newOriginOverride } = storeToRefs(mapStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const featureGroup = ref(L.featureGroup());
const cardData = ref(null);
const huntId = computed(() => _.get(cardData.value, "hunt.fieldContentId"));
const huntState = computed(() =>
  _.get(cardData.value, "hunt.fieldContent.state"),
);
const inPlay = computed(() => {
  return huntState.value && !_.includes(["ceased"], huntState.value);
});
const huntDate = computed(() =>
  _.get(cardData.value, "hunt.fieldContent.date"),
);
const dateLabel = computed(() => {
  switch (huntState.value) {
    case "ceased":
    case "active":
      return _.find(_.get(milestones, `Hunt`, []), {
        value: huntState.value,
      }).statusLabel;
    case "fulfilled":
    default: {
      if (huntDate.value) {
        return moment(huntDate.value).format("MMM YYYY");
      } else {
        return "Undated";
      }
    }
  }
});
const locationCenter = computed(() => {
  return [42.361145, -71.057083];
});
const map = ref(null);
const baseLayers = ref({
  Base: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: mapStyles.styles,
  }),
});

watch(
  () => props.stackIndex,
  () => {
    fetchCardData();
  },
);

onMounted(() => {
  fetchCardData();
});

async function fetchCardData() {
  if (
    props.stackIndex === props.cardIndex &&
    !_.includes(props.completedCardIds, props.card.id)
  ) {
    const cardDataResponse = await api.get(
      `unlocked_hunt_card_data/${
        props.card.collectibleCardId || props.card.id
      }?generic=${props.generic}`,
    );
    cardData.value = cardDataResponse.data;

    await nextTick();
    mountMap();
  }
}

function mountMap() {
  if (!map.value) {
    let blockEl = document.getElementById(
      `hunt-location-map-${huntId.value}-${props.context}`,
    );

    if (!blockEl) return;

    map.value = L.map(`hunt-location-map-${huntId.value}-${props.context}`, {
      scrollWheelZoom: false,
      zoomControl: false,
      dragging: false,
      boxZoom: false,
      doubleClickZoom: false,
      zoomAnimation: false,
      fadeAnimation: true,
      markerZoomAnimation: true,
    }).setView(locationCenter.value, 13);

    baseLayers.value["Base"].addTo(map.value);
    addPolygon();
  }
}

function addPolygon() {
  if (cardData.value?.regions) {
    for (const region of cardData.value?.regions) {
      if (region.coordinates) {
        const mapPolygon = L.polygon(region.coordinates, {
          interactive: false,
          color: "#f43f5e",
          stroke: true,
          fill: true,
        });

        mapPolygon.addTo(map.value);
        featureGroup.value.addLayer(mapPolygon);
        map.value.fitBounds(featureGroup.value.getBounds());
      }
    }
  }
}

const router = useRouter();
const route = useRoute();

function viewCompany() {
  if (cardData.value?.company) {
    router.push({
      name: "CompanyShell",
      params: { companyId: cardData.value.company.fieldContent.companyId },
      query: {
        ...route.query,
      },
    });
  }
}
</script>
