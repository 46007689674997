import { useUserStore } from "@/stores/user";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { defineStore, storeToRefs, acceptHMRUpdate } from "pinia";
import { computed, markRaw, ref, watch } from "vue";
import moment from "moment";
import api from "@/router/api";
import {
  ChatBubbleOvalLeftIcon,
  GlobeAmericasIcon,
  MapPinIcon,
  PlusCircleIcon,
  SparklesIcon,
} from "@heroicons/vue/24/outline";
import _ from "lodash";
import ComingSoon from "@/components/ComingSoon.vue";
import TeamForm from "@/components/users/teams/TeamForm.vue";
import WeTeamIcon from "@/components/we/WeTeamIcon.vue";
import WeHeroImageIcon from "@/components/we/WeHeroImageIcon.vue";
import WeShell from "@/components/we/WeShell.vue";
import NearbyMessagesSelector from "@/components/we/selector-views/NearbyMessagesSelector.vue";
import TeamMessagesSelector from "@/components/we/selector-views/TeamMessagesSelector.vue";
import DatafieldMessagesSelector from "@/components/we/selector-views/DatafieldMessagesSelector.vue";
import DirectMessagesSelector from "@/components/we/selector-views/DirectMessagesSelector.vue";

export const useWePanelStore = defineStore("we", () => {
  const secondaryPanelStore = useSecondaryPanelStore();
  const companyDetailStore = useCompanyDetailStore();
  const { companyIdParam, companyDataField } = storeToRefs(companyDetailStore);
  const contactDetailStore = useContactDetailStore();
  const { contactIdParam, contactDataField } = storeToRefs(contactDetailStore);
  const propertyDiagramStore = usePropertyDiagramStore();
  const {
    propertyIdParam,
    propertyDiagramPropertyDataFields,
    propertyDiagramPropertyIds,
  } = storeToRefs(propertyDiagramStore);
  const userStore = useUserStore();
  const { signedIn } = storeToRefs(userStore);
  const layoutStore = useWorkspaceLayoutStore();
  const { isDesktop, workspaceLayout } = storeToRefs(layoutStore);

  const nearby = ref(true);
  const regionPagyContainer = ref({
    data: [],
    pagy: null,
  });
  const regions = computed(() => regionPagyContainer.value.data);
  const regionsPagy = computed(() => regionPagyContainer.value.pagy);
  function patchRegion(region) {
    regionPagyContainer.value.data = _.unionBy([region], regions.value, "id");
  }

  const categories = ref(
    _.times(3, function (i) {
      return {
        id: i + 1,
        name: `Category ${i + 1}`,
        expanded: true,
      };
    }),
  );

  const channels = ref(
    _.times(15, function (i) {
      return {
        id: i + 1,
        categoryId: _.random(1, 3),
        contentType: "channel",
        name: `Channel ${i + 1}`,
      };
    }),
  );

  const threads = ref(
    _.times(8, function (i) {
      return {
        id: i + 1,
        contentType: "thread",
        channelId: _.random(1, 15),
        name: `Thread ${i + 1}`,
        isVisible: true,
        locked: false,
      };
    }),
  );

  const teams = ref([]);
  function dropTeam(team) {
    teams.value = teams.value.filter((t) => t.id !== team.id);
    changeView();
    activeOption.value = null;
  }
  function patchTeam(team) {
    teams.value = _.unionBy([team], teams.value, "id");
  }

  const expandedView = computed(
    () => isDesktop.value && workspaceLayout.value === "topAndBottom",
  );
  const rightEdgeComponent = ref(null);
  const mainContent = ref(null);
  
  const bottomEdgeComponent = ref(null);
  const bottomEdgeProps = ref({});
  
  function selectionIsActive(selectionObject) {
    if (!!mainContent.value && !!rightEdgeComponent.value) {
      // console.log("selectionIsActive", selectionObject, mainContent.value);
      if (_.isObject(mainContent.value)) {
        const regionMatch =
          !!mainContent.value.coordinates &&
          mainContent.value.id === selectionObject.id;
        const channelMatch =
          mainContent.value.contentType === "channel" &&
          selectionObject.contentType === "channel" &&
          mainContent.value.content.id === selectionObject.id;
        const threadMatch =
          mainContent.value.contentType === "thread" &&
          selectionObject.contentType === "thread" &&
          mainContent.value.content.id === selectionObject.id;
        const dmMatch =
          mainContent.value.contentType === "directMessage" &&
          mainContent.value.content.id === selectionObject;
        return regionMatch || channelMatch || threadMatch || dmMatch;
      } else {
        return selectionObject === mainContent.value;
      }
    } else {
      return false;
    }
  }
  function activeSelectionOptionClass(option) {
    return selectionIsActive(option) ? "bg-gray-300" : "bg-white";
  }

  const networkTab = ref("Online");
  const networkTabs = ref([
    { name: "Online", href: "" },
    { name: "All", href: "" },
    { name: "Pending", href: "" },
  ]);

  const defaultView = {
    component: markRaw(ComingSoon),
    props: {},
  };
  const lastView = ref({
    component: null,
    props: {},
  });
  const activeOption = ref(null);
  const activeView = ref(defaultView);
  function changeView(newView = defaultView) {
    lastView.value = activeView.value;
    activeView.value = newView;
    closeRightEdgePanel();
    closeBottomEdgePanel();
  }
  function closeRightEdgePanel() {
    rightEdgeComponent.value = null;
    mainContent.value = null;
  }
  function closeBottomEdgePanel() {
    bottomEdgeComponent.value = null;
    bottomEdgeProps.value = {};
  }
  function closeTakeoverPanel() {
    if (
      activeOption.value?.container &&
      activeOption.value?.takeover?.component
    ) {
      activeOption.value.takeover = {
        component: null,
        props: {},
      };
    }
  }
  function closeSecondaryContentPanel() {
    if (expandedView.value) {
      closeTakeoverPanel();
    } else {
      closeBottomEdgePanel();
    }
  }
  function openSecondaryContentPanel({ component, props }) {
    if (signedIn.value) {
      if (expandedView.value) {
        activeOption.value.takeover = {
          component: markRaw(component),
          props,
        };
      } else {
        bottomEdgeComponent.value = markRaw(component);
        bottomEdgeProps.value = props;
      }
    } else {
      secondaryPanelStore.promptToRegister();
    }
  }

  const dragging = ref(false);
  const instantUpload = ref(false);
  function instantUploadHandler() {
    if (dragging.value) {
      instantUpload.value = !instantUpload.value;
    } else {
      instantUpload.value = false;
    }
  }

  function optionIconClass(option) {
    return activeOption.value?.name === option.name
      ? "text-gray-700 bg-gray-300"
      : "text-gray-500 hover:bg-gray-200";
  }

  const nearbyMessagesTab = computed(() => {
    return nearby.value
      ? {
          name: "Nearby Intel",
          container: true,
          href: "",
          handler: () => {
            changeView({
              component: markRaw(WeShell),
              props: {
                selectionComponent: markRaw(NearbyMessagesSelector),
              },
            });
          },
          icon: MapPinIcon,
          iconProps: {},
          iconClass: (option) => {
            return optionIconClass(option);
          },
        }
      : null;
  });
  const companyPreviewTab = computed(() => {
    return companyIdParam.value && companyDataField.value
      ? {
          name: companyDataField.value.fieldContent.name,
          matchType: companyDataField.value.fieldContentType,
          matchId: companyDataField.value.fieldContentId,
          matchContent: companyDataField.value,
          container: true,
          previewMode: true,
          href: "",
          handler: () => {
            changeView({
              component: markRaw(WeShell),
              props: {
                selectionComponent: markRaw(DatafieldMessagesSelector),
              },
            });
          },
          icon: markRaw(WeHeroImageIcon),
          iconProps: { dataField: companyDataField.value },
          iconClass: (option) => {
            return optionIconClass(option);
          },
        }
      : null;
  });
  const contactPreviewTab = computed(() => {
    return contactIdParam.value && contactDataField.value
      ? {
          name: contactDataField.value.fieldContent.name,
          matchType: contactDataField.value.fieldContentType,
          matchId: contactDataField.value.fieldContentId,
          matchContent: contactDataField.value,
          container: true,
          previewMode: true,
          href: "",
          handler: () => {
            changeView({
              component: markRaw(WeShell),
              props: {
                selectionComponent: markRaw(DatafieldMessagesSelector),
              },
            });
          },
          icon: markRaw(WeHeroImageIcon),
          iconProps: { dataField: contactDataField.value },
          iconClass: (option) => {
            return optionIconClass(option);
          },
        }
      : null;
  });
  const detailPageTabs = computed(() => {
    return _.compact([companyPreviewTab.value, contactPreviewTab.value]);
  });
  const propertyDiagramPreviewTabs = computed(() => {
    if (
      propertyIdParam.value &&
      _.size(propertyDiagramPropertyDataFields.value) > 0
    ) {
      return _.map(
        propertyDiagramPropertyDataFields.value,
        function (dataField) {
          return {
            name: dataField.fieldContent.name,
            matchType: dataField.fieldContentType,
            matchId: dataField.fieldContentId,
            matchContent: dataField,
            container: true,
            previewMode: true,
            href: "",
            handler: () => {
              changeView({
                component: markRaw(WeShell),
                props: {
                  selectionComponent: markRaw(DatafieldMessagesSelector),
                },
              });
            },
            icon: markRaw(WeHeroImageIcon),
            iconProps: { dataField },
            iconClass: (option) => {
              return optionIconClass(option);
            },
          };
        },
      );
    } else {
      return [];
    }
  });

  watch(companyIdParam, () => {
    if (activeOption.value?.matchType === "Company" && !companyIdParam.value) {
      changeView();
      activeOption.value = null;
    }
  });
  watch(contactIdParam, () => {
    if (activeOption.value?.matchType === "Contact" && !contactIdParam.value) {
      changeView();
      activeOption.value = null;
    }
  });
  watch(
    propertyDiagramPropertyDataFields,
    () => {
      if (activeOption.value?.matchType === "Property") {
        if (
          !_.includes(
            propertyDiagramPropertyIds.value,
            activeOption.value.matchId,
          )
        ) {
          changeView();
          activeOption.value = null;
        }
      }
    },
    { deep: true },
  );

  const primaryNavigationOptions = computed(() => {
    const teamTabs = {
      order: 3,
      name: "teams",
      options: teams.value.map((team) => {
        return {
          name: team.name,
          matchType: "Team",
          matchId: team.id,
          matchContent: team,
          container: true,
          takeover: {
            component: null,
            props: {},
          },
          href: "",
          handler: () => {
            changeView({
              component: markRaw(WeShell),
              props: {
                selectionComponent: markRaw(TeamMessagesSelector),
              },
            });
          },
          icon: markRaw(WeTeamIcon),
          iconProps: { team },
          iconClass: () => {
            return activeOption.value?.matchType === "Team" &&
              activeOption.value?.matchId === team.id
              ? "text-gray-700 bg-gray-300"
              : "text-gray-500 hover:bg-gray-200";
          },
        };
      }),
    };
    const defaultMessagesTabs = _.concat(
      propertyDiagramPreviewTabs.value,
      detailPageTabs.value,
      _.compact([
        nearbyMessagesTab.value,
        {
          name: "Direct Messages",
          container: true,
          href: "",
          handler: () => {
            changeView({
              component: markRaw(WeShell),
              props: {
                selectionComponent: markRaw(DirectMessagesSelector),
              },
            });
          },
          icon: ChatBubbleOvalLeftIcon,
          iconProps: {},
          iconClass: (option) => {
            return optionIconClass(option);
          },
        },
      ]),
    );
    const rawTabs = [
      {
        order: 1,
        name: "headerActions",
        options: [
          {
            name: "Nearby",
            label: nearby.value ? "Nearby" : "All",
            container: false,
            href: "",
            handler: () => {
              nearby.value = !nearby.value;

              if (
                activeOption.value?.name === "Nearby Intel" &&
                !nearby.value
              ) {
                changeView();
                activeOption.value = null;
              }
            },
            icon: GlobeAmericasIcon,
            iconProps: {},
            iconClass: () => {
              return nearby.value
                ? "bg-emerald-400"
                : "text-gray-500 hover:bg-gray-200";
            },
          },
        ],
      },
      {
        order: 2,
        name: "defaultMessages",
        options: defaultMessagesTabs,
      },
      teams.value.length > 0 ? teamTabs : null,
      {
        order: 4,
        name: "footerOptions",
        options: [
          {
            name: "Add a Team",
            container: false,
            href: "",
            handler: () => {
              if (signedIn.value) {
                changeView({
                  component: markRaw(TeamForm),
                  props: { teamId: null },
                });
              } else {
                secondaryPanelStore.promptToRegister();
              }
            },
            icon: PlusCircleIcon,
            iconProps: {},
            iconClass: () => {
              return "text-gray-500 hover:bg-gray-200";
            },
          },
          {
            name: "Discover",
            container: true,
            href: "",
            handler: () => {
              changeView();
            },
            icon: SparklesIcon,
            iconProps: {},
            iconClass: (option) => {
              return activeOption.value?.name === option.name
                ? "text-white bg-indigo-600"
                : "text-gray-500 hover:bg-gray-200";
            },
          },
        ],
      },
    ];

    return _.orderBy(_.compact(rawTabs), ["order"], ["asc"]);
  });

  async function fetchTeams() {
    if (signedIn.value) {
      const teamsResponse = await api.get(`teams`);

      if (teamsResponse?.data) {
        teams.value = teamsResponse.data;
      }
    }
  }

  function cleanUrl(url) {
    return url.replace("/api/v1/", "");
  }

  async function fetchRegions() {
    if (signedIn.value) {
      const regionsResponse = await api.get(`geography_regions`);

      if (regionsResponse?.data) {
        regionPagyContainer.value = regionsResponse.data;
      }
    }
  }

  async function loadRegions(paginationState) {
    if (signedIn.value && regionsPagy.value.next) {
      const endpoint = cleanUrl(regionsPagy.value.next_url);
      try {
        api.get(endpoint).then((json) => {
          const { data, pagy } = json.data;

          regionPagyContainer.value.data = _.concat(
            regionPagyContainer.value.data,
            data,
          );
          regionPagyContainer.value.pagy = pagy;
          if (data.length < 25) paginationState.complete();
          else {
            paginationState.loaded();
          }
        });
      } catch (error) {
        paginationState.error();
      }
    } else {
      paginationState.complete();
    }
  }

  async function hideRegion(region) {
    const hideResponse = await api.patch(
      `geography_region_hidings/${region.id}`,
    );

    if (hideResponse?.data) {
      regionPagyContainer.value.data = regionPagyContainer.value.data.filter(
        (r) => r.id !== region.id,
      );
    }
  }

  async function leaveDirectMessage(directMessage) {
    console.log("TODO: Leave DM");
  }

  return {
    nearby,
    regionPagyContainer,
    regions,
    regionsPagy,
    fetchRegions,
    loadRegions,
    hideRegion,
    patchRegion,
    categories,
    channels,
    threads,
    leaveDirectMessage,
    primaryNavigationOptions,
    activeOption,
    activeView,
    lastView,
    rightEdgeComponent,
    mainContent,
    selectionIsActive,
    activeSelectionOptionClass,
    bottomEdgeComponent,
    bottomEdgeProps,
    networkTabs,
    networkTab,
    expandedView,
    nearbyMessagesTab,
    dragging,
    instantUpload,
    instantUploadHandler,
    changeView,
    fetchTeams,
    patchTeam,
    dropTeam,
    closeRightEdgePanel,
    closeBottomEdgePanel,
    closeTakeoverPanel,
    closeSecondaryContentPanel,
    openSecondaryContentPanel,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWePanelStore, import.meta.hot));
}
