<template>
  <div v-if="jobs.pagy" class="p-2 flex flex-col">
    <DataInputPartnershipButton
      v-if="
        jobs.data.length > 0 && emailVerified && !activeEasyDataInputPayloadItem
      "
      class="self-end mb-2"
    />
    <div v-if="jobs.data.length > 0" class="-my-2 -mx-4 overflow-x-auto">
      <div class="inline-block min-w-full py-2 align-middle px-4">
        <div
          class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg"
        >
          <table class="min-w-full table-fixed divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="relative whitespace-nowrap py-3.5 pl-4 pr-3"
                >
                  <span class="sr-only">Expand</span>
                </th>
                <th
                  scope="col"
                  class="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Raw Data
                </th>
                <th
                  scope="col"
                  class="pl-3 pr-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Date
                </th>
              </tr>
            </thead>
            <template v-if="rowsDisplayable">
              <EasyDataInputJobTableRow
                v-for="(job, index) in jobs.data"
                :key="job.id"
                :job="job"
                :data-test="`easy-data-input-job-table-row-${index}`"
              />
            </template>
            <PaginationFooter
              v-if="jobs.pagy.prev || jobs.pagy.next"
              :pagination-meta="jobs.pagy"
              :table-meta="{ colspan: 4 }"
              @previous="fetchJobs"
              @next="fetchJobs"
              @page="fetchJobs"
              @infinite-fetch="loadJobs"
            />
          </table>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col text-center items-center justify-center max-w-lg mx-auto"
      data-test="my-contributions-empty-state"
    >
      <BoltIcon class="mt-8 mx-auto h-12 w-12 text-gray-400" />
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        No Data Input Job Referrals
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Data you want added to Tower Hunt will appear here. Contributors earn
        revenue!
      </p>
      <DataInputEasyButton
        v-if="!activeEasyDataInputPayloadItem"
        class="mt-3"
      />
      <DataInputPartnershipButton
        v-if="emailVerified && !activeEasyDataInputPayloadItem"
        class="mt-3"
      />
    </div>
  </div>
</template>

<script setup>
import { useDataInputJobStatusesChannelStore } from "@/stores/dataInputJobStatusesChannel";
import EasyDataInputJobTableRow from "@/components/crowdsourcing/contributions/EasyDataInputJobTableRow.vue";
import PaginationFooter from "@/components/PaginationFooter.vue";
import DataInputEasyButton from "@/components/DataInputEasyButton.vue";
import { BoltIcon } from "@heroicons/vue/24/outline";
import { ref, onMounted } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import { createChannel } from "@/channels/channel";
import _ from "lodash";
import DataInputPartnershipButton from "../../DataInputPartnershipButton.vue";

const userStore = useUserStore();
const { currentUser, emailVerified, activeEasyDataInputPayloadItem } =
  storeToRefs(userStore);
const dataInputJobStatusesChannelStore = useDataInputJobStatusesChannelStore();
const { DataInputJobStatusesChannel } = storeToRefs(
  dataInputJobStatusesChannelStore,
);

const jobs = ref({
  data: [],
  pagy: null,
});
const rowsDisplayable = ref(false);

onMounted(() => {
  subscribeToChannel();
  fetchJobs();
});

function subscribeToChannel() {
  if (!DataInputJobStatusesChannel.value) {
    DataInputJobStatusesChannel.value = createChannel(
      {
        channel: "DataInputJobStatusesChannel",
        userId: currentUser.value.id,
      },
      {
        connected() {},
        received() {
          fetchJobs();
        },
      },
    );
  }
}

async function fetchJobs(paginationUrl = null) {
  jobs.value = ref({
    data: [],
    pagy: null,
  });
  rowsDisplayable.value = false;

  api.get(paginationUrl || `easy_data_input_jobs`).then((json) => {
    jobs.value = json.data;
    rowsDisplayable.value = true;
  });
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadJobs = async ($state) => {
  if (jobs.value.pagy?.next) {
    const endpoint = cleanUrl(jobs.value.pagy?.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        jobs.value.data = _.concat(jobs.value.data, data);
        jobs.value.pagy = pagy;
        if (data.length < 10) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};
</script>
