<template>
  <ol class="h-full overflow-y-auto space-y-3">
    <li v-for="category in categories" :key="category.id">
      <ChannelCategory :category="category" />
      <ChannelListElement
        v-for="channel in channelsFor(category)"
        :key="channel"
        :channel="channel"
      />
    </li>
  </ol>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import ChannelListElement from "@/components/we/channels-format/ChannelListElement.vue";
import ChannelCategory from "./ChannelCategory.vue";

const wePanelStore = useWePanelStore();
const { categories, channels } = storeToRefs(wePanelStore);

function channelsFor(category) {
  return channels.value.filter((channel) => channel.categoryId === category.id);
}
</script>
