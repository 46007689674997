// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer } from "@rails/actioncable";

let consumer;

export const createChannel = (...args) => {
  if (!consumer) {
    consumer = createConsumer();
  }

  return consumer.subscriptions.create(...args);
};

export const removeChannel = (subscription) => {
  if (consumer && subscription) {
    consumer.subscriptions.remove(subscription);
  } else {
    console.log("Subscription or consumer is null, cannot remove");
  }
}
