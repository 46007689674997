<template>
  <div class="py-2">
    <h3 class="font-medium leading-6 text-gray-900">Identity verification</h3>
    <div class="mt-1 max-w-xl text-sm text-gray-500">
      <p v-if="adminMode">Masquerading admins not allowed.</p>
      <p v-else>To continue, first verify it's you.</p>
    </div>
    <form
      v-if="!adminMode"
      @submit.prevent="authenticate"
      class="mt-3"
      :class="
        workspaceLayout === 'sideBySide' ? '' : 'flex items-center space-x-2'
      "
      :data-test="`${inputId}-step-up-form`"
    >
      <div
        class="w-full"
        :class="workspaceLayout === 'sideBySide' ? '' : 'max-w-xs'"
      >
        <label for="password" class="sr-only">Password</label>
        <input
          v-focus
          v-model="password"
          type="password"
          name="password"
          :id="`${inputId}-password`"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
          placeholder="Enter your password"
          :data-test="`${inputId}-step-up-password`"
        />
      </div>
      <div class="flex items-center space-x-2">
        <button
          v-if="cancellable"
          @click="$emit('cancel')"
          type="button"
          class="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white p-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-sm"
          :class="
            workspaceLayout === 'sideBySide' ? 'mt-3 w-full' : 'mt-0 w-auto'
          "
        >
          Cancel
        </button>
        <button
          type="submit"
          class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          :class="
            workspaceLayout === 'sideBySide' ? 'mt-3 w-full' : 'mt-0 w-auto'
          "
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useUserStore } from "@/stores/user";
import { useAuthStore } from "@/stores/auth";
import { ref } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";

defineProps(["inputId", "cancellable"]);
const emit = defineEmits(["cancel", "authenticated"]);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { currentUser, adminMode } = storeToRefs(userStore);
const authStore = useAuthStore();

const password = ref("");

async function authenticate() {
  if (!adminMode.value) {
    const payload = {
      email: currentUser.value.email,
      password: password.value,
    };
    api.post("authenticate", payload).then(
      () => {
        emit("authenticated");
      },
      async (failure) => {
        console.log(failure);
        authStore.signOut();
      },
    );
  }
}
</script>
