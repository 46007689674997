<template>
  <DataField
    calculation-name="price_per_area"
    :intercept-calculation="interceptCalculation"
    :fully-licensed="fullyLicensed"
    :fetching-prop-data="fetchingBundleData"
    :fetched-all-fields-data="combinedAllFieldsData"
    :calculation-value="pricePerAreaData?.perAreaString"
    :calculation-option-data="pricePerAreaData"
    :calculation-content-type="contentType"
    :calculation-content-ids="contentIds"
    :calculation-is-survey="false"
    :text-classes="textClasses"
    :analyze="context === 'analyze'"
    @unlocked="refetch"
  />
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import { onMounted, ref, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useCalculationFieldsStore } from "@/stores/calculationFields";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useNotificationsStore } from "@/stores/notifications";
import pricePerAreaCalc from "@/components/analyze/calculations/pricePerArea";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "contentType",
  "contentIds",
  "decoratingContentType",
  "decoratingContentId",
  "context",
  "textClasses",
]);
const emit = defineEmits(["refetch"]);

const fetchingBundleData = ref(false);

const calculationFieldsStore = useCalculationFieldsStore();
const notificationsStore = useNotificationsStore();
const { asOfMilliseconds } = storeToRefs(useTimeTravelStore());
const analyzePanelStore = useAnalyzePanelStore();
const { calculationOptions, timeMismatch } = storeToRefs(analyzePanelStore);
const dealBuilderStore = useDealBuilderStore();

const targetKey = computed(() => {
  return `${props.contentType}_${props.contentIds.join("_")}`;
});
const contentKey = computed(() => {
  if (props.decoratingContentType && props.decoratingContentId) {
    return `${props.decoratingContentType}${props.decoratingContentId}`;
  } else {
    return null;
  }
});
const calculationDataFromStore = computed(() => {
  if (contentKey.value) {
    switch (props.context) {
      case "deal-builder":
        return dealBuilderStore.calculationDataByKey(contentKey.value);
      case "analyze":
        return analyzePanelStore.contentCalculationDataByKey(contentKey.value);
      default:
        return {};
    }
  } else {
    return {};
  }
});

const allFieldsData = ref(null);
const rawCalculationData = computed(() =>
  _.get(allFieldsData.value, "calculationFields", []),
);

const hasSizeUseData = computed(() => {
  return _.some(
    calculationDataFromStore.value?.sizesUses || {},
    function (fields) {
      return !_.isEmpty(fields);
    },
  );
});
const hasOutputs = computed(() => {
  return _.size(combinedAllFieldsData.value.calculationFields) > 0;
});
const interceptCalculation = computed(() => {
  return combinedCalculationData.value?.interceptableFields?.length > 0;
});

const combinedAllFieldsData = computed(() => {
  return {
    calculationFields: _.unionBy(
      _.get(allFieldsData.value, "calculationFields", []),
      _.get(
        calculationDataFromStore.value,
        "allFieldsData.calculationFields",
        [],
      ),
      "localId",
    ),
    freeCount: _.sum([
      _.get(allFieldsData.value, "freeCount", 0),
      _.get(calculationDataFromStore.value, "allFieldsData.freeCount", 0),
    ]),
    freeIds: _.union(
      _.get(allFieldsData.value, "freeIds", 0),
      _.get(calculationDataFromStore.value, "allFieldsData.freeIds", 0),
    ),
    licenseableCount: _.sum([
      _.get(allFieldsData.value, "licenseableCount", 0),
      _.get(
        calculationDataFromStore.value,
        "allFieldsData.licenseableCount",
        0,
      ),
    ]),
    licenseableIds: _.union(
      _.get(allFieldsData.value, "licenseableIds", 0),
      _.get(calculationDataFromStore.value, "allFieldsData.licenseableIds", 0),
    ),
    myIds: _.union(
      _.get(allFieldsData.value, "myIds", 0),
      _.get(calculationDataFromStore.value, "allFieldsData.myIds", 0),
    ),
    openSourceCount: _.sum([
      _.get(allFieldsData.value, "openSourceCount", 0),
      _.get(calculationDataFromStore.value, "allFieldsData.openSourceCount", 0),
    ]),
    openSourceIds: _.union(
      _.get(allFieldsData.value, "openSourceIds", 0),
      _.get(calculationDataFromStore.value, "allFieldsData.openSourceIds", 0),
    ),
    price: _.sum([
      _.toNumber(_.get(allFieldsData.value, "price", 0)),
      _.toNumber(
        _.get(calculationDataFromStore.value, "allFieldsData.price", 0),
      ),
    ]),
    safezoneCount: _.sum([
      _.get(allFieldsData.value, "safezoneCount", 0),
      _.get(calculationDataFromStore.value, "allFieldsData.safezoneCount", 0),
    ]),
    safezoneIds: _.union(
      _.get(allFieldsData.value, "safezoneIds", 0),
      _.get(calculationDataFromStore.value, "allFieldsData.safezoneIds", 0),
    ),
    unlicensedIds: _.union(
      _.get(allFieldsData.value, "unlicensedIds", 0),
      _.get(calculationDataFromStore.value, "allFieldsData.unlicensedIds", 0),
    ),
  };
});

const combinedCalculationData = computed(() => {
  const valuationInterceptableFields = rawCalculationData.value.filter(
    (df) => df.meteringStatus === "prompt_to_subscribe",
  );
  const sizeUseInterceptableFields = _.get(
    calculationDataFromStore.value,
    "sizesUses.interceptableFields",
    [],
  );
  const interceptableFields = _.unionBy(
    valuationInterceptableFields,
    sizeUseInterceptableFields,
    "localId",
  );
  return _.merge(
    {},
    {
      valuationDataFields: rawCalculationData.value.filter(
        (df) => df.fieldContentType === "Valuation",
      ),
      valueDataFields: rawCalculationData.value.filter(
        (df) =>
          df.decoratingContentType === "Valuation" && df.fieldName === "value",
      ),
    },
    _.get(calculationDataFromStore.value, "sizesUses", {}),
    { interceptableFields },
  );
});

const unlicensedCount = computed(() => {
  const ids = _.get(combinedAllFieldsData.value, "unlicensedIds");

  if (_.isArray(ids)) {
    return ids.length;
  } else {
    return undefined;
  }
});
const fullyLicensed = computed(
  () => _.isNumber(unlicensedCount.value) && unlicensedCount.value === 0,
);

const pricePerAreaData = computed(() => {
  if (
    hasOutputs.value &&
    hasSizeUseData.value &&
    fullyLicensed.value &&
    !interceptCalculation.value
  ) {
    return pricePerAreaCalc(
      combinedCalculationData.value,
      calculationOptions.value,
    );
  } else {
    return null;
  }
});

watch(timeMismatch, (val) => {
  if (val) fetchAllFields();
});
watch(targetKey, () => {
  fetchAllFields();
});

onMounted(() => {
  fetchAllFields();
});

function refetch() {
  emit("refetch", "all");
  fetchAllFields();
}

async function fetchAllFields() {
  try {
    allFieldsData.value = null;
    let calculationFields = null;
    fetchingBundleData.value = true;

    calculationFields = await fetchBundleData();

    if (calculationFields?.data) {
      const payload = {
        bundleFieldIds: calculationFields.data.map(({ localId }) => localId),
        bundle: "calculation",
      };
      const bundleDataResponse = await api.post(
        `crowdsourced_data_field_calculation_bundles`,
        payload,
      );
      allFieldsData.value = bundleDataResponse?.data;
      allFieldsData.value.calculationFields = calculationFields.data;
    }
  } catch (error) {
    console.error(error);
    notificationsStore.addNotification("anErrorOccurred");
  } finally {
    fetchingBundleData.value = false;
  }
}

const calculationKey = computed(() => `price_per_area-${props.contentType}-${props.contentIds}-${asOfMilliseconds.value}`)

async function fetchBundleData() {
  const payload = {
    contentType: props.contentType,
    contentIds: props.contentIds,
    calculationName: "price_per_area",
    bundle: "calculation",
    asOf: asOfMilliseconds.value,
  };

  const response = await calculationFieldsStore.calculationDataInitialFetch({
    payload,
    key: calculationKey.value,
    endpoint: `calculation_data_fields`,
    calculationName: payload.calculationName,
    paginationCount: 10,
  });

  return response;
}
</script>
