<template>
  <transition
    enter-active-class="transform ease-out duration-300 transition"
    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-active-class="transition ease-in duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="show"
      class="flex-shrink-0 pointer-events-auto w-full max-w-lg overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/15"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <CloudArrowDownIcon
              class="h-6 w-6 text-teal-500"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900">
              {{ paginationTypeLabel
              }}<template v-if="keyType === 'calculation'"
                >&nbsp;({{ contentType }})</template
              >
              loading
            </p>
            <div v-if="fetchProgress && !loadingComplete" class="mt-1">
              <ProgressBar
                :value="fetchProgress"
                pt:root="h-auto bg-teal-200 rounded-md"
                :pt:value="
                  fetchProgress == 100
                    ? 'bg-teal-600 rounded-md'
                    : 'bg-teal-600 rounded-l-md'
                "
                pt:label="text-teal-100 text-[10px] w-full text-center truncate"
                >{{ fetchLabel }}</ProgressBar
              >
            </div>
            <p v-else-if="loadingComplete" class="mt-1 text-sm text-gray-500">
              Datapoints finished loading. This notification will disappear
              shortly.
            </p>
            <p v-else class="mt-1 text-sm text-gray-500">
              Awaiting initial loading status...
            </p>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button
              type="button"
              @click="show = false"
              class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span class="sr-only">Close</span>
              <XMarkIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import ProgressBar from "primevue/progressbar";
import { useCalculationFieldsStore } from "@/stores/calculationFields";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useNearbyCompaniesStore } from "@/stores/nearbyCompanies";
import { onMounted, ref, computed, watch } from "vue";
import { CloudArrowDownIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import _ from "lodash";

const props = defineProps(["autoHideSeconds", "paginationKey", "keyType"]);

const calculationFieldsStore = useCalculationFieldsStore();
const timeTravelStore = useTimeTravelStore();
const contactDetailStore = useContactDetailStore();
const companyDetailStore = useCompanyDetailStore();
const nearbyCompaniesStore = useNearbyCompaniesStore();

const show = ref(true);
const loadingComplete = ref(false);

const fetchProgress = computed(() => {
  switch (props.keyType) {
    case "calculation":
      return calculationFieldsStore.fetchProgressFor(props.paginationKey);
    case "timeline":
      return timeTravelStore.fetchProgressFor(props.paginationKey);
    case "nearbyCompanies":
      return nearbyCompaniesStore.fetchProgressFor(props.paginationKey);
    case "companyDetail":
      return companyDetailStore.fetchProgress();
    case "contactDetail":
      return contactDetailStore.fetchProgress();
    default:
      return null;
  }
});
const fetchLabel = computed(() => {
  switch (props.keyType) {
    case "calculation":
      return calculationFieldsStore.fetchLabelFor(props.paginationKey);
    case "timeline":
      return timeTravelStore.fetchLabelFor(props.paginationKey);
    case "nearbyCompanies":
      return nearbyCompaniesStore.fetchLabelFor(props.paginationKey);
    case "companyDetail":
      return companyDetailStore.fetchLabel();
    case "contactDetail":
      return contactDetailStore.fetchLabel();
    default:
      return null;
  }
});
const keyParts = computed(() => {
  switch (props.keyType) {
    case "calculation":
      return _.split(props.paginationKey, "-");
    case "timeline":
      return _.split(props.paginationKey, "_");
    default:
      return [];
  }
});
const paginationType = computed(() => _.head(keyParts.value));
const paginationTypeLabel = computed(() => {
  switch (props.keyType) {
    case "calculation": {
      switch (paginationType.value) {
        case "price_per_area":
          return "Price per area";
        case "investment_volume":
          return "Investment volume";
        case "sizes_uses":
          return "Sizes & uses";
        case "building_count":
          return "Building count";
        default:
          return null;
      }
    }
    case "timeline": {
      switch (paginationType.value) {
        case "dated":
          return "Dated timeline events";
        case "undated":
          return "Undated timeline events";
        default:
          return null;
      }
    }
    case "nearbyCompanies":
      return "Nearby companies";
    case "companyDetail":
      return "Company events";
    case "contactDetail":
      return "Contact events";
    default:
      return null;
  }
});
const contentType = computed(() => keyParts.value[1]);

watch(fetchProgress, (val, oldVal) => {
  if ((oldVal && !val) || val == 100) {
    if (props.autoHideSeconds > 1000) {
      loadingComplete.value = true;
      setTimeout(() => {
        show.value = false;
      }, 3000);
    }
  }
});

onMounted(() => {
  if (props.autoHideSeconds) {
    setTimeout(() => {
      show.value = false;
    }, props.autoHideSeconds * 1000);
  }
});
</script>
