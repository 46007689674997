<template>
  <section class="h-full flex flex-col" aria-labelledby="occupier-needs">
    <ContentHeader :no-margin="true">
      <template v-slot:title>Occupier Needs</template>
      <template v-if="context !== 'table-row'" v-slot:description
        >Hunting for space? These datapoints help describe what is
        needed.</template
      >
    </ContentHeader>

    <div class="flex flex-1">
      <!-- Narrow sidebar-->
      <nav
        aria-label="Sidebar"
        class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
      >
        <div class="relative flex flex-col space-y-2">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            @click.prevent="selectedTab = tab"
            :href="tab.href"
            v-tooltip="tab.name"
            :class="[
              selectedTab.name === tab.name
                ? 'text-gray-700 bg-gray-300'
                : 'text-gray-500 hover:bg-gray-200',
              'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
            ]"
            class=""
            :data-test="`${_.kebabCase(tab.name)}-nav`"
          >
            <span class="sr-only">{{ tab.name }}</span>
            <svg
              :class="selectedTab.name === tab.name ? '' : ''"
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                :d="tab.svgPath"
              />
            </svg>
          </a>
        </div>
      </nav>

      <div class="flex-grow">
        <component
          :is="selectedTab.component"
          v-bind="selectedTabProperties"
          @refetch="refetch"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import OccupierTiming from "@/components/crowdsourcing/OccupierTiming.vue";
import OccupierSpace from "@/components/crowdsourcing/OccupierSpace.vue";
import OccupierTriggeringSpaces from "@/components/crowdsourcing/OccupierTriggeringSpaces.vue";
import { ref, computed, markRaw } from "vue";
import _ from "lodash";

const props = defineProps(["dataField", "contentDataField", "context"]);
const emit = defineEmits(["refetch"]);
const tabs = ref([
  {
    name: "Space",
    href: "",
    svgPath:
      "M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z",
    component: markRaw(OccupierSpace),
  },
  {
    name: "Triggering Space Usages",
    href: "",
    svgPath:
      "M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244",
    component: markRaw(OccupierTriggeringSpaces),
  },
  {
    name: "Timing",
    href: "",
    svgPath: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z",
    component: markRaw(OccupierTiming),
  },
]);
const selectedTab = ref({
  name: "Space",
  href: "",
  svgPath:
    "M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z",
  component: markRaw(OccupierSpace),
});

const selectedTabProperties = computed(() => {
  return {
    dataField: props.dataField,
    contentDataField: props.contentDataField,
    context: props.context,
  };
});

function refetch() {
  emit("refetch");
}
</script>
